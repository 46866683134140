export const createEpisode = (data: any, showId: string|undefined, isFileChanged:boolean, duration: string|undefined ) => {
    let episode:any = {
        episodeNumber: data.episodeNumber ?? "0",
        title: data.title.trim(),
        description: data.description.trim(),
        duration: duration ?? "",
        // displayOrder: (data.displayOrder ?? "0").toString(),
        picture: data.thumbnail ?? "",
        // isTopPick: isTopPick,
        authorIdList: getAuthorIdList(data.author),
    }

    if(data.id) {
        episode.id = (data.id).toString();
        episode.isFileChanged =  isFileChanged ? "1" : "2";
    }
    else{
        episode.series_id = showId;
    }

    if (data.file_name) {
        episode.file_name = ((data.file_name.name).replaceAll(" ","")).replaceAll("+", "_");
        episode.mime_type = data.file_name.type;
    }

    return episode;
}


const getAuthorIdList = (author:any) => {
    let authorList:any = [];

    for (let i = 0; i < author.length; i++) {
        authorList.push((author[i].id).toString());
    }
    return authorList;
}


export const mediaAdd = (mediaToken:string, fileName:string, fileKey:string) => {
    let Add:any = {
        "mediaToken":mediaToken,
        "file_name":fileName,
        "file_key":fileKey
    }

    return Add;
}
