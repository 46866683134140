import { Reducer } from 'react';
import { UPDATE_USER_CONTENT_DATA } from '../../constants';
import { DashboardActions, dashboardState } from './types';

export const initialState: dashboardState = {
    userContent: [],
};

export const dasboardReducer: Reducer<dashboardState, DashboardActions> = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER_CONTENT_DATA:
            return {
                ...state,
                userContent: [...(action?.data || [])],
            };

        default:
            return state;
    }
};
