import { Reducer } from 'react';
import { UPDATE_CONSUMER_SPEND_LIST_CONTENT_DATA, UPDATE_CONSUMER_BALANCE_LIST_CONTENT_DATA } from '../../constants';
import { consumerSpendListActions, spendListState, balanceListState, consumerBalanceListActions } from './types';

export const initialState: spendListState = {
    spendListContent: []
};

export const initialBalanceState: balanceListState = {
    balanceListContent: []
};

export const consumerSpendListReducer: Reducer<spendListState, consumerSpendListActions> = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CONSUMER_SPEND_LIST_CONTENT_DATA:
            return {
                ...state,
                spendListContent: [...(action?.data || [])],
            };

        // case ADD_CREATOR_LIST_CONTENT_DATA:
        //     return {
        //         ...state,
        //         // creatorListContent: [...state.creatorListContent, ...(action?.data || [])],
        //         creatorListContent: state.creatorListContent.concat(action?.data),
        //     };

        // case UPDATE_CREATOR_ACCOUNT_STATUS:
        //     return {
        //         ...state,
        //         creatorListContent: state.creatorListContent.map(creator => {
        //             if (creator.id === action.data) {
        //                 creator.status = !creator.status
        //                 return {...creator}
        //             };
        //             return creator;
        //         })
        //     }

        // case SAVE_CREATOR_SEARCH_TEXT:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         searchedValue : action.data
        //     };

        // case SAVE_CREATOR_CURRENT_PAGE:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         currentPage : action.data
        //     };

        // case SAVE_TOTAL_CREATORS:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         totalCreators : action.data
        //     };

        // case RESET:
        //     return {
        //         ...initialState //Always return the initial state
        //     };

        default:
            return state;
    }
};


export const consumerBalanceListReducer: Reducer<balanceListState, consumerBalanceListActions> = (state = initialBalanceState, action) => {
    switch (action.type) {
        case UPDATE_CONSUMER_BALANCE_LIST_CONTENT_DATA:
            return {
                ...state,
                balanceListContent: [...(action?.data || [])],
            };

        // case ADD_CREATOR_LIST_CONTENT_DATA:
        //     return {
        //         ...state,
        //         // creatorListContent: [...state.creatorListContent, ...(action?.data || [])],
        //         creatorListContent: state.creatorListContent.concat(action?.data),
        //     };

        // case UPDATE_CREATOR_ACCOUNT_STATUS:
        //     return {
        //         ...state,
        //         creatorListContent: state.creatorListContent.map(creator => {
        //             if (creator.id === action.data) {
        //                 creator.status = !creator.status
        //                 return {...creator}
        //             };
        //             return creator;
        //         })
        //     }

        // case SAVE_CREATOR_SEARCH_TEXT:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         searchedValue : action.data
        //     };

        // case SAVE_CREATOR_CURRENT_PAGE:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         currentPage : action.data
        //     };

        // case SAVE_TOTAL_CREATORS:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         totalCreators : action.data
        //     };

        // case RESET:
        //     return {
        //         ...initialState //Always return the initial state
        //     };

        default:
            return state;
    }
};
