import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, GridRowParams } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { allGiftSpendListContentDataModifiedObj } from '../../data/mappers/allTransactions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Console } from 'console';
import { useNavigate } from 'react-router-dom';


export function SortedDescendingIcon() {
    return <ExpandMoreIcon className="icon" />;
  }

  export function SortedAscendingIcon() {
    return <ExpandLessIcon className="icon" />;
  }


function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{mr: 2}} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}

// sender:any
// type: string
// runs: string
// creator:any
// podcast: string
// date: string


const durationCalculate = (x) => {
    var durationFinal = "-";
    let duration:number = parseInt(x);
    if (duration >= 0){
        if (duration > 61){
            let durationMinutes= parseInt((duration/60).toString());
            let seconds = (duration%60).toString();
            if (seconds){
                durationFinal =  durationMinutes + " min " + seconds + " sec"
            }
            else{
                durationFinal =  durationMinutes + " min "
            }
        }else{
            durationFinal =  duration.toString() + " sec"
        }
    }
    return durationFinal
}

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 0, hide: true },
    { field: 'receiveTo',
    headerName: 'Gifted to',
    type: 'string',
    minWidth: 290,
    flex: 1.5,
    align: 'left',headerAlign: 'left',
    valueGetter: (params) => params.row.receiveTo.title,
    renderCell: (params) => (
        <>
          <Link to={
              (params.row.type === "Podcast") ?
              `/admin/consumer/show/episode/detail/${params.row.receiveTo.id}`
              : (params.row.type === "Creator") ?
              `/admin/creators/${params.row.receiveTo.id}`
              :
              `/admin/match/${params.row.receiveTo.id}`}

          style={{
              display: 'inline-block',
              maxWidth: '100%',
              flexShrink: 0,
              width: '100%',
              }}>
              <span style={{
              whiteSpace: 'nowrap',
              display: 'inline-block',
              maxHeight: 'auto',
              width: 'calc(100% - 10px)',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
          }} >{params.row.receiveTo.title}</span>
          </Link>

      </>
      )
    },

    { field: 'runs', headerName: 'Runs', type: 'number', minWidth: 100,align: 'left',headerAlign: 'left', flex: 1 },
    { field: 'type', headerName: 'Type', type: 'string', minWidth: 150, align: 'left',headerAlign: 'left',flex: 1 },
    { field: 'duration', headerName: 'Frequent Interval', type: 'string', minWidth: 200, flex: 1, align: 'left',headerAlign: 'left',
    renderCell: (params) => (
        <>
            <span>{durationCalculate(params.row.duration)}</span>
        </>
      )
    },



    // { field: 'creator',
    //   headerName: 'Creator',
    //   type: 'string',
    //   width: 180,
    //   renderCell: (params: GridRenderCellParams<Date>) => (
    //     <>
    //         {
    //             params.row.creator.id
    //             ?
    //             <Link to={`/admin/creators/${params.row.creator.id}`}
    //             style={{
    //                 display: 'inline-block',
    //                 maxWidth: '100%',
    //                 flexShrink: 0,
    //                 width: '100%',
    //               }}
    //               >
    //                 <span style={{
    //                 whiteSpace: 'nowrap',
    //                 display: 'inline-block',
    //                 maxHeight: 'auto',
    //                 width: 'calc(100% - 10px)',
    //                 textOverflow: 'ellipsis',
    //                 overflow: 'hidden',
    //               }} >{params.row.creator.fullName}</span>
    //             </Link>
    //         :
    //         <span>{params.row.creator.fullName}</span>
    //         }
    //     </>
    //     ),
    // },
    // { field: 'podcast',
    // headerName: 'Podcast',
    // type: 'string',
    // width: 240,
    // renderCell: (params: GridRenderCellParams<Date>) => (
    //   <>
    //       {
    //           params.row.podcast.id ?
    //           <Link to={`/admin/all/gift/spend/show/episode/detail/${params.row.podcast.id}`}
    //           style={{
    //             display: 'inline-block',
    //             maxWidth: '100%',
    //             flexShrink: 0,
    //             width: '100%',
    //           }}
    //           >
    //               <span style={{
    //                 whiteSpace: 'nowrap',
    //                 display: 'inline-block',
    //                 maxHeight: 'auto',
    //                 width: 'calc(100% - 10px)',
    //                 textOverflow: 'ellipsis',
    //                 overflow: 'hidden',
    //               }}>{params.row.podcast.title}</span>
    //           </Link>
    //           :
    //           <span>{params.row.podcast.title}</span>
    //       }
    //   </>
    //   ),
    // },
    //   {
    //     field: 'stream',
    //   headerName: 'Stream',
    //   type: 'string',
    //   width: 180,
    //   renderCell: (params: GridRenderCellParams<Date>) => (
    //       <>
    //         {
    //             params.row.stream.id ?
    //             <Link to={`/admin/match/${params.row.stream.id}`}
    //             style={{
    //               display: 'inline-block',
    //               maxWidth: '100%',
    //               flexShrink: 0,
    //               width: '100%',
    //             }}
    //             >
    //                 <span style={{
    //                   whiteSpace: 'nowrap',
    //                   display: 'inline-block',
    //                   maxHeight: 'auto',
    //                   width: 'calc(100% - 10px)',
    //                   textOverflow: 'ellipsis',
    //                   overflow: 'hidden',
    //                 }}>{params.row.stream.title}</span>
    //             </Link>
    //             :
    //             <span>{'-'}</span>
    //         }
    //     </>
    //     ),
    //  },
    // { field: 'package',
    //   headerName: 'Package',
    //   type: 'string',
    //   width: 150,
    //   renderCell: (params: GridRenderCellParams<Date>) => (
    //     <>
    //         {
    //             params.row.package.id
    //             ?
    //             <Link to={`/admin/package/${params.row.package.id}`}>
    //                 <span>{params.row.package.title}</span>
    //             </Link>
    //         :
    //         <span>{params.row.package.title}</span>
    //         }
    //     </>
    //     ),
    // },
    // {
    //     field: 'view',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'left',
    //     headerAlign: 'left',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'view-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <Link to={`/admin/package/${params.row.id}`}>
    //             {/* <button type="button">View</button> */}
    //             <span><ArrowRightAltIcon/></span>

    //         </Link>
    //     ),
    // },
];


interface userContentDataTableProps {
    allGiftSpendListContent: Array<allGiftSpendListContentDataModifiedObj>;
}

export default function AllGiftSpendListDataTable({ allGiftSpendListContent }: userContentDataTableProps) {
    let navigate = useNavigate();

    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState<Array<allGiftSpendListContentDataModifiedObj>>(allGiftSpendListContent);

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = allGiftSpendListContent.filter((row: any) => {
          return Object.keys(row).some((field: any) => {
            if (["runs","type","duration"].includes(field)){
                return false;
            }
            if (["receiveTo"].includes(field)){
                return searchRegex.test(row[field]?.title);
            }
            return searchRegex.test(row[field]);
          });
        });
        setRows(filteredRows);
    };

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
          field: 'id',
          sort: 'asc',
        }
      ]);

    useEffect(() => {
        setRows(allGiftSpendListContent);
    }, [allGiftSpendListContent]);

    function NoRowsOverlay() {
        return (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No result found
          </Stack>
        );
      }

    return (
        <>
            <div style={{ height: 810, width: '100%' }}>
                <DataGrid
                    className="datatable__custom cursor__pointer"
                    disableColumnMenu
                    components={{ Toolbar: QuickSearchToolbar,ColumnSortedDescendingIcon: SortedDescendingIcon, ColumnSortedAscendingIcon: SortedAscendingIcon, NoRowsOverlay }}
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    sortingOrder={['desc', 'asc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    // checkboxSelection
                    onRowClick= {
                        (value: GridRowParams) => {
                            let rowValue:any = value.row;
                            {
                                (rowValue?.type === "Podcast") ?
                                navigate(`/admin/consumer/show/episode/detail/${rowValue.receiveTo.id}`)
                                : (rowValue?.type === "Creator") ?
                                navigate(`/admin/creators/${rowValue.receiveTo.id}`)
                                :
                                navigate(`/admin/match/${rowValue.receiveTo.id}`)
                            }
                        }
                    }
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
}
