import { Reducer } from "react";
import { CLEAR_MATCH_DETAIS, SAVE_MATCH_DETAILS } from "../../constants";
import { MatchInterface, MacthActions } from "./types";

export const initialState: MatchInterface = {
    title: "",
    roomId: "",
    id: 0,
    isLive: false,
    startTime: "",
    description: "",
    streams: [{
        id: 0,
        matchId: 0,
        startTime: "",
        streamtags: [],
        thumbnail: "",
        streamUrl: "",
        title: "",
        videoCallId: "",
        eventLanguage: "",
        eventType: "",
        user:null
    }]
}


export const matchReducer: Reducer<MatchInterface, MacthActions> = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case SAVE_MATCH_DETAILS:
            return {
                // @ts-ignore
                ...payload.payload,
            };
        case CLEAR_MATCH_DETAIS:
            return {
                ...initialState
            };
        default:
            return state;
    }
};
