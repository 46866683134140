import { Reducer } from 'react';
import {
    UPDATE_EPISODE_LIST_CONTENT_DATA,
    UPDATE_EPISODE_STATUS,
    DELETE_EPISODE_STATUS,
    ADD_EPISODE_LIST_CONTENT_DATA,
    SAVE_EPISODE_SEARCH_TEXT,
    SAVE_EPISODE_CURRENT_PAGE,
    SAVE_TOTAL_EPISODE,
    RESET
    } from '../../constants';

import { EpisodeListActions, episodeListState } from './types';


export const initialState: episodeListState = {
    episodeListContent: [],
    searchedValue: "",
    currentPage: 0,
    totalEpisode: 0
};

export const episodeListReducer: Reducer<episodeListState, EpisodeListActions> = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_EPISODE_LIST_CONTENT_DATA:
            return {
                ...state,
                episodeListContent: [...(action?.data || [])],
            };

        case ADD_EPISODE_LIST_CONTENT_DATA:
            return {
                ...state,
                episodeListContent: state.episodeListContent.concat(action?.data),
            };

        case UPDATE_EPISODE_STATUS:
            return {
                ...state,
                episodeListContent: state.episodeListContent.map(episode => {
                    if (episode.id === action.data) {
                        episode.isActive = !episode.isActive
                        return {...episode}
                    };
                    return episode;
                })
            }

        case DELETE_EPISODE_STATUS:
            return {
                ...state,
                episodeListContent: state.episodeListContent.filter((items) => items?.id !== action?.data)
            }

        case SAVE_EPISODE_SEARCH_TEXT:
            return {
                ...state,
                searchedValue : action.data
            };

        case SAVE_EPISODE_CURRENT_PAGE:
            return {
                ...state,
                currentPage : action.data
            };

        case SAVE_TOTAL_EPISODE:
            return {
                ...state,
                totalEpisode : action.data
            };

        case RESET:
            return {
                ...initialState //Always return the initial state
            };
        default:
            return state;
    }
};
