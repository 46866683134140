export const CREATOR_LOGGED_IN = 'CREATOR_LOGGED_IN';
export const CREATOR_LOGGED_OUT = 'CREATOR_LOGGED_OUT';
export const SHOW_SUCCESS_ALERT = 'SHOW_SUCCESS_ALERT';
export const SHOW_ERROR_ALERT = 'SHOW_ERROR_ALERT';
export const SHOW_DIALOG_BOX = 'SHOW_DIALOG_BOX';
export const SAVE_MATCH_DETAILS = 'SAVE_MATCH_DETAILS';
export const CLEAR_MATCH_DETAIS = 'CLEAR_MATCH_DETAIS';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const UPDATE_USER_CONTENT_DATA = 'UPDATE_USER_CONTENT_DATA';
export const SHOW_BACKDROP = "SHOW_BACKDROP";
export const HIDE_BACKDROP = "HIDE_BACKDROP";
export const SUPER_ADMIN_LOGGED_IN = 'SUPER_ADMIN_LOGGED_IN';
export const SUPER_ADMIN_LOGGED_OUT = 'SUPER_ADMIN_LOGGED_OUT';

export const UPDATE_SHOW_LIST_CONTENT_DATA = 'UPDATE_SHOW_LIST_CONTENT_DATA';
export const UPDATE_SHOW_DATA = 'UPDATE_SHOW_DATA';
export const CLEAR_SHOW_DATA = 'CLEAR_SHOW_DATA';

export const SHOW_UPLOAD_LOADER = 'SHOW_UPLOAD_LOADER';
export const HIDE_UPLOAD_LOADER = 'HIDE_UPLOAD_LOADER';

export const UPDATE_EPISODE_LIST_CONTENT_DATA = 'UPDATE_EPISODE_LIST_CONTENT_DATA';
export const UPDATE_EPISODE_DATA = 'UPDATE_EPISODE_DATA';
export const CLEAR_EPISODE_DATA = 'CLEAR_EPISODE_DATA';

export const SHOW_PODCAST_UPLOAD_LOADER = 'SHOW_PODCAST_UPLOAD_LOADER';
export const HIDE_PODCAST_UPLOAD_LOADER = 'HIDE_PODCAST_UPLOAD_LOADER';
export const UPDATE_APP_VERSION_LIST_CONTENT_DATA = 'UPDATE_APP_VERSION_LIST_CONTENT_DATA';

export const UPDATE_CREATOR_LIST_CONTENT_DATA = 'UPDATE_CREATOR_LIST_CONTENT_DATA';

export const ADD_CREATOR_LIST_CONTENT_DATA = 'ADD_CREATOR_LIST_CONTENT_DATA'
export const UPDATE_CREATOR_ACCOUNT_STATUS = 'UPDATE_CREATOR_ACCOUNT_STATUS';
export const UPDATE_EPISODE_STATUS = 'UPDATE_EPISODE_STATUS';
export const DELETE_EPISODE_STATUS = 'DELETE_EPISODE_STATUS';


export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const UPDATE_ADMIN_LIST_CONTENT_DATA = 'UPDATE_ADMIN_LIST_CONTENT_DATA';
export const ADD_ADMIN_LIST_CONTENT_DATA = 'ADD_ADMIN_LIST_CONTENT_DATA';
export const DELETE_SHOW_STATUS = 'DELETE_SHOW_STATUS';
export const UPDATE_SHOW_STATUS = 'UPDATE_SHOW_STATUS';


export const ADD_MATCH_LIST_CONTENT_DATA = 'ADD_MATCH_LIST_CONTENT_DATA';
export const UPDATE_MATCH_LIST_CONTENT_DATA = 'UPDATE_MATCH_LIST_CONTENT_DATA';
export const SAVE_SEARCHED_SHOW = 'SAVE_SEARCHED_SHOW';
export const SAVE_CREATOR_SEARCH_TEXT = 'SAVE_CREATOR_SEARCH_TEXT';
export const SAVE_ADMIN_SEARCH_TEXT = 'SAVE_ADMIN_SEARCH_TEXT';
export const SAVE_MATCH_SEARCH_TEXT = 'SAVE_MATCH_SEARCH_TEXT';
export const ADD_SHOW_LIST_CONTENT_DATA = 'ADD_SHOW_LIST_CONTENT_DATA';
export const ADD_EPISODE_LIST_CONTENT_DATA = 'ADD_EPISODE_LIST_CONTENT_DATA';
export const SAVE_EPISODE_SEARCH_TEXT = 'SAVE_EPISODE_SEARCH_TEXT';

export const UPDATE_CONSUMER_LIST_CONTENT_DATA = 'UPDATE_CONSUMER_LIST_CONTENT_DATA';
export const ADD_CONSUMER_LIST_CONTENT_DATA = 'ADD_CONSUMER_LIST_CONTENT_DATA';
export const UPDATE_CONSUMER_ACCOUNT_STATUS = 'UPDATE_CONSUMER_ACCOUNT_STATUS';
export const SAVE_CONSUMER_SEARCH_TEXT = 'SAVE_CONSUMER_SEARCH_TEXT';

export const SAVE_CREATOR_CURRENT_PAGE = 'SAVE_CREATOR_CURRENT_PAGE';
export const SAVE_TOTAL_CREATORS = 'SAVE_TOTAL_CREATORS';

export const SAVE_ADMIN_CURRENT_PAGE = 'SAVE_ADMIN_CURRENT_PAGE';
export const SAVE_TOTAL_ADMIN = 'SAVE_TOTAL_ADMIN';

export const SAVE_SHOW_CURRENT_PAGE = 'SAVE_SHOW_CURRENT_PAGE';
export const SAVE_TOTAL_SHOW = 'SAVE_TOTAL_SHOW';

export const SAVE_MATCH_CURRENT_PAGE = 'SAVE_MATCH_CURRENT_PAGE';
export const SAVE_TOTAL_MATCH = 'SAVE_TOTAL_MATCH';

export const SAVE_EPISODE_CURRENT_PAGE = 'SAVE_EPISODE_CURRENT_PAGE';
export const SAVE_TOTAL_EPISODE = 'SAVE_TOTAL_EPISODE';

export const SAVE_CONSUMER_CURRENT_PAGE = 'SAVE_CONSUMER_CURRENT_PAGE';
export const SAVE_TOTAL_CONSUMER = 'SAVE_TOTAL_CONSUMER';

export const RESET = 'RESET';

export const UPDATE_PACKAGE_LIST_CONTENT_DATA = 'UPDATE_PACKAGE_LIST_CONTENT_DATA';
export const UPDATE_EPISODE_GIFT_LIST_CONTENT_DATA = 'UPDATE_EPISODE_GIFT_LIST_CONTENT_DATA';

export const UPDATE_CONSUMER_SPEND_LIST_CONTENT_DATA = 'UPDATE_CONSUMER_SPEND_LIST_CONTENT_DATA';
export const UPDATE_CONSUMER_BALANCE_LIST_CONTENT_DATA = 'UPDATE_CONSUMER_BALANCE_LIST_CONTENT_DATA';
export const UPDATE_ALL_GIFT_SPEND_LIST_CONTENT_DATA = 'UPDATE_ALL_GIFT_SPEND_LIST_CONTENT_DATA';

export const UPDATE_PACKAGE_STATUS = 'UPDATE_PACKAGE_STATUS';
