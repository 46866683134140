export const createMatch = (data: any, startType: string) => {
    let match:any = {
        title: data.title?.trim(),
        description: data.description?.trim(),
        tags: getTags(data.tags),
        startTime: data.startTime,
        showName: data.showName?.trim(),
        eventType: data.type.toUpperCase(),
        eventLanguage:data.language.toUpperCase(),
        thumbnail: data.thumbnail ?? "",
        countryIdList: getCountries(data.countries),
        co_hosts_list: [],
        co_hosts_edit_list: [],
        co_hosts_deleted_id_list: [],
    }
    if(data.id) {
        match.id = data.id;
        match.is_immediate = (startType === '0') ? "1"  : "2";
    }

    if(data.creator) {
        match.creator_id = data.creator?.id;
    }

    if(startType === '0') {
        match.startTime = new Date();
    }

    return match;
}

const getTags = (tags:any) => {
    let selectedTags:any = [];
    tags.map((tag:any) => {
        if(!selectedTags.includes(tag)) {
            selectedTags.push(tag.label)
        }
    });
    return selectedTags;
}

const getCountries = (countries:any) => {
    let selectedCountries:any = [];
    countries.map((country:any) => {
        if(!selectedCountries.includes(country.id)) {
            selectedCountries.push(country.id)
        }
    });
    return selectedCountries;
}
