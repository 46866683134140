import { Box } from '@mui/material';

import EpisodeCustomDateListDataTable from '../common/EpisodeCustomDateListDataTable';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from 'moment';


import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PODCAST_API } from '../../constants';
import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { RootStateType } from '../../store';

import SingleStar from '../../icons/SingleStarIcon'
import { episodeListContentData, episodeListContentDataModified } from '../../data/mappers/episodeList';
import { UpdateEpisodeListContent, AddEpisodeListContent, TotalEpisodeSave, EpisodeCurrentPage } from '../../store/episodeList';

import { HideProgressBar, ShowProgressBar } from '../../store/alerts';
import profilepicplaceholder from '../../styles/images/placeholder.png';


import { ShowInterface, saveShowDetails } from '../../store/show';
import GoToTop from '../common/GoTop'
import { useLocation } from 'react-router-dom';


const ShowDetailCustomDate = (props) => {
    const { state }:any = useLocation();
    let startDate = state?.startDate;
    let endDate = state?.endDate;

    let navigate = useNavigate();

    const { id } = useParams();
    const [showDetails, setShowDetails] = useState<any>(null);
    const [episodeList, setEpisodeList] = useState<any>([]);

    const [rating, setRating] = useState<string | undefined>("");
    const [ratingCount, setRatingCount] = useState<string | undefined>("");

    let user = useSelector((state: RootStateType) => state.user);
    let token = user.access_token;


    const dispatch = useDispatch();


    useEffect(() => {
        if (showDetails){
            getEpisodeListContent();
        }
    }, [showDetails]);



    const getEpisodeListContent = () => {
        console.log(showDetails?.episodes)
        let formattedEpisodeListContentData = showDetails?.episodes as any,
        formattedEpisodeListContent: any = [];
            formattedEpisodeListContentData.forEach((x) => {

                formattedEpisodeListContent.push({
                    title: x.title,
                    id: x.id,
                    description: x.description,
                    authors:(x.authors.map((author) => {
                        return (author?.user?.firstName) ? (author?.user?.firstName) : (author?.user?.lastName)
                    }).join(', ')),

                    episodeLikeCount: x.episodeLikeCount ?? 0,
                    runs:x.runs_received,
                    date: x.createdAt,
                    isActive: x.isActive,
                });
            });
            setEpisodeList(formattedEpisodeListContent)
        //         // dispatch(UpdateEpisodeListContent(formattedEpisodeListContent));
        //         if (!offsetValue){
        //             dispatch(EpisodeCurrentPage(0))
        //             dispatch(UpdateEpisodeListContent(formattedEpisodeListContent));
        //         }
        //         else{
        //             dispatch(AddEpisodeListContent(formattedEpisodeListContent))
        //         }
        //     }
        // }
        // setLoader(false);

    };


    const getShowDetail = async (id: number|undefined) => {
        dispatch(ShowProgressBar());
        const response = await postRequest(PODCAST_API.SERIES_DETAIL_DATE_RANGE, {
            seriesId: id,
            start_date: (startDate),
            end_date: (endDate)
        }, token);
        if (response) {
            if (response.data.code === 200) {
                setRating(response.data?.data?.ratingAverage);
                setRatingCount(response.data?.data?.ratingCount);
                setShowDetails(response.data.data);

                dispatch(HideProgressBar());

                dispatch(saveShowDetails(response.data.data));
            }
        }
    }

    useEffect(() => {
        if (id) {
            getShowDetail(parseInt(id));
        }
    }, [id]);

    const buttonBox = (
        <>
        {/* <Button
        type="button"
        styleName={`button--outlined active`}
            onClick={() => navigate('/admin/show/detail/' + id + '/edit/',{ replace: true })}
        >Edit Show</Button>

        <Button
            type="button"
            styleName={`button--outlined active`}
            onClick={() => navigate('/admin/show/' + id +'/episode/',{ replace: true })}
        >Add New Episode
        </Button> */}
        </>
    )

    return (
        <>
            <Box className="dashboard__topbar">
                <Box className="row align--items--center">
                    <div className="md-col-5 mb--20 mb--md--0">
                        <Box className="button__text__navigate">
                            <button onClick={() => navigate(-1)}><ArrowBackOutlinedIcon /></button>
                            <Box className="dashboard__topbar--heading" component="h2">Show Details</Box>
                        </Box>
                    </div>
                    <div className="md-col-7">
                        <Box className="button__box font--14">{buttonBox}</Box>
                    </div>
                </Box>
            </Box>

            <Box className='dashboard__content'>
                    <Box className='showdetail__topbar'>
                        {showLogoBox(showDetails?.logo)}
                        <Box>
                            <Box component="h2" className='showdetail__topbar--title'>
                                {showDetails?.title}
                            </Box>
                            <Box className='showdetail__ratingBox'>
                                <Box className='showdetail__ratingBox__outer'>
                                    <b>{rating || "0.0"}</b>
                                    <SingleStar/>
                                    <span>({ratingCount || "0"})</span>
                                </Box>
                            </Box>
                            <Box className='showdetail__creators'>
                                <Box>
                                    <label htmlFor="" className='creator--title'>Creators</label>
                                    {showCretorBox(getHostsString(showDetails, user))}
                                </Box>
                            </Box>
                        </Box>
                    </Box>


                    <Box className='showdetail__descBox'>
                        <Scrollbars
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            autoHeight
                            autoHeightMin={0}
                            autoHeightMax={200}
                            thumbMinSize={30}
                            >
                            {showDetails?.description}
                        </Scrollbars >
                    </Box>
                <div className="shw-dtl-dt-rng">
                    Date Range: {moment(startDate).format('D.MM.YYYY')} - {moment(endDate).format('D.MM.YYYY')}
                </div>
                <Box>
                    <EpisodeCustomDateListDataTable episodeListContent={episodeList} />
                </Box>
            </Box>
            <GoToTop/>
        </>
    );
}

const showLogoBox = (value?: any) => {
    return (
        <>
            <Box className="showdetail__imgBox">
                <Box
                    component="img"
                    src={value}
                ></Box>
            </Box>
        </>
    )
}

const showCretorBox = (value?: any) => {
    return (
        <>
            <Box className='showdetail__area'>
                {value}
            </Box>
        </>
    )
}


const getHostsString = (showDetails: any, user:any) => {
    var hostsString = "";
    if (showDetails) {
        let hosts = showDetails.host
        hostsString = (hosts.map((host: any, index) => {
            return (
                <>
                <Box className='profie__circle__box' key={index}>
                    {
                        (user.permissions.creator) ?
                        <a href={"/admin/creators/"+host.user.id} >
                            <Box className='profie__circle__box--img'>
                                    <Box
                                    component="img"
                                    src = {(host.user.profile_pic_path) ? host.user.profile_pic_path : profilepicplaceholder}
                                    >
                                    </Box>
                                </Box>
                            <Box component='p'>
                                    {(host.user.firstName) ? host.user.firstName : host.user.lastName}
                            </Box>
                        </a>
                        :
                        <>
                            <Box className='profie__circle__box--img'>
                                    <Box
                                    component="img"
                                    src = {(host.user.profile_pic_path) ? host.user.profile_pic_path : profilepicplaceholder}
                                    >
                                    </Box>
                                </Box>
                            <Box component='p'>
                                    {(host.user.firstName) ? host.user.firstName : host.user.lastName}
                            </Box>
                        </>

                    }

                    </Box>
            </>
            )
        }))
    }
    return hostsString;
}


export default ShowDetailCustomDate;
