 import React from 'react'

 export default function PlayIcon() {
   return (
     <>
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path d="M19.2284 17.7221H1L3.27855 14.3465C3.27855 6.75128 5.16432 2.53161 9.60787 2.53174C14.0514 2.53187 15.7245 4.21956 16.4942 14.3465L17.8613 16.0343L19.2284 17.7221Z" stroke="#C2C1E6" strokeWidth="2" strokeLinejoin="round"/>
        <circle cx="9.6079" cy="18.7349" r="1.51904" fill="#C2C1E6"/>
        <ellipse cx="10.1143" cy="1.68782" rx="1.82284" ry="1.68782" fill="#C2C1E6"/>
        </svg>
     </>
   )
 }
