import { RESET, UPDATE_CREATOR_LIST_CONTENT_DATA, ADD_CREATOR_LIST_CONTENT_DATA, UPDATE_CREATOR_ACCOUNT_STATUS, SAVE_CREATOR_SEARCH_TEXT, SAVE_CREATOR_CURRENT_PAGE, SAVE_TOTAL_CREATORS } from '../../constants';
import { creatorListContentDataModifiedObj } from '../../data/mappers/creatorList';
import { UpdateCreatorListContentAction , AddCreatorListContentAction} from './types';

export const UpdateCreatorListContent = (data: Array<creatorListContentDataModifiedObj>): UpdateCreatorListContentAction => ({
    type: UPDATE_CREATOR_LIST_CONTENT_DATA,
    data,
});


export const AddCreatorListContent = (data: Array<creatorListContentDataModifiedObj>): AddCreatorListContentAction => ({
    type: ADD_CREATOR_LIST_CONTENT_DATA,
    data,
});

export const UpdateCreatorAccountStatus = (data:  number) => ({
    type: UPDATE_CREATOR_ACCOUNT_STATUS,
    data,
});

export const CreatorSearchText = (data:  string) => ({
    type: SAVE_CREATOR_SEARCH_TEXT,
    data,
});

export const CreatorCurrentPage = (data:  number) => ({
    type: SAVE_CREATOR_CURRENT_PAGE,
    data,
});

export const TotalCreatorsSave = (data:  number) => ({
    type: SAVE_TOTAL_CREATORS,
    data,
});

export const CreatorResetAction = () => ({
    type: RESET
});

