import { Reducer } from 'react';
import { UPDATE_APP_VERSION_LIST_CONTENT_DATA } from '../../constants';
import { AppVersionListActions, AppVersionListState } from './types';

export const initialState: AppVersionListState = {
    appVersionListContent: [],
};

export const appVersionListReducer: Reducer<AppVersionListState, AppVersionListActions> = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_APP_VERSION_LIST_CONTENT_DATA:
            return {
                ...state,
                appVersionListContent: [...(action?.data || [])],
            };

        default:
            return state;
    }
};
