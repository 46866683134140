import { Box } from "@mui/material";
import React from "react";
import SidebarLayout from "./SidebarLayout";
import {
    Routes,
    Route,
} from "react-router-dom";
import { PrivateRoute } from "../../App";
import Dashboard from "../pages/Dashboard";
import LiveStream from "../pages/LiveStream";

import MatchList from "../pages/MatchList";

import ShowList from "../pages/ShowList";
import ShowCreate from "../pages/ShowCreate";
import Episode from "../pages/Episode";
import EpisodeDetail from "../pages/EpisodeDetail";
import AppVersionCreate from "../pages/AppVersion";
import CustomPushCreate from "../pages/CustomPush";
import AppVersionList from "../pages/AppVersionList";
import AddCreator from "../pages/AddCreator";
import EpisodeTransactionsList from "../pages/EpisodeTransactionsList";

import ConsumerList from "../pages/ConsumerList"
import ConsumerCustomData from "../pages/ConsumerCustomData"
import ConsumerDetail from "../pages/ConsumerDetail"
import ConsumerTransactionsList from "../pages/ConsumerTransactions"
import AllGiftSpendList from "../pages/AllGiftSpendList"

import AddAdmin from "../pages/AdminCreate";
import AdminList from "../pages/AdminList";
import PackageList from "../pages/PackageList"
import PackageAdd from "../pages/PackageAdd"


import CreatorsList from "../pages/CreatorsList";

import ShowDetail from "../pages/ShowDetail";
import Settings from "../pages/Settings";
import MatchDetails from "../pages/MatchDetails";

//Responsive Drawer
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useMediaQuery } from "@mui/material";
import { RootStateInterface } from "../../store";

import { useDispatch, useSelector } from "react-redux";
import ShowDetailCustomDate from "../pages/ShowDetailCustomDate";


const DashboardLayout = (props: any) => {

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const user = useSelector((state:RootStateInterface) => state.user);
    if (!user.permissions){
        user.permissions = {
            match:true,
            series:true,
            push:true,
            app_version:true,
            creator:true,
            series_delete:true,
            consumer:true
        }
    }

    user.permissions.consumer = true;

    // const role = props.role || "creator";
    const role = user.role || "creator";

    const md = useMediaQuery('(min-width:900px)');
    const handleDrawerToggle = () => {
        if(!md) {
            setMobileOpen(!mobileOpen);
        }
    };

    const drawerWidth = 300;
    const mddrawerWidth = 340;

    const drawer = (
        <div>
          <Toolbar />
          <SidebarLayout handleDrawerToggle={handleDrawerToggle} />
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;


    const matchRoutes = (
        <>
            <Route path='/match' element={
                <PrivateRoute>
                    <MatchList />
                </PrivateRoute>
            } />

            <Route path='/match/create' element={
                <PrivateRoute>
                    <LiveStream modeType="add"  />
                </PrivateRoute>
            } />

            <Route path='/match/edit/:matchId' element={
                <PrivateRoute>
                     <LiveStream modeType="edit" />
                </PrivateRoute>
            } />

            {/* <Route path='/show/detail/:id/edit/' element={
                <PrivateRoute>
                     <ShowCreate modeType="edit" back="detail" />
                </PrivateRoute>
            } /> */}

        </>
    )

    const showRoutes = (
        <>
            <Route path='/show' element={
                <PrivateRoute>
                    <ShowList />
                </PrivateRoute>
            } />

            <Route path='/show/create' element={
                <PrivateRoute>
                    <ShowCreate modeType="add" back="list"  />
                </PrivateRoute>
            } />

            <Route path='/show/:id' element={
                <PrivateRoute>
                    <ShowDetail />
                </PrivateRoute>
            } />


            <Route path='/creators/show/:id' element={
                <PrivateRoute>
                    <ShowDetailCustomDate />
                </PrivateRoute>
            } />
            <Route path='/show/:id/edit/' element={
                <PrivateRoute>
                     <ShowCreate modeType="edit" back="list" />
                </PrivateRoute>
            } />

            <Route path='/show/detail/:id/edit/' element={
                <PrivateRoute>
                     <ShowCreate modeType="edit" back="detail" />
                </PrivateRoute>
            } />

        </>
    )

    const episodeRoutes = (
        <>
            <Route path='/show/:showId/episode/' element={
                <PrivateRoute>
                     <Episode modeType="add" back="list" />
                </PrivateRoute>
            } />

            <Route path='/show/:showId/episode/:id' element={
                <PrivateRoute>
                     <Episode modeType="edit" back="list"/>
                </PrivateRoute>
            } />

            <Route path='/show/episode/detail/:episodeId' element={
                <PrivateRoute>
                     <EpisodeDetail back="show"/>
                </PrivateRoute>
            } />

            <Route path='/show/:showId/episode/detail/:id' element={
                <PrivateRoute>
                     <Episode modeType="edit" back="detail"/>
                </PrivateRoute>
            } />

            <Route path='/consumer/show/episode/detail/:episodeId' element={
                <PrivateRoute>
                     <EpisodeDetail back="consumer_transaction"/>
                </PrivateRoute>
            } />

            <Route path='/all/gift/spend/show/episode/detail/:episodeId' element={
                <PrivateRoute>
                     <EpisodeDetail back="all_gift_spend"/>
                </PrivateRoute>
            } />

            <Route path='/show/episode/:id/gifts/recieved/' element={
                <PrivateRoute>
                     <EpisodeTransactionsList />
                </PrivateRoute>
            } />


        </>
    )

    const pushRoutes = (
        <>
            <Route path='/app/notification' element={
                <PrivateRoute>
                    <CustomPushCreate />
                </PrivateRoute>
            } />
        </>
    )

    const appVersionRoutes = (
        <>
            <Route path='/app/version' element={
                <PrivateRoute>
                     <AppVersionList />
                </PrivateRoute>
            } />
            <Route path='/app/version/create' element={
                <PrivateRoute>
                     <AppVersionCreate modeType="add" back="list" />
                </PrivateRoute>
            } />

            <Route path='/app/version/:id' element={
                <PrivateRoute>
                     <AppVersionCreate modeType="edit" back="list" />
                </PrivateRoute>
            } />
        </>
    )

    const creators = (
        <>
            <Route path='/creators' element={
                <PrivateRoute>
                    <CreatorsList />
                </PrivateRoute>
            } />

            <Route path='/creators/add/' element={
                <PrivateRoute>
                     <AddCreator modeType="add" />
                </PrivateRoute>
            } />

            <Route path='/creators/edit/:id' element={
                <PrivateRoute>
                     <AddCreator modeType="edit" />
                </PrivateRoute>
            } />

            <Route path='/creators/:id' element={
                <PrivateRoute>
                     <AddCreator modeType="detail" />
                </PrivateRoute>
            } />
        </>
    )

    const consumers = (
        <>
            <Route path='/consumers' element={
                <PrivateRoute>
                    <ConsumerList />
                </PrivateRoute>
            } />

            <Route path='/consumers/customDate' element={
                <PrivateRoute>
                    <ConsumerCustomData />
                </PrivateRoute>
            } />

            <Route path='/consumers/:id' element={
                <PrivateRoute>
                    <ConsumerDetail />
                </PrivateRoute>
            } />

            <Route path='/consumers/:id/transactions' element={
                <PrivateRoute>
                    <ConsumerTransactionsList />
                </PrivateRoute>
            } />
        </>
    )

    let permission = user.permissions

    const adminAllowedRoutes = (
        <>
            { (permission.match) ? matchRoutes : ""}
            { (permission.series) ? showRoutes : ""}
            { (permission.series) ? episodeRoutes : "" }
            { (permission.push) ? pushRoutes : "" }
            { (permission.app_version) ? appVersionRoutes : "" }
            { (permission.creator) ? creators : "" }
            { (permission.consumer) ? consumers : "" }
        </>
        )

    const superAdminAllowedRoutes = (
        <>
            {adminAllowedRoutes}
            <Route path='/admin' element={
                <PrivateRoute>
                     <AdminList />
                </PrivateRoute>
            } />

            <Route path='/admin/add' element={
                <PrivateRoute>
                     <AddAdmin modeType="add" />
                </PrivateRoute>
            } />

            <Route path='/admin/:id' element={
                <PrivateRoute>
                     <AddAdmin modeType="detail" />
                </PrivateRoute>
            } />

            <Route path='/package' element={
                <PrivateRoute>
                     <PackageList />
                </PrivateRoute>
            } />

            <Route path='/package/add' element={
                <PrivateRoute>
                     <PackageAdd modeType="add"/>
                </PrivateRoute>
            } />

            <Route path='/package/:id' element={
                <PrivateRoute>
                     <PackageAdd modeType="detail"/>
                </PrivateRoute>
            } />

            <Route path='/all/gift/spend' element={
                <PrivateRoute>
                     <AllGiftSpendList />
                </PrivateRoute>
            } />
        </>
    )


    // const verificationPage = (
    //     <>
    //         <Route path='/email/verify/:uid/:token' element={
    //             <VerifyEmail />
    //         } />

    //     </>
    // )

    return (
        <>
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
            position="fixed"
            sx={{
                width: { md: `calc(100% - ${mddrawerWidth}px)` },
                ml: { md: `${mddrawerWidth}px` },
                display: {md: `none`}
            }}
            >
            <Toolbar>
                <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: 'none' } }}
                >
                <MenuIcon />
                </IconButton>
            </Toolbar>
            </AppBar>
            <Box
            component="aside"
            sx={{ width: { md: `${mddrawerWidth}px` }, flexShrink: { md: 0 } }}
            aria-label="sidebar components"
            >
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                '& .MuiDrawer-paperone': {width: { md: `${mddrawerWidth}px` }},
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                className="sidebarFixed"
                variant="permanent"
                sx={{
                display: { xs: 'none', md: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                '& .MuiDrawer-paperone': {width: { md: `${mddrawerWidth}px` }},
                }}
                open
            >
                {drawer}
            </Drawer>
            </Box>
            <Box
            component="main"
            sx={{
                flexGrow: 1, pt: 2, pb: 2,
                width: { md: `calc(100% - ${mddrawerWidth}px)` },
                padding: {md: 0}
            }}
            >
            <Toolbar
                sx={{
                    display: {md: `none`}
                }}
            />
            <Box
                sx={{
                    background: "#191840"
                }}
            >
                <Box sx={{
                    minHeight: "100vh",
                    background: "#191840"
                }}>
                    <Box>
                        <Routes>
                            <Route path='/dashboard' element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            } />
                            <Route path='/live-stream' element={
                                <PrivateRoute>
                                    <LiveStream modeType="add" />
                                </PrivateRoute>
                            } />
                            <Route path='/edit_match/:matchId' element={
                                <PrivateRoute>
                                    <LiveStream modeType="edit" />
                                </PrivateRoute>
                            } />
                            <Route path='/match/:id' element={
                                <PrivateRoute>
                                    <MatchDetails />
                                </PrivateRoute>
                            } />
                            <Route path='/settings' element={
                                <PrivateRoute>
                                    <Settings />
                                </PrivateRoute>
                            } />

                            {
                                role === 'admin' ?
                                adminAllowedRoutes
                                :
                                (role === 'superadmin')
                                ?
                                superAdminAllowedRoutes :
                                ""
                            }
                        </Routes>
                    </Box>
                </Box>
            </Box>
            </Box>
        </Box>
        </>
    );
}


export default DashboardLayout;
