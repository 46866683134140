import React from 'react'

export default function GiftIcon() {
  return (
    <>
    <svg width="38" height="51" viewBox="0 0 38 51" fill="none">
        <path d="M0.0732422 36.5644V49.5393C0.0763639 49.6065 0.092466 49.6724 0.121085 49.7332C0.149703 49.7941 0.190202 49.8486 0.239948 49.8938C0.289694 49.9391 0.347832 49.9741 0.411111 49.9968C0.47439 50.0195 0.541571 50.0295 0.608721 50.0262H16.6264C16.6264 44.7438 16.6264 40.2889 16.6264 36.467L0.0732422 36.5644Z" fill="white"/>
        <path d="M16.5776 18.1857H10.9057C10.5004 18.211 10.0939 18.211 9.68855 18.1857H0.535479C0.468329 18.1824 0.401148 18.1924 0.337869 18.2151C0.27459 18.2378 0.216452 18.2728 0.166706 18.318C0.116959 18.3633 0.0767584 18.4178 0.0481397 18.4787C0.019521 18.5395 0.00312168 18.6054 0 18.6726V31.7936H16.4804C16.5291 24.6123 16.5289 20.547 16.5776 18.1857Z" fill="white"/>
        <path d="M7.88701 12.392C8.62128 12.8571 9.45571 13.1409 10.3213 13.2197H16.4802V7.81544C16.4807 6.73938 16.1585 5.68794 15.5552 4.7969C15.0434 4.04252 14.3537 3.42575 13.547 3.00118C12.7403 2.57661 11.8415 2.35731 10.9299 2.36258C10.5575 2.32673 10.1826 2.32673 9.81022 2.36258C8.73341 2.57561 7.74286 3.10044 6.96195 3.87185C6.18699 4.63065 5.6607 5.60692 5.45269 6.67138C5.25839 7.7443 5.38591 8.85064 5.81908 9.85127C6.25225 10.8519 6.97172 11.7021 7.88701 12.2947V12.392Z" fill="white"/>
        <path d="M31.8896 12.1974C32.9754 11.4578 33.8215 10.4171 34.3239 9.20321C34.8404 7.99876 34.9764 6.66518 34.7135 5.38131C34.4548 4.08995 33.8195 2.9042 32.8878 1.97335C31.9433 1.02907 30.7405 0.385336 29.4309 0.123239C28.1143 -0.128267 26.7531 0.0069505 25.5117 0.512665C24.2785 1.00441 23.2193 1.85175 22.4688 2.94699C21.7391 4.034 21.3494 5.31359 21.3491 6.62283V13.1955H29.1632C30.1333 13.0633 31.0634 12.7227 31.8896 12.1974Z" fill="white"/>
        <path d="M37.4402 18.089H29.3581C28.9527 18.1132 28.5463 18.1132 28.1409 18.089H21.4465C21.4465 20.8155 21.4465 25.8302 21.4465 31.5995H38V18.4785C37.971 18.356 37.8977 18.2486 37.7944 18.1767C37.6911 18.1048 37.5651 18.0737 37.4402 18.089Z" fill="white"/>
        <path d="M21.4953 50.1239H37.4402C37.5073 50.1272 37.5745 50.1172 37.6378 50.0945C37.7011 50.0718 37.7592 50.0367 37.809 49.9915C37.8587 49.9463 37.8989 49.8917 37.9275 49.8309C37.9561 49.7701 37.9725 49.7041 37.9757 49.637V36.5647H21.4465C21.4709 41.1899 21.4709 45.9369 21.4953 50.1239Z" fill="white"/>
    </svg>
    </>
  )
}



