// import * as React from 'react';

import React, { useEffect, useState } from 'react';

// import Button from '@mui/material/Button';
import { Button } from '../common/Button';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Field, reduxForm } from 'redux-form';

interface PasswordFormInterface {
    password:string;
    confirmPassword:string;
}

const validate = (values: PasswordFormInterface) => {
    const errors: any = {}
    if (!values.password || (values.password && !values.password.trim())) {
        errors.password = 'Field is required.';
    }

    if (!values.confirmPassword || (values.confirmPassword && !values.confirmPassword.trim())) {
        errors.confirmPassword = 'Field is required.';
    }
    return errors
}


export function PasswordDialog(props:any) {

  const { handleSubmit, setNewPassword } = props;

  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState<string | undefined>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string | undefined>("");


  const [passwordError, setPasswordError] = React.useState<[boolean,string]>([false,""]);
  const [confirmError, setConfirmError] = React.useState<[boolean,string]>([false,""]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setPasswordError([false, ""]);
    if (password && password.length < 8){
        setPasswordError([true, "Password must contains at least 8 characters."]);
    }
  }, [password]);


  useEffect(() => {
    setConfirmError([false, ""]);
    if (confirmPassword && (confirmPassword !== password)) {
        setConfirmError([true, "Password does not match"]);
    }
  }, [confirmPassword]);


  const savePassword = () => {
    if (!password){
        setPasswordError([true, "Field is required"]);
    }
    else if (password && password.length < 8){
        setPasswordError([true, "Password must contains at least 8 characters."]);
    }
    else if (!confirmPassword){
        setConfirmError([true, "Field is required"]);
    }
    else {
        setNewPassword(password);
        handleClose();
    }
    };

  return (
    <div>
        <Button styleName='saveBtn' type='button'  onClick={handleClickOpen} >
            Change password
        </Button>
      <Dialog className='w--100' open={open} onClose={handleClose}>
        <DialogTitle style={{fontWeight: 600, fontSize: '1.4rem'}}>Change Password</DialogTitle>
        <DialogContent>

        {/* <form onSubmit={handleSubmit(savePassword)}> */}
        <div className="dialog__content__box m-b-20">
          <label className='input--label m-b-10' htmlFor="passwordEdit">Password</label>
          <TextField
            // autoFocus
            // margin="dense"
            id="passwordEdit"
            // label="Password"
            type="password"
            fullWidth
            // variant="standard"
            value={password}
            onChange={(event) => {setPassword(event.target.value)}}
            error={passwordError[0] || false}
            helperText={passwordError[1]}
          />
        </div>

        <div className="dialog__content__box">
          <label className='input--label m-b-10' htmlFor="passwordConfirm">Confirm Password</label>
            <TextField
                // autoFocus
                // margin="dense"
                id="passwordConfirm"
                // label="Confirm Password"
                type="text"
                fullWidth
                // variant="standard"
                value={confirmPassword}
                onChange={(event) => {setConfirmPassword(event.target.value)}}
                error={confirmError[0] || false}
                helperText={confirmError[1]}
          />
        </div>
            {/* <label className='input--label m-b-10' htmlFor="password">Password</label>
            <Field name="password" component={TextField} type="password" placeholder='Write Password here'
                styleName='input--transparent' />

            <div className='col-12 md-col-12 m-b-20'>
            </div>


            <label className='input--label m-b-10' htmlFor="confirmPassword">Confirm password</label>
            <Field name="confirmPassword" component={TextField} type="text" placeholder='Confirm password'
                styleName='input--transparent' />

          </form> */}
        </DialogContent>
        <DialogActions>
          {/* <Button styleName='cancelButton' type='button' onclick={savePassword}>Update</Button> */}
          <Button styleName='deleteButton normal--btn deleted-class' type='button' onClick={handleClose}>Cancel</Button>
          <Button styleName='deleteButton normal--btn cancel-class' type='button' onClick={savePassword}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}



export function CancelEditConfirmationDialog(props:any) {
    const { setModeType,setResetForm, formId } = props;

    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setResetForm(false);
    };

    const confirmUpdate = () => {
        setModeType("detail")
        setResetForm(false);
    }

    return (
      <div>
        <Dialog className='w--100' open={open} onClose={handleClose}>
          <DialogTitle style={{fontWeight: 600, fontSize: '1.4rem'}}>Are you sure you want to cancel? None of your changes will be saved.</DialogTitle>
          <DialogActions>
            {/* <Button styleName='cancelButton' type='button' onclick={savePassword}>Update</Button> */}
            {
                formId ?
                <>
                    <Button styleName='deleteButton normal--btn cancel-class' form={formId} type="submit">Save</Button>
                </>
                :
                  <Button styleName='deleteButton normal--btn cancel-class' type='button' onClick={handleClose}>No, I want to Save</Button>

            }
            <Button styleName='deleteButton normal--btn  deleted-class' type='button' onClick={confirmUpdate}>Yes, I don't want to Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

// export default reduxForm({
//     form: 'passwordUpdate',
//     validate
// })(PasswordDialog)
