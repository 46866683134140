import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, GridRowParams } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { packageListContentDataModifiedObj } from '../../data/mappers/package';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import {  postRequest, isApiCodeSucess } from '../../data/helpers/services';
import { PACKAGES_API } from '../../constants';
import { ShowSuccessAlert, ShowProgressBar, HideProgressBar } from '../../store/alerts';
import { UpdatePackageStatusContent } from '../../store/packageList';

import { useSelector, useDispatch } from 'react-redux';
import { RootStateType } from '../../store';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';


export function SortedDescendingIcon() {
    return <ExpandMoreIcon className="icon" />;
  }

  export function SortedAscendingIcon() {
    return <ExpandLessIcon className="icon" />;
  }


function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{mr: 2}} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}

function UpdatePackageStatus(params) {
    let dispatch = useDispatch()

    const [ updating, setUpdating ] = React.useState<boolean>(false)

    const [ showActive, setShowActive] = React.useState<boolean>(params.params.row.isActive)

    let token = useSelector((state: RootStateType) => state.user.access_token);
    let id = params.params.row.id;

    const handleClick = async () => {
        if (!updating){
            setUpdating(true);

            const res: any = await postRequest(PACKAGES_API.UPDATE_PACKAGE_STATUS, {
                gift_pack_id: id,
                status: (showActive ? '2' : "1")
            }, token);
            if (res) {
                dispatch(ShowSuccessAlert({visible:true, message: "Package status updated successfully"}));
                setShowActive(!showActive);
                dispatch(UpdatePackageStatusContent(id))
            }
            setUpdating(false);
        }
    }

    return (
        <div>
            <button className={`deleteButton normal--btn ${(showActive ? 'active--class' : 'deleted-class')}`} onClick={handleClick}>
                {(showActive) ? "Active" : "Inactive"}
            </button>
        </div>
    );
  }


  function AlertDialog(params) {
    const [open, setOpen] = React.useState(false);
    let dispatch = useDispatch()
    const [inProgress, setInProgress] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    let token = useSelector((state: RootStateType) => state.user.access_token);
    let id = params.params.row.id;

    const deleteConfirmed = async() => {
        // dispatch(ShowProgressBar());
        // setInProgress(true);
        // const res: any = await postRequest(PODCAST_API.SERIES_DELETE, {
        //     series_id: id
        // }, token);
        // dispatch(HideProgressBar());
        // if (res) {
        //     setOpen(false);
        //     dispatch(ShowSuccessAlert({visible:true, message: "Show deleted successfully."}));
        //     dispatch(DeleteShowContent(id))
        // }
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <button className='deleteButton normal--btn deleted-class' onClick={handleClickOpen}>
            Delete
        </button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <h4>{"Package Delete Confirmation"}</h4>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this package <b>{params.params.row.title}</b> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>

          {
                inProgress
                ?
                    <span>
                        <h4>Deletion in Progress...</h4>
                    </span>
                :
                    <>
                    <button className='deleteButton normal--btn cancel-class' onClick={handleClose}>
                        Cancel
                    </button>

                    <button className='deleteButton normal--btn deleted-class' onClick={deleteConfirmed}>
                        Delete
                    </button>
                    </>
            }          </DialogActions>
        </Dialog>
      </div>
    );
  }


const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 0, hide: true },
    // { field: 'sku', headerName: 'SKU', type: 'string', width: 220 },
    { field: 'showTitle', headerName: 'Show Title', type: 'string', minWidth: 300, flex:1.5 },
    { field: 'runs', headerName: 'Runs Recieved', type: 'number', minWidth: 220, flex:1 ,align: 'left', headerAlign: 'left'},
    // { field: 'purchases', headerName: 'Purchase', type: 'number', width: 130, align: 'center', headerAlign: 'center' },
    // { field: 'countriesCount', headerName: 'Countries', type: 'number', width: 130, align: 'center', headerAlign: 'center' },

    {
        field: 'createdAt',
        headerName: 'Show Created Date',
        type: 'date',
        minWidth: 300,
        flex:1,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
                <label>{moment(params.row.createdAt).format('D.MM.YYYY')}</label>
            </>
        ),
        align: 'left', headerAlign: 'left'
    },
    // { field: 'streamtags', headerName: 'Tag', type: 'string', width: 220 },
    // { field: 'count', headerName: 'View Count', type: 'number', width: 150, align: 'left', headerAlign: 'left' },
    // {
    //     field: 'delete',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'delete-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <>
    //         <AlertDialog params={params} />
    //         </>
    //     ),
    // },

    // {
    //     field: 'view',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'left',
    //     headerAlign: 'left',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'view-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         // <Link to={`/admin/package/${params.row.id}`}>
    //         // {/* <button type="button">View</button> */}

    //         // </Link>
    //         <span><ArrowRightAltIcon/></span>
    //     ),
    // },
];


interface creatorTransactionContentDataTableProps {
    creatorTransactionListContent: Array<packageListContentDataModifiedObj>;
    startDate: any;
    endDate: any;
}

export default function CreatorTransactionListDataTable({ creatorTransactionListContent, startDate, endDate }: creatorTransactionContentDataTableProps) {
    let navigate = useNavigate();
    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState<Array<packageListContentDataModifiedObj>>(creatorTransactionListContent);

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = creatorTransactionListContent.filter((row: any) => {
          return Object.keys(row).some((field: any) => {
            if (["id","purchases","countriesCount","startDate","endDate","packageActiveStatus"].includes(field)){
                return false;
            }

            return searchRegex.test(row[field]);
          });
        });
        setRows(filteredRows);
    };

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
          field: 'id',
          sort: 'desc',
        }
      ]);

    useEffect(() => {
        setRows(creatorTransactionListContent);
    }, [creatorTransactionListContent]);

    function NoRowsOverlay() {
        return (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No result found
          </Stack>
        );
      }

    return (
        <>
            <div style={{ height: 500, width: '100%' }}>
                <DataGrid
                    className="datatable__custom cursor__pointer searchbar--absolute searchbar__size"
                    disableColumnMenu
                    components={{ Toolbar: QuickSearchToolbar,ColumnSortedDescendingIcon: SortedDescendingIcon, ColumnSortedAscendingIcon: SortedAscendingIcon, NoRowsOverlay }}
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    sortingOrder={['desc', 'asc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    onRowClick= {
                        (value: GridRowParams) => {
                            let rowValue:any = value.row;
                            navigate(`/admin/creators/show/${rowValue.id}`, {state: {startDate: startDate, endDate: endDate}})
                        }
                    }
                    // checkboxSelection
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
}
