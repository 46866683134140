import { Box, Paper } from '@mui/material';
import React from 'react';
import ProfileForm from '../profile/ProfileForm';
import GoToTop from '../common/GoTop'

const Settings = (props: any) => {

    return (
        <>
            <Box className='dashboard__topbar'>
                <Box className='dashboard__topbar--heading col-12'>
                    <Box component="h2">
                        Profile
                    </Box>
                </Box>
            </Box>
            <Box className='dashboard__content max--760'>
                { //@ts-ignore
                <ProfileForm redirect={false} />}
            </Box>
            <GoToTop/>
        </>
    );

}

export default Settings;
