import React from 'react'

export default function LiveStreamIcon() {
  return (
      <>
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.805 5.7368C21.2175 7.88924 18.656 9.282 15.7828 9.282C12.5493 9.282 9.7248 7.51914 8.21517 4.90894C7.88403 5.76602 7.69897 6.69128 7.69897 7.66523C7.69897 11.9019 11.137 15.34 15.3738 15.34C19.6105 15.34 23.0485 11.9019 23.0485 7.66523C23.0485 7.00294 22.9609 6.35039 22.805 5.7368Z" fill="#C2C1E6"/>
        <path d="M14.7893 7.0904C17.7112 7.0904 20.2143 5.32754 21.3051 2.805C19.8928 1.09083 17.7599 0 15.3737 0C12.5687 0 10.1143 1.50963 8.77026 3.75947C10.0267 5.75608 12.2473 7.0904 14.7893 7.0904Z" fill="#C2C1E6"/>
        <path d="M7.20241 12.0674L0.774296 18.4955C-0.258099 19.5279 -0.258099 21.1934 0.774296 22.2258C1.80669 23.2581 3.47216 23.2581 4.50455 22.2258L10.9619 15.7684C9.36459 14.9406 8.04975 13.6452 7.20241 12.0674ZM6.91022 17.132L5.73174 18.3104C5.44929 18.5929 4.99153 18.5929 4.71882 18.3104C4.44611 18.028 4.43637 17.5702 4.71882 17.2975L5.89731 16.119C6.17975 15.8366 6.63752 15.8366 6.91022 16.119C7.18293 16.4015 7.19267 16.8495 6.91022 17.132Z" fill="#C2C1E6"/>
        </svg>
      </>
  )
}



