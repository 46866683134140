import React from 'react'
import SuperAdminLoginForm from '../login/Form';


interface userTypeInterface {
    isAdmin: any;
}

const AdminLogin = (props: userTypeInterface) => {
    return (
        <div className='login--wrapperbox max--760'>
        <SuperAdminLoginForm
            // @ts-ignore
        isAdmin={props.isAdmin} />
    </div>
  )
}


export default AdminLogin
