import { RESET, UPDATE_EPISODE_LIST_CONTENT_DATA, UPDATE_EPISODE_STATUS, DELETE_EPISODE_STATUS, ADD_EPISODE_LIST_CONTENT_DATA, SAVE_EPISODE_SEARCH_TEXT, SAVE_EPISODE_CURRENT_PAGE, SAVE_TOTAL_EPISODE } from '../../constants';
import { episodeListContentDataModifiedObj } from '../../data/mappers/episodeList';
import { UpdateEpisodeListContentAction, AddEpisodeListContentAction } from './types';

export const UpdateEpisodeListContent = (data: Array<episodeListContentDataModifiedObj>): UpdateEpisodeListContentAction => ({
    type: UPDATE_EPISODE_LIST_CONTENT_DATA,
    data,
});


export const AddEpisodeListContent = (data: Array<episodeListContentDataModifiedObj>): AddEpisodeListContentAction => ({
    type: ADD_EPISODE_LIST_CONTENT_DATA,
    data,
});

export const UpdateEpisodeStatusContent = (data:  number) => ({
    type: UPDATE_EPISODE_STATUS,
    data,
});


export const DeleteEpisodeContent = (data:  number) => ({
    type: DELETE_EPISODE_STATUS,
    data,
});


export const EpisodeSearchText = (data:  string) => ({
    type: SAVE_EPISODE_SEARCH_TEXT,
    data,
});

export const EpisodeCurrentPage = (data:  number) => ({
    type: SAVE_EPISODE_CURRENT_PAGE,
    data,
});

export const TotalEpisodeSave = (data:  number) => ({
    type: SAVE_TOTAL_EPISODE,
    data,
});

export const EpisodeResetAction = () => ({
    type: RESET
});
