import { creatorState } from "../../store/creator"

export const creatorMapper = (creatorData:any, token:string) => {
    let creator:creatorState = {
        id : creatorData.id,
        firstName: creatorData.firstName,
        lastName: creatorData.lastName,
        isProfileCreated: creatorData.isProfileCreated,
        email: creatorData.email,
        email_verified: creatorData.email_verified,
        profile_pic_path: creatorData.profile_pic_path,
        handle: creatorData.handle,
        access_token: token,
        role: "CREATOR",
        bio: creatorData.bio
    }
    return creator;
}

export const profileApiMapper = (data:any) => {
    let creatorProfile:any = {
        firstName: data.firstName?.trim(),
        lastName: data.lastName?.trim(),
        handle: data.handle?.trim(),
        profile_pic_path: data.profile_pic_path ? data.profile_pic_path : '',
        bio: data.bio?.trim() ?? '',

    }

    if (data.email){
        creatorProfile.email =  (data.email) ? (data.email.trim()).toLowerCase() : ""
        creatorProfile.password = data.password
    }

    if (data?.id){
        creatorProfile.creator_id = (data.id).toString();
    }

    return creatorProfile;
}
