import { UPDATE_EPISODE_DATA, CLEAR_EPISODE_DATA, SHOW_PODCAST_UPLOAD_LOADER, HIDE_PODCAST_UPLOAD_LOADER } from "../../constants";
import { SaveEpisodeDetailsAction, ClearEpisodeDetaisAction, EpisodeInterface, ShowPodcastUploadingLoaderAction, HidePodcastUploadingLoaderAction, PodcastUploadInterface } from "./types";

export const saveEpisodeDetails = (payload: EpisodeInterface) : SaveEpisodeDetailsAction => ({
    type: UPDATE_EPISODE_DATA,
    payload: payload
});

export const clearEpisodeDetails = () : ClearEpisodeDetaisAction => ({
    type: CLEAR_EPISODE_DATA
});

export const ShowPodcastUploadingBar = (payload: PodcastUploadInterface) : ShowPodcastUploadingLoaderAction => ({
    type: SHOW_PODCAST_UPLOAD_LOADER,
    payload: payload
});


export const HidePodcastUploadingBar = () : HidePodcastUploadingLoaderAction => ({
    type: HIDE_PODCAST_UPLOAD_LOADER
});

