import { Avatar, Button, Drawer, Grid, List, ListItemIcon, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { AdminResetAction } from "../../store/adminList"
import { ConsumerResetAction } from "../../store/consumerList"
import { CreatorResetAction } from "../../store/creatorList"
import { EpisodeResetAction } from "../../store/episodeList"
import { MatchResetAction } from "../../store/matchList"
import { ShowResetAction } from "../../store/showList"
import { HidePodcastUploadingBar } from "../../store/episode"


import BrandLogo from "../../icons/BrandLogo";
import {
    NavLink
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { creatorLoggedOut } from "../../store/creator";


import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import RememberMeOutlinedIcon from '@mui/icons-material/RememberMeOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

import { RootStateInterface } from "../../store";
import ListIcon from "../../icons/ListIcon";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingIcon from "../../icons/SettingIcon";
import LiveStreamIcon from "../../icons/LiveStream";
import ShowIcon from "../../icons/ShowsIcon";
import NotificationIcon from "../../icons/NotificationIcon";
import { userLoggedOut } from "../../store/user";

const creatorMenuList = [
    {
        text: "Dashboard",
        icon: <ListIcon />,
        url: "/creator/dashboard"
    },
    // {
    //     text: "Live Streams",
    //     icon: <LiveStreamIcon />,
    //     url: "/creator/live-stream"
    // },
    {
        text: "Profile",
        icon: <SettingIcon />,
        url: "/creator/settings"
    },
];

// const adminMenuList = [
//     {
//         text: "Dashboard",
//         icon: <ListIcon />,
//         url: "/admin/dashboard"
//     },
//     {
//         text: "Live Streams",
//         icon: <LiveStreamIcon />,
//         url: "/admin/live-stream"
//     },
//     {
//         text: "Shows",
//         icon: <ShowIcon />,
//         url: "/admin/show"
//     },
//     {
//         text: "Push Notifications",
//         icon: <NotificationIcon />,
//         url: "/admin/app/notification"
//     },
//     {
//         text: "App Version",
//         icon: <SettingIcon />,
//         url: "/admin/app/version"
//     },
//     // {
//     //     text: "Creators",
//     //     icon: <RecordVoiceOverIcon />,
//     //     url: "/admin/creators/list"
//     // },

//     {
//         text: "Admin",
//         icon: <RecordVoiceOverIcon />,
//         url: "/admin/admin/list"
//     },
// ]

const sidebarStyle = {
    drawer: {
        display: 'flex',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            alignItems: 'center',
        },
    },
    logo: {
        width: '95px',
        height: 'auto',
        textAlign: 'center',
        alignItems: 'center',
        maxHeight: { xs: 233, md: 167 },
        maxWidth: { xs: 350, md: 250 },
        marginTop: "40px"
    },
    menusList: {
        '& .MuiListItem-root:hover': {
            background: 'linear-gradient(132.14deg, #F89E24 6.93%, #F6413C 90.47%)'
        }
    },
    profilePicture: {
        minWidth: '70px',
        minHeight: '70px',
        maxWidth: '95px',
        maxHeight: '100px',
        textAlign: 'center',
    }
}

const SidebarLayout = (props: any) => {

    const dispatch = useDispatch();
    // const creator = useSelector((state:RootStateInterface) => state.user);
    // const creatorToken = useSelector((state:RootStateInterface) => state.creator.access_token);

    const user = useSelector((state:RootStateInterface) => state.user);

    const handleStore = () => {
        dispatch(AdminResetAction())
        dispatch(userLoggedOut())
        dispatch(ConsumerResetAction())
        dispatch(CreatorResetAction())
        dispatch(EpisodeResetAction())
        dispatch(HidePodcastUploadingBar())
        dispatch(MatchResetAction())
        dispatch(ShowResetAction())
    }

    if (!user.permissions){
        user.permissions = {
            match:true,
            series:true,
            push:true,
            app_version:true,
            creator:true,
            series_delete:true,
            consumer:true,
        }
    }
    user.permissions.consumer=true;

    let adminMenuList = [
        {
            text: "Dashboard",
            icon: <ListIcon />,
            url: "/admin/dashboard"
        }
    ]

    let permission = user.permissions


    if (permission.match) {
         adminMenuList.push({
                text: "Live Matches",
                icon: <LiveStreamIcon />,
                url: "/admin/match"
            })
    }
    if (permission.series) {
        adminMenuList.push( {
            text: "Shows",
            icon: <ShowIcon />,
            url: "/admin/show"
        })
   }

    if (permission.creator) {
         adminMenuList.push(  {
            text: "Creators",
            icon: <RecordVoiceOverOutlinedIcon />,
            url: "/admin/creators"
        })
     }

     if (permission.consumer) {
        adminMenuList.push(  {
           text: "Users",
           icon: <AccountCircleIcon />,
           url: "/admin/consumers"
       })
    }


     if (user.role === "superadmin"){
        adminMenuList.push({
            text: "Admin",
            icon: <SupervisorAccountOutlinedIcon />,
            url: "/admin/admin"
        })

        adminMenuList.push({
            text: "Packages",
            icon: <InventoryOutlinedIcon />,
            url: "/admin/package"
        })
     }


     if (permission.push) {
        adminMenuList.push({
             text: "Push Notifications",
             icon: <NotificationIcon />,
             url: "/admin/app/notification"
         })
     }

     if (permission.app_version) {
         adminMenuList.push({
             text: "App Version",
             icon: <SettingIcon />,
             url: "/admin/app/version"
         })
     }


    const menuList = (user.role === "creator") ? creatorMenuList : adminMenuList;

    return (
        <Grid>
            <Drawer
                sx={sidebarStyle.drawer}
                variant="permanent"
                anchor="left"
                >
                {/* {(user.role ===  "creator")? */}
                <>
                <Box className='menuItem--list'>
                    <Box className='brandlogo'>
                        <NavLink to={menuList[0].url}>
                            <img src="/images/loho-without-desc.png" alt="9994DM-logo" />
                            {/* <BrandLogo/> */}
                        </NavLink>
                    </Box>
                </Box>
                <Box className='creator__side'>
                    <Box className='menuItem--list'>
                    <List className='' sx={sidebarStyle.menusList}>

                        {menuList.map((item, index) => {
                            return (
                                <NavLink onClick={()=> {props.handleDrawerToggle()}} to={item.url} key={index} className={({ isActive }) =>
                                isActive ? 'sidebar-link active' : 'sidebar-link'
                            }>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText sx={{margin: '0px'}} primary={item.text} />
                                </NavLink>
                            );
                        })}
                    </List>
                    </Box>
                </Box>
                <Box className='avatar--logo'>

                    <Avatar
                        sx={sidebarStyle.profilePicture}
                        alt={user.firstName}
                        src={user.profile_pic_path}
                        variant="square"
                        className="avator--img"
                        />
                    <Box component="p" sx={{ paddingTop: '10px' }}>👋 <span className="ms--1 fw--400">Hello,</span> <b>{user.firstName}</b></Box>
                    <Box className='m-t-30'>
                        <Button className='cancel--btn' variant="contained" onClick={() => handleStore()}>Log Out</Button>
                    </Box>
                </Box>
                </>
            </Drawer>
        </Grid>
    );
}

export default SidebarLayout;
