export const detectAnchorLinks = (str: string) => {
    return str.replace(
      /(https?:\/\/[^\s]+)/g,
      "<a href='$1' target='_blank' >$1</a>"
    );
};

export const StringToHtml = (str: any) => {
    return str.replace(
        new RegExp('\r?\n','g'), '<br />'
    );
}
