import React from 'react';
import ShowCreateForm from '../show/Form';
import GoToTop from '../common/GoTop'


interface ShowCreateProps {
    modeType: string;
    back: string;
}

const ShowCreate = (props:ShowCreateProps) => {
    return (
        <>
            {//@ts-ignore
            <ShowCreateForm modeType={props.modeType} back={props.back} />}
            <GoToTop/>
        </>
    );

}


export default ShowCreate;
