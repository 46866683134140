import React from 'react';
import PackageCreateForm from '../package/Form';
import GoToTop from '../common/GoTop'


interface PackageCreateProps {
    modeType: string;
}

const PackageAdd = (props:PackageCreateProps) => {
    return (
        <>
            {//@ts-ignore
            <PackageCreateForm modeTypeProp={props.modeType} />}
            <GoToTop/>
        </>
    );

}


export default PackageAdd;
