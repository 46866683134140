import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import PlayIcon from '../../icons/PlayIcon';
import LikeIcon from '../../icons/LikeIcon';
import GiftIcon from '../../icons/GiftIcon';
import ViewIcon from '../../icons/ViewIcon';
import DataTable from '../common/DataTable';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import { Link } from 'react-router-dom';

import MovieIcon from '@mui/icons-material/Movie';
import RadioIcon from '@mui/icons-material/Radio';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { DASHBOARD_API, CREATOR_API } from '../../constants';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { userContentData, userContentDataModified } from '../../data/mappers/dashboard';
import { UpdateUserContent } from '../../store/dasboard';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import GroupIcon from '@mui/icons-material/Group';
import GoToTop from '../common/GoTop'

interface dataTypes {
    creator_count: number;
    episode_gift_count: number;
    episode_count: number;
    liked_episode_count: number;
    live_match_count: number;
    series_count: number;
}

interface creatorDataTypes {
    episode_count: number;
    liked_episode_count: number;
    // live_match_count: number;
    series_count: number;
}

const Dashboard = (props: any) => {
    const dispatch = useDispatch();
    let user = useSelector((state: RootStateType) => state.user);
    let token = useSelector((state: RootStateType) => state.user.access_token);

    let role = user.role;

    const userContent = useSelector((state: RootStateType) => state.dashboard.userContent);
    const [buttonSelected, setButtonSelected] = useState(0);
    const boxs = [0, 1, 2];
    const handleOnClick = (index: any) => setButtonSelected(index);

    let initialValues = {
        creator_count: 0,
        episode_gift_count:0,
        episode_count: 0,
        liked_episode_count: 0,
        live_match_count: 0,
        series_count: 0
    }

    let initialCreatorValues = {
        episode_count: 0,
        liked_episode_count: 0,
        // live_match_count: number;
        series_count: 0
    }

    const [data, setData] = useState<dataTypes>(initialValues)
    const [creatorData, setCreatorData] = useState<creatorDataTypes>(initialCreatorValues)

    useEffect(() => {
        getUserContent();
        getTotalValues();
    }, []);


    const getTotalValues = async () => {
        if (role !== 'creator') {
            const res: any = await postRequest(DASHBOARD_API.ADMIN, {}, token);
            if (isApiCodeSucess(res)) {
                setData(res.data.data)
            }
        }
        else if (role === 'creator'){
            const res: any = await postRequest(DASHBOARD_API.CREATOR, {}, token);
            if (isApiCodeSucess(res)) {
                setCreatorData(res.data.data)
            }
        }
    }


    const getUserContent = async () => {
        if (role === 'creator') {
            const res: any = await postRequest(CREATOR_API.OWN_CREATOR, {}, token);
            if (isApiCodeSucess(res)) {
                let formattedUserContentData = res.data?.data as userContentData,
                    formattedUserContent: userContentDataModified = [];

                formattedUserContentData.forEach((x) => {

                    let matchStatus = (x.status === "upcoming")
                    ? "Scheduled" : (x.status === "completed") ?
                    "Ended" : "Live";


                    formattedUserContent.push({
                        date: x.startTime,
                        title: x.title,
                        isEnded:x.isEnded,
                        // type: x.streams
                        //     .map((streams) => {
                        //         return streams.eventType;
                        //     })
                        //     .join(' '),
                        type: matchStatus,

                        id: x.id,
                        streamtags: x.streams
                            .map((streams) => {
                                return streams.streamtags
                                    .map((streamtags) => {
                                        return '#' + streamtags;
                                    })
                                    .join(' ');
                            })
                            .join(' '),
                        count: '0',
                    });
                });
                dispatch(UpdateUserContent(formattedUserContent));
            }
        }
    };

    const buttonBox = boxs.map((el, index) => {
        return (
            <Button
                type="button"
                styleName={`button--outlined ${buttonSelected === index ? 'active' : ''}`}
                key={index}
                onClick={() => handleOnClick(index)}
            >
                {el === 0 ? <>All Time</> : el === 1 ? <>Weekly</> : <>Daily</>}
            </Button>
        );
    });

    return (
        <>
            <Box className="dashboard__topbar">
                <Box className="row">
                    <Box className="dashboard__topbar--heading col-12 md-col-4">
                        <Box component="h2">Dashboard</Box>
                    </Box>
                    <Box className="col-12 md-col-8">
                        {/* <Box className="button__box">{buttonBox}</Box> */}
                    </Box>
                    <Box className="col-12">
                        <Box className="card__columns">
                            <Box className="card__columns--box stripe--danger">
                                <Box className="stripe--shape">
                                    <img src="../images/stripe-video.svg" alt="99.94DM-stripe-videos" />
                                </Box>
                                <Link to= {(role !== 'creator') ? "/admin/match" : "" }>
                                    <Box className="card__columns--inner">
                                        <Box className="card__columns--inner--icon">
                                            <PlayIcon />
                                        </Box>
                                        <Box className="card__columns--inner--text">
                                            <span className="card__columns--title">Live Matches</span>
                                            <p className="card__columns--numbers">{(role === "creator") ? 0  : data.live_match_count }</p>
                                        </Box>
                                    </Box>
                                </Link>
                            </Box>


                            <Box className="card__columns--box stripe--secondary">
                                <Box className="stripe--shape">
                                    <img src="../images/stripe-like.svg" alt="99.94DM-stripe-icons" />
                                </Box>
                                <Link to= {(role !== 'creator') ? "/admin/show" : "" }>
                                    <Box className="card__columns--inner">
                                        <Box className="card__columns--inner--icon">
                                            <MovieIcon />
                                        </Box>
                                        <Box className="card__columns--inner--text">
                                            <span className="card__columns--title">Total Shows</span>
                                            <p className="card__columns--numbers">{(role === "creator") ? creatorData.series_count : data.series_count}</p>
                                        </Box>
                                    </Box>
                                </Link>
                            </Box>

                            <Box className="card__columns--box stripe--newPrimary">
                                <Box className="stripe--shape">
                                    <img src="../images/stripe-like.svg" alt="99.94DM-stripe-icons" />
                                </Box>
                                <Link to= {(role !== 'creator') ? "/admin/show" : "" }>
                                    <Box className="card__columns--inner">
                                        <Box className="card__columns--inner--icon">
                                            <RadioIcon />
                                        </Box>
                                        <Box className="card__columns--inner--text">
                                            <span className="card__columns--title">Total Podcasts</span>
                                            <p className="card__columns--numbers">{(role === "creator") ? creatorData.episode_count : data.episode_count}</p>
                                        </Box>
                                    </Box>
                                </Link>

                            </Box>


                            {/* <Box className="card__columns--box stripe--primary">
                                <Box className="stripe--shape">
                                    <img src="../images/stripe-like.svg" alt="99.94DM-stripe-icons" />
                                </Box>
                                <Box className="card__columns--inner">
                                    <Box className="card__columns--inner--icon">
                                        <LikeIcon />
                                    </Box>
                                    <Box className="card__columns--inner--text">
                                        <span className="card__columns--title">Total Likes</span>
                                        <p className="card__columns--numbers">{(role === "creator") ? creatorData.liked_episode_count : data.liked_episode_count}</p>
                                    </Box>
                                </Box>
                            </Box> */}
                            {/* <Box className="card__columns--box stripe--accent">
                                <Box className="stripe--shape">
                                    <img src="../images/stripe-gift.svg" alt="99.94DM-stripe-gifts" />
                                </Box>
                                <Box className="card__columns--inner">
                                    <Box className="card__columns--inner--icon">
                                        <GiftIcon />
                                    </Box>
                                    <Box className="card__columns--inner--text">
                                        <span className="card__columns--title">Total Gifts</span>
                                        <p className="card__columns--numbers">$0</p>
                                    </Box>
                                </Box>
                            </Box> */}
                            {/* <Box className="card__columns--box stripe--warning">
                                <Box className="stripe--shape">
                                    <img src="../images/stripe-views.svg" alt="99.94DM-stripe-views" />
                                </Box>
                                <Box className="card__columns--inner">
                                    <Box className="card__columns--inner--icon">
                                        <ViewIcon />
                                    </Box>
                                    <Box className="card__columns--inner--text">
                                        <span className="card__columns--title">Total Views</span>
                                        <p className="card__columns--numbers">0</p>
                                    </Box>
                                </Box>
                            </Box> */}

                            {
                            (role !== 'creator') ?
                            <>
                            <Box className="card__columns--box stripe--creatorCard">
                                <Box className="stripe--shape">
                                    <img src="../images/stripe-like.svg" alt="99.94DM-stripe-icons" />
                                </Box>
                                <Link to="/admin/creators">
                                    <Box className="card__columns--inner">
                                        <Box className="card__columns--inner--icon">
                                            <PeopleAltIcon />
                                        </Box>
                                        <Box className="card__columns--inner--text">
                                            <span className="card__columns--title">Total Creators</span>
                                            <p className="card__columns--numbers">{data.creator_count}</p>
                                        </Box>
                                    </Box>
                                </Link>
                            </Box>

                             {/* <Box className="card__columns--box stripe--episodeGiftCard">
                                <Box className="stripe--shape">
                                    <img src="../images/stripe-like.svg" alt="99.94DM-stripe-icons" />
                                </Box>
                                <Link to="/admin/all/gift/spend">
                                    <Box className="card__columns--inner">
                                        <Box className="card__columns--inner--icon">
                                            <RedeemOutlinedIcon />
                                        </Box>
                                        <Box className="card__columns--inner--text">
                                            <span className="card__columns--title">Run Received</span>
                                            <p className="card__columns--numbers">{data.episode_gift_count}</p>
                                        </Box>
                                    </Box>
                                </Link>
                             </Box> */}

                              <Box className="card__columns--box stripe--accent">
                                <Box className="stripe--shape">
                                    <img src="../images/stripe-gift.svg" alt="99.94DM-stripe-gifts" />
                                </Box>
                                <Link to="/admin/all/gift/spend">

                                    <Box className="card__columns--inner">
                                        <Box className="card__columns--inner--icon">
                                            <GiftIcon />
                                        </Box>
                                        <Box className="card__columns--inner--text">
                                            <span className="card__columns--title">Run Received</span>
                                            <p className="card__columns--numbers">{data.episode_gift_count}</p>
                                        </Box>
                                    </Box>
                                </Link>
                            </Box>

                            {/* <Box className="card__columns--box stripe--packages">
                                <Box className="stripe--shape">
                                    <img src="../images/stripe-gift.svg" alt="99.94DM-stripe-gifts" />
                                </Box>
                                <Link to="/admin/all/gift/spend">

                                    <Box className="card__columns--inner">
                                        <Box className="card__columns--inner--icon">
                                            <InventoryOutlinedIcon />
                                        </Box>
                                        <Box className="card__columns--inner--text">
                                            <span className="card__columns--title">Packages</span>
                                            <p className="card__columns--numbers">650</p>
                                            <p className='card__columns--subtext'>Active packages : 65</p>
                                        </Box>
                                    </Box>
                                </Link>
                            </Box> */}

                            {/* <Box className="card__columns--box stripe--users">
                                <Box className="stripe--shape">
                                    <img src="../images/stripe-gift.svg" alt="99.94DM-stripe-gifts" />
                                </Box>
                                <Link to="/admin/all/gift/spend">

                                    <Box className="card__columns--inner">
                                        <Box className="card__columns--inner--icon">
                                            <GroupIcon />
                                        </Box>
                                        <Box className="card__columns--inner--text">
                                            <span className="card__columns--title">Total users</span>
                                            <p className="card__columns--numbers">250</p>
                                        </Box>
                                    </Box>
                                </Link>
                            </Box> */}

                            </>
                            : "" }
                        </Box>
                    </Box>
                </Box>
            </Box>
            {
                (user.role === 'creator')
                ?
                <Box className="dashboard__content">
                    <Box component="h2" className="dashboard__topbar--heading">
                        Manage Content
                    </Box>
                    <Box>
                        <DataTable userContent={userContent} />
                    </Box>
                </Box>
                :
                ""
            }
            <GoToTop/>
        </>
    );
};

export default Dashboard;
