import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { PasswordDialog } from '../common/PassDialog';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import Chip from '@mui/material/Chip';

import { CONSUMER_ADMIN_API, UPLOAD_FILE } from '../../constants';
import { postFileRequest, postRequest, putRequest } from '../../data/helpers/services';
import { profileApiMapper } from '../../data/mappers';
import UploadIcon from '../../icons/UploadIcon';
import { RootStateType } from '../../store';
import { Button } from '../common/Button';
import { TextArea, TextField } from '../common/TextField';
// @ts-ignore
import profilepicplaceholder from '../../styles/images/placeholder.png';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import Compressor from 'compressorjs';
import EmailIcon from '@mui/icons-material/Email';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FlagIcon from '@mui/icons-material/Flag';
import PhoneIcon from '@mui/icons-material/Phone';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ConsumerTransactionsList from './ConsumerTransactions';

interface ConsumerProfileFormInterface {
    bio:string;
    country_data:any;
    createdAt:string;
    email:string;
    email_verified:boolean;
    firstName:string;
    handle:string;
    isProfileCreated:boolean;
    lastName:string;
    profile_pic_path:string;
    signupType:string;
    status:string;
    phone:string;
    country_code:string;
}

const validate = (values: ConsumerProfileFormInterface) => {
    const errors: any = {}

//     let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+(?:\.[a-zA-Z0-9-]+)*$/;

//     if (!values.email || (values.email && !values.email.trim())) {
//         errors.email = 'Field is required.';
//     } else if(!(values.email).match(validRegex)){
//         errors.email = 'Please enter valid email address.';
//     }
//     // else if (values.email && values.email.trim().length > 32) {
//     //     errors.email = 'Field value must be 32 characters or less.';
//     // }

//     if (!values.password || (values.password && !values.password.trim())) {
//         errors.password = 'Field is required.';
//     } else if (values.password && values.password.trim().length < 8) {
//         errors.password = 'Password must contains at least 8 characters.';
//     }
//     else if (values.password && values.password.trim().length > 32) {
//         errors.password = 'Field value must be 32 characters or less.';
//     }

//     if (!values.firstName || (values.firstName && !values.firstName.trim())) {
//         errors.firstName = 'Field is required.';
//     } else if (values.firstName && values.firstName.trim().length > 32) {
//         errors.firstName = 'Field value must be 32 characters or less.';
//     }

//     if (!values.lastName || (values.lastName && !values.lastName.trim())) {
//         errors.lastName = 'Field is required.';
//     } else if (values.lastName && values.lastName.trim().length > 32) {
//         errors.lastName = 'Field value must be 32 characters or less.';
//     }

//     if (!values.handle || (values.handle && !values.handle.trim())) {
//         errors.handle = "Field is required.";
//     } else if (values.handle && values.handle.length > 20) {
//         errors.handle = 'Field value must be 20 characters or less.';
//     } else if (values.handle.trim().indexOf(" ") !== -1 || values.handle.trim().indexOf("@") !== -1) {
//         errors.handle = 'Spaces and @ is not allowed in handle.';
//     }

//     if (values.bio && values.bio.trim().length > 4096) {
//         errors.bio = "Field value must be 4096 characters or less.";
//     }

    return errors
}

const ConsumerDetail = (props:any) => {
    const { handleSubmit, change, submitting } = props;
    const { id } = useParams();

    const [ modeType, setModeType] = useState<string | undefined>(props.modeType ?? "detail");

    const user = useSelector((state: RootStateType) => state.user);
    const token = user.access_token;

    const [ consumerDetails, setConsumerDetails ] = useState<ConsumerProfileFormInterface | null>(null);
    const [ emailVerified, setEmailVerified ] = useState<boolean>(false);



    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [profileImage, setProfileImage] = useState<string>("");
    const [fileUploading, setFileUploading] = useState(false);

    // const saveProfile = async (data: any) => {
    //     if (!profileImage){
    //         dispatch(ShowErrorAlert({ visible: true, message: 'Profile Pic is required' }));
    //     }
    //     else{
    //         dispatch(ShowProgressBar());
    //         if (!data.profile_pic_path){
    //             data.profile_pic_path = profileImage
    //         }
    //         const apiData = profileApiMapper(data);

    //         let response: any = {}

    //         if (creatorDetails){
    //             if (creatorDetails?.is_password_exists ||  (newPassword !== (undefined ?? ""))) {
    //                 apiData.password = newPassword
    //                 response = await putRequest(CREATOR_ADMIN_API.EDIT, apiData, token);
    //             }
    //             else {
    //                 dispatch(ShowErrorAlert({ visible: true, message: 'Password does not exist for this profile' }));
    //             }
    //         }
    //         else {

    //             response = await postRequest(CREATOR_ADMIN_API.CREATE, apiData, token);
    //         }
    //         if (response) {
    //             const resData = response.data;
    //             dispatch(HideProgressBar());
    //             if (resData.code === 200) {
    //                 dispatch(ShowSuccessAlert({visible:true, message: "Profile saved successfully."}));
    //                 if (modeType === "edit"){
    //                     fillCreatorDetails(creatorDetails);

    //                     setModeType('detail')
    //                 }
    //                 else {
    //                     navigate('/admin/creators', { replace: true })
    //                 }
    //             }
    //         }
    //     }
    // }

    // const handleCompressedUpload = async(e: any) => {
    //     const image = e.target.files[0];
    //     new Compressor(image, {
    //       quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
    //     success: async(res) => {
    //         let reader = new FileReader();
    //         reader.onload = (e: any) => {
    //             setProfileImage(e.target.result);
    //         }
    //         reader.readAsDataURL(e.target.files[0]);

    //         // upload file and obtain url for image
    //         setFileUploading(true);
    //         dispatch(ShowProgressBar());

    //         const formData = new FormData();

    //         formData.append('file', res, (Date.now()).toString() + e.target.files[0].name );

    //         const response: any = await postFileRequest(UPLOAD_FILE, formData, token);

    //         if (response) {
    //             const resData = response.data;
    //             if (resData.code === 200) {
    //                 let fileUrl = resData.data.file_path;
    //                 change( 'profile_pic_path', fileUrl);
    //             }
    //         } else {
    //             setProfileImage('')
    //         }

    //         dispatch(HideProgressBar());
    //         setFileUploading(false);

    //         return true;

    //       },
    //     });
    //   };


    // const handleFileSelect = async (event: any) => {
    //     const filetype = event.target.files[0].type;

    //     const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
    //     // if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 10000)) {
    //     if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 500)) {
    //         event.target.value = null;
    //         dispatch(ShowErrorAlert({visible:true, message: "Must be a .png, .jpg, .jpeg, .webp format image & not more than 500kb"}));
    //         dispatch(HideProgressBar());
    //         setFileUploading(false);
    //         return false;
    //     }
    //     setFileUploading(true);
    //     dispatch(ShowProgressBar());

    //     handleCompressedUpload(event);
    //     return true;
    // }

    const initForm = () => {
    }

    useEffect(() => {
        initForm();
    }, []);

    useEffect(() => {
        if(modeType === 'add') {
            initForm();
        }else if (modeType === 'detail') {
            getConsumerDetails(id ?? "");
        }
    }, [modeType]);

    useEffect(() => {
        if (id && ((modeType === 'edit') || (modeType === 'detail'))) {
            getConsumerDetails(id);
        }
    }, [id]);

    useEffect(() => {
        if (consumerDetails && ((modeType === 'edit') || (modeType === 'detail'))) {
            fillConsumerDetails(consumerDetails);
        }
    }, [consumerDetails]);


    const getConsumerDetails = async (id: string) => {
        dispatch(ShowProgressBar());

        const response = await postRequest(
            CONSUMER_ADMIN_API.DETAIL,
            {
                consumer_id: id,
            },
            token
        );

        if (response) {
            if (response.data.code === 200) {
                setConsumerDetails(response.data.data);
            }
        }
        dispatch(HideProgressBar());
    };


    const fillConsumerDetails = async (consumerDetails:any) => {
        // change('id', consumerDetails?.id);
        // change('lastName', consumerDetails?.lastName);
        // change('firstName', consumerDetails?.firstName);
        // change('email', consumerDetails?.email);
        // change('phone', consumerDetails?.phone);
        // change('country', consumerDetails?.country_data?.country_name);
        setProfileImage(consumerDetails?.profile_pic_path)
        setEmailVerified(consumerDetails?.email_verified)
    }


    const updateModeType = () => {
        if (modeType === "detail"){
            setModeType("edit");
        }
        else{
            setModeType("detail");
        }
    }

    const buttonBox = (
        <>
        <Button styleName='btn--pill button--outlined active' type='button'
            onClick={() => navigate('/admin/consumers/' + id +'/transactions')}>
            Transactions
        </Button>
        </>
    );

    return (
        <>
        <Box className="dashboard__topbar">
            <Box className="row align--items--center">

                <div className="md-col-8 mb--20 mb--md--0">
                    <Box className="button__text__navigate">
                        {
                        (modeType !== "edit") ?
                            <button onClick={() => navigate(-1)}><ArrowBackOutlinedIcon /></button>
                        :
                        <span></span>
                        }
                        {/* <Box className="dashboard__topbar--heading" component="h2">Show Details</Box> */}
                        <Box className="breadcrumb__title dashboard__topbar--heading" component="h2">
                            {(modeType === "add") ? "Add User" : (modeType === "edit") ? "Edit User" : "User Details"}
                        </Box>
                    </Box>
                </div>

                {(modeType === "detail") ?
                // <div className="md-col-4">
                //     <Box className="button__box">{buttonBox}</Box>
                // </div>
                ""
                : ""
                }
            </Box>
        </Box>
        <Box className='dashboard__content max--760'>
            {/* <form onSubmit={handleSubmit(saveProfile)}> */}
            {/* <form> */}
                {/* <div className="row"> */}
                    {/* <div className='col-12 m-b-20'>
                        <div className='profilepic__circle__wrapper'>
                            <div className='profilepic__circle'>
                                <img src={(profileImage) ? profileImage : profilepicplaceholder} alt="creator Profile of 99.94 DM" />
                            </div>
                            {/* <Box component={"label"}>
                                <Button type='button' styleName='pic--uploadBtn'>
                                    <span>
                                        <UploadIcon />
                                    </span>
                                </Button>
                            </Box> */}
                            <Field
                                name="profile_pic_path"
                                type="hidden"
                                component="input"
                                id="profile_pic_path"
                                placeholder="profile_pic_path"
                                />
                            {/* <input type="file" name="profile_pic" disabled={modeType==="detail"} className="hidden-file-input" onChange={handleFileSelect} /> */}
                            {/* <input type="file" name="profile_pic" disabled={modeType==="detail"} className="hidden-file-input" />
                        </div>
                        <Box sx={{
                            fontSize: '14px',
                            marginTop: '8px',
                            display: 'grid',
                            fontWeight: 400,
                            gridTemplateColumns: '30px 1fr',
                            alignItems: 'center',
                            color: '#fff'
                        }}>
                            <HelpOutlineIcon/>
                            Recomended size is 180X180 for best fit.
                        </Box>
                    </div> */}



                    {/* <div className='col-12 md-col-6 m-t-20 m-b-20'>
                        <label className='input--label' htmlFor="profile">Profile   -   {
                        consumerDetails?.isProfileCreated
                        ?
                        <Chip variant="outlined" color="success" label="Completed"/>
                        :
                        (!consumerDetails) ?
                        ""
                        :
                        <Chip variant="outlined" color="warning" label="pending"/>
                        }
                        </label>
                    </div>

                    <div className='col-12 md-col-6 m-t-20 m-b-20'>
                        <label className='input--label' htmlFor="status">Status    -    {
                        (consumerDetails?.status === "active")
                        ?
                        <Chip variant="outlined" color="success" label="Active"/>
                        :
                        (consumerDetails?.status === "inactive") ?
                        <Chip variant="outlined" color="error" label="Inactive"/>
                        :
                        ""
                        }</label>
                    </div>


                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="firstName">First Name</label>
                        <Field name="firstName" component={TextField} type="text" placeholder=''
                            styleName='input--transparent' disabled={modeType==="detail"} />
                    </div>
                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="lastName">Last Name</label>
                        <Field name="lastName" component={TextField} type="text" placeholder=''
                            styleName='input--transparent' disabled={modeType==="detail"} />
                    </div>

                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="email">Email    {
                        emailVerified
                        ?
                        <VerifiedOutlinedIcon color="success"/>
                        :
                        (consumerDetails?.signupType === "email")
                        ?
                        <CancelOutlinedIcon color="error"/>
                        :
                        ""
                        }
                        </label>
                        <Field name="email" component={TextField} type="text" placeholder=''
                            styleName='input--transparent' disabled={modeType==="detail"} />
                    </div>

                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="country">Country</label>
                        <Field name="country" component={TextField} type="text" placeholder=''
                            styleName='input--transparent text--initial' disabled={modeType==="detail"}/>
                    </div>

                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="phoe">Phone Number  {
                            (consumerDetails?.phone) ?
                            <VerifiedOutlinedIcon color="success"/>
                            :
                            (consumerDetails?.signupType === "phone")
                            ?
                            <CancelOutlinedIcon color="error"/>
                            :
                            ""
                        }
                        </label>
                        <Field name="phone" component={TextField} type="text" placeholder=''
                            styleName='input--transparent' disabled={modeType==="detail"} />
                        </div> */}
                {/* </div> */}
                {/* </form> */}

                <div className='workerprofile__wrapper'>
                    <div className='workerprofile__wrapper--container'>
                        <div className='profile__quality'>
                            <div className='workerprofile__wrapper--img'>
                                <img src={(profileImage) ? profileImage : profilepicplaceholder} alt="creator Profile of 99.94 DM" />
                            </div>
                            <div className='profile__quality--status'>
                                <label className='input--label'>{
                                    (consumerDetails?.status === "active")
                                    ?
                                    <Chip variant="outlined" color="success" label="Active"/>
                                    :
                                    (consumerDetails?.status === "inactive")
                                    ?
                                    <Chip variant="outlined" color="error" label="Inactive"/>
                                    : ''
                                    }
                                </label>
                            </div>
                        </div>
                        <div className='workerprofile__wrapper--content'>
                            <h4 className='worker-profile-heading' style={{paddingRight:'6rem', display:'inline', wordBreak:'break-all'}}>
                                {consumerDetails?.firstName} {consumerDetails?.lastName}
                            </h4>
                                {
                                    (consumerDetails && consumerDetails?.isProfileCreated) ?
                                    <div className='verified__status' >
                                            <Chip variant="outlined" color="success" label="Completed"/>
                                            {/* <VerifiedOutlinedIcon color="success"/> */}
                                        </div>
                                        : (consumerDetails && !consumerDetails?.isProfileCreated) ?
                                        <div className='verified__status'>
                                            <Chip variant="outlined" color="warning" label="pending"/>
                                            {/* <HourglassBottomIcon color="warning"/> */}

                                        </div>
                                        :
                                    ""
                                }
                            {
                                consumerDetails?.email ?
                            // (consumerDetails && (["email","google"].includes(consumerDetails?.signupType))) ?
                            <div className='td__mailinfo'>
                                <span>
                                    <EmailIcon/>
                                </span>
                                {consumerDetails?.email}
                                <div className="verified__status">
                                    {
                                        emailVerified
                                        ?
                                        <VerifiedOutlinedIcon color="success"/>
                                        :
                                        <CancelOutlinedIcon color="error"/>
                                    }
                                </div>
                            </div>
                            :
                            ""
                            }
                            {
                            consumerDetails?.signupType === "phone" ?
                            <div className='td__mailinfo'>
                                <span>
                                    <PhoneIcon/>
                                </span>
                                {(consumerDetails.country_code) +consumerDetails?.phone}
                                <div className="verified__status">
                                    <VerifiedOutlinedIcon color="success"/>
                                </div>
                            </div>
                            :
                            ""
                            }
                            {
                                consumerDetails?.country_data?.country_name ?

                                <div className='td__mailinfo'>
                                    <span>
                                        <FlagIcon/>
                                    </span>
                                    {consumerDetails?.country_data?.country_name}
                                </div>
                            :
                            ""
                            }
                        </div>
                    </div>
                </div>
        </Box>
                <ConsumerTransactionsList />
    </>
    );

}


export default reduxForm({
    form: 'consumerProfile',
    validate
})(ConsumerDetail)

// export default ConsumerDetail;
