import { Reducer } from 'react';
import { RESET, UPDATE_MATCH_LIST_CONTENT_DATA, ADD_MATCH_LIST_CONTENT_DATA, SAVE_MATCH_SEARCH_TEXT, SAVE_MATCH_CURRENT_PAGE, SAVE_TOTAL_MATCH } from '../../constants';
import { MatchListActions, MatchListState } from './types';

export const initialState: MatchListState = {
    matchListContent: [],
    searchedValue: "",
    currentPage: 0,
    totalMatch: 0
};

export const matchListReducer: Reducer<MatchListState, MatchListActions> = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_MATCH_LIST_CONTENT_DATA:
            return {
                ...state,
                matchListContent: [...(action?.data || [])],
            };

        case ADD_MATCH_LIST_CONTENT_DATA:
            return {
                ...state,
                // creatorListContent: [...state.creatorListContent, ...(action?.data || [])],
                matchListContent: state.matchListContent.concat(action?.data),
            };

        case SAVE_MATCH_SEARCH_TEXT:
            return {
                ...state,
                // showListContent: state.showListContent,
                searchedValue : action.data
            };

        case SAVE_MATCH_CURRENT_PAGE:
            return {
                ...state,
                currentPage : action.data
            };

        case SAVE_TOTAL_MATCH:
            return {
                ...state,
                totalMatch : action.data
            };

        case RESET:
            return {
                ...initialState //Always return the initial state
            };
        default:
            return state;
    }
};
