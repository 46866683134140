import { userState } from "../../store/user"

export const userMapper = (userData:any, token:string) => {
    let user:userState = {
    //     email: adminData.email.trim(),
    //     password: adminData.password.trim(),
    // }

        id : userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        isProfileCreated: userData.isProfileCreated,
        email: userData.email,
        email_verified: userData.email_verified,
        profile_pic_path: userData.profile_pic_path,
        handle: userData.handle,
        access_token: token,
        role: userData.role,
        bio: userData.bio,
        permissions: {
            match:userData.permissions.match,
            series:userData.permissions.series,
            push:userData.permissions.push,
            app_version:userData.permissions.app_version,
            creator:userData.permissions.creator,
            series_delete:userData.deleteShow,
            consumer:userData.permissions.consumer
            }
    }

    return user;
}


export const userLogin = (data:any) => {
    let user= {
        email: (data.email.trim()).toLowerCase(),
        password: data.password.trim(),
    }

    return user;
}

export const adminProfileApiMapper = (data:any, permissions:any) => {
    let adminProfile:any = {
        email: (data.email.trim()).toLowerCase(),
        password: data.password ?? "",
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        permissions: {
            match:permissions.match,
            series:permissions.show,
            push:permissions.push,
            app_version:permissions.appVersion,
            creator:permissions.creator,
            series_delete:permissions.deleteShow,
            consumer:permissions.consumer
            }
    }

    if (data.id){
        adminProfile.admin_id = (data.id).toString();
    }
    return adminProfile
}

export type adminListContentDataModified = adminListContentDataObj[]

export interface adminListContentDataObj {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    // status:boolean;
}


export interface adminListContentData {
    email: string;
    firstName: string;
    lastName: string;
    id: number;
    fullName: string;
    // status: boolean;
}
