import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { showListContentDataModifiedObj } from '../../data/mappers/showList';
import Stack from '@mui/material/Stack';

import { UpdateShowStatusContent, DeleteShowContent, ShowCurrentPage } from '../../store/showList'
import { ShowSuccessAlert, ShowProgressBar, HideProgressBar } from '../../store/alerts';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import { SaveSearchedShowContent } from '../../store/showList';

import { RootStateType } from '../../store';

import { PODCAST_API } from '../../constants';
import { postRequest } from '../../data/helpers/services';

import moment from 'moment';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export function SortedDescendingIcon() {
    return <ExpandMoreIcon className="icon" />;
  }

  export function SortedAscendingIcon() {
    return <ExpandLessIcon className="icon" />;
  }


function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{mr: 2}} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}



function UpdateShowStatus(params) {
    let dispatch = useDispatch()

    const [ updating, setUpdating ] = React.useState<boolean>(false)

    const [ showActive, setShowActive] = React.useState<boolean>(params.params.row.isActive)

    let token = useSelector((state: RootStateType) => state.user.access_token);
    let id = params.params.row.id;

    const handleClick = async () => {
        if (!updating){
            setUpdating(true);

            const res: any = await postRequest(PODCAST_API.SERIES_STATUS_UPDATE, {
                series_id: id,
                status: (showActive ? '2' : "1")
            }, token);
            if (res) {
                dispatch(ShowSuccessAlert({visible:true, message: "Show status updated successfully"}));
                setShowActive(!showActive);
                dispatch(UpdateShowStatusContent(id))
            }
            setUpdating(false);
        }
    }

    return (
        <div>
            <button className={`deleteButton normal--btn ${(showActive ? 'active--class' : 'deleted-class')}`} onClick={handleClick}>
                {(showActive) ? "Active" : "Inactive"}
            </button>
        </div>
    );
  }


function AlertDialog(params) {
    const [open, setOpen] = React.useState(false);
    let dispatch = useDispatch()
    const [inProgress, setInProgress] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    let token = useSelector((state: RootStateType) => state.user.access_token);
    let id = params.params.row.id;

    const deleteConfirmed = async() => {
        dispatch(ShowProgressBar());
        setInProgress(true);
        const res: any = await postRequest(PODCAST_API.SERIES_DELETE, {
            series_id: id
        }, token);
        dispatch(HideProgressBar());
        if (res) {
            setOpen(false);
            dispatch(ShowSuccessAlert({visible:true, message: "Show deleted successfully."}));
            dispatch(DeleteShowContent(id))
        }
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <button className='deleteButton normal--btn deleted-class' onClick={handleClickOpen}>
            Delete
        </button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <h4>{"Show Delete Confirmation"}</h4>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this show <b>{params.params.row.title}</b> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>

          {
                inProgress
                ?
                    <span>
                        <h4>Deletion in Progress...</h4>
                    </span>
                :
                    <>
                    <button className='deleteButton normal--btn cancel-class' onClick={handleClose}>
                        Cancel
                    </button>

                    <button className='deleteButton normal--btn deleted-class' onClick={deleteConfirmed}>
                        Delete
                    </button>
                    </>
            }          </DialogActions>
        </Dialog>
      </div>
    );
  }


const columns: GridColDef[] = [
    { field: 'displayOrder', headerName: 'Order', type: 'string', minWidth: 70, align: 'left',sortable: false, headerAlign: 'left', flex: 1, },
    { field: 'title', headerName: 'Title', type: 'string',sortable: false, minWidth: 200, flex: 1, },
    { field: 'hosts', headerName: 'Hosts', type: 'string',sortable: false, minWidth: 200, flex: 1, },
    // { field: 'description', headerName: 'Description', type: 'string', sortable: false, width: 200 },
    { field: 'count', headerName: 'Count', type: 'number', minWidth: 70, align: 'left',sortable: false, headerAlign: 'left', flex: 1, },

    { field: 'runs', headerName: 'Runs', type: 'string', minWidth: 70, align: 'left',sortable: false, headerAlign: 'left', flex: 1, },

    { field: 'id', headerName: 'ID', minWidth: 0, hide: true, flex: 1, },
    {
        field: 'date',
        headerName: 'Created on',
        type: 'date',
        sortable: false,
        minWidth: 170,
        flex: 1,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
                <label>{moment(params.row.date).format('D.MM.YYYY, h:mm a')}</label>
            </>
        ),
    },

    {
        field: 'edit',
        headerName: '',
        type: 'actions',
        headerAlign: 'left',
        minWidth: 100,
        flex: 1,
        sortable: false,
        cellClassName: 'edit-actions',
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Link to={`/admin/show/${params.row.id}/edit/`}>
                <span >Edit</span>
            </Link>
        ),
    },
    // {
    //     field: 'showActiveStatus',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'delete-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <>
    //         {/* <button className='deleteButton normal--btn deleted-class'>
    //             Delete
    //         </button> */}
    //         <UpdateShowStatus params={params} />
    //         </>
    //     ),
    // },
    //  {
    //     field: 'delete',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'delete-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <>
    //         {/* <button className='deleteButton normal--btn deleted-class'>
    //             Delete
    //         </button> */}
    //         <AlertDialog params={params} />
    //         </>
    //     ),
    // },
    {
        field: 'view',
        headerName: '',
        type: 'actions',
        headerAlign: 'left',
        minWidth: 100,
        flex: 1,
        sortable: false,
        cellClassName: 'view-actions',
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Link to={`/admin/show/${params.row.id}`}>
                <span><ArrowRightAltIcon/></span>
            </Link>
        ),
    },
];


interface showListContentDataTableProps {
    showListContent: Array<showListContentDataModifiedObj>;
    deleteAccess: boolean;
    offsetValue:any;
    setOffsetValue:any;
    maxNumber:number;
    searchValue: string;
    setSearchValue: any;
    loader:boolean;
    currentPage:number;
}

export default function ShowListDataTable({ showListContent, deleteAccess, offsetValue, setOffsetValue, maxNumber, searchValue, setSearchValue, loader, currentPage }: showListContentDataTableProps) {
    const [rows, setRows] = React.useState<Array<showListContentDataModifiedObj>>(showListContent);

    const [searchTimerReset , setSearchTimerReset ] = React.useState<boolean>(false);
    const [userInput , setUserInput ] = React.useState<any>("");
    const [searchExists , setSearchExists] = React.useState<boolean>(true);
    const [timerInstance, setTimerInstance ] = React.useState<any>();

    const dispatch = useDispatch();

    const updateSearch = () =>{
        dispatch(SaveSearchedShowContent(userInput ?? ""));
        setSearchValue(userInput);
        dispatch(ShowCurrentPage(0))
        setOffsetValue(0);
    };

    useEffect(() => {
        if (timerInstance){
            let check = clearTimeout(timerInstance);
        }
        if (!searchExists){
            let timer1 = setTimeout(() => updateSearch(), 1600);
            setTimerInstance(timer1)
        }
    },[searchTimerReset])

    const requestSearch = (search: string) => {
        if (searchValue !== search) {
            setUserInput(search);
            setSearchExists(false);
            setSearchTimerReset(!searchTimerReset);
        }
    };

    const indexOfStatus = columns.findIndex(object => {
        return (
            //@ts-ignore
            object.field === "showActiveStatus");
    });
    const indexOfDelete = columns.findIndex(object => {
        return (
            //@ts-ignore
            object.field === "delete");
    });

    if (deleteAccess){
        const showActiveStatusField = ({
                field: 'showActiveStatus',
                headerName: '',
                type: 'actions',
                align: 'left',
                headerAlign: 'left',
                width: 100,
                sortable: false,
                cellClassName: 'delete-actions',
                renderCell: (params: GridRenderCellParams<Date>) => (
                    <>
                    <UpdateShowStatus params={params} />
                    </>
                ),
            });
        const deleteField = (
            {
                field: 'delete',
                headerName: '',
                type: 'actions',
                align: 'left',
                headerAlign: 'left',
                width: 100,
                sortable: false,
                cellClassName: 'delete-actions',
                renderCell: (params: GridRenderCellParams<Date>) => (
                    <>
                    <AlertDialog params={params} />
                    </>
                ),
            }
        )
        var arr = []

        columns.forEach((x) => {
            //@ts-ignore
            arr.push(x.field)
        });


        //@ts-ignore
        if (arr.includes("showActiveStatus")){
        }else{
            //@ts-ignore
            columns.splice(-1, 0 ,showActiveStatusField)
        }

        //@ts-ignore
        if (arr.includes("delete")){
        }else{
            //@ts-ignore
            columns.splice(-1, 0 ,deleteField);
        }

    }else{
        if (columns[indexOfDelete]){
            if (columns[indexOfDelete].field === ("delete")){
                columns.splice(indexOfDelete, 1);
            }}


        if (columns[indexOfStatus]){
            if (columns[indexOfStatus].field === ("showActiveStatus")){
                columns.splice(indexOfStatus, 1);
            }}
    }


    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        // {
        //   field: 'id',
        //   sort: 'desc',
        // }
      ]);

    useEffect(() => {
        setRows(showListContent);
    }, [showListContent]);

    function NoRowsOverlay() {
        return (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No result found
          </Stack>
        );
      }

      return (
        <>
            {/* <div style={{ height: 500, width: '100%', marginTop: '22px' }}> */}
            <div style={{ height: 810, width: '100%'}}>
                <DataGrid
                    className="datatable__custom"
                    disableColumnMenu
                    components={{ Toolbar: QuickSearchToolbar,ColumnSortedDescendingIcon: SortedDescendingIcon, ColumnSortedAscendingIcon: SortedAscendingIcon, NoRowsOverlay}}
                    rows={rows}
                    columns={columns}
                    page={currentPage}
                    loading={loader}
                    pageSize={10}
                    rowCount={maxNumber}
                    onPageChange={(params) => {
                         {dispatch(ShowCurrentPage(params))}
                        if((params*10) > offsetValue ){
                            if ((((params+1)*10) <= showListContent.length) || (showListContent.length === maxNumber)){
                            }
                            else{
                                setOffsetValue((params)*10)
                            }
                        }
                    }}
                    componentsProps={{
                        toolbar: {
                            // value: userInput ? userInput :searchValue  || undefined,
                            value: searchExists ? searchValue : userInput,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
}
