import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { episodeGiftListContentDataModifiedObj} from '../../data/mappers/allTransactions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

export function SortedDescendingIcon() {
    return <ExpandMoreIcon className="icon" />;
  }

  export function SortedAscendingIcon() {
    return <ExpandLessIcon className="icon" />;
  }


function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

const BasicPagination = (props) => {
    // @ts-ignore
    let rowCount:any = parseInt(props.count/props.pageSize)
    if (props.count%props.pageSize !== 0) {
        rowCount += 1
    }

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        props.setCurrentPage(value);
      };


    return (
      <Stack className='pagination--end' spacing={2}>
        <Pagination style={{marginTop: '-10px'}} count={rowCount} page={props.currentPage} onChange={handleChange} />
      </Stack>
    );
  }

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{mr: 2}} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}

const durationCalculate = (x) => {
    var durationFinal = "-";
    let duration:number = parseInt(x);
    if (duration >= 0){
        if (duration > 61){
            let durationMinutes= parseInt((duration/60).toString());
            let seconds = (duration%60).toString();
            if (seconds){
                durationFinal =  durationMinutes + " min " + seconds + " sec"
            }
            else{
                durationFinal =  durationMinutes + " min "
            }
        }else{
            durationFinal =  duration.toString() + " sec"
        }
    }
    return durationFinal
}

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 0, hide: true },
    { field: 'runs', headerName: 'Runs', type: 'string', width: 100 },
    // { field: 'sender', headerName: 'Sender', type: 'string', width: 130, align: 'center', headerAlign: 'center' },

    { field: 'sender',
      headerName: 'Sender',
      type: 'string',
      width: 220,
      valueGetter: (params) => params.row.sender.fullName,
      renderCell: (params: GridRenderCellParams<Date>) => (
        <>
            {
                params.row.sender.id
                ?
                <Link to={`/admin/consumers/${params.row.sender.id}`}>
                    <span>{params.row.sender.fullName}</span>
                </Link>
            :
            <span>{params.row.sender.fullName}</span>
            }
        </>
        ),
    },

    { field: 'duration', headerName: 'At Duration', type: 'number', width: 200, align: 'left',headerAlign: 'left',
    renderCell: (params) => (
        <>
            <span>{durationCalculate(params.row.duration)}</span>
        </>
      )
    },

    // { field: 'purchases', headerName: 'Purchase', type: 'number', width: 130, align: 'center', headerAlign: 'center' },
    {
        field: 'date',
        headerName: 'Received At',
        type: 'date',
        width: 150,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
                <label>{moment(params.row.date).format('D.MM.YYYY')}</label>
            </>
        ),
        align: 'left', headerAlign: 'left'
    },
    // {
    //     field: 'endDate',
    //     headerName: 'Expiry Date',
    //     type: 'date',
    //     width: 150,
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <>
    //             <label>{params.row.endDate ? moment(params.row.endDate).format('D.MM.YYYY') : '-'}</label>
    //         </>
    //     ),
    //     align: 'center', headerAlign: 'center'
    // },
    // { field: 'streamtags', headerName: 'Tag', type: 'string', width: 220 },
    // { field: 'count', headerName: 'View Count', type: 'number', width: 150, align: 'left', headerAlign: 'left' },

    // {
    //     field: 'view',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'edit-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <Link to={`/admin/package/${params.row.id}`}>
    //             <button type="button">View</button>
    //         </Link>
    //     ),
    // },
];


interface userContentDataTableProps {
    episodeGiftListContent: Array<episodeGiftListContentDataModifiedObj>;
}

export default function EpisodeGiftRecieveListDataTable({ episodeGiftListContent }: userContentDataTableProps) {
    const pageSize = 9
    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState<Array<episodeGiftListContentDataModifiedObj>>(episodeGiftListContent);
    const [currentPage, setCurrentPage] = React.useState(1);

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = episodeGiftListContent.filter((row: any) => {
          return Object.keys(row).some((field: any) => {

            if (["runs","date","id", "duration"].includes(field)){
                return false;
            }
            if (["sender"].includes(field)){
                return searchRegex.test(row[field]?.fullName);
            }
            return searchRegex.test(row[field].toString());
          });
        });
        setRows(filteredRows);
    };

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
          field: 'id',
          sort: 'desc',
        }
      ]);

    useEffect(() => {
        setRows(episodeGiftListContent);
    }, [episodeGiftListContent]);

    function NoRowsOverlay() {
        return (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No result found
          </Stack>
        );
      }

    return (
        <>
            <div style={{ height: 750, width: '100%' }}>
                <DataGrid
                    className="datatable__custom pagination--none"
                    disableColumnMenu
                    components={{ Toolbar: QuickSearchToolbar,ColumnSortedDescendingIcon: SortedDescendingIcon, ColumnSortedAscendingIcon: SortedAscendingIcon, NoRowsOverlay }}
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    page={currentPage-1}
                    sortingOrder={['desc', 'asc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    // checkboxSelection
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                />
            </div>

            <BasicPagination setCurrentPage={setCurrentPage} currentPage={currentPage} count={rows ? rows.length : 0} pageSize={pageSize}/>

        </>
    );
}
