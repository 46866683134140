import { CREATOR_LOGGED_IN, CREATOR_LOGGED_OUT } from "../../constants";
import { CreatorLoggedInAction, CreatorLoggedOutAction, creatorState } from "./types";

export const creatorLoggedIn = (creator: creatorState) : CreatorLoggedInAction => ({
    type: CREATOR_LOGGED_IN,
    creator: creator
});

export const creatorLoggedOut = () : CreatorLoggedOutAction => ({
    type: CREATOR_LOGGED_OUT
});
