import CountryCurrencyListDataTable from "../../components/common/CountryCurrencyDataTable";

export const createPackage = (data: any ) => {
    let packageDetail:any = {
        gift_quantity: data.runs ?? "0",
        title: data.title?.trim(),
        // description: data.description.trim(),
        description: "",
        // icon: data.thumbnail ?? "",
        start_at: data.startDate ?? new Date(),
        expiry_at: data.endDate ?? "",
        icon: data.thumbnail ?? ""

    }

    return packageDetail;
}


export const packageSubmitMapper = (giftTypeId,basicPackageDetails,countriesPackage,StartPush,EndPush,extras) => {
    let finalizePackage = {
        // gift_region_deleted_list : (extras.modeType ===  "add" ? [] : [""]),
        after_start_push_list:StartPush,
        before_end_push_list:EndPush
    }

    if (extras.modeType === "add") {
        finalizePackage['gift_id'] = giftTypeId
        finalizePackage['gift_region_price_list']= countriesPackage

    }


    if (extras.modeType === "edit"){
        let UpdateCountriesArray:any = []

        countriesPackage.forEach((data) => {
            let existingId = ""
            extras.storedCountries.forEach((x) => {
                if (data.country_id === x.countryId){
                    existingId = x.dbId
                }
            }
            )
            UpdateCountriesArray.push({
                id: existingId,
                ...data,
            })
        })

        let oldCountryIdArray:any = extras.storedCountries.map(x => x.countryId)
        let newCountryIdArray:any = countriesPackage.map(x => x.country_id)

        let deletedCountryArray:any = [];
        deletedCountryArray = oldCountryIdArray.filter(x => !newCountryIdArray.includes(x));
        let getdeletedArrayDbIdList:any = []

        extras.storedCountries.forEach((x) => {
            if (deletedCountryArray.includes(x.countryId)){
                getdeletedArrayDbIdList.push(x.dbId)
            }
        })



        // finalizePackage['gift_id'] = giftTypeId
        finalizePackage["id"] = extras.id
        finalizePackage['gift_region_price_list'] = UpdateCountriesArray
        finalizePackage['gift_region_deleted_list'] = getdeletedArrayDbIdList

    }

    let packageDetail = Object.assign({}, finalizePackage, basicPackageDetails)

    return packageDetail;
}

interface countryData {
    country_id: string;
    price:number;
    discount_price:number;
    currency:string
}

export const countriesMapper = (countriesList, modeType) => {
    let countriesFinalized: countryData[] = [];

    countriesList.map((country) =>
        {
            return (countriesFinalized.push({
                country_id: country.id,
                price: country.amount,
                discount_price: 0,
                currency: country.currency
        })
            )
        }
    )

    return countriesFinalized

}



export type packageListContentDataModified = packageListContentDataModifiedObj[]
export interface packageListContentDataModifiedObj {
    id: number;
    sku: string;
    title: string;
    purchases: number;
    startDate: string;
    endDate: string;
    countriesCount: string;
    isActive: boolean;
    runs: string;
}




