import { combineReducers } from "redux";
import { creatorReducer } from "./creator";
import { alertsReducer } from "./alerts";
import { matchReducer } from "./match";
import { dasboardReducer } from "./dasboard";
import { userReducer } from "./user";
import {showListReducer} from "./showList";
import {episodeListReducer} from "./episodeList";
import {creatorListReducer} from "./creatorList";
import {adminListReducer} from "./adminList";
import {matchListReducer} from "./matchList";
import {consumerListReducer} from "./consumerList";
import {packageListReducer} from "./packageList";
// import {episodeGiftListReducer} from "./episodeGiftList";
import { consumerSpendListReducer, consumerBalanceListReducer } from "./consumerTransaction";
import { allGiftSpendListReducer, episodeGiftListReducer } from "./allTransactions";

import {showReducer} from "./show";
import {episodeReducer, PodcastUploadReducer} from "./episode";
import {appVersionListReducer} from "./appVersionList";

import { reducer as formReducer } from 'redux-form';


export const rootReducer = combineReducers({
    creator: creatorReducer,
    form: formReducer,
    alerts: alertsReducer,
    match: matchReducer,
    show: showReducer,
    dashboard: dasboardReducer,
    user: userReducer,
    showList:showListReducer,
    episodeList: episodeListReducer,
    episode: episodeReducer,
    podcastUpload: PodcastUploadReducer,
    versionList: appVersionListReducer,
    creatorList: creatorListReducer,
    adminList: adminListReducer,
    matchList: matchListReducer,
    consumerList: consumerListReducer,
    packageList: packageListReducer,
    episodeGiftList: episodeGiftListReducer,
    consumerSpendList : consumerSpendListReducer,
    consumerBalanceList: consumerBalanceListReducer,
    allGiftSpendList: allGiftSpendListReducer
});

  export default rootReducer;
