import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';

import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { CONSUMER_ADMIN_API } from '../../constants';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { consumerSpendListContentDataModified,
        consumerSpendListContentData,
        consumerBalanceListContentData,
        consumerBalanceListContentDataModified
            } from '../../data/mappers/consumerList';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { UpdateConsumerSpendListContent, UpdateConsumerBalanceListContent } from '../../store/consumerTransaction';
import GoToTop from '../common/GoTop'

import { useNavigate, useParams } from 'react-router-dom';
import ConsumerSpendListDataTable from '../common/ConsumerSpendListDataTable';
import ConsumerBalanceListDataTable from '../common/ConsumerBalanceListDataTable';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const ConsumerTransactionsList = (props: any) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { id } = useParams();

    const [table, setTable] = useState<number>(0);

    const [totalSpending, setTotalSpending] = useState<number>(0);
    const [totalBalance, setTotalBalance] = useState<number>(0);


    let token = useSelector((state: RootStateType) => state.user.access_token);

    let spendListContent:any = useSelector((state: RootStateType) => state.consumerSpendList.spendListContent);
    let balanceListContent:any = useSelector((state: RootStateType) => state.consumerBalanceList.balanceListContent);

    useEffect(() => {
        getSpendingContent();
        getBalanceContent();
    }, []);

    const getSpendingContent = async () => {
            const res: any = await postRequest(CONSUMER_ADMIN_API.TRANSACTION_SPENDING, {
                consumer_id:id
            }, token);
            if (isApiCodeSucess(res)) {
                setTotalSpending(res.data.data.runs_gifted)
                let formattedSpendContentData = res.data?.data?.gift_sent_list as consumerSpendListContentData,
                formattedSpendContent: consumerSpendListContentDataModified = [];
                formattedSpendContentData.forEach((x) => {
                //     let finalType = ((x.event_type === "direct") ? "Creator" : (x.event_type === "podcast") ? "Podcast" : "Stream" );
                //     formattedSpendContent.push({
                //         id: x.id,
                //         runs: x.gift_quantity,
                //         date: x.createdAt,
                //         type: finalType,
                //         creator: {
                //             id:x.creator.id,
                //             title:
                //             ((x.creator.id) ? (x.creator?.firstName ?? "") + " " + (x.creator?.lastName ?? "") : "-" ),
                //         },
                //         stream: {id: x.stream?.id, title: (x.stream?.title ?? "-") },
                //         podcast: {id: x.episode?.id, title: (x.episode?.title ?? "-") }
                //     });
                // });

                let finalType = ((x.event_type === "direct") ? "Creator" : (x.event_type === "podcast") ? "Podcast" : "Stream" );
                let recieve_id =  ((x.event_type === "direct") ?
                    x.creator.id : (x.event_type === "podcast") ?
                    x.episode?.id : x.stream?.id
                );
                let recieve_title =  ((x.event_type === "direct") ?
                    ((x.creator.id) ? (x.creator?.firstName ?? "") + " " + (x.creator?.lastName ?? "") : "-" )
                    : (x.event_type === "podcast") ?
                    x.episode?.title : x.stream?.title
                );
                formattedSpendContent.push({
                        id: x.id,
                        runs: x.gift_quantity,
                        date: x.createdAt,
                        type: finalType,
                        receiveTo: {
                            id:recieve_id,
                            title:recieve_title
                        },
                        duration: x.event_second ?? "-"
                    });
                });
                dispatch(UpdateConsumerSpendListContent(formattedSpendContent));
        }
    };


    const getBalanceContent = async () => {
        const res: any = await postRequest(CONSUMER_ADMIN_API.TRANSACTION_BALANCE, {
            consumer_id:id
        }, token);
        if (isApiCodeSucess(res)) {
            setTotalBalance(res.data.data.consumer_gift_balance)
            let formattedBalanceContentData = res.data?.data?.gift_purchased_list as consumerBalanceListContentData,
            formattedBalanceContent: consumerBalanceListContentDataModified = [];
            formattedBalanceContentData.forEach((x) => {
                formattedBalanceContent.push({
                    id: x.id,
                    amount: x.amount,
                    runs:x.gift_quantity,
                    date: x.createdAt,
                    currency: x.currency,
                    package: {id: x.gift_pack?.id, title:(x.gift_pack?.title ?? "-")},
                    status:x.transaction_status
                });
            });
            dispatch(UpdateConsumerBalanceListContent(formattedBalanceContent));
        }
    };


    const buttonBox = (
        <Button
            type="button"
            styleName={`button--outlined active`}
            onClick={() => navigate('/admin/package/add')}
        >Add New Package
        </Button>
    )

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTable(newValue);
      };

    return (
        <>
             {/* <Box className="dashboard__topbar"> */}
            {/* </Box> */}

            {/* <Box className="dashboard__topbar">
            <Box className="row align--items--center">
                <div className="md-col-8 mb--20 mb--md--0">
                        <Box className="button__text__navigate">
                            <button onClick={() => navigate(-1)}><ArrowBackOutlinedIcon /></button>
                            <Box className="breadcrumb__title dashboard__topbar--heading" component="h2">
                                User transaction details
                            </Box>
                        </Box>
                </div>
              </Box>
        </Box> */}

            <Box className="dashboard__content pt--0">
                <Box className="row align--items--center" style={{display: 'block'}}>
                    <section aria-label='signIn-section' className='h-inherit bg--dark'>

                        <Box>
                            <Box className='signin__tabs' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs  value={table} onChange={handleChange} aria-label="99.94 Login Options">
                                <Tab label={"Spendings"} {...a11yProps(0)} aria-label="99.94 Login with Creator"/>
                                <Tab label={"Top Ups"}  {...a11yProps(1)} aria-label="99.94 Login with Admin"/>``
                                </Tabs>
                            </Box>

                            {/* <TabPanel value={value} index={1}>
                                <GoogleLoginLogin />
                            </TabPanel> */}


                        </Box>
                    </section>
                </Box>
                {/* <Box className="col-12 md-col-8 m-b-20">
                        <Box className="button__box" style={{display: 'block'}}>{buttonBox}</Box>
                </Box> */}

                {
                    (table === 0) ?

                <Box className="col-12 m-t-20">
                    <Box>
                        <div className="row">

                            <div className="col-12 text--center">
                                <p className="input--label" style={{
                                    fontSize:'18px'
                                    }}>Total Spendings:
                                    <strong style={{marginLeft:'5px'}}>
                                        {totalSpending} Runs
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </Box>

                    <ConsumerSpendListDataTable transactionListContent={spendListContent} />
                </Box>
                :
                <Box className="col-12 m-t-20">
                    <Box>
                        <div className="row">
                            <div className="col-12 text--center">
                                <p className="input--label" style={{
                                    fontSize:'18px'
                                    }}>Current Balance:
                                    <strong style={{marginLeft:'5px'}}>
                                        {totalBalance} Runs
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </Box>

                    <ConsumerBalanceListDataTable balanceListContent={balanceListContent} />
                </Box>
                }
            </Box>
            <GoToTop/>
        </>
    );
};

export default ConsumerTransactionsList;
