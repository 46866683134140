import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import AdminListDataTable from '../common/AdminListDataTable';
import { getRequest, postRequest } from '../../data/helpers/services';
import { ADMIN_API } from '../../constants';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { adminListContentData, adminListContentDataModified } from '../../data/mappers/user';
import { UpdateAdminListContent, AddAdminListContent, TotalAdminSave, AdminCurrentPage } from '../../store/adminList';
import GoToTop from '../common/GoTop'

import { useNavigate } from 'react-router-dom';


const AdminList = (props: any) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [ offsetValue, setOffsetValue ] = useState<number | 0>(0)
    const [ maxNotification, setMaxNotification ] = useState<number | 0 >(0)
    const [ searchValue, setSearchValue ] = useState<string | "">("")
    const [ loader, setLoader ] = useState<boolean>(false)

    const [ compareSearchRedux, setCompareSearchRedux ] = useState<string | "">("")
    const [ currentPage, setCurrentPage ] = useState<number>(0)

    let token = useSelector((state: RootStateType) => state.user.access_token);

    let adminListObject = useSelector((state: RootStateType) => state.adminList);

    let adminListContent = adminListObject.adminListContent;

    if ((adminListObject.currentPage) && (adminListObject.currentPage !== currentPage)) {
        setCurrentPage(adminListObject.currentPage);
    }else if ((adminListObject.currentPage === 0) && (currentPage !== 0)) {
        setCurrentPage(0)
    }

    if ((adminListObject.searchedValue) && (adminListObject.searchedValue !== searchValue)){
        setSearchValue(adminListObject.searchedValue);
    }

    if ((currentPage > 0) && (offsetValue === 0)){
        setOffsetValue(adminListContent.length)
    }

    // useEffect(() => {
    //     getAdminListContent(searchValue);
    // }, [offsetValue, searchValue]);


    if ((currentPage > 0) && (offsetValue === 0) && (searchValue !== compareSearchRedux)) {
        setOffsetValue(adminListContent.length)
    } else if ((currentPage > 0) && (offsetValue === 0)) {
        setOffsetValue(adminListContent.length)
    }

    useEffect(() => {
        if ((currentPage !== 0) &&
            (((currentPage+1)*10) <= adminListContent.length) &&
            (adminListObject.totalAdmin >= adminListContent.length) &&
            (searchValue === compareSearchRedux) &&
            (offsetValue !== 0)
            ){
            setMaxNotification(adminListObject.totalAdmin)
        } else{
            getAdminListContent(searchValue);
            setCompareSearchRedux(searchValue);
        }

    }, [offsetValue, searchValue]);



    const getAdminListContent = async (searchingValue:string) => {
        let res:any = {}
        if ((searchingValue === (searchValue) || (searchingValue === ""))) {
            setLoader(true);
            res = await postRequest(ADMIN_API.List,{
                    "offset": offsetValue,
                    "search_text": searchValue
                }, token);
        }
        if (res) {
            if ((searchingValue === (searchValue) || (searchingValue === ""))) {
                setMaxNotification(res.data?.data.total_admins)
                dispatch(TotalAdminSave(res.data?.data.total_admins))

                let formattedCreatorListContentData = res.data?.data.admin_list as adminListContentData[],
                    formattedCreatorListContent: adminListContentDataModified = [];
                    formattedCreatorListContentData.forEach((x) => {
                        formattedCreatorListContent.push({
                            email:x.email,
                            firstName: x.firstName,
                            id: x.id,
                            lastName: x.lastName,
                            fullName: x.firstName + ' ' +  x.lastName,
                            // status:true
                    });
                });
                if (!offsetValue){
                    dispatch(AdminCurrentPage(0))
                    dispatch(UpdateAdminListContent(formattedCreatorListContent));
                }
                else{
                    dispatch(AddAdminListContent(formattedCreatorListContent))
                }
            }
        }
        setLoader(false);
    };

    const buttonBox = (
        <Button
            type="button"
            styleName={`button--outlined active`}
            onClick={() => navigate('/admin/admin/add')}
        >Add New Admin
        </Button>
    )

    return (
        <>
            <Box className="dashboard__content">
                <Box className="col-12 md-col-8 m-b-20">
                        <Box className="button__box" style={{display: 'block'}}>{buttonBox}</Box>
                </Box>
                <Box>
                    <AdminListDataTable adminListContent={adminListContent} offsetValue={offsetValue} setOffsetValue={setOffsetValue} maxNumber={maxNotification} searchValue={searchValue} setSearchValue={setSearchValue} loader={loader} currentPage={currentPage}/>
                </Box>

            </Box>
            <GoToTop/>
        </>
    );
};

export default AdminList;
