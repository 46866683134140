import React, { useEffect, useState } from 'react';
import LeftBarContainer from '../common/LeftBar.Component'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {  postRequest } from '../../data/helpers/services';
import { RESET_PASSWORD, PUBLIC_API } from '../../constants';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import GoToTop from '../common/GoTop'


const PasswordReset = (props: any) => {
    const {type} = props;
    console.log("type",type)

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const {token, exp_token, intent} =  useParams();


    const [password, setPassword] = useState<string | "">("");
    const [confirmPassword, setConfirmPassword] = useState<string | "">("");

    const [tokenValidated, setTokenValidated] = useState<boolean>(false);
    const [editable, setEditable] = useState<boolean>(false);


    const [passwordError, setPasswordError] = useState<[boolean,string]>([false,""]);
    const [confirmError, setConfirmError] = useState<[boolean,string]>([false,""]);

    useEffect(() => {
        if (type === "change_password"){
            getTokenValidStatus();
        }
        else if (type === "redirect_to_stripe"){
            getStripeUrl()
        }
        else if (type === "success_stripe_redirect_to_app"){
            window.location.replace("https://94dm.page.link/1vDT");
        }
        else if (type === "fail_stripe_redirect_to_app"){
            window.location.replace("https://94dm.page.link/hhkV");
        }

    }, []);

    useEffect(() => {
      setPasswordError([false, ""]);
    }, [password]);


    useEffect(() => {
      setConfirmError([false, ""]);
      if (confirmPassword && (confirmPassword !== password)) {
          setConfirmError([true, "Password does not match"]);
      }
    }, [confirmPassword]);


    const getStripeUrl = async () => {
        dispatch(ShowProgressBar());
        let data = {
            user_token: exp_token,
            payment_intent: intent
        }
        let response = await postRequest(PUBLIC_API.VALIDATE_INTENT_TOKEN , data, "");

        if (response?.data?.code === 400){
            dispatch(ShowErrorAlert({visible:true, message: response.data.message}));
        }else{
            let redirect_URL = response?.data?.data
            window.location.replace(redirect_URL);
        }
        dispatch(HideProgressBar());
        // setTokenValidated(true);
      };


    const getTokenValidStatus = async () => {
        dispatch(ShowProgressBar());
        let data = {
            reset_token: token
        }
        let response = await postRequest(RESET_PASSWORD.TOKEN_VALIDATE, data, "");

        if (response?.data?.code === 400){
            setEditable(false);
        }else{
            setEditable(true);
        }
        dispatch(HideProgressBar());
        setTokenValidated(true);
      };


    const validate = () => {
        let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#^~$!%*?&])[A-Za-z\d@#^~$!%*?&]{8,16}$/;
        if (!password){
            setPasswordError([true, "Password is required"]);
            return false;
        }
        else if (!confirmPassword){
            setConfirmError([true, "Please confirm password"]);
            return false;
        }
        else if (!re.test(password)) {
            setPasswordError([true, "Password must be at least 8 characters containing 1 upper case, 1 lower case, 1 number and 1 special character."]);
            return false;
        }
        else if (confirmPassword && (confirmPassword !== password)) {
            setConfirmError([true, "Password does not match"]);
            return false;
        }
        else{
            return true;
        }
    }
    const savePassword = async () => {
        let validated = validate();
        let data = {
            password: password,
            reset_token: token
        }
        if (validated){
            dispatch(ShowProgressBar());
            let response = await postRequest(RESET_PASSWORD.APP_USER, data, "");
            if (response){
                debugger;
                if ((response.data.code === 400)){
                    dispatch(ShowErrorAlert({visible:true, message: response.data.message}));
                }else{
                    dispatch(ShowSuccessAlert({visible:true, message: ("Password Update successfully.")}));
                    setTimeout(() => {
                        window.open("https://94dm.page.link/Xyzu","_self")
                    }, 2000)
                }
            }
            dispatch(HideProgressBar());
        }
      };

    const showForm = (
        <>
            {
                (editable)
                ?
                <>
                <div className="dialog__content__box m-b-20">
                <label className='input--label m-b-10' htmlFor="passwordEdit">Password</label>
                <TextField
                id="passwordEdit"
                type="password"
                fullWidth
                value={password}
                onChange={(event) => {setPassword(event.target.value)}}
                error={passwordError[0] || false}
                helperText={passwordError[1]}
                />
                </div>

                <div className="dialog__content__box m-b-20">
                <label className='input--label m-b-10' htmlFor="passwordConfirm">Confirm Password</label>
                <TextField
                id="passwordConfirm"
                type="text"
                fullWidth
                value={confirmPassword}
                onChange={(event) => {setConfirmPassword(event.target.value)}}
                error={confirmError[0] || false}
                helperText={confirmError[1]}
                />
            </div>
            <div className="col-12 m-t-30">
                <button type="submit" className={`btn--pill saveBtn`} onClick={savePassword}>
                    Reset Password
                </button>
            </div>
                </>
            :
            <div className="dialog__content__box m-b-20">
                <h1 style={{ color: 'red' }}>
                    Either password reset link is expired or link is invalid.
                </h1>
            </div>
            }
            <GoToTop/>
        </>
    )

   return (
        <>
        <section aria-label='signIn-section' className='h-inherit bg--dark'>
        <div className='authentication__wrapper h-inherit grid__container grid-md-1-2-column'>
            <LeftBarContainer />
            <div className='login__innerarea justify-content-loginstart'>
                <Box sx={{ width: '100%' }}>
                    {
                        (type === "change_password")?
                            <div className='login--wrapperbox max--760'>
                            {
                                (tokenValidated) ? showForm : ""
                            }
                            </div>
                        :
                        ""
                    }
                </Box>
            </div>
        </div>
        </section>
        </>
      )
    }

    export default PasswordReset;
