import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import PackageListDataTable from '../common/PackageListDatatable';
import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { PACKAGES_API } from '../../constants';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { packageListContentDataModified } from '../../data/mappers/package';
import { UpdatePackageListContent } from '../../store/packageList';
import GoToTop from '../common/GoTop'

import { useNavigate } from 'react-router-dom';


const PackageList = (props: any) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    let token = useSelector((state: RootStateType) => state.user.access_token);

    let packageListContent = useSelector((state: RootStateType) => state.packageList.packageListContent);

    useEffect(() => {
        getPackageContent();
    }, []);


    const getPackageContent = async () => {
            const res: any = await postRequest(PACKAGES_API.LIST, {}, token);
            if (isApiCodeSucess(res)) {
                let formattedPackageContentData = res.data?.data as any,
                formattedPackageContent: packageListContentDataModified = [];
                formattedPackageContentData.forEach((x) => {
                    formattedPackageContent.push({
                        id: x.id,
                        sku:x.sku ?? "-",
                        title:x.title,
                        purchases:x.gift_purchase_count,
                        startDate:x.start_at,
                        endDate:x.expiry_at,
                        countriesCount:x.gift_pack_countries_count,
                        isActive:x.is_active,
                        runs:x.gift_quantity
                    });
                });
                dispatch(UpdatePackageListContent(formattedPackageContent));
        }
    };
    const buttonBox = (
        <Button
            type="button"
            styleName={`button--outlined active`}
            onClick={() => navigate('/admin/package/add')}
        >Add New Package
        </Button>
    )

    return (
        <>
            <Box className="dashboard__content">
                <Box className="col-12 md-col-8 m-b-20">
                        <Box className="button__box" style={{display: 'block'}}>{buttonBox}</Box>
                </Box>
                <Box>
                    <PackageListDataTable packageListContent={packageListContent} />
                </Box>

            </Box>
            <GoToTop/>
        </>
    );
};

export default PackageList;
