import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, GridRowParams } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { consumerBalanceListContentDataModifiedObj } from '../../data/mappers/consumerList';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from 'react-router-dom';


export function SortedDescendingIcon() {
    return <ExpandMoreIcon className="icon" />;
  }

  export function SortedAscendingIcon() {
    return <ExpandLessIcon className="icon" />;
  }


function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{mr: 2}} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}

const columns: GridColDef[] = [
    { field: 'package',
      headerName: 'Package',
      type: 'string',
      width: 300,
      valueGetter: (params) => params.row.package.title,
      renderCell: (params: GridRenderCellParams<Date>) => (
        <>
            {
                params.row.package.id
                ?
                <Link to={`/admin/package/${params.row.package.id}`}
                style={{
                    display: 'inline-block',
                    maxWidth: '100%',
                    flexShrink: 0,
                    width: '100%',
                  }}>
                    <span style={{
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    maxHeight: 'auto',
                    width: 'calc(100% - 10px)',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }} >{params.row.package.title}</span>
                </Link>
            :
            <span>{params.row.package.title}</span>
            }
        </>
        ),
    },
    { field: 'id', headerName: 'ID', width: 0, hide: true },
    { field: 'runs', headerName: 'Runs', type: 'string', width: 150 },
    { field: 'currency', headerName: 'Currency', type: 'string', width: 190 },
    { field: 'amount', headerName: 'Amount', type: 'string', width: 150 },
    {
        field: 'date',
        headerName: 'Purchased on',
        type: 'date',
        width: 150,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
                <label>{moment(params.row.date).format('D.MM.YYYY')}</label>
            </>
        ),
        align: 'center', headerAlign: 'center'
    },
    { field: 'status', headerName: 'Status', type: 'string', width: 150 },

    // {
    //     field: 'view',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'left',
    //     headerAlign: 'left',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'view-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <Link to={`/admin/package/${params.row.id}`}>
    //             {/* <button type="button">View</button> */}
    //             <span><ArrowRightAltIcon/></span>

    //         </Link>
    //     ),
    // },
];


interface userContentDataTableProps {
    balanceListContent: Array<consumerBalanceListContentDataModifiedObj>;
}

export default function ConsumerBalanceListDataTable({ balanceListContent }: userContentDataTableProps) {
    let navigate = useNavigate();

    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState<Array<consumerBalanceListContentDataModifiedObj>>(balanceListContent);

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = balanceListContent.filter((row: any) => {
          return Object.keys(row).some((field: any) => {
            if (["package"].includes(field)){
                return searchRegex.test(row[field]?.title);
            }
            else{
                return false;
            }

            return searchRegex.test(row[field]);
          });
        });
        setRows(filteredRows);
    };

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
          field: 'id',
          sort: 'desc',
        }
      ]);

    useEffect(() => {
        setRows(balanceListContent);
    }, [balanceListContent]);

    function NoRowsOverlay() {
        return (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No result found
          </Stack>
        );
      }

    return (
        <>
            <div style={{ height: 810, width: '100%' }}>
                <DataGrid
                    className="datatable__custom cursor__pointer"
                    disableColumnMenu
                    components={{ Toolbar: QuickSearchToolbar,ColumnSortedDescendingIcon: SortedDescendingIcon, ColumnSortedAscendingIcon: SortedAscendingIcon, NoRowsOverlay }}
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    sortingOrder={['desc', 'asc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    // checkboxSelection
                    onRowClick= {
                        (value: GridRowParams) => {
                            let rowValue:any = value.row;
                            if (rowValue.package.id){
                                navigate(`/admin/package/${rowValue.package.id}`)
                            }
                        }
                    }
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
}
