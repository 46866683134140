import { Reducer } from 'react';
import { UPDATE_ALL_GIFT_SPEND_LIST_CONTENT_DATA, UPDATE_EPISODE_GIFT_LIST_CONTENT_DATA } from '../../constants';
import { allGiftSpendListActions, allGiftSpendListState, episodeGiftListState, episodeGiftListActions } from './types';

export const initialState: allGiftSpendListState = {
    allGiftSpendListContent: []
};

export const episodeGiftInitialState: episodeGiftListState = {
    episodeGiftListContent: []
};


export const allGiftSpendListReducer: Reducer<allGiftSpendListState, allGiftSpendListActions> = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ALL_GIFT_SPEND_LIST_CONTENT_DATA:
            return {
                ...state,
                allGiftSpendListContent: [...(action?.data || [])],
            };

        // case ADD_CREATOR_LIST_CONTENT_DATA:
        //     return {
        //         ...state,
        //         // creatorListContent: [...state.creatorListContent, ...(action?.data || [])],
        //         creatorListContent: state.creatorListContent.concat(action?.data),
        //     };

        // case UPDATE_CREATOR_ACCOUNT_STATUS:
        //     return {
        //         ...state,
        //         creatorListContent: state.creatorListContent.map(creator => {
        //             if (creator.id === action.data) {
        //                 creator.status = !creator.status
        //                 return {...creator}
        //             };
        //             return creator;
        //         })
        //     }

        // case SAVE_CREATOR_SEARCH_TEXT:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         searchedValue : action.data
        //     };

        // case SAVE_CREATOR_CURRENT_PAGE:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         currentPage : action.data
        //     };

        // case SAVE_TOTAL_CREATORS:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         totalCreators : action.data
        //     };

        // case RESET:
        //     return {
        //         ...initialState //Always return the initial state
        //     };

        default:
            return state;
    }
};

export const episodeGiftListReducer: Reducer<episodeGiftListState, episodeGiftListActions> = (state = episodeGiftInitialState, action) => {
    switch (action.type) {
        case UPDATE_EPISODE_GIFT_LIST_CONTENT_DATA:
            return {
                ...state,
                episodeGiftListContent: [...(action?.data || [])],
            };

        // case ADD_CREATOR_LIST_CONTENT_DATA:
        //     return {
        //         ...state,
        //         // creatorListContent: [...state.creatorListContent, ...(action?.data || [])],
        //         creatorListContent: state.creatorListContent.concat(action?.data),
        //     };

        // case UPDATE_CREATOR_ACCOUNT_STATUS:
        //     return {
        //         ...state,
        //         creatorListContent: state.creatorListContent.map(creator => {
        //             if (creator.id === action.data) {
        //                 creator.status = !creator.status
        //                 return {...creator}
        //             };
        //             return creator;
        //         })
        //     }

        // case SAVE_CREATOR_SEARCH_TEXT:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         searchedValue : action.data
        //     };

        // case SAVE_CREATOR_CURRENT_PAGE:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         currentPage : action.data
        //     };

        // case SAVE_TOTAL_CREATORS:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         totalCreators : action.data
        //     };

        // case RESET:
        //     return {
        //         ...initialState //Always return the initial state
        //     };

        default:
            return state;
    }
};

