import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import ShowListDataTable from '../common/ShowListDataTable';
import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { PODCAST_API } from '../../constants';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { showListContentData, showListContentDataModified } from '../../data/mappers/showList';
import { UpdateShowListContent, AddShowListContent, TotalShowSave, ShowCurrentPage } from '../../store/showList';

import { useNavigate } from 'react-router-dom';
import GoToTop from '../common/GoTop'


const ShowList = (props: any) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    // const[ deleted,setDeleted ] = useState<boolean>(false);

    const [ offsetValue, setOffsetValue ] = useState<number | 0>(0)
    const [ maxNotification, setMaxNotification ] = useState<number | 0 >(0)
    const [ searchValue, setSearchValue ] = useState<string | "">("")
    const [ loader, setLoader ] = useState<boolean>(false)

    const [ compareSearchRedux, setCompareSearchRedux ] = useState<string | "">("")
    const [ currentPage, setCurrentPage ] = useState<number>(0)

    // let creatorToken = useSelector((state: RootStateType) => state.creator.access_token);
    let user = useSelector((state: RootStateType) => state.user);
    let token = user.access_token

    const showListObject = useSelector((state: RootStateType) => state.showList);
    const showListContent = showListObject.showListContent;

    if ((showListObject.currentPage) && (showListObject.currentPage !== currentPage)) {
        setCurrentPage(showListObject.currentPage);
    }else if ((showListObject.currentPage === 0) && (currentPage !== 0)) {
        setCurrentPage(0)
    }


    // useEffect(() => {
    //     getShowListContent(searchValue);
    // }, [offsetValue]);


    if ((showListObject.searchedValue) && (showListObject.searchedValue !== searchValue)){
        setSearchValue(showListObject.searchedValue);
    }

    if ((currentPage > 0) && (offsetValue === 0)){
        setOffsetValue(showListContent.length)
    }

    // useEffect(() => {
    //     getShowListContent(searchValue);
    // }, [searchValue]);


    if ((currentPage > 0) && (offsetValue === 0) && (searchValue !== compareSearchRedux)) {
        setOffsetValue(showListContent.length)
    } else if ((currentPage > 0) && (offsetValue === 0)) {
        setOffsetValue(showListContent.length)
    }

    useEffect(() => {
        if ((currentPage !== 0) &&
            (((currentPage+1)*10) <= showListContent.length) &&
            (showListObject.totalShow >= showListContent.length) &&
            (searchValue === compareSearchRedux) &&
            (offsetValue !== 0)
            ){
            setMaxNotification(showListObject.totalShow)
        } else{
            getShowListContent(searchValue);
            setCompareSearchRedux(searchValue);
        }

    }, [offsetValue, searchValue]);


    const getShowListContent = async (searchingValue: string) => {
        // setDeleted(false);
        let res:any = {}
        if ((searchingValue === (searchValue) || (searchingValue === ""))) {
            setLoader(true);
            res = await postRequest(PODCAST_API.SERIES_LIST,{
                "offset": offsetValue,
                "search_text": searchValue
            }, token);
        }

        if (isApiCodeSucess(res)) {
            if ((searchingValue === (searchValue) || (searchingValue === ""))) {
                setMaxNotification(res.data?.data.series_count)
                dispatch(TotalShowSave(res.data?.data.series_count))

                let formattedShowListContentData = res.data?.data.series_list as showListContentData,
                formattedShowListContent: showListContentDataModified = [];
                    formattedShowListContentData.forEach((x) => {
                        formattedShowListContent.push({
                            title: x.title,
                            id: x.id,
                            description: x.description,
                            hosts: (x.host.map((host) => {
                                return (host?.user?.firstName) ? (host?.user?.firstName) : (host?.user?.lastName)
                            }).join(', ')),

                            count:x.episodeCount,
                            date: x.createdAt,
                            isActive: x.isActive,
                            displayOrder: (x.displayOrder !== "0") ? x.displayOrder : "-",
                            runs: x.runs_received
                            // deleteStatus: setDeleted,
                    });
                });

                if (!offsetValue){
                    dispatch(ShowCurrentPage(0))
                    dispatch(UpdateShowListContent(formattedShowListContent));
                }
                else{
                    dispatch(AddShowListContent(formattedShowListContent))
                }
            }
        }
        setLoader(false);
    };

    const buttonBox = (
        <Button
            type="button"
            styleName={`button--outlined active`}
            onClick={() => navigate('/admin/show/create')}
        >Create New Show
        </Button>
    )

    return (
        <>
            <Box className="dashboard__content">
                <Box className="col-12 md-col-8 m-b-20">
                        <Box className="button__box" style={{display: 'block'}}>{buttonBox}</Box>
                </Box>
                <Box>
                    <ShowListDataTable showListContent={showListContent} deleteAccess={user.permissions.series_delete} offsetValue={offsetValue} setOffsetValue={setOffsetValue} maxNumber={maxNotification} searchValue={searchValue} setSearchValue={setSearchValue} loader={loader} currentPage={currentPage}/>
                </Box>
            </Box>
            <GoToTop/>
        </>
    );
};

export default ShowList;
