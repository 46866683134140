// import { Box, FormControl } from '@mui/system';
import { Box, FormControl, FormControlLabel } from '@mui/material';


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import {  renderDateField } from '../form/Fields';

import DateRangeForm from '../common/StartEndDateForm'

import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { PasswordDialog, CancelEditConfirmationDialog } from '../common/PassDialog';

import CreatorTransactionListDataTable from '../common/CreatorTransactionDataTable';

import { CREATOR_ADMIN_API, UPLOAD_FILE } from '../../constants';
import { postFileRequest, postRequest, putRequest, isApiCodeSucess } from '../../data/helpers/services';
import { profileApiMapper } from '../../data/mappers';
import UploadIcon from '../../icons/UploadIcon';
import { RootStateType } from '../../store';
import { Button } from '../common/Button';
import { TextArea, TextField } from '../common/TextField';
// @ts-ignore
import profilepicplaceholder from '../../styles/images/placeholder.png';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import Compressor from 'compressorjs';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EmailIcon from '@mui/icons-material/Email';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

interface CustomCreatorProfileFormInterface {
    email:string;
    password:string;
    firstName: string;
    lastName: string;
    handle: string;
    bio: string;
    is_password_exists:boolean;
    runs_received: any;
}

const validate = (values: CustomCreatorProfileFormInterface) => {
    const errors: any = {}

    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!values.email || (values.email && !values.email.trim())) {
        errors.email = 'Field is required.';
    } else if(!(values.email).match(validRegex)){
        errors.email = 'Please enter valid email address.';
    }
    // else if (values.email && values.email.trim().length > 32) {
    //     errors.email = 'Field value must be 32 characters or less.';
    // }

    if (!values.password || (values.password && !values.password.trim())) {
        errors.password = 'Field is required.';
    } else if (values.password && values.password.trim().length < 8) {
        errors.password = 'Password must contains at least 8 characters.';
    }
    else if (values.password && values.password.trim().length > 32) {
        errors.password = 'Field value must be 32 characters or less.';
    }

    if (!values.firstName || (values.firstName && !values.firstName.trim())) {
        errors.firstName = 'Field is required.';
    } else if (values.firstName && values.firstName.trim().length > 32) {
        errors.firstName = 'Field value must be 32 characters or less.';
    }

    if (!values.lastName || (values.lastName && !values.lastName.trim())) {
        errors.lastName = 'Field is required.';
    } else if (values.lastName && values.lastName.trim().length > 32) {
        errors.lastName = 'Field value must be 32 characters or less.';
    }

    if (!values.handle || (values.handle && !values.handle.trim())) {
        errors.handle = "Field is required.";
    } else if (values.handle && values.handle.length > 20) {
        errors.handle = 'Field value must be 20 characters or less.';
    } else if (values.handle.trim().indexOf(" ") !== -1 || values.handle.trim().indexOf("@") !== -1) {
        errors.handle = 'Spaces and @ is not allowed in handle.';
    }

    if (values.bio && values.bio.trim().length > 4096) {
        errors.bio = "Field value must be 4096 characters or less.";
    }

    return errors
}

const CustomCreatorProfileForm = (props:any) => {
    const { handleSubmit, change, submitting } = props;
    const { id } = useParams();

    const user = useSelector((state: RootStateType) => state.user);
    const [ modeType, setModeType] = useState<string | undefined>(props.modeType);

    const [ newPassword, setNewPassword ] = useState<string | "">("");
    const token = user.access_token;

    const [ creatorDetails, setCreatorDetails ] = useState<CustomCreatorProfileFormInterface | null>(null);

    const [ creatorTransactionListContent, setCreatorTransactionListContent ] = useState([]);

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [profileImage, setProfileImage] = useState<string>("");

    const [resetForm, setResetForm] = useState<boolean>(false);

    const [fileUploading, setFileUploading] = useState(false);

    const [ startDate,setStartDate ] = useState((new Date(new Date().setDate((new Date().getDate()) - 30))));
    const [ endDate,setEndDate ] = useState(new Date());


    const saveProfile = async (data: any) => {
        if (!profileImage){
            dispatch(ShowErrorAlert({ visible: true, message: 'Profile Pic is required' }));
        }
        else{
            dispatch(ShowProgressBar());
            if (!data.profile_pic_path){
                data.profile_pic_path = profileImage
            }
            const apiData = profileApiMapper(data);

            let response: any = {}

            if (creatorDetails){
                if (creatorDetails?.is_password_exists ||  (newPassword !== (undefined ?? ""))) {
                    apiData.password = newPassword
                    response = await putRequest(CREATOR_ADMIN_API.EDIT, apiData, token);
                }
                else {
                    dispatch(ShowErrorAlert({ visible: true, message: 'Password does not exist for this profile' }));
                }
            }
            else {

                response = await postRequest(CREATOR_ADMIN_API.CREATE, apiData, token);
            }
            if (response) {
                const resData = response.data;
                dispatch(HideProgressBar());
                if (resData.code === 200) {
                    dispatch(ShowSuccessAlert({visible:true, message: "Profile saved successfully."}));
                    setResetForm(false)
                    if (modeType === "edit"){
                        fillCreatorDetails(creatorDetails);

                        setModeType('detail')
                    }
                    else {
                        navigate('/admin/creators', { replace: true })
                    }
                }
            }
        }
    }

    const handleCompressedUpload = async(e: any) => {
        const image = e.target.files[0];
        new Compressor(image, {
          quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
        success: async(res) => {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                setProfileImage(e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);

            // upload file and obtain url for image
            setFileUploading(true);
            dispatch(ShowProgressBar());

            const formData = new FormData();

            formData.append('file', res, (Date.now()).toString() + e.target.files[0].name );

            const response: any = await postFileRequest(UPLOAD_FILE, formData, token);

            if (response) {
                const resData = response.data;
                if (resData.code === 200) {
                    let fileUrl = resData.data.file_path;
                    change( 'profile_pic_path', fileUrl);
                }
            } else {
                setProfileImage('')
            }

            dispatch(HideProgressBar());
            setFileUploading(false);

            return true;

          },
        });
      };


    const handleFileSelect = async (event: any) => {
        const filetype = event.target.files[0].type;

        const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
        // if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 10000)) {
        if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 500)) {
            event.target.value = null;
            dispatch(ShowErrorAlert({visible:true, message: "Must be a .png, .jpg, .jpeg, .webp format image & not more than 500kb"}));
            dispatch(HideProgressBar());
            setFileUploading(false);
            return false;
        }
        setFileUploading(true);
        dispatch(ShowProgressBar());

        handleCompressedUpload(event);
        return true;
    }

    const initForm = () => {
    }

    useEffect(() => {
        initForm();
    }, []);

    useEffect(() => {
        if(modeType === 'add') {
            initForm();
        }else if (modeType === 'detail') {
            getAdminDetails(id ?? "");
        }
    }, [modeType]);

    useEffect(() => {
        if (id && ((modeType === 'edit') || (modeType === 'detail'))) {
            getAdminDetails(id);
        }
    }, [id]);

    useEffect(() => {
        if (creatorDetails && ((modeType === 'edit') || (modeType === 'detail'))) {
            fillCreatorDetails(creatorDetails);
        }


        if (creatorDetails && (modeType === 'detail')) {
            let runsRecievedAPI = creatorDetails?.runs_received as any;
            let updatedRunsRecieved: any = [];
            if (runsRecievedAPI){
                runsRecievedAPI.forEach((x) => {
                    updatedRunsRecieved.push({
                        id: x.series.id,
                        showTitle: x.series.title,
                        createdAt: x.series.createdAt,
                        runs: x.runs_gifted
                    })
                })
                setCreatorTransactionListContent(updatedRunsRecieved);
            }
        }
    }, [creatorDetails]);




    const getAdminDetails = async (id: string) => {
        dispatch(ShowProgressBar());

        const response = await postRequest(
            CREATOR_ADMIN_API.DETAIL,
            {
                creator_id: id,
            },
            token
        );

        if (response) {
            if (response.data.code === 200) {
                setCreatorDetails(response.data.data);


            }
        }
        dispatch(HideProgressBar());
    };


    const fillCreatorDetails = async (creatorDetails:any) => {
        change('id', creatorDetails?.id);
        change('lastName', creatorDetails?.lastName);
        change('firstName', creatorDetails?.firstName);
        change('email', creatorDetails?.email);
        change('handle', creatorDetails?.handle);
        change('bio', creatorDetails?.bio);
        setProfileImage(creatorDetails?.profile_pic_path)
    }


    const updateModeType = () => {
        if (modeType === "detail"){
            setModeType("edit");
        }
        else{
            if ((newPassword !== undefined) && (newPassword !== "")){
                setResetForm(true);
            }
            else{
                setModeType("detail");
            }
        }
    }

    const buttonBox = (
        <>
        <Button styleName='btn--pill button--outlined active' type='button' onClick={updateModeType}>
            {modeType === "detail" ? "Edit" : "Cancel" }
        </Button>
        </>
    );

    return (
        <>
        <Box className="dashboard__topbar">
            <Box className="row align--items--center">

                <div className="md-col-8 mb--20 mb--md--0">
                    <Box className="button__text__navigate">
                        {
                        (modeType !== "edit") ?
                            <button onClick={() => navigate(-1)}><ArrowBackOutlinedIcon /></button>
                        :
                        <span></span>
                        }
                        {/* <Box className="dashboard__topbar--heading" component="h2">Show Details</Box> */}
                        <Box className="breadcrumb__title dashboard__topbar--heading" component="h2">
                            {(modeType === "add") ? "Add Creator" : (modeType === "edit") ? "Edit Creator" : "Creator Details"}
                        </Box>
                    </Box>
                </div>

                {(modeType !== "add") ?
                <div className="md-col-4">
                    <Box className="button__box">{buttonBox}</Box>
                </div>
                : ""
                }
            </Box>
        </Box>
        {
            resetForm ?
            <CancelEditConfirmationDialog setModeType={setModeType} setResetForm={setResetForm} formId={"creatorForm"} />
            : ""
        }

        {
        (modeType && (["add","edit"].includes(modeType))) ?
        <Box className='dashboard__content max--760'>
            <form id="creatorForm" onSubmit={handleSubmit(saveProfile)}>
                <div className="row">
                    <div className='col-12 m-b-20'>
                        <div className='profilepic__circle__wrapper'>
                            <div className='profilepic__circle'>
                                <img src={(profileImage) ? profileImage : profilepicplaceholder} alt="creator Profile of 99.94 DM" />
                            </div>
                            {
                            modeType==="detail" ?
                                ""
                            :
                            <Box component={"label"}>
                                <Button type='button' styleName='pic--uploadBtn'>
                                    <span>
                                        <UploadIcon />
                                    </span>
                                </Button>
                            </Box>
                            }
                            <Field
                                name="profile_pic_path"
                                type="hidden"
                                component="input"
                                id="profile_pic_path"
                                placeholder="profile_pic_path"
                                />
                            <input type="file" name="profile_pic" disabled={modeType==="detail"} className="hidden-file-input" onChange={handleFileSelect} />
                        </div>
                        <Box sx={{
                            fontSize: '14px',
                            marginTop: '8px',
                            display: 'grid',
                            fontWeight: 400,
                            gridTemplateColumns: '30px 1fr',
                            alignItems: 'center',
                            color: '#fff'
                        }}>
                            <HelpOutlineIcon/>
                            Recomended size is 180X180 for best fit.
                        </Box>
                    </div>

                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="firstName">First Name</label>
                        <Field name="firstName" component={TextField} type="text" placeholder='Write First Name'
                            styleName='input--transparent' disabled={modeType==="detail"} />
                    </div>
                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="lastName">Last Name</label>
                        <Field name="lastName" component={TextField} type="text" placeholder='Write Last Name'
                            styleName='input--transparent' disabled={modeType==="detail"} />
                    </div>

                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="email">Email</label>
                        <Field name="email" component={TextField} type="text" placeholder='Write Email here'
                            styleName='input--transparent' disabled={modeType==="detail"} />
                    </div>
                    {
                        (modeType ==="add")
                        ?
                        <div className='col-12 md-col-6 m-b-40'>
                            <label className='input--label m-b-10' htmlFor="password">Password</label>
                            <Field name="password" component={TextField} type="password" placeholder='Write Password here'
                                styleName='input--transparent' />
                        </div>
                        :
                        (modeType ==="edit")
                        ?
                        <div className="col-12 md-col-6 m-b-40">
                            <label className='input--label m-b-10' htmlFor="password">Password</label>
                            <PasswordDialog handleSubmit={handleSubmit} setNewPassword={setNewPassword} />
                        </div>
                        :
                        <div className="col-12 md-col-6 m-b-40">
                        </div>
                    }

                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="userName">Handle</label>
                        <Field name="handle" component={TextField} type="text" placeholder='Write Handle Name'
                            styleName='input--transparent text--initial' disabled={modeType==="detail"} />
                    </div>
                    <div className='col-12 m-b-40'>
                        <label className='input--label m-b-10' htmlFor="userBio">Bio</label>
                        <Field name="bio" component={TextArea} row='4' type="text" placeholder='Write description'
                            styleName='input--transparent' disabled={modeType==="detail"} />
                    </div>
                    {
                        modeType==="detail"
                        ?
                        ""
                        :
                    <div className="col-12 m-b-20">
                        <Button styleName='saveBtn' type='submit' disabled={submitting || fileUploading}>
                            {showSubmitButtonLabel(submitting, fileUploading)}
                        </Button>
                    </div>
                    }
                </div>
            </form>
        </Box>
        // </Box>
        : (modeType === "detail") ?

        <Box className='dashboard__content'>

            {
                creatorDetails ?
                <>
                    {/* <Box className='dashboard__content max--760'> */}
                    <div className='row'>
                        <div className='workerprofile__wrapper max--760 md-col-8 xl-col-5'>
                            <div className='workerprofile__wrapper--container'>
                                <div className='workerprofile__wrapper--img'>
                                    <img src={(profileImage) ? profileImage : profilepicplaceholder} alt="creator Profile of 99.94 DM" />
                                </div>
                                <div className='workerprofile__wrapper--content'>
                                            <h4 className='worker-profile-heading'>{creatorDetails?.firstName} {creatorDetails?.lastName}</h4>
                                            <div className='td__mailinfo'>
                                                <span>
                                                    <EmailIcon/>
                                                </span>
                                                {creatorDetails?.email}
                                            </div>
                                            <div className='td__mailinfo'>
                                                <span>
                                                    <DriveFileRenameOutlineIcon/>
                                                </span>
                                                {creatorDetails?.handle}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className='workerprofile__bio m-t-20'>{creatorDetails?.bio}</p>
                                    </div>

                        </div>

                        <div className='md-col-6'>
                        </div>
                    </div>

                    {/* </Box> */}

                    <Box className="m-t-10 p-t-20" style={{
                        position: "relative",
                    }} >

                         <Box className="cs__range m-b-20">
                            <DateRangeForm
                                //@ts-ignore
                                creatorId={id}
                                creatorDetails={creatorDetails} setCreatorDetails={setCreatorDetails} setStartDate={setStartDate}
                                setEndDate={setEndDate}/>
                        </Box>


                        <CreatorTransactionListDataTable creatorTransactionListContent={creatorTransactionListContent} startDate={startDate} endDate={endDate}/>
                    </Box>
                </>
                :
                ""
            }
        </Box>
        :
        ""
        }
    </>
    );

}

const showSubmitButtonLabel = (submitting:boolean, fileUploading:boolean) => {
    if (submitting) {
        return "Saving...";
    } else if (fileUploading) {
        return "Saving Photo...";
    } else {
        return "Save";
    }
}

// export default CustomCreatorProfileForm;
export default reduxForm({
    form: 'profile',
    validate
})(CustomCreatorProfileForm)
