import { HIDE_BACKDROP, HIDE_LOADER, SHOW_BACKDROP, SHOW_DIALOG_BOX, SHOW_ERROR_ALERT, SHOW_LOADER, SHOW_SUCCESS_ALERT, SHOW_UPLOAD_LOADER,
    HIDE_UPLOAD_LOADER } from "../../constants";
import { ShowSuccessAlertAction, ShowErrorAlertAction, ShowDialogBoxAction, alertParamsInterface, dialogInterface, ShowLoaderAction, HideLoaderAction, ShowBackdropAction, HideBackdropAction, ShowUploadingLoaderAction,
    HideUploadingLoaderAction } from "./types";

export const ShowSuccessAlert = (payload: alertParamsInterface) : ShowSuccessAlertAction => ({
    type: SHOW_SUCCESS_ALERT,
    payload: payload

});

export const ShowErrorAlert = (payload: alertParamsInterface) : ShowErrorAlertAction => ({
    type: SHOW_ERROR_ALERT,
    payload: payload
});

export const ShowDialogBox = (payload: dialogInterface) : ShowDialogBoxAction => ({
    type: SHOW_DIALOG_BOX,
    payload: payload
})

export const ShowProgressBar = () : ShowLoaderAction => ({
    type: SHOW_LOADER
});

export const HideProgressBar = () : HideLoaderAction => ({
    type: HIDE_LOADER
});

export const ShowBackdrop = () : ShowBackdropAction => ({
    type: SHOW_BACKDROP
});

export const HideBackdrop = () : HideBackdropAction => ({
    type: HIDE_BACKDROP
});

export const ShowUploadingBar = () : ShowUploadingLoaderAction => ({
    type: SHOW_UPLOAD_LOADER
});

export const HideUploadingBar = () : HideUploadingLoaderAction => ({
    type: HIDE_UPLOAD_LOADER
});
