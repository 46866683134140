import { Reducer } from 'react';
import { UPDATE_PACKAGE_LIST_CONTENT_DATA, UPDATE_PACKAGE_STATUS } from '../../constants';
import { packageListActions, packageListState } from './types';

export const initialState: packageListState = {
    packageListContent: []
};

export const packageListReducer: Reducer<packageListState, packageListActions> = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PACKAGE_LIST_CONTENT_DATA:
            return {
                ...state,
                packageListContent: [...(action?.data || [])],
            };

        // case ADD_CREATOR_LIST_CONTENT_DATA:
        //     return {
        //         ...state,
        //         // creatorListContent: [...state.creatorListContent, ...(action?.data || [])],
        //         creatorListContent: state.creatorListContent.concat(action?.data),
        //     };

        case UPDATE_PACKAGE_STATUS:
            return {
                ...state,
                packageListContent: state.packageListContent.map(plan => {
                    if (plan.id === action.data) {
                        plan.isActive = !plan.isActive
                        return {...plan}
                    };
                    return plan;
                })
            }

        // case SAVE_CREATOR_SEARCH_TEXT:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         searchedValue : action.data
        //     };

        // case SAVE_CREATOR_CURRENT_PAGE:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         currentPage : action.data
        //     };

        // case SAVE_TOTAL_CREATORS:
        //     return {
        //         ...state,
        //         // creatorListContent: state.creatorListContent,
        //         totalCreators : action.data
        //     };

        // case RESET:
        //     return {
        //         ...initialState //Always return the initial state
        //     };

        default:
            return state;
    }
};
