import React from 'react';
import { Autocomplete, InputLabel, MenuItem, Select, TextField, createFilterOptions } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { Box } from '@mui/system';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import {useState, useEffect} from 'react'
import { CircularProgress } from '@mui/material';
import { APP_USER,CREATOR_API } from '../../constants';
import { postRequest,getRequest } from '../../data/helpers/services';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../store';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const renderSelectField = (field: any) => {
    return (
        <>
            <InputLabel id={field.labelId}>{field.label}</InputLabel>
            <Select
                {...field.input}
                labelId={field.labelId}
                id={field.id}
                label={field.label}
            >
                {field.options.map((option: any, index: number) => {
                    return <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>
                })}
            </Select>
        </>
    );
}

export const renderTagsField = (field: any) => {
    return (
        <Autocomplete
            className='tag-t-0'
            autoComplete={true}
            multiple
            filterSelectedOptions
            {...field.input}
            id={field.id}
            options={field.options}
            getOptionLabel={(option: any) => option.label}
            value={field.input.value || []}
            onChange={(e, newValue) => {
                field.setValue(newValue);
                // field.input.onChange(newValue);
            }}
            onBlur={(e) => {
                console.log("Item blured");
            }}
            renderInput={params => {
                params.inputProps.onKeyDown = field.handleKeyDown;
                return (
                    <TextField
                        placeholder='Write your tags separated by comma'
                        {...params}
                        margin="normal"
                        fullWidth
                    />
                );
            }}
        />
    );
}

export const renderCountriesField = (field: any) => {
    let isDisable=false;
    if (field.disabled){
        isDisable=true;
    }

    let tempArray: any = [];
    if (field.countryValue && field.countryValue.length > 0){
        field.input.value = (field.countryValue)
        field.countryValue.forEach((country) => {
            tempArray.push(country.id)
        })
    }

    if (tempArray.length === 0){
        field.input.value = null
    }
    return (
            <Autocomplete
                className='tag-t-0'
                multiple
                disableCloseOnSelect
                disabled={isDisable}
                {...field.input}
                id={field.id}
                limitTags={1}
                options={field.options}
                getOptionLabel={(option: any) => option.title}
                value={field.input.value || []}
                onChange={(e, newValue:any) => {
                    if (tempArray.length > 0){
                        let tempId:any = []
                        let finalArray:any = []
                        newValue.forEach((x)=>{
                            if ((tempId.length>0) && (tempId.indexOf(x.id) >= 0)){
                                finalArray.splice(tempId.indexOf(x.id),1)
                            }else{
                                tempId.push(x.id)
                                finalArray.push(x)
                            }
                        })
                        field.input.onChange(finalArray);
                        field.setCountryValue(finalArray);
                    }
                    else{
                        field.setCountryValue(newValue);
                        field.input.onChange(newValue);
                    }
                }}
                onBlur={(e) => {
                    console.log("Item blured");
                }}
                renderOption={(props, option:any, { selected }) => (
                    <>
                    <li {...props}>
                        <Checkbox
                        className='checkbox--calculate'
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={(tempArray.indexOf(option.id) >= 0) ? true : selected}
                        // checked={selected}
                        />
                         {option.title}
                    </li>
                    </>
                )}
                renderInput={params => {
                    params.inputProps.onKeyDown = field.handleCountryKeyDown;
                    return (
                        <TextField
                            placeholder='Please select available countries'
                            {...params}
                            margin="normal"
                            fullWidth
                        />
                    );
                }}
            />
    );
}


export const renderStreamCountriesField = (field: any) => {
    let isDisable=false;
    if (field.disabled){
        isDisable=true;
    }

    let tempArray: any = [];
    if (field.countryValue && field.countryValue.length > 0){
        field.input.value = (field.countryValue)
        field.countryValue.forEach((country) => {
            tempArray.push(country.id)
        })
    }
    return (
            <Autocomplete
                className='tag-t-0'
                multiple
                disableCloseOnSelect
                disabled={isDisable}
                {...field.input}
                id={field.id}
                options={field.options}
                getOptionLabel={(option: any) => option.title}
                value={field.input.value || []}
                onChange={(e, newValue:any) => {
                    if (tempArray.length > 0){
                        let tempId:any = []
                        let finalArray:any = []
                        newValue.forEach((x)=>{
                            if ((tempId.length>0) && (tempId.indexOf(x.id) >= 0)){
                                finalArray.splice(tempId.indexOf(x.id),1)
                            }else{
                                tempId.push(x.id)
                                finalArray.push(x)
                            }
                        })
                        field.input.onChange(finalArray);
                        field.setCountryValue(finalArray);
                    }
                    else{
                        field.setCountryValue(newValue);
                        field.input.onChange(newValue);
                    }
                }}
                onBlur={(e) => {
                    console.log("Item blured");
                }}
                renderOption={(props, option:any, { selected }) => (
                    <>
                    <li {...props}>
                        <Checkbox
                        className='checkbox--calculate'
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={(tempArray.indexOf(option.id) >= 0) ? true : selected}
                        // checked={selected}
                        />
                            {option.title}
                    </li>
                    </>
                )}
                renderInput={params => {
                    params.inputProps.onKeyDown = field.handleCountryKeyDown;
                    return (
                        <TextField
                            placeholder='Please select available countries'
                            {...params}
                            margin="normal"
                            fullWidth
                        />
                    );
                }}
            />
    );
}


export const renderDateTimeField = (field: any) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                {...field.input}
                renderInput={(props) => <TextField {...props} />}
                label={field.label}
                {...field.meta}
                disablePast={field.disablePrevious}
                onChange={(newValue) => {
                    if (newValue){
                        // field.setSelectedSchedule(newValue);
                        field.input.onChange(newValue);
                    }
                }}
            />
        </LocalizationProvider>
    );
}

export const renderPackageDateTimeField = (field: any) => {
    if (field.isNull && (field.input.value === "")){
        field.input.value = null;
    }
    // else if (field.input.value === ""){
    //     field.input.value = new Date();
    // }
    let setTime = new Date()
    let finalTime = setTime.setMinutes(setTime.getMinutes() + 15)
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                {...field.input}
                renderInput={({ inputProps, ...restParams }) => (
                    <TextField {...restParams}
                    inputProps={{
                        ...inputProps,
                        placeholder: "mm/dd/YYYY hh:mm",
                    }}
                    />
                    )}
                label={field.label}
                minDateTime = {(field.disablePrevious) ? new Date(finalTime) :  ""}
                {...field.meta}
                disabled={field.disabled}
                disablePast={field.disablePrevious}
                onChange={(newValue) => {
                    if (newValue){
                        // field.setSelectedSchedule(newValue);
                        field.input.onChange(newValue);
                    }
                }}
            />
        </LocalizationProvider>
    );
}


export const renderDateField = (field: any) => {
    if (field.isNull && (field.input.value === "")){
        field.input.value = null;
    }
    else if (field.input.value === ""){
        field.input.value = new Date();
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
             <DesktopDatePicker
                {...field.input}
                label={field.label}
                inputFormat="dd/MM/yyyy"
                disabled={field.disabled}
                disableFuture = {field.disableFuture}
                renderInput={(params) => <TextField {...params} />}
                {...field.meta}
                disablePast={field.disablePrevious}
                onChange={(newValue) => {
                    if (newValue){
                        // field.setSelectedSchedule(newValue);
                        field.input.onChange(newValue);
                    }
                }}
                />
            {/* <DateTimePicker
                {...field.input}
                renderInput={(props) => <TextField {...props} />}
                label={field.label}
                {...field.meta}
                // disablePast={field.disablePrevious}
                onChange={(newValue) => {
                    if (newValue){
                        // field.setSelectedSchedule(newValue);
                        field.input.onChange(newValue);
                    }
                }}
            />*/}
        </LocalizationProvider>
    );
}

export const renderHostsField = (field: any) => {
    return (
        <Autocomplete
            className='tag-t-0'
            // multiple
            autoComplete={true}
            {...field.input}
            id={field.id}
            options={field.options ? field.options : []}
            filterSelectedOptions
            isOptionEqualToValue = {(option:any, value:any) => option.id === value.id}
            getOptionLabel={(option: any) => option.fullName ? option.fullName : ""}
            value={field.input.value || []}
            onChange={(e, newValue) => {
                field.setHostValue(newValue);
                field.input.onChange(newValue);
            }}
            onBlur={(e) => {
                console.log("Item blured");
            }}
            renderInput={params => {
                // params.inputProps.onKeyDown = field.handleHostKeyDown;
                return (
                    <TextField
                        placeholder='Please select host'
                        {...params}
                        margin="normal"
                        fullWidth
                    />
                );
            }}
        />
    );
}


export const renderCreatorsField = (field: any) => {
    return (
        <Autocomplete
            className='tag-t-0'
            // multiple
            autoComplete={true}
            {...field.input}
            id={field.id}
            options={field.options ? field.options : []}
            filterSelectedOptions
            isOptionEqualToValue = {(option:any, value:any) => option.id === value.id}
            getOptionLabel={(option: any) => option.fullName ? option.fullName : ""}
            value={field.input.value || []}
            onChange={(e, newValue) => {
                field.setCreatorValue(newValue);
                field.input.onChange(newValue);
            }}
            onBlur={(e) => {
                console.log("Item blured");
            }}
            renderInput={params => {
                // params.inputProps.onKeyDown = field.handleHostKeyDown;
                return (
                    <TextField
                        placeholder='Please select creator'
                        {...params}
                        margin="normal"
                        fullWidth
                    />
                );
            }}
        />
    );
}


export const renderCoHostsField = (field: any) => {
    let coHostOptions = field.options.filter(function( obj ) {
        return obj.id !== field.hostValue?.id;
    });

    return (
        <Autocomplete
            className='tag-t-0'
            multiple
            {...field.input}
            autoComplete={true}
            id={field.id}
            options={field.options ? coHostOptions : []}
            filterSelectedOptions
            getOptionLabel={(option: any) => option.fullName ? option.fullName : ""}
            value={field.input.value || []}
            onChange={(e, newValue) => {
                field.setCoHostValue(newValue);
                field.input.onChange(newValue);
            }}
            onBlur={(e) => {
                console.log("Item blured");
            }}
            renderInput={params => {
                // params.inputProps.onKeyDown = field.handleCoHostKeyDown;
                return (
                    <TextField
                        placeholder='Please select co host'
                        {...params}
                        margin="normal"
                        fullWidth
                    />
                );
            }}
        />
    );
}

export const renderAuthorsField = (field: any) => {
    return (
        <Autocomplete
            className='tag-t-0'
            multiple
            {...field.input}
            autoComplete={true}
            id={field.id}
            options={field.options ? field.options : []}
            filterSelectedOptions
            isOptionEqualToValue = {(option:any, value:any) => option.id === value.id}
            getOptionLabel={(option: any) => option.fullName ? option.fullName : ""}
            value={field.input.value || []}
            onChange={(e, newValue) => {
                field.setAuthorValue(newValue);
                field.input.onChange(newValue);
            }}
            onBlur={(e) => {
                console.log("Item blured");
            }}
            renderInput={params => {
                // params.inputProps.onKeyDown = field.handleAuthorKeyDown;

                // let text:string = ""
                // if (!field.authorValue){
                //     text = " Field is required"
                // }

                return (
                    <TextField

                        // error={text ? true : false}
                        // helperText={text || ""}

                        placeholder='Please select author'
                        {...params}
                        margin="normal"
                        fullWidth

                    />
                );
            }}
        />
    );
}


export const renderCreatorsFollowingField = (field: any) => {
    return (
        <Autocomplete
            className='tag-t-0'
            multiple
            {...field.input}
            autoComplete={true}
            id={field.id}
            options={field.options ? field.options : []}
            filterSelectedOptions
            isOptionEqualToValue = {(option:any, value:any) => option.id === value.id}
            getOptionLabel={(option: any) => option.fullName ? option.fullName : ""}
            value={field.input.value || []}
            onChange={(e, newValue) => {
                field.setCreatorFollowingListValue(newValue);
                field.input.onChange(newValue);
            }}
            onBlur={(e) => {
                console.log("Item blured");
            }}
            renderInput={params => {
                // params.inputProps.onKeyDown = field.handleAuthorKeyDown;

                // let text:string = ""
                // if (!field.authorValue){
                //     text = " Field is required"
                // }

                return (
                    <TextField

                        // error={text ? true : false}
                        // helperText={text || ""}

                        placeholder='Please select creator'
                        {...params}
                        margin="normal"
                        fullWidth

                    />
                );
            }}
        />
    );
}


interface appUser{
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    handle: string;
}

export const renderSpecificUserField = (field: any) => {

    const [open,setOpen,options,setOptions, token, loading, setLoading] = [field.open,field.setOpen,field.options,field.setOptions, field.token, field.loading ,field.setLoading]
    // const loading = open && options.length === 0;

    const onChangeHandle = async (value) => {
        // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
        // if (value.length>2){

            // let response = await getRequest(CREATOR_API.LIST, token);
            setLoading(true);
            let response = await postRequest(APP_USER.LIST,
                {
                    search_text:value
                },
                token);
            setLoading(false);
            if (response) {
                let users = response?.data?.data;
                let usersList: appUser[] = [];
                if (users && users.length) {
                    users.map((appUser: appUser) => usersList.push({
                        id: appUser.id, fullName: ((appUser?.firstName ?? "") + ' ' + (appUser?.lastName ?? "") + (appUser.handle ? '  ('+ (appUser.handle ?? "") + ')' : "" )),
                        firstName: appUser.firstName,
                        lastName: appUser.lastName,
                        handle: appUser.handle
                    }));
                }
                setOptions(usersList);


            } else {
                console.log('Unable to get list');
            }
        // }
        // else{
        //     setOptions([]);
        // }
    }

    return (
        <Autocomplete
            className='tag-t-0'
            multiple
            // {...field.input}
            // autoComplete={true}
            id={field.id}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            open={open}

            filterSelectedOptions
            isOptionEqualToValue = {(option:any, value:any) => option.id === value.id}
            getOptionLabel={(option: any) => option.fullName ? option.fullName : ""}
            options={options}
            loading = {false}

            onChange={(e, newValue) => {
                field.input.onChange(newValue);
                field.setSpecificUserListValue(newValue);
            }}
            onBlur={(e) => {
                console.log("Item blured");
            }}

            renderInput={params => {

                return (
                    <TextField
                        placeholder="search consumer by name"
                        {...params}
                        margin="normal"
                        fullWidth
                        onChange={ev => {
                            // dont fire API if the user delete or not entered anything
                            if (ev.target.value !== "" || ev.target.value !== null) {
                              onChangeHandle(ev.target.value);
                            }
                          }}

                          InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                  <>
                            {loading ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                            </>
                        )
                        }}
                    />
                );
            }}
        />
    );
}


export const renderShowFollowingField = (field: any) => {
    return (
        <Autocomplete
            className='tag-t-0'
            multiple
            {...field.input}
            autoComplete={true}
            id={field.id}
            options={field.options ? field.options : []}
            filterSelectedOptions
            isOptionEqualToValue = {(option:any, value:any) => option.id === value.id}
            getOptionLabel={(option: any) => option.title ? option.title : ""}
            value={field.input.value || []}
            onChange={(e, newValue) => {
                field.setShowFollowingListValue(newValue);
                field.input.onChange(newValue);
            }}
            onBlur={(e) => {
                console.log("Item blured");
            }}
            renderInput={params => {
                return (
                    <TextField

                        // error={text ? true : false}
                        // helperText={text || ""}

                        placeholder='Please select shows'
                        {...params}
                        margin="normal"
                        fullWidth

                    />
                );
            }}
        />
    );
}


export const renderPackageField = (field: any) => {
    return (
        <Autocomplete
            className='tag-t-0'
            // multiple
            {...field.input}
            autoComplete={true}
            id={field.id}
            options={field.options ? field.options : []}
            filterSelectedOptions
            isOptionEqualToValue = {(option:any, value:any) => option.id === value.id}
            getOptionLabel={(option: any) => option.title ? option.title : ""}
            value={field.input.value || []}
            onChange={(e, newValue) => {
                field.setPackageListValue(newValue);
                field.input.onChange(newValue);
            }}
            onBlur={(e) => {
                console.log("Item blured");
            }}
            renderInput={params => {
                return (
                    <TextField

                        // error={text ? true : false}
                        // helperText={text || ""}

                        placeholder='Please select package'
                        {...params}
                        margin="normal"
                        fullWidth

                    />
                );
            }}
        />
    );
}

