import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Field, reduxForm } from 'redux-form';
import {  TextField, PasswordField } from '../common/TextField';

import { userLogin } from '../../data/mappers/user';
import { userLoggedIn } from '../../store/user';
import { creatorLoggedOut } from "../../store/creator";

import { HidePodcastUploadingBar } from '../../store/episode'

import {  postRequest } from '../../data/helpers/services';
import { SUPERADMIN_LOGIN, CREATOR_LOGIN } from '../../constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';


interface SuperAdminLoginFormInterface {
    email: string;
    password: string;
}

const validate = (values: SuperAdminLoginFormInterface) => {
    const errors: any = {};
    if (!values.email || (values.email && !values.email.trim())) {
        errors.email = 'Field is required.';
    } else if (values.email.trim().length > 25) {
        errors.title = 'Email value must be 25 characters or less.';
    }

    if (!values.password || (values.password && !values.password.trim())) {
        errors.password = 'Field is required.';
    } else if (values.password.trim() && values.password.trim().length > 100) {
        errors.description = 'Field value must be 100 characters or less.';
    }

    return errors;
};

const SuperAdminLoginForm = (props: any) => {
    const { handleSubmit, change, submitting, reset, isAdmin } = props;

    const [ emailError, setEmailError ] = useState<[boolean,string]>([false,""]);
    const [ passwordError, setPasswordError] = useState<[boolean,string]>([false,""]);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const initForm = () => {
    }


    useEffect(() => {
        initForm();
    }, []);


    const initialState = () => {
        setEmailError([false,""]);
        setPasswordError([false,""]);
    }

    const validateBeforeAdd = (values: any) => {
        if (!values.email && !values.password){
            dispatch(ShowErrorAlert({visible:true, message: "Email & Password are required"}));
            // setEmailError([true,"Field is required."])
            // setPasswordError([true,"Field is required."])
            return false;
        }
        else{
            return true;
        }
    }

    const signin = async (values: any) => {
        let dataValidated = validateBeforeAdd(values);

        if (dataValidated) {
            dispatch(ShowProgressBar());

            let data = userLogin(values);

            var response: any = null;

            response = await postRequest((isAdmin ? SUPERADMIN_LOGIN : CREATOR_LOGIN), data, "");

            if(response) {
                if(response.status === 201 || response.status === 200) {
                    try {
                        if (response.data.code === 400){
                            dispatch(HideProgressBar());
                            dispatch(ShowErrorAlert({visible:true, message: response.data.message}));
                        }else{
                            dispatch(userLoggedIn(response.data.data));
                            dispatch(creatorLoggedOut())
                            dispatch(HidePodcastUploadingBar())
                            dispatch(ShowSuccessAlert({visible:true, message: (isAdmin ? "Admin " : "Creator ").concat("Logged in successfully.")}));
                            dispatch(HideProgressBar());

                            isAdmin ? navigate('/admin/dashboard') : navigate('/creator/dashboard');
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        }
    };


    return (
        <>
        <Box className='mx--auto'>
            <Box component="form" onSubmit={handleSubmit(signin)}>
                <Box component="div" className="row m-t-30">
                    <div className='col-12 md-col-12 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="Email">Email</label>
                        <Field name="email" component={TextField} type="text" placeholder='Enter registered email here'
                            //@ts-ignore
                            styleName='input--transparent' />
                            {/* onChange={setEmailError([false,""])} */}
                        {
                            (emailError[0])
                            ?
                                <span className="error--pill text--error">{emailError[1]}</span>
                            :
                            ""
                        }
                    </div>

                    <div className='col-12 md-col-12 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="Password">Password</label>
                        <Field name="password" component={PasswordField} type="password" placeholder='Enter password here'
                            //@ts-ignore
                            styleName='input--transparent' />
                            {/* onChange={setPasswordError([false,""])} */}
                        {
                            (passwordError[0])
                            ?
                                <span className="error--pill text--error">{passwordError[1]}</span>
                            :
                            ""
                        }
                    </div>

                    <div className="col-12 m-b-40">
                        <button type="submit" className={`btn--pill saveBtn`} disabled={submitting}>
                            {showSubmitButtonLabel(submitting)}
                        </button>
                    </div>
                </Box>
            </Box>
        </Box>
        </>
    );
};

const showSubmitButtonLabel = (submitting: boolean) => {
        return `Sign In`;
};

export default reduxForm({
    form: 'superAdminLogin',
    validate,
})(SuperAdminLoginForm);
