import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, ThemeProvider, ThemeOptions } from '@mui/material';

export const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#232157',
        },
        secondary: {
            main: '#F6433B',
        },
        background: {
            paper: '#232157',
            default: '#191840',
        },
        text: {
            primary: 'rgba(255,255,255,0.87)',
            secondary: '#C2C1E6',
            disabled: '#C2C1E6'
        },
        mode: 'dark'
    },
    typography: {
        allVariants: {
            fontFamily: 'poppins',
            textTransform: 'none',
            fontSize: 15,
            fontWeight: 300
        },
    }
};

const theme = createTheme(themeOptions);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
