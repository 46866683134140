 import React from 'react'

 export default function PlayIcon() {
   return (
     <>
        <svg width="29" height="38" viewBox="0 0 29 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.51237 36.9021L27.442 20.5976C27.7601 20.371 28.0166 20.0852 28.1927 19.7612C28.3688 19.4371 28.4602 19.0827 28.4602 18.7239C28.4602 18.3651 28.3688 18.0108 28.1927 17.6867C28.0166 17.3627 27.7601 17.0769 27.442 16.8503L4.51237 0.545694C4.10666 0.255651 3.61496 0.0724618 3.09471 0.0174996C2.57446 -0.0374626 2.04716 0.0380874 1.57414 0.235296C1.10112 0.432504 0.701869 0.743242 0.423143 1.1313C0.144418 1.51936 -0.0022552 1.9688 -1.98266e-05 2.42705L-2.2676e-05 35.0208C-0.00225813 35.4791 0.144415 35.9284 0.42314 36.3165C0.701866 36.7046 1.10111 37.0153 1.57414 37.2125C2.04716 37.4097 2.57446 37.4853 3.09471 37.4303C3.61495 37.3753 4.10666 37.1922 4.51237 36.9021Z" fill="white"/>
        </svg>
     </>
   )
 }
