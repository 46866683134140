import React, { useEffect, useState } from "react";
import { AppContextProvider } from './context/AppContext';
import logo from "../styles/images/dashlogo.png";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { HMSThemeProvider, Box } from "@100mslive/react-ui";
import { shadeColor } from "./common/utils";

import {
    getUserToken as defaultGetUserToken,
    getBackendEndpoint
  } from "./services/tokenService";
import { FeatureFlags } from './services/FeatureFlags';
import Conference from "./Conference";

const tokenEndpoint = process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT;
const policyConfig = {};
const metadata = "";

const defaultTokenEndpoint = process.env
  .REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
  ? `${getBackendEndpoint()}${
      process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
    }/`
  : process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT;

const envPolicyConfig = JSON.parse(process.env.REACT_APP_POLICY_CONFIG || "{}");

const ConfrenceContainer = () => {
  const config =  {
        roomId : "",
        tokenEndpoint : defaultTokenEndpoint,
        themeConfig: {
          aspectRatio : "1-1",
          font : "Roboto",
          color : "#2F80FF",
          theme : "dark",
          logo : "",
          headerPresent : "false",
          metadata : "",
        },
        getUserToken : defaultGetUserToken,
        policyConfig : envPolicyConfig,
      }

const { 0: width, 1: height } = config.themeConfig.aspectRatio
    .split("-")
    .map(el => parseInt(el));
  const [themeType, setThemeType] = useState(config.themeConfig.theme);
  useEffect(() => {
      //@ts-ignore
    window.toggleUiTheme = () => {
      setThemeType(themeType === "dark" ? "light" : "dark");
    };
  }, [themeType]);

  useEffect(() => {
    setThemeType(config.themeConfig.theme);
  }, [config.themeConfig.theme]);

  useEffect(() => {
      console.log("Confrence Container");
  }, []);

    return (<HMSThemeProvider
        //@ts-ignore
        themeType={themeType}
        aspectRatio={{ width, height }}
        theme={{
          colors: {
            //@ts-ignore
            brandDefault: config.themeConfig.color,
            //@ts-ignore
            brandDark: shadeColor(config.themeConfig.color, -30),
            //@ts-ignore
            brandLight: shadeColor(config.themeConfig.color, 30),
            //@ts-ignore
            brandDisabled: shadeColor(config.themeConfig.color, 10),
          },
          fonts: {
            //@ts-ignore
            sans: [config.themeConfig.font, "Inter", "sans-serif"],
          },
        }}
      >
    { //@ts-ignore
    <HMSRoomProvider isHMSStatsOn={FeatureFlags.enableStatsForNerds}>
        <AppContextProvider
            //@ts-ignore
            roomId=""
            tokenEndpoint={tokenEndpoint}
            policyConfig={policyConfig}
            appDetails={metadata}
            logo={logo}
        >
                <Box
                    css={{
                        bg: "$mainBg",
                        w: "100%",
                        ...(config.themeConfig.headerPresent === "true"
                            ? { flex: "1 1 0", minHeight: 0 }
                            : { h: "100%" }),
                    }}
                >
                    {//@ts-ignore
                    <Conference getUserToken={defaultGetUserToken} />}
                </Box>
        </AppContextProvider>
    </HMSRoomProvider>}
    </HMSThemeProvider>);
}

export default ConfrenceContainer;
