import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Button } from '../common/Button';
// import ShowListDataTable from '../common/ShowListDataTable';
import AppVersionListDataTable from '../common/AppVersionListDataTable';
import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { APP_VERSION_API } from '../../constants';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { appVersionListContentDataModified, appVersionListContentData } from '../../data/mappers/appVersionList';
import { UpdateAppVersionListContent } from '../../store/appVersionList';

import { useNavigate } from 'react-router-dom';
import GoToTop from '../common/GoTop'


const AppVersionList = (props: any) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    // let creatorToken = useSelector((state: RootStateType) => state.creator.access_token);
    let token =  useSelector((state: RootStateType) => state.user.access_token);

    const appVersionListContent = useSelector((state: RootStateType) => state.versionList.appVersionListContent);

    useEffect(() => {
        getAppVersionListContent();
    }, []);

    const getAppVersionListContent = async () => {
        const res: any = await postRequest(APP_VERSION_API.LIST, {}, token);
        if (isApiCodeSucess(res)) {
            let formattedAppVersionListContentData = res.data?.data as appVersionListContentData,
                formattedAppVersionListContent: appVersionListContentDataModified = [];
                formattedAppVersionListContentData.forEach((x) => {
                    formattedAppVersionListContent.push({
                        version: x.version,
                        id: x.id,
                        deviceType: (x.OSType === 'ios') ? "iOS": "Android",
                        isForceUpdate:x.isForceUpdate,
                        date: x.createdAt
                });
            });

            dispatch(UpdateAppVersionListContent(formattedAppVersionListContent));
        }
    };

    const buttonBox = (
        <Button
            type="button"
            styleName={`button--outlined active`}
            onClick={() => navigate('/admin/app/version/create')}
        >Add New Version
        </Button>
    )

    return (
        <>
            <Box className="dashboard__content">
                <Box className="col-12 md-col-8 m-b-20">
                        <Box className="button__box" style={{display: 'block'}}>{buttonBox}</Box>
                </Box>
                <Box>
                    <AppVersionListDataTable appVersionListContent={appVersionListContent} />
                </Box>
            </Box>
            <GoToTop/>
        </>
    );
};

export default AppVersionList;
