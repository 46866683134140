import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Field, reduxForm } from 'redux-form';
import { TextArea, TextField } from '../common/TextField';
import { renderSelectField, renderTagsField, renderDateTimeField, renderCountriesField, renderStreamCountriesField, renderCreatorsField } from '../form/Fields';
import { createMatch } from '../../data/mappers/createMatch';
import { RootStateType } from '../../store';
import { getRequest, postFileRequest, postRequest, putRequest } from '../../data/helpers/services';
import { COUNTRIES_LIST, MATCH_API, TAGS_LIST, UPLOAD_FILE, CREATOR_API, MATCH } from '../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';

import Compressor from 'compressorjs';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import UploadIcon from '../../icons/UploadIcon';
import { Button } from '../common/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar } from '../../store/alerts';
import { MatchInterface } from '../../store/match';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from "@mui/material/styles";
import GoToTop from '../common/GoTop'

interface tag {
    id: number;
    label: string;
}

interface country {
    id: number;
    title: string;
}

const typeOptions = [
    {
        name: 'Podcast',
        value: 'podcast',
    },
    {
        name: 'Live',
        value: 'live',
    }
];

const languageOptions = [
    {
        name: 'English',
        value: 'english',
    },
    {
        name: 'Hindi',
        value: 'hindi',
    }
];


interface host {
    id: number;
    handle: string;
    firstName: string;
    lastName: string;
    fullName: string;
}

const prepareHosts = (hosts:any, hostsList:any) => {
    let hostsListArray:host[] = [];
    let arrHostsIds:number[] = [];
    hosts.map((host:any) => arrHostsIds.push(host.user.id));
    hostsList.map((host:host) => {
        if(arrHostsIds.includes(host.id)) {
            hostsListArray.push(host);
        }
        return true;
    });
    return hostsListArray;
};


const prepareTags = (tags:any, tagsList:any) => {
    let tagsListArray:tag[] = [];
    tagsList.map((tag:tag) => {
        if(tags.includes(tag.label)) {
            tagsListArray.push(tag);
        }
        return true;
    });
    return tagsListArray;
};

const prepareCountries = (countries:any, countriesList:any) => {
    let countriesListArray:country[] = [];
    let arrCountryIds:number[] = [];
    countries.map((country:any) => arrCountryIds.push(country.id));

    countriesList.map((country:country) => {
        if(arrCountryIds.includes(country.id)) {
            countriesListArray.push(country);
        }
        return true;
    });
    return countriesListArray;
};


interface creator {
    id: number;
    handle: string;
    firstName: string;
    lastName: string;
    fullName: string;
}

const prepareCreators = (creators:any, creatorsList:any) => {
    let creatorsListArray:creator[] = [];
    let arrCreatorIds:number[] = [];
    // creators.map((creator:any) => arrCreatorIds.push(creator.id));
    arrCreatorIds.push(creators.id);

    creatorsList.map((creator:creator) => {
        if(arrCreatorIds.includes(creator.id)) {
            creatorsListArray.push(creator);
        }
        return true;
    });
    return creatorsListArray;
};

interface liveStreamForm {
    title: string;
    showName: string;
    description: string;
}

const validate = (values: liveStreamForm) => {
    const errors: any = {};
    if (!values.title || (values.title && !values.title.trim())) {
        errors.title = 'Field is required.';
    } else if (values.title.trim().length > 256) {
        errors.title = 'Field value must be 256 characters or less.';
    }

    if (!values.description || (values.description && !values.description.trim())) {
        errors.description = 'Field is required.';
    } else if (values.description.trim() && values.description.trim().length > 4096) {
        errors.description = 'Field value must be 4096 characters or less.';
    }

    return errors;
};

const tooltipStyle = {
    tooltip: {
        '& .MuiTooltip-tooltip': {
            background : 'red'
        },
    },
}

const LiveStreamForm = (props: any) => {
    const { handleSubmit, change, submitting, reset, modeType } = props;
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [startType, setStartType] = useState('0');
    const [thumbnailImage, setThumbnailImage] = useState('');

    const [value, setValue] = useState<tag[]>([]);
    const [countryValue, setCountryValue] = useState<country[]>([]);
    const [tagsList, setTagsList] = useState<tag[]>([]);
    const { matchId } = useParams();
    const [matchDetails, setMatchDetails] = useState<MatchInterface | null>(null);
    const [disablePrevious, setDisablePrevious] = useState(true);
    const [fileUploading, setFileUploading] = useState(false);
    const [countriesList, setCountriesList] = useState<country[]>([]);

    const [creatorValue, setCreatorValue] = useState<creator|null>(null);
    const [creatorsList, setCreatorsList] = useState<creator[]>([]);

    // const creatorToken = useSelector((state: RootStateType) => state.creator.access_token);
    const user = useSelector((state: RootStateType) => state.user);
    const token = user.access_token;

    const getTagsList = async () => {
        let response = await getRequest(TAGS_LIST, token);
        if (response) {
            let tags = response?.data?.data;
            let tagsList: tag[] = [];
            if (tags && tags.length) {
                tags.map((tag: any) => tagsList.push({ id: tag.id, label: tag.tag }));
            }

            setTagsList(tagsList);
            if (matchDetails) {
                let stream = matchDetails.streams[0];
                let tags = prepareTags(stream.streamtags, tagsList);
                change('tags', tags);
            }
        } else {
            console.log('Unable to get list');
        }
    };

    const getMatchDetails = async (id: string) => {
        dispatch(ShowProgressBar());
        await getTagsList();
        await getCountriesList();
        let url = (user.role === "creator") ? MATCH_API.DETAIL : MATCH.DETAIL;
        const response = await postRequest(
            url,
            {
                matchId: id,
            },
            token
        );
        if (response) {
            if (response.data.code === 200) {
                setMatchDetails(response.data.data);
            }
        }
        dispatch(HideProgressBar());
    };

    const initForm = () => {
        change('startTime', new Date());
        change('type', 'podcast');
        change('language', 'english');
        setStartType('0');
        setCreatorValue(null);
        setCreatorsList([]);
        setThumbnailImage("");
        setMatchDetails(null);
    }

    const getCreatorList = async () => {
        let response = await getRequest(CREATOR_API.LIST, token);

        if (response) {
            let creators = response?.data?.data;
            let creatorList: any = [];
            if (creators && creators.length) {
                creators.map((creator: creator) => creatorList.push({
                    id: creator.id, fullName: ((creator.firstName ?? "") + ' ' + (creator.lastName ?? "")),
                }));
            }
            setCreatorsList(creatorList);
        } else {
            console.log('Unable to get list');
        }
    }



    const getCountriesList = async () => {
        let response = await postRequest(COUNTRIES_LIST, [], token);
        if (response) {
            let countries = response?.data?.data;
            let countriesList: country[] = [];
            if (countries && countries.length) {
                countries.map((country: country) => countriesList.push({ id: country.id, title: country.title }));
            }

            setCountriesList(countriesList);
            if (matchDetails) {
                let countries = prepareCountries(matchDetails.countrySupported, countriesList);

                change('countries', countries);
            }
        } else {
            console.log('Unable to get list');
        }
    }

    useEffect(() => {
        initForm();
        getCreatorList();
        getTagsList();
        getCountriesList();
    }, []);

    useEffect(() => {
        if(modeType === 'add') {
            // reset("liveStream");
            initForm();
        }
    }, [modeType]);

    useEffect(() => {
        change('tags', value);
    }, [value]);

    useEffect(() => {
        if (matchId && modeType === 'edit') {
            getMatchDetails(matchId);
        }
    }, [matchId]);

    useEffect(() => {
        if (matchDetails && modeType === 'edit') {
            fillMatchDetails(matchDetails);
        }
    }, [matchDetails]);

    const fillMatchDetails = async (matchDetails:any) => {
        change('id', matchDetails?.id);
        change('showName', matchDetails?.title);
        change('title', matchDetails?.streams[0].title);
        change('startTime', matchDetails?.startTime);
        setStartType('1');
        change('description', matchDetails?.description);
        change('type', matchDetails?.streams[0].eventType);
        change('language', matchDetails?.streams[0].eventLanguage);
        setDisablePrevious(true);

        let stream = matchDetails.streams[0];

        setThumbnailImage(stream.thumbnail);
        change('thumbnail', stream.thumbnail);

        if (tagsList.length) {
            let tags = prepareTags(stream.streamtags, tagsList);
            change('tags', tags);
        }

        if (countriesList.length) {
            let countries = prepareCountries(matchDetails.countrySupported, countriesList);
            change('countries', countries);
        }

        if (matchDetails.streams[0]) {
            let creator = matchDetails.streams[0].user;
            let creators = prepareCreators(creator, creatorsList);
            change('creator', creators[0]);
        }
    }

    const handleKeyDown = (event: any) => {
        switch (event.key) {
            case ',':
            case ' ':
            case 'Enter': {
                event.preventDefault();
                event.stopPropagation();
                if (event.target.value.length > 0) {
                    setValue([...value, { id: 0, label: event.target.value }]);
                }
                break;
            }
            default:
                console.log('...');
        }
    };

    const handleCountryKeyDown = (event: any) => {
        switch (event.key) {
            case ',':
            case 'Enter': {
                event.preventDefault();
                event.stopPropagation();
                // if (event.target.value.length > 0) {
                //     setCountryValue([...countryValue, { id: 0, title: event.target.value }]);
                // }
                break;
            }
            default:
                console.log('...');
        }
    };

    const validateBeforeAdd = (values: any) => {
        if ((values.tags).length === 0) {
            dispatch(ShowErrorAlert({visible:true, message: "Tags is required"}));
            return false;
        }
        else if ((user.role !== "creator") && (!values.creator)) {
            dispatch(ShowErrorAlert({visible:true, message: "Creator is required"}));
            return false;
        }
        else if (!values.countries){
            dispatch(ShowErrorAlert({visible:true, message: "Countries is required"}));
            return false;
        }
        else if ((modeType === 'edit') && (startType === '1') && (values.startTime !== matchDetails?.startTime) && (values.startTime < Date.now() )) {
            dispatch(ShowErrorAlert({visible:true, message: "Scheduled time is passed"}));
            return false;
        }
        else{
            return true;
        }
    }

    const handleCreatorKeyDown = (event: any) => {
        switch (event.key) {
            case ',':
            case 'Enter': {
                event.preventDefault();
                event.stopPropagation();
                break;
            }
            default:
                console.log('...');
        }
    };


    const saveStream = async (values: any) => {
        let dataValidated = validateBeforeAdd(values);

        if (dataValidated){
            dispatch(ShowProgressBar());
            let data = createMatch(values, startType);
            var response: any = null;
            if (matchDetails) {
                response = await putRequest(MATCH_API.UPDATE, data, token);
            } else {
                response = await postRequest(MATCH_API.CREATE, data, token);
                // response = ""
            }
            dispatch(HideProgressBar());
            if (response) {
                const resData = response.data;
                if (resData) {
                    (user.role === "creator") ?
                    navigate('/creator/match/' + resData.data.id, { replace: true })
                    :
                    navigate('/admin/match/' + resData.data.id, { replace: true });
                }
            }
        }
    };


    const handleCompressedUpload = async(e: any) => {
        const image = e.target.files[0];
        new Compressor(image, {
          quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
        //   maxHeight: imageHeight,
        //   maxWidth : imageWidth,
          success: async(res) => {

            let reader = new FileReader();
            reader.onload = (e: any) => {
                setThumbnailImage(e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);

            // upload file and obtain url for image
            setFileUploading(true);
            dispatch(ShowProgressBar());

            const formData = new FormData();

            formData.append('file', res, (Date.now()).toString() + e.target.files[0].name );

            const response: any = await postFileRequest(UPLOAD_FILE, formData, token);

            if (response) {
                const resData = response.data;
                if (resData.code === 200) {
                    let fileUrl = resData.data.file_path;
                    change( 'thumbnail', fileUrl);
                }
            } else {
                setThumbnailImage('')
            }

            dispatch(HideProgressBar());
            setFileUploading(false);

          },
        });
      };

    const handleFileSelect = async (event: any) => {
        if (event !== undefined && event.target.files && event.target.files[0]) {
            const filetype = event.target.files[0].type;

            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
            // if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 10000)) {
            if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 500)) {

                dispatch(ShowErrorAlert({ visible: true, message: 'Must be a .png, .jpg, .jpeg, .webp format image & not more than 500kb' }));
                event.target.value = null;
                return false;
            }

            handleCompressedUpload(event);

            // let reader = new FileReader();
            // reader.onload = (e: any) => {
            //     setThumbnailImage(e.target.result);
            // };
            // reader.readAsDataURL(event.target.files[0]);

            // upload file and obtain url for image
            // const formData = new FormData();
            // setFileUploading(true);
            // dispatch(ShowProgressBar());
            // Update the formData object
            // formData.append('file', event.target.files[0], event.target.files[0].name);

            // const response: any = await postFileRequest(UPLOAD_FILE, formData, token);
            // if (response) {
            //     const resData = response.data;
            //     if (resData.code === 200) {
            //         let fileUrl = resData.data.file_path;
            //         change('thumbnail', fileUrl);
            //     }
            // } else {
            //     setThumbnailImage('');
            // }
            // dispatch(HideProgressBar());
            // setFileUploading(false);
        }
        dispatch(HideProgressBar());
        setFileUploading(false);
        return true;
        // set that url in profile_image field which will be sent to backend
    };

    const handleFileRemove = () => {
        setThumbnailImage("");
        change('thumbnail', "");
    }

    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const [tags, setTags] = React.useState([
        { id: 'Thailand', text: 'Thailand' },
        { id: 'India', text: 'India' },
        { id: 'Vietnam', text: 'Vietnam' },
        { id: 'Turkey', text: 'Turkey' }
      ]);

      const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
      };

      const handleAddition = tag => {
        setTags([...tags, tag]);
      };

      const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
      };

      const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
      };

    return (
        <>
        <Box className="dashboard__topbar">
            {/* <Box className="dashboard__topbar--heading col-12">
                <Box component="h2">Live Stream</Box>
            </Box> */}
            <Box className="row align--items--center">
                <Box className="button__text__navigate">
                    <button onClick={() => navigate(-1)}><ArrowBackOutlinedIcon /></button>
                    <Box className="breadcrumb__title dashboard__topbar--heading" component="h2">{"Live Stream"}</Box>
                </Box>
            </Box>
        </Box>
        <Box className='dashboard__content max--760 mx--auto'>
            <Box component="form" onSubmit={handleSubmit(saveStream)}>
                <Box component="div" className="row">

                {   (user.role === "creator")
                    ?
                        <div className='col-12 md-col-12 m-b-20'>
                            <label className='input--label m-b-10' htmlFor="title">Title</label>
                            <Field name="title" onChange={(e:any) => {
                                change('title', e.target.value);
                                change('showName', e.target.value);
                            }} component={TextField} type="text" placeholder='Write your title here'
                                //@ts-ignore
                                styleName='input--transparent' />
                        </div>

                    :
                    <>
                        <div className='col-12 md-col-6 m-b-20'>
                            <label className='input--label m-b-10' htmlFor="title">Title</label>
                            <Field name="title" onChange={(e:any) => {
                                change('title', e.target.value);
                                change('showName', e.target.value);
                            }} component={TextField} type="text" placeholder='Write your title here'
                                //@ts-ignore
                                styleName='input--transparent' />
                        </div>

                        <div className='col-12 md-col-6 m-b-20'>
                            <label className='input--label m-b-10' htmlFor="cretor">Creator</label>
                            <FormControl className='input-inner-transparent' fullWidth>
                                <Field name="creator" component={renderCreatorsField} id="stream-creator" options={creatorsList} defaultValue={' '} handleCreatorKeyDown={handleCreatorKeyDown} setCreatorValue={setCreatorValue} />
                            </FormControl>
                        </div>
                    </>
                    }

                    <div className='col-12 md-col-6 m-b-20 d-none'>
                        <FormControl className='input-inner-dropdown' fullWidth>
                            <label className='input--label m-b-10' htmlFor="video-type-select">Type</label>
                            <Field name="type" component={renderSelectField} labelId="video-type-select-label" id="video-type-select" defaultValue="live" options={typeOptions} />
                        </FormControl>
                    </div>
                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="showName">Assign to show</label>
                        <Field name="showName" readOnly={true} component={TextField} type="text" placeholder='Write the show name'
                            styleName='input--transparent' />
                    </div>
                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="language-select">Language</label>
                        <FormControl className='input-inner-dropdown' fullWidth>
                            <Field name="language" component={renderSelectField} labelId="language-select-label" id="language-select" defaultValue="english" options={languageOptions} />
                        </FormControl>
                    </div>
                    {/* <div className='col-12 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="stream-tags">Invite users</label>
                        <div>
                            <ReactTags
                            tags={tags}
                            delimiters={delimiters}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            autofocus={false}
                            allowDragDrop={false}
                            // handleDrag={handleDrag}
                            handleTagClick={handleTagClick}
                            inputFieldPosition="top"
                            autocomplete={false}
                            editable
                            />
                        </div>
                    </div> */}
                    <div className='col-12 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="stream-tags">Tags</label>
                        <FormControl className='input-inner-transparent' fullWidth>
                            <Field name="tags" component={renderTagsField} id="stream-tags" options={tagsList} defaultValue={[]} handleKeyDown={handleKeyDown} setValue={setValue} />
                        </FormControl>
                    </div>
                    {/* <div className='col-12 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="stream-countries">Countries</label>
                        <FormControl className='input-inner-transparent' fullWidth>
                            <Field name="countries" component={renderCountriesField} id="stream-countries" options={countriesList} defaultValue={[]} handleCountryKeyDown={handleCountryKeyDown} setCountryValue={setCountryValue} />
                        </FormControl>
                    </div> */}
                    <div className='col-12 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="stream-countries">Countries</label>
                        <FormControl className='input-inner-transparent' fullWidth>
                            <Field name="countries" component={renderStreamCountriesField} id="stream-countries" options={countriesList} defaultValue={[]} handleCountryKeyDown={handleCountryKeyDown} setCountryValue={setCountryValue} />
                        </FormControl>
                    </div>
                    <div className='col-12 md-col-6 m-b-20'>
                        <FormControl>
                            <label className='input--label' htmlFor="event-timing-setting-label">Timing</label>
                            <RadioGroup
                                className='radio--select'
                                aria-labelledby="event-timing-setting-label"
                                name="event-timing-setting-buttons-group"
                                value={startType}
                                onChange={(e: any) => setStartType(e.target.value)}
                            >
                                <Box className='col-6'>
                                    <FormControlLabel value="0" control={<Radio />} label="Immediately" />
                                </Box>
                                <Box className='col-6'>
                                    <FormControlLabel value="1" control={<Radio />} label="Schedule" />
                                </Box>
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className={`dateoutlined col-12 md-col-6 m-t-20 m-b-20 ${startType === '0' ? 'd-none' : ''}`}>
                        <FormControl fullWidth>
                            <Field component={renderDateTimeField} disablePrevious={disablePrevious} name="startTime" label="Start time" />
                        </FormControl>
                    </div>
                    <div className='col-12 md-col-8 m-b-20 overflow--hidden'>
                        <label className='input--label m-b-10' htmlFor="userBio">Description</label>
                        <Field name="description" component={TextArea} row='5' type="text" placeholder='Write description'
                            styleName='input--transparent' />
                    </div>
                    <div className="col-12 md-col-4">
                        <div className='row m-b-10 align--items--center'>
                            <div className='col-9'>
                                <label className='input--label' htmlFor="thumbnail">Upload file (ratio: 4:3)</label>
                            </div>
                            <div className='col-3 text--end'>
                                <Tooltip title="Get your image ready by making sure it has right width, height, resolution, size, type. This protect's the image quality & prevents error during upload process. Recomended aspect ratio is 4:3 & less than 10 MB for best fit."
                                PopperProps={{
                                    sx: {
                                      "& .MuiTooltip-tooltip": {
                                        backgroundColor: '#191840',
                                        fontSize: '12px',
                                        padding: '12px',
                                        fontWeight: 600,
                                        border: '1px solid #F89E24'
                                      }
                                    }
                                }}
                                >
                                    <HelpOutlineIcon/>
                                </Tooltip>
                            </div>
                        </div>

                        <Field
                            name="thumbnail"
                            type="hidden"
                            component="input"
                            id="thumbnail"
                            placeholder="thumbnail"
                        />
                        <Field
                            name="id"
                            type="hidden"
                            component="input"
                            id="id"
                            placeholder="id"
                        />
                        {!thumbnailImage || thumbnailImage === '' ?
                            (<Box component="div"
                                sx={{
                                    width: '100%',
                                    background: '#232157',
                                    marginBottom: "20px",
                                    position: 'relative',
                                    borderRadius: "15px",
                                    cursor: "pointer",
                                    aspectRatio: '4/3'
                                }}
                            >
                                    <Box
                                        component="div"
                                        sx={{
                                            position: 'absolute',
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%,-50%)',
                                        }}
                                    >
                                        <UploadIcon />
                                    </Box>
                                    <Box
                                        component="input"
                                        type="file"
                                        name="thumbnail"
                                        onChange={handleFileSelect}
                                        sx={{
                                            height: '100%',
                                            width: '100%',
                                            opacity: '0',
                                            cursor: 'pointer',
                                        }}
                                    ></Box>
                                </Box>
                            ) : (
                                <>
                                    <Box className="img--uploadBg" style={{
                                        minHeight: '155px',
                                        maxHeight: '155px'
                                    }}>

                                        {submitting || fileUploading ?
                                        '' : <Button type="button" styleName="remove--pic" onClick={() => handleFileRemove()}>
                                        <CancelIcon />
                                    </Button>}
                                        <Box
                                            component="img"
                                            src={thumbnailImage}
                                            sx={{
                                                maxHeight: '130px',
                                                maxWidth: '100%',
                                                objectFit: 'contain',
                                                borderRadius: '15px',
                                                margin: 'auto'
                                            }}
                                        ></Box>
                                    </Box>
                                </>
                            )}
                        </div>
                        <div className="col-12 m-b-40">
                            <button type="submit" className={`btn--pill saveBtn`} disabled={submitting || fileUploading}>
                                {/* Save {startType == '0' ? '& Start Stream' : ''} */}
                                {showSubmitButtonLabel(submitting, fileUploading, startType)}
                            </button>
                        </div>
                    </Box>
                </Box>
        </Box>
        <GoToTop/>
        </>
    );
};

const showSubmitButtonLabel = (submitting: boolean, fileUploading: boolean, startType: string) => {
    if (submitting) {
        return 'Saving...';
    } else if (fileUploading) {
        return 'Saving Photo...';
    } else {
        return `Save ${startType === '0' ? '& Start Stream' : ''}`;
    }
};

export default reduxForm({
    form: 'liveStream',
    validate,
})(LiveStreamForm);
