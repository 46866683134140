import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import CreatorListDataTable from '../common/CreatorListDataTable';
import { getRequest, postRequest } from '../../data/helpers/services';
import { CREATOR_ADMIN_API } from '../../constants';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { creatorListContentData, creatorListContentDataModified } from '../../data/mappers/creatorList';
import { UpdateCreatorListContent, AddCreatorListContent, TotalCreatorsSave, CreatorCurrentPage } from '../../store/creatorList';

import { useNavigate } from 'react-router-dom';
import GoToTop from '../common/GoTop'


const CreatorList = (props: any) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [ offsetValue, setOffsetValue ] = useState<number | 0>(0)
    const [ maxNotification, setMaxNotification ] = useState<number | 0 >(0)
    const [ searchValue, setSearchValue ] = useState<string | "">("")

    const [ compareSearchRedux, setCompareSearchRedux ] = useState<string | "">("")

    const [ loader, setLoader ] = useState<boolean>(false)
    // const [ searching, setSearching ] = useState<boolean>(false)

    const [ currentPage, setCurrentPage ] = useState<number>(0)

    let token = useSelector((state: RootStateType) => state.user.access_token);

    const CreatorListObject = useSelector((state: RootStateType) => state.creatorList);

    const CreatorListContent = CreatorListObject.creatorListContent

    if ((CreatorListObject.currentPage) && (CreatorListObject.currentPage !== currentPage)) {
        setCurrentPage(CreatorListObject.currentPage);
    }else if ((CreatorListObject.currentPage === 0) && (currentPage !== 0)) {
        setCurrentPage(0)
    }

    if ((CreatorListObject.searchedValue) && (CreatorListObject.searchedValue !== searchValue)){
        setSearchValue(CreatorListObject.searchedValue);
    }

    // if ((currentPage > 0) && (offsetValue === 0)){
    //     setOffsetValue(CreatorListContent.length)
    // }

    // console.log("{{{{{{{{{{{",searchValue)



    if ((currentPage > 0) && (offsetValue === 0) && (searchValue !== compareSearchRedux)) {
        setOffsetValue(CreatorListContent.length)
    } else if ((currentPage > 0) && (offsetValue === 0)) {
        setOffsetValue(CreatorListContent.length)
    }

    useEffect(() => {
        // getCreatorListContent(searchValue);
        if ((currentPage !== 0) &&
            (((currentPage+1)*10) <= CreatorListContent.length) &&
            (CreatorListObject.totalCreators >= CreatorListContent.length) &&
            (searchValue === compareSearchRedux) &&
            (offsetValue !== 0)
            ){
            setMaxNotification(CreatorListObject.totalCreators)
        } else{
            getCreatorListContent(searchValue);
            setCompareSearchRedux(searchValue);
        }

    }, [offsetValue, searchValue]);

    // useEffect(() => {
    //     setTimeout(() =>  getCreatorListContent(searchValue), 1200);
    //     // getCreatorListContent(searchValue);
    // }, [searchValue]);


    const getCreatorListContent = async (searchingValue:string) => {
        let res:any = {}
        if ((searchingValue === (searchValue) || (searchingValue === ""))) {
            setLoader(true);
            res = await postRequest(CREATOR_ADMIN_API.LIST,{
                "offset": offsetValue,
                "search_text": searchValue
            }, token);
        }

        if (res) {
            if ((searchingValue === (searchValue) || (searchingValue === ""))) {
                setMaxNotification(res.data?.data.total_creators)
                dispatch(TotalCreatorsSave(res.data?.data.total_creators))
                let formattedCreatorListContentData = res.data?.data.creator_list as creatorListContentData,
                formattedCreatorListContent: creatorListContentDataModified = [];
                formattedCreatorListContentData.forEach((x) => {
                    formattedCreatorListContent.push({
                        email: x.email,
                        firstName: x.firstName,
                        id: x.id,
                        lastName: x.lastName,
                        fullName: x.firstName + ' ' +  x.lastName,
                        handle: x.handle,
                        status: x.status
                    });
                });

                if (!offsetValue){
                    dispatch(CreatorCurrentPage(0))
                    dispatch(UpdateCreatorListContent(formattedCreatorListContent));
                }
                else{
                    dispatch(AddCreatorListContent(formattedCreatorListContent))
                }
            }
        }
        setLoader(false);
    };

    const buttonBox = (
        <Button
            type="button"
            styleName={`button--outlined active`}
            onClick={() => navigate('/admin/creators/add')}
        >Add New Creator
        </Button>
    )

    return (
        <>
            <Box className="dashboard__content">
                <Box className="col-12 md-col-8 m-b-20">
                        <Box className="button__box" style={{display: 'block'}}>{buttonBox}</Box>
                </Box>
                <Box>
                    <CreatorListDataTable creatorListContent={CreatorListContent} offsetValue={offsetValue} setOffsetValue={setOffsetValue} maxNumber={maxNotification} searchValue={searchValue} setSearchValue={setSearchValue} loader={loader} currentPage={currentPage} />
                </Box>

            </Box>
            <GoToTop/>
        </>
    );
};

export default CreatorList;
