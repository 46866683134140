import React from 'react'

export function Button({type, styleName, children, ...rest}) {
  return (
    <button type={type} className={`btn--pill ${styleName}`} {...rest}>
        {children}
    </button>
  )
}

export function CustomButton({type, styleName, children, ...rest}) {
    return (
      <button type={type} className={`btn--pill ${styleName}`} {...rest}>
          {children}
      </button>
    )
  }
