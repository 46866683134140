export const GOOGLE_LOGIN = "google_login";
export const CREATOR_API = {
    LIST : "creator/list/",
    UPDATE: "creator/profile",
    OWN_CREATOR:"match/list/own/creator"
};
export const MATCH_API = {
    CREATE: "match/create/",
    DETAIL: "match/detail/",
    UPDATE: "match/update/"
}
export const UPLOAD_FILE = "s3/upload";

export const TAGS_LIST = "tag/list/";
export const COUNTRIES_LIST = "match/country/list/";
export const ROOM_CREATE = "100ms/room/create/";
export const CHANNEL_START = "medialive/channel/start/";
export const STREAM_UPDATE = "stream/update/";
export const ROOM_END = "100ms/room/end/";
export const CHANNEL_END = "medialive/channel/stop/";

export const PODCAST_API = {
    SERIES_LIST : "podcast/series/list/own/superadmin/",
    SERIES_CREATE: "podcast/series/create/",
    SERIES_DETAIL: "podcast/series/detail/",
    SERIES_UPDATE: "podcast/series/update/",
    SERIES_DELETE: "podcast/series/delete/",
    SERIES_STATUS_UPDATE: "podcast/series/status/update/",

    PUSH_SERIES_LIST : "podcast/series/list_for_push/",


    SERIES_DETAIL_DATE_RANGE: "podcast/series/detail/in_date_range/",


    EPISODE_CREATE: "podcast/episode/create/",
    EPISODE_UPDATE: "podcast/episode/update/",
    EPISODE_DETAIL: "podcast/episode/detail/",
    EPISODE_DELETE: "podcast/episode/delete/",
    EPISODE_STATUS_UPDATE: "podcast/episode/status/update/",

    MEDIA_ADD : "podcast/episode/media/add/",
};


export const APP_VERSION_API = {
    CREATE: "app/version/create/",
    DETAIL: "app/version/detail/",
    UPDATE: "app/version/edit/",
    LIST: "app/version/list",
}

// export const SUPERADMIN_LOGIN = "super_admin/login";
export const SUPERADMIN_LOGIN = "admin/login";

export const CREATOR_LOGIN = "creator/email_login/";

export const SEND_PUSH = "custom/push/admin/";

export const APP_USER = {
    LIST: "admin/consumer/search/",
}

export const CREATOR_ADMIN_API = {
    CREATE:"admin/add/creator",
    LIST: "admin/creator/list",
    DELETE:"",
    STATUS_UPDATE: "",
    DETAIL: "admin/creator/detail/",
    EDIT: "admin/edit/creator/",
}

export const ADMIN_API = {
    CREATE: "superadmin/add/admin/",
    List: "superadmin/admin/list/",
    EDIT:"superadmin/edit/admin/",
    DETAIL:"superadmin/detail/admin",
}

export const RESET_PASSWORD = {
    APP_USER: "forgot/password/confirm/",
    TOKEN_VALIDATE: "forgot/password/token/validate",
}

export const PUBLIC_API = {
    VALIDATE_INTENT_TOKEN : "gift/pack/checkout/"
}

export const MATCH = {
    LIST: "match/list/admin",
    DETAIL: "match/detail/admin/",
}


export const CONSUMER_ADMIN_API = {
    CREATE:"",
    LIST: "consumer/list/",
    STATUS_UPDATE: "",
    DETAIL: "admin/consumer/detail/",
    EDIT: "",
    REPORT:"admin/consumer/report/",
    TRANSACTION_SPENDING: "gift/spending/consumer/history/admin/",
    TRANSACTION_BALANCE: "gift/purchase/consumer/history/admin/"
}

export const DASHBOARD_API = {
    ADMIN: "dashboard/admin/",
    CREATOR: "dashboard/creator/",
}

export const ALL_TRANSACTION_API = {
    // ALL_SPEND_LIST: "gift/received/list/",
    ALL_SPEND_LIST: "gift/received/list/test/",

    EPISODE_TRANSACTION_LIST: "gift/received/episode/history/admin/",
}


export const PACKAGES_API = {
    CREATE: "gift/pack/create/",
    LIST: "gift/pack/list/admin/",
    GIFT_TYPE_LIST: "gift/list/",
    DETAIL: "gift/pack/detail/",
    UPDATE: "gift/pack/edit/",
    UPDATE_PACKAGE_STATUS: "gift/pack/status/update/"
}



