import React from 'react'

export default function ListIcon() {
  return (
      <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M21 14H14V21H21V14Z" stroke="#C2C1E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 14H3V21H10V14Z" stroke="#C2C1E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21 3H14V10H21V3Z" stroke="#C2C1E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 3H3V10H10V3Z" stroke="#C2C1E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </>
  )
}
