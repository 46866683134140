import { SAVE_MATCH_DETAILS, CLEAR_MATCH_DETAIS } from "../../constants";
import { SaveMatchDetailsAction, ClearMatchDetaisAction, MatchInterface } from "./types";

export const saveMatchDetails = (payload: MatchInterface) : SaveMatchDetailsAction => ({
    type: SAVE_MATCH_DETAILS,
    payload: payload

});

export const clearMatchDetails = () : ClearMatchDetaisAction => ({
    type: CLEAR_MATCH_DETAIS
});
