import { UPDATE_ADMIN_LIST_CONTENT_DATA, ADD_ADMIN_LIST_CONTENT_DATA, SAVE_ADMIN_SEARCH_TEXT, SAVE_ADMIN_CURRENT_PAGE, SAVE_TOTAL_ADMIN, RESET } from '../../constants';
import { adminListContentDataObj } from '../../data/mappers/user';
import { UpdateAdminListContentAction, AddAdminListContentAction } from './types';

export const UpdateAdminListContent = (data: Array<adminListContentDataObj>): UpdateAdminListContentAction => ({
    type: UPDATE_ADMIN_LIST_CONTENT_DATA,
    data,
});

export const AddAdminListContent = (data: Array<adminListContentDataObj>): AddAdminListContentAction => ({
    type: ADD_ADMIN_LIST_CONTENT_DATA,
    data,
});

export const AdminSearchText = (data:  string) => ({
    type: SAVE_ADMIN_SEARCH_TEXT,
    data,
});

export const AdminCurrentPage = (data:  number) => ({
    type: SAVE_ADMIN_CURRENT_PAGE,
    data,
});

export const TotalAdminSave = (data:  number) => ({
    type: SAVE_TOTAL_ADMIN,
    data,
});

export const AdminResetAction = () => ({
    type: RESET
});
