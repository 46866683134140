import React from 'react'
import LeftBarContainer from '../common/LeftBar.Component'
import GoogleLoginLogin from '../common/GoogleLogin.Component'
import AdminLogin from '../common/AdminLogin.Component'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


const SignIn = (props: any) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
   return (
        <section aria-label='signIn-section' className='h-inherit bg--dark'>
        <div className='authentication__wrapper h-inherit grid__container grid-md-1-2-column'>
            <LeftBarContainer />
            <div className='login__innerarea justify-content-loginstart'>
                <Box sx={{ width: '100%' }}>
                    <Box className='signin__tabs' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs  value={value} onChange={handleChange} aria-label="99.94 Login Options">
                          <Tab label="Creator" {...a11yProps(0)} aria-label="99.94 Login with Creator"/>
                          <Tab label="Admin" {...a11yProps(1)} aria-label="99.94 Login with Admin"/>
                        </Tabs>
                    </Box>

                    <TabPanel value={value} index={0}>
                        <AdminLogin isAdmin={false} />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <AdminLogin isAdmin={true} />
                    </TabPanel>

                    {/* <TabPanel value={value} index={1}>
                        <GoogleLoginLogin />
                    </TabPanel> */}

                </Box>
            </div>
        </div>
        </section>
      )
    }

    export default SignIn;
