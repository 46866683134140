import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';

import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { ALL_TRANSACTION_API } from '../../constants';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { episodeGiftListContentDataModified, episodeGiftListContentData } from '../../data/mappers/allTransactions';

import { UpdateEpisodeTransactionListContent } from '../../store/allTransactions';
import GoToTop from '../common/GoTop'

import { useNavigate, useParams } from 'react-router-dom';
import EpisodeGiftListDataTable from '../common/EpisodeGiftListDataTable';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const EpisodeTransactionsList = (props: any) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [episodeId,setEpisodeId] = useState<string>(props.id);

    const { id } = useParams();
    if (id){
        setEpisodeId(id);
    }


    const [table, setTable] = useState<number>(0);

    const [totalSpending, setTotalSpending] = useState<number>(0);
    const [episodeTitle, setEpisodeTitle] = useState<string>("");

    let token = useSelector((state: RootStateType) => state.user.access_token);

    let episodeGiftListContent:any = useSelector((state: RootStateType) => state.episodeGiftList.episodeGiftListContent);

    useEffect(() => {
        if (episodeId){
            getSpendingContent();
        }
    }, [episodeId]);

    const getSpendingContent = async () => {
            const res: any = await postRequest(ALL_TRANSACTION_API.EPISODE_TRANSACTION_LIST, {
                episode_id:episodeId
            }, token);
            if (isApiCodeSucess(res)) {
                setTotalSpending(res.data.data.runs_gifted)
                setEpisodeTitle(res.data.data.episode_title)
                let formattedEpisodeContentData = res.data?.data?.gift_received_list as episodeGiftListContentData,
                formattedEpisodeContent: episodeGiftListContentDataModified = [];
                formattedEpisodeContentData.forEach((x) => {
                    formattedEpisodeContent.push({
                        id: x.id,
                        runs: x.gift_quantity,
                        date: x.createdAt,
                        sender: {
                            id:x.sender.id,
                            fullName:
                            ((x.sender.id) ? (x.sender?.firstName ?? "") + " " + (x.sender?.lastName ?? "") : "-" ),
                        },
                        duration: x.event_second ?? "-"
                        // stream: '-',
                        // podcast: {id: x.episode?.id, title: (x.episode?.title ?? "-") }
                    });
                });
                dispatch(UpdateEpisodeTransactionListContent(formattedEpisodeContent));
        }
    };


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTable(newValue);
      };

    return (
        <>
            <Box className="dashboard__topbar">
                <Box className="row align--items--center">
                    <div className="md-col-8 mb--20 mb--md--0">
                            <Box className="button__text__navigate">
                                <button onClick={() => navigate(-1)}><ArrowBackOutlinedIcon /></button>
                                {/* <Box className="dashboard__topbar--heading" component="h2">Show Details</Box> */}
                                <Box style={{width: 'calc(100% - 10px)',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                    }} className="breadcrumb__title dashboard__topbar--heading" component="h2">
                                Episode Gifts received on <ArrowForwardIosIcon /> {episodeTitle}
                                </Box>
                            </Box>
                    </div>
                </Box>
            </Box>
            <Box className="dashboard__content">
                <Box className="col-12 m-t-20">
                    <EpisodeGiftListDataTable episodeGiftListContent={episodeGiftListContent} />
                </Box>
            </Box>
            <GoToTop/>
        </>
    );
};

export default EpisodeTransactionsList;
