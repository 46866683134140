import React from 'react'

export default function UploadIcon() {
  return (
    <>
        <svg width="88" height="82" viewBox="0 0 88 82" fill="none">
        <path d="M71.569 21.2445C70.628 15.6529 67.8593 10.5499 63.6249 6.67736C58.92 2.37055 52.8036 0 46.4339 0C41.5118 0 36.7164 1.41147 32.6087 4.07156C29.1886 6.27925 26.3475 9.26506 24.3389 12.7756C23.4703 12.6128 22.5655 12.5223 21.6607 12.5223C13.97 12.5223 7.70882 18.7835 7.70882 26.4742C7.70882 27.4695 7.8174 28.4285 7.99835 29.3695C3.022 32.9887 0 38.8155 0 45.0224C0 50.0349 1.86387 54.9027 5.26589 58.7571C8.75838 62.702 13.3728 65.0364 18.2949 65.3078C18.3492 65.3078 18.3854 65.3078 18.4396 65.3078H34.0021C35.3592 65.3078 36.445 64.2221 36.445 62.8649C36.445 61.5077 35.3592 60.4219 34.0021 60.4219H18.512C11.1108 59.9695 4.88587 52.9303 4.88587 45.0043C4.88587 39.8832 7.63644 35.1059 12.0699 32.5182C13.1014 31.921 13.5357 30.6724 13.1376 29.5505C12.7757 28.5733 12.5947 27.5418 12.5947 26.438C12.5947 21.4435 16.6663 17.372 21.6607 17.372C22.7284 17.372 23.7779 17.5529 24.7551 17.9149C25.9494 18.3492 27.2704 17.8063 27.8133 16.6662C31.1972 9.48221 38.5079 4.84968 46.452 4.84968C57.1285 4.84968 65.9412 12.848 66.9546 23.4522C67.0631 24.556 67.8955 25.4427 68.9813 25.6237C77.0339 26.999 83.1141 34.4363 83.1141 42.9233C83.1141 51.9169 76.0387 59.7343 67.3165 60.4038H53.9798C52.6227 60.4038 51.5369 61.4896 51.5369 62.8468C51.5369 64.204 52.6227 65.2897 53.9798 65.2897H67.407C67.4612 65.2897 67.5155 65.2897 67.5879 65.2897C73.1071 64.8916 78.2645 62.3582 82.1008 58.1238C85.919 53.9255 88 48.533 88 42.9233C87.9819 32.7715 81.0512 23.7417 71.569 21.2445Z" fill="#C2C1E6"/>
        <path d="M58.6666 47.3389C59.6257 46.3798 59.6257 44.8416 58.6666 43.8826L45.7281 30.944C45.2757 30.4917 44.6424 30.2202 44.009 30.2202C43.3757 30.2202 42.7423 30.4736 42.2899 30.944L29.3514 43.8826C28.3923 44.8416 28.3923 46.3798 29.3514 47.3389C29.8219 47.8094 30.4552 48.0627 31.0705 48.0627C31.6858 48.0627 32.3191 47.8275 32.7896 47.3389L41.5661 38.5624V78.898C41.5661 80.2552 42.6518 81.3409 44.009 81.3409C45.3662 81.3409 46.452 80.2552 46.452 78.898V38.5624L55.2284 47.3389C56.1694 48.2979 57.7076 48.2979 58.6666 47.3389Z" fill="#C2C1E6"/>
        </svg>
    </>
  )
}
