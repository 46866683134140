import { Avatar, Box, Button, Chip, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CHANNEL_END, CHANNEL_START, MATCH, MATCH_API, ROOM_CREATE } from '../../constants';
import { postRequest } from '../../data/helpers/services';
import { RootStateType } from '../../store';
import { ShowErrorAlert } from '../../store/alerts';
import { MatchInterface, saveMatchDetails } from '../../store/match';
import moment from 'moment';
import GoToTop from '../common/GoTop'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { CustomButton } from '../common/Button';

import profilepicplaceholder from '../../styles/images/placeholder.png';

var timer:any = null;
const timeBefore = 30;

const MatchDetails = () => {
    const { id } = useParams();
    const [matchDetails, setMatchDetails] = useState<MatchInterface | null>(null);
    let user = useSelector((state: RootStateType) => state.user);
    let token = user.access_token;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [diffTillNow, setDiffTillNow] = useState("");
    const [showStreamButton, setShowStreamButton] = useState(false);
    const [editable, setEditable] = useState(false);

    const getMatchDetails = async (id: number|undefined) => {
        let url = (user.role === "creator") ? MATCH_API.DETAIL : MATCH.DETAIL
        const response = await postRequest(url, {
            matchId: id
        }, token);
        if (response) {
            if (response.data.code === 200) {
                setMatchDetails(response.data.data);
                (response.data.data.isEnded) ? setEditable(false) : setEditable(true);
                dispatch(saveMatchDetails(response.data.data));
                startTimer(response.data.data.startTime);
            }
        }
    }

    const handleStreamStart = async () => {
        if (matchDetails?.isEnded === true) {
            dispatch(ShowErrorAlert({visible: true, message: "Stream is ended, So you can not join again."}));
        } else {
            let data = {
                matchId: matchDetails?.id
            }
            const response = await postRequest(CHANNEL_START, data, token);
            if (response) {
                getMatchDetails(matchDetails?.id);
            }
        }
    }

    const startTimer = (startTime:string)  => {
        timer = setInterval(function() {
            showTimer(startTime);
        }, 1000);
    }

    const prevHandler = (e) => {
        navigate('/creator/dashboard')
    }

    useEffect(() => {
        if (id) {
            getMatchDetails(parseInt(id));
        }
    }, [id]);

    useEffect(() => {
        return () => {
            clearInterval(timer);
        }
    }, [])

    const showTimer = (startTime) => {
        let diff = moment(startTime).subtract(timeBefore, "minutes").diff(moment());
        if(diff > 1) {
            let duration = moment.duration(diff);
            setDiffTillNow(duration.days() + ' Days ' + duration.hours()+ ' Hours ' + duration.minutes()+ ' Minutes ' + duration.seconds() + ' Seconds');
            setShowStreamButton(false);
        } else {
            setShowStreamButton(true);
        }
    }

    const printButtonOrText = () => {
        return <>{!showStreamButton ? printText(matchDetails?.startTime, diffTillNow) :
                    <button className="btn--pill btn--pill btn--pill button--outlined active" style={{
                        marginTop: "30px"
                    }} onClick={handleStreamStart}>
                          Start Stream
                    </button>}</>
    }

    const handleStreamEnd = async () => {
        if (matchDetails?.isEnded === true) {
            setEditable(false);
            dispatch(ShowErrorAlert({visible: true, message: "Stream is ended, So you can not end again."}));
        } else {
            let data = {
                matchId: matchDetails?.id
            }
            const response = await postRequest(CHANNEL_END, data, token);
            if (response) {
                getMatchDetails(matchDetails?.id);
            }
        }
    }

    const buttonBox = (
        <>
        {
            editable
            ?
                <CustomButton styleName='btn--pill btn--pill button--outlined active' type='button' onClick={() => navigate('/admin/match/edit/'+id)}>
                    {"Edit"}
                </CustomButton>
            :
                 ""
        }
        </>
    );

    return (
        <>
            <Box className="dashboard__topbar">
                {
                    (user.role === 'creator')
                    ?
                    // <Box className="dashboard__topbar--heading col-12">
                    //     <Box className="button__text__navigate">
                    //         <button onClick={prevHandler}>
                    //             <svg width="24" height="16" viewBox="0 0 24 16" fill="none">
                    //                     <path d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM24 7L1 7V9L24 9V7Z" fill="#6E6CA3"/>
                    //                 </svg>
                    //         </button>
                    //         <Box className="breadcrumb__title dashboard__topbar--heading" component="h2">Stream Content Details</Box>
                    //     </Box>
                    // </Box>
                    <Box className="row align--items--center">
                        <Box className="button__text__navigate">
                            <button onClick={() => navigate(-1)}><ArrowBackOutlinedIcon /></button>
                            <Box className="breadcrumb__title dashboard__topbar--heading" component="h2">{"Stream Content Details"}</Box>
                        </Box>
                    </Box>

                    :

                    <Box className="row align--items--center">
                        <div className="md-col-8 mb--20 mb--md--0">

                            <Box className="row align--items--center">
                                <Box className="button__text__navigate">
                                    <button onClick={() => navigate(-1)}><ArrowBackOutlinedIcon /></button>
                                    <Box className="breadcrumb__title dashboard__topbar--heading" component="h2">{"Stream Content Details"}</Box>
                                </Box>
                            </Box>
                            {/* Stream Content Details */}
                            {/* <Box className="breadcrumb__title dashboard__topbar--heading" component="h2">Stream Content Details</Box> */}
                        </div>
                        <div className="md-col-4">
                            <Box className="button__box">{buttonBox}</Box>
                        </div>
                    </Box>
                }
            </Box>

            {/* <Box className="dashboard__topbar">
            <Box className="row align--items--center">
                <div className="md-col-8 mb--20 mb--md--0">
                        <Box className="button__text__navigate">
                            {
                            (modeType !== "edit") ?
                                <button onClick={() => navigate(-1)}><ArrowBackOutlinedIcon /></button>
                            :
                            <span></span>
                            }
                            <Box className="dashboard__topbar--heading col-12" component="h2">
                                {(modeType === "add") ? "Add Admin" : (modeType === "edit") ? "Edit Admin" : "Admin Details"}
                            </Box>
                        </Box>
                </div>
                {(modeType !== "add") ?
                <div className="md-col-4">
                    <Box className="button__box">{buttonBox}</Box>
                </div>
                : ""
                }
            </Box>
        </Box> */}

            <Box className='dashboard__content max--760'>
                    {matchDetailRow("Date & Time", moment(matchDetails?.streams[0].startTime).format('D.MM.YYYY, h:mm a'))}

                    {matchDetailRow("Title", matchDetails?.streams[0].title)}

                    {matchDetailRow("Type", matchDetails?.streams[0].eventType.toUpperCase())}

                    {
                        (user.role !== 'creator') ?
                            matchDetailRow("Creator",
                                //@ts-ignore
                                getCreator(matchDetails, user))
                        :
                            ""
                    }

                    {matchDetailRow("Language", matchDetails?.streams[0].eventLanguage.toUpperCase())}

                    {matchDetailRow("Show", matchDetails?.title)}

                    {matchDetailRow("Tags", getTagsString(matchDetails))}

                    {matchDetailRow("Description", matchDetails?.description)}

                    {matchDetails && matchDetails.countrySupported.length ? countriesRow(matchDetails.countrySupported) : matchDetailRow("Countries Selected", "No Data")}

                    { (matchDetails?.inputUrl != '' && matchDetails?.isLive) ? printMatchInputUrls(matchDetails, handleStreamEnd) :  matchDetails?.isEnded ? printStreamEndedText() : printButtonOrText() }

                    {(matchDetails && matchDetails.streams[0].thumbnail) ? <Avatar className='avatorpic--16by9' src={getMatchThumbnail(matchDetails)} sx={{
                        height: 'auto',
                        width: '100%',
                        maxWidth: '760px',
                        borderRadius: "10px",
                        marginTop: "20px",
                        marginBottom: "20px"
                    }} variant="square" /> : ""}
            </Box>
            <GoToTop/>
        </>
    );
}

const matchDetailRow = (label?: string, value?: string) => {
    return (
        <Box component="div" sx={{
            display: 'flex',
            mt: 1,
        }}>
            <Box component="div" sx={{
                flex: 0.3,
                p: 1,
                border: '1px solid #27264d'
            }}>
                {label}
            </Box>
            <Box component="div" sx={{
                flex: 0.7,
                p: 1,
                border: '1px solid #27264d',
                wordBreak: 'break-all'
            }}>
                <b>{value}</b>
            </Box>
        </Box>
    )
}

const printMatchInputUrls = (matchDetails:MatchInterface, handleStreamEnd:any) => {
    return (<>
            {matchDetailRow("RTMP Url", matchDetails?.inputUrl)}

            {matchDetailRow("RTMP Key", matchDetails?.inputKey)}

            {matchDetails.isLive ? <Button className="video--button" sx={{
                        marginTop: "30px"
                    }} onClick={handleStreamEnd}>
                          End Stream
                    </Button> : ""}
            </>);
}

const countriesRow = (countrySupported:any) => {
    return (
        <Box component="div" sx={{
            display: 'flex',
            mt: 1,
        }}>
            <Box component="div" sx={{
                flex: 0.3,
                p: 1,
                border: '1px solid #27264d'
            }}>
                Countries
            </Box>
            <Box component="div" sx={{
                flex: 0.7,
                p: 1,
                border: '1px solid #27264d',
                wordBreak: 'break-all'
            }}>
                {countrySupported.map((country:any) => <Chip sx={{marginRight:1}} label={country.country_name} variant="outlined" /> )}
            </Box>
        </Box>
    )
}

const getTagsString = (matchDetails: any) => {
    var tagsString = "";
    if (matchDetails) {
        let tags = matchDetails.streams[0].streamtags;
        tags.map((tag: any) => tagsString += ' #' + tag);
    }
    return tagsString;
}

const getMatchThumbnail = (matchDetails: any) => {
    if (matchDetails && matchDetails.streams[0].thumbnail) {
        return matchDetails.streams[0].thumbnail;
    }
    return "";
}


const getCreator = (matchDetails: any, user:any) => {
    if (matchDetails) {
        let creator = matchDetails?.streams[0].user
        return (
            <>
            <Box className='profie__circle__box'>
                {
                    (user.permissions.creator) ?
                    <a href={"/admin/creators/"+creator.id} >
                        <Box className='profie__circle__box--img'>
                                <Box
                                component="img"
                                src = {(creator.profile_pic_path) ? creator.profile_pic_path : profilepicplaceholder}
                                >
                                </Box>
                            </Box>
                        <Box component='p'>
                                {(creator.firstName) ? creator.firstName : creator.lastName}
                        </Box>
                    </a>

                    :
                    <>
                        <Box className='profie__circle__box--img'>
                                <Box
                                component="img"
                                src = {(creator.profile_pic_path) ? creator.profile_pic_path : profilepicplaceholder}
                                >
                                </Box>
                            </Box>
                        <Box component='p'>
                                {(creator.firstName) ? creator.firstName : creator.lastName}
                        </Box>
                    </>

                }

                </Box>
        </>
        )
    };
    return "";
}


const streamStartTime = (startTime:any) => {
    if(startTime) {
        var now = moment();
        var startTimeM = moment(startTime).subtract(timeBefore, "minutes");
        if(startTimeM.isBefore(now)) {
            clearInterval(timer);
            return false;
        }
        return true;
    }
    clearInterval(timer);
    return false;
}

const printText = (startTime:any, diffTillNow:string) => {
    if(startTime) {
        var time = moment(startTime).subtract(timeBefore, "minutes").format('D.MM.YYYY, h:mm a');
        return <Typography sx={{marginTop:"20px"}}>Your match streaming will start in <b>{diffTillNow}</b>.</Typography>;
    }
    return <Typography sx={{marginTop:"20px"}}>It is too early to start a stream</Typography>;
}

const printStreamEndedText = () => {
    return <Typography sx={{marginTop:"20px"}}><b>Stream is ended.</b></Typography>;
}

export default MatchDetails;
