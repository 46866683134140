function getIdsInArray(Data:any){
    let tempArray = []
    Data.forEach((x) =>
        //@ts-ignore
        tempArray.push(x?.id));
    return tempArray;
}

export const createCustomPush = (data: any, notificationType: string | undefined, promotionalPush) => {
    let push:any = {
        title: data.title.trim(),
        description: data.description.trim(),
        notification_type: notificationType,
        custom_user_ids:  data.specificUser ? getIdsInArray(data.specificUser) : [],
        is_creator_notification : ((data.creatorFollowed) && (data.creatorFollowed.length > 0)) ? "1" : "0",
        creator_ids: data.creatorFollowed ? getIdsInArray(data.creatorFollowed) : [],
        is_series_notification : ((data.showFollowed) && (data.showFollowed.length > 0)) ? "1" : "0",
        series_ids: data.showFollowed ? getIdsInArray(data.showFollowed) : [],
        push_type: (promotionalPush ? "1" : "2"),
        package_id : ((!promotionalPush) ? data.packageChoose.id : ""),
    }

    return push;
}


// const getAuthorIdList = (author:any) => {
//     let authorList:any = [];

//     for (let i = 0; i < author.length; i++) {
//         authorList.push((author[i].id).toString());
//     }
//     return authorList;
// }


// export const mediaAdd = (mediaToken:string, fileName:string, fileKey:string) => {
//     let Add:any = {
//         "mediaToken":mediaToken,
//         "file_name":fileName,
//         "file_key":fileKey
//     }

//     return Add;
// }
