import { Box, FormControl, FormControlLabel } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';

import {  renderDateField } from '../form/Fields';
import TextField from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';

import { Button } from '../common/Button';
import AllGiftSpendListDataTable from '../common/AllGiftSpendDataTable';
import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { ALL_TRANSACTION_API } from '../../constants';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { allGiftSpendListContentDataModified, allGiftSpendListContentData } from '../../data/mappers/allTransactions';
import { UpdateAllGiftSpendListContent } from '../../store/allTransactions';
import GoToTop from '../common/GoTop'

import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';

import { useNavigate } from 'react-router-dom';



const validate = (values: any) => {
    const errors: any = {};
    return errors;
};

const AllGiftSpendList = (props: any) => {
    const { handleSubmit, change } = props
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [allFilter, setAllFilter] = useState<boolean>(false);
    const [streamFilter, setStreamFilter] = useState<boolean>(true);
    const [creatorsFilter, setCreatorsFilter] = useState<boolean>(false);
    const [episodeFilter, setEpisodeFilter] = useState<boolean>(true);


    let token = useSelector((state: RootStateType) => state.user.access_token);

    let allGiftSpendListContent = useSelector((state: RootStateType) => state.allGiftSpendList.allGiftSpendListContent);

    let dateToday = new Date().getDate();

    const [totalRunsRecieved, setTotalRunsRecieved] = useState<string>("0");
    const [topScorerDetails, setTopScorerDetails] = useState<any>({});

    const initForm = () => {
        change('startDate', (new Date(new Date().setDate(dateToday - 30))));
        change('endDate', new Date);
    }

    useEffect(() => {
        initForm();
        let startDate = (new Date(new Date().setDate(dateToday - 30)));
        let endDate = new Date
        getAllGiftSpendContent(startDate, endDate);
    }, []);

    const sendRequest = async (values: any) => {
        if (!values.startDate){
            dispatch(ShowErrorAlert({ visible: true, message: 'Start Date is required' }));
            return false;
        }
        else if (!values.endDate){
            dispatch(ShowErrorAlert({ visible: true, message: 'Start Date is required' }));
            return false;
        }
        else if (values.startDate > values.endDate){
            dispatch(ShowErrorAlert({ visible: true, message: 'Start Date cannot be greater than last date' }));
            return false;

        }else{
            getAllGiftSpendContent(values.startDate, values.endDate)
            return true;
        }
    }

    const getAllGiftSpendContent = async (startDate,endDate) => {
        dispatch(ShowProgressBar());
        const res: any = await postRequest(ALL_TRANSACTION_API.ALL_SPEND_LIST, {
            start_date: startDate,
            end_date: endDate,
            is_all_filter: allFilter ? "1" : "0",
            is_episode_filter: episodeFilter ? "1" : "0",
            is_creator_filter: creatorsFilter ? "1" : "0",
            is_stream_filter: streamFilter ? "1" : "0",
        }, token);
        dispatch(HideProgressBar());
        if (isApiCodeSucess(res)) {
            setTopScorerDetails(res.data.data.top_scorer_detail)
            setTotalRunsRecieved(res.data.data.total_runs_gifted)
            let formattedAllSpendContentData = res.data?.data.gift_sent as any,
            formattedAllSpendContent: allGiftSpendListContentDataModified = [];

            formattedAllSpendContentData.forEach((x) => {

                let finalType = ((x.event_type === "direct") ? "Creator" : (x.event_type === "podcast") ? "Podcast" : "Stream" );
                let recieve_id =  ((x.event_type === "direct") ?
                    x.creator.id : (x.event_type === "podcast") ?
                    x.episode?.id : x.stream?.id
                );
                let recieve_title =  ((x.event_type === "direct") ?
                    ((x.creator.id) ? (x.creator?.firstName ?? "") + " " + (x.creator?.lastName ?? "") : "-" )
                    : (x.event_type === "podcast") ?
                    x.episode?.title : x.stream?.title
                );

                // var durationFinal = "-";
                // let duration:number = parseInt(x.highest_run_received_second)
                // if (finalType === "Podcast"){
                //     if (duration > 61){
                //         let durationMinutes= parseInt((duration/60).toString());
                //         let seconds = (duration%60).toString();
                //         if (seconds){
                //             durationFinal =  durationMinutes + " min " + seconds + " sec"
                //         }
                //         else{
                //             durationFinal =  durationMinutes + " min "
                //         }
                //     }else{
                //         durationFinal =  duration.toString() + " sec"
                //     }
                // }
                formattedAllSpendContent.push({
                    id: parseInt(x.id),
                    type: finalType,
                    runs: x.runs_gifted,
                    receiveTo: {
                        id:recieve_id,
                        title:recieve_title
                    },
                    duration: x.highest_run_received_second ?? "-"
                });
            });
            dispatch(UpdateAllGiftSpendListContent(formattedAllSpendContent));
        }
    };

    useEffect(() => {
        if (allFilter){
            setStreamFilter(true);
            setCreatorsFilter(true);
            setEpisodeFilter(true);
        }
    },[allFilter,streamFilter,creatorsFilter,episodeFilter])

    const buttonBox = (
        <Button
            type="button"
            styleName={`button--outlined active`}
            onClick={() => navigate('/admin/package/add')}
        >Add New Package
        </Button>
    )

    const handleAllFilter = () => {
        if (allFilter) {
            setStreamFilter(false);
            setCreatorsFilter(false);
            setEpisodeFilter(false);
        }else{
            setStreamFilter(true);
            setCreatorsFilter(true);
            setEpisodeFilter(true);
        }
        setAllFilter(!allFilter)
    }

    useEffect(() => {
        if (streamFilter && episodeFilter && creatorsFilter ){
            setAllFilter(true);
        }
    }, [streamFilter,episodeFilter,creatorsFilter]);


    const [topScorerUrl,setTopScorerUrl] = useState<string>("")

    useEffect(() => {
        if (topScorerDetails?.top_scorer_type==="Podcast"){
            setTopScorerUrl(`/admin/consumer/show/episode/detail/${topScorerDetails.top_scorer_detail.id}`)
        }
        else if (topScorerDetails?.top_scorer_type==="Stream"){
            setTopScorerUrl(`/admin/match/${topScorerDetails.top_scorer_detail.id}`)
        }

    }, [topScorerDetails]);





    return (
        <>
            <Box className="dashboard__content ">
                <div className="row">
                    <div className="col-12 m-b-20 row__gridset">
                        <div className='card__columns'>
                            <div className='top__cardbox'>
                                <div className='card__columns--inner'>
                                    <div className='card__columns--inner--icon'>
                                    <img src="/images/ball.png" alt="9994DM-giftball" />
                                    </div>
                                    <div className='card__columns--inner--text'>
                                        <span className="card__columns--title">Total Runs</span>
                                        <p className="card__columns--numbers">{totalRunsRecieved}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='top__cardbox top__cardbox__price'>
                                <Link to={topScorerUrl} >
                                <div className='top__cardbox--content'>
                                    <label htmlFor="">Top Scorer</label>
                                    <p>{topScorerDetails?.top_scorer_detail?.title ?? ""}</p>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <span>
                                                Runs:
                                                <strong style={{marginLeft: '0px', fontSize: '20px', verticalAlign: 'middle', display: 'block'}}>{topScorerDetails?.top_scorer_runs}</strong>
                                            </span>
                                        </div>
                                        <div className='col-6'>
                                            <span>
                                                Type:
                                                <strong style={{marginLeft: '0px', fontSize: '20px', verticalAlign: 'middle', display: 'block'}}>{topScorerDetails?.top_scorer_type}</strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 m-b-20">
                        <label className='input--label m-b-10'>Filters</label>
                        {/* <div className='tab__boxes'>
                            <input type="checkbox" id="allLabel" checked={allFilter} onChange={() => {handleAllFilter()}} />
                            <label className='input--label' htmlFor="allLabel">All</label>
                        </div> */}

                        <div className='tab__boxes'>
                            <input type="checkbox" id="streamLabel" checked={streamFilter} onChange={() => {
                                if(streamFilter){
                                    setAllFilter(false);
                                }
                                setStreamFilter(!streamFilter)
                                }}/>
                            <label className='input--label' htmlFor="streamLabel">Streams</label>
                        </div>
                        {/* <div className='tab__boxes'>
                            <input type="checkbox" id="streamShows" checked={creatorsFilter} onChange={() => {
                                if(creatorsFilter){
                                    setAllFilter(false);
                                }
                                setCreatorsFilter(!creatorsFilter)
                            }}/>
                            <label className='input--label' htmlFor="streamShows">Cretors</label>
                        </div> */}
                        <div className='tab__boxes'>
                            <input type="checkbox" id="streamEpisode" checked={episodeFilter} onChange={() => {
                                if(episodeFilter){
                                    setAllFilter(false);
                                }
                                setEpisodeFilter(!episodeFilter)
                            }}/>
                            <label className='input--label' htmlFor="streamEpisode">Episodes</label>
                        </div>
                    </div>
                    <Box className="col-12 xl-col-7 m-b-20">
                        {/* <Box className="button__box" style={{display: 'block'}}>{buttonBox}</Box> */}
                        <Box component="form" onSubmit={handleSubmit(sendRequest)}>
                            <Box component="div" className="row">
                                <div className="col-12">
                                    <label className='input--label m-b-10' htmlFor="startDate">Date Range</label>
                                </div>
                                <div className='col-12 md-col-4 xl-col-4 m-b-10 overflow--hidden  bdr-rad--6'>
                                    <FormControl fullWidth>
                                        <Field component={renderDateField} name="startDate" disablePrevious={false} disableFuture={true} />
                                    </FormControl>
                                </div>

                                <div className='col-12 md-col-4 xl-col-4 overflow--hidden  bdr-rad--6'>
                                    <FormControl fullWidth>
                                        <Field component={renderDateField} name="endDate" disablePrevious={false} disableFuture={true} />
                                    </FormControl>
                                </div>

                                <div className='col-12 m-t-10 md-col-auto'>
                                    <button style={{background: 'rgb(44, 43, 93)'}} type="submit" className={`btn--pill button--outlined active`}>
                                        Apply
                                    </button>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </div>
                <Box>
                    <AllGiftSpendListDataTable allGiftSpendListContent={allGiftSpendListContent} />
                </Box>

            </Box>
            <GoToTop/>
        </>
    );
};

// export default AllGiftSpendList;
export default reduxForm({
    form: 'ConsumerCustomData',
    validate,
})(AllGiftSpendList);
