import React from 'react';
import CustomPushForm from '../customPush/Form';
import GoToTop from '../common/GoTop'


const CustomPushCreate = () => {
    return (
        <>
            {//@ts-ignore
            <CustomPushForm />}
            <GoToTop/>
        </>
    );

}


export default CustomPushCreate;
