import { UPDATE_CONSUMER_SPEND_LIST_CONTENT_DATA, UPDATE_CONSUMER_BALANCE_LIST_CONTENT_DATA } from '../../constants';
import { consumerSpendListContentModifiedObj, consumerBalanceListContentDataModifiedObj } from '../../data/mappers/consumerList';
import { consumerSpendListActions, consumerBalanceListActions } from './types';

export const UpdateConsumerSpendListContent = (data: Array<consumerSpendListContentModifiedObj>): consumerSpendListActions => ({
    type: UPDATE_CONSUMER_SPEND_LIST_CONTENT_DATA,
    data,
});

export const UpdateConsumerBalanceListContent = (data: Array<consumerBalanceListContentDataModifiedObj>): consumerBalanceListActions => ({
    type: UPDATE_CONSUMER_BALANCE_LIST_CONTENT_DATA,
    data,
});



// export const AddCreatorListContent = (data: Array<creatorListContentDataModifiedObj>): AddCreatorListContentAction => ({
//     type: ADD_CREATOR_LIST_CONTENT_DATA,
//     data,
// });

// export const UpdateCreatorAccountStatus = (data:  number) => ({
//     type: UPDATE_CREATOR_ACCOUNT_STATUS,
//     data,
// });

// export const CreatorSearchText = (data:  string) => ({
//     type: SAVE_CREATOR_SEARCH_TEXT,
//     data,
// });

// export const CreatorCurrentPage = (data:  number) => ({
//     type: SAVE_CREATOR_CURRENT_PAGE,
//     data,
// });

// export const TotalCreatorsSave = (data:  number) => ({
//     type: SAVE_TOTAL_CREATORS,
//     data,
// });

// export const CreatorResetAction = () => ({
//     type: RESET
// });

