import React from 'react'
import LogoLarge from '../../icons/logoLarge'

export default function LeftBarContainer() {
  return (
    <div className='leftbarlogo__container'>
        <div className='glass__tab'>
          <img src="/images/9994DM-logo.png" alt="9994DM-logo" />
        </div>
    </div>
  )
}
