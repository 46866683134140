import { Reducer } from "react";
import { CLEAR_EPISODE_DATA, UPDATE_EPISODE_DATA,SHOW_PODCAST_UPLOAD_LOADER, HIDE_PODCAST_UPLOAD_LOADER  } from "../../constants";
import { EpisodeInterface, EpisodeActions, PodcastUploadInterface,PodcastUploadActions } from "./types";

export const initialState: EpisodeInterface = {
    title: "",
    id: 0,
    description: "",
    createdAt: "",
    picture: "",
    series:"",
    runs_received:""
}

export const podcastInitialState:PodcastUploadInterface = {
    status:0
}


export const episodeReducer: Reducer<EpisodeInterface, EpisodeActions> = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case UPDATE_EPISODE_DATA:
            return {
                // @ts-ignore
                ...payload.payload,
            };
        case CLEAR_EPISODE_DATA:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export const PodcastUploadReducer: Reducer<PodcastUploadInterface, PodcastUploadActions> = (state = podcastInitialState, { type, ...payload }) => {
    switch (type) {
        case SHOW_PODCAST_UPLOAD_LOADER:
            return {
                // @ts-ignore
                ...payload.payload,
            };
        case HIDE_PODCAST_UPLOAD_LOADER:
            return {
                ...podcastInitialState
            };
        default:
            return state;
    }
};
