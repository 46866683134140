import React from 'react';
import LiveStreamForm from '../liveStream/Form';
import GoToTop from '../common/GoTop'


interface LiveStreamProps {
    modeType: string;
}

const LiveStream = (props:LiveStreamProps) => {

    return (
        <>
            {//@ts-ignore
            <LiveStreamForm modeType={props.modeType} />}
            <GoToTop />
        </>
    );

}


export default LiveStream;
