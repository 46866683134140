import { Box } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { CustomButton } from '../common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import GoToTop from '../common/GoTop'

import parse from "html-react-parser";
import { detectAnchorLinks, StringToHtml } from '../common/AnchorParser';

import { HidePodcastUploadingBar } from '../../store/episode';

import { PODCAST_API } from '../../constants';
import {  postRequest } from '../../data/helpers/services';
import { RootStateType } from '../../store';
import { ShowProgressBar, HideProgressBar } from '../../store/alerts';

import { EpisodeInterface, saveEpisodeDetails } from '../../store/episode';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import moment from 'moment';
import profilepicplaceholder from '../../styles/images/placeholder.png';

import ReactAudioPlayer from 'react-audio-player';
import EpisodeTransactionsList from '../pages/EpisodeTransactionsList'
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
    position: 'absolute' as 'absolute',
    top: 'auto',
    left: '50%',
    bottom: '-100px',
    transform: 'translateX(-50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    transition: 'all 0.2s ease-out',
    opacity: 0,
    visibility: 'hidden'
  };

export function BasicModal(props: any) {
    const [alert, setAlert] = useState("");
    const [displayModal, setDisplayModal] = useState(false);

    useEffect(() => {
        const clearMessage = setTimeout(() => {
          setAlert("");
        }, 5000);
        return () => clearTimeout(clearMessage);
      }, [alert]);

      return (
        <div className="app__cs__modal">
            <div className='ball__received'>
                <img src="/images/ball.png" alt="9994DM-giftball" />
                    <button
                // type="button"
                // styleName={`button--outlined active`}
                // onClick={() => navigate('/admin/show/episode/' + episodeDetails?.id + '/gifts/recieved/')}
                onClick={() => setDisplayModal(!displayModal)}
                >{(props.episodeDetails?.runs_received ?? "0")} Runs recieved</button>
            </div>

          <div className={`Modal ${displayModal ? "Show" : ""}`}>
            <div>
                <EpisodeTransactionsList id={props.id} />
            </div>
            <button
              className="Close remove--pic"
              onClick={() => setDisplayModal(!displayModal)}
            >
              <CancelIcon />
            </button>
            {/* <button
              className="Button"
              onClick={() => setAlert("This is an alert!")}
            >
              Trigger alert
            </button> */}
          </div>
          <div
            className={`Overlay ${displayModal ? "Show" : ""}`}
            onClick={() => setDisplayModal(!displayModal)}
          />
          <p className="Alert">{alert}</p>
        </div>
      );
  }


const EpisodeDetail = (props:any) => {
    const { back } = props;
    const { episodeId } = useParams();

    const [episodeDetail, setEpisodeDetails] = useState<EpisodeInterface | null>(null);
    const [seriesId, setSeriesId] = useState<string | undefined>(undefined);

    const token = useSelector((state: RootStateType) => state.user.access_token);
    let episodeDetails = useSelector((state:RootStateType) => state.episode);
    let podcastUploadStatus = useSelector((state:RootStateType) => state.podcastUpload);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getEpisodeDetail = async (id: number|undefined) => {
        dispatch(ShowProgressBar());

        const response = await postRequest(PODCAST_API.EPISODE_DETAIL, {
            episodeId: id
        }, token);


        if (podcastUploadStatus.status === 0 || podcastUploadStatus.status === 2){
            dispatch(HideProgressBar());
        }
        if (response) {
            if (response.data.code === 200) {
                setEpisodeDetails(response.data.data);
                setSeriesId(response.data.data.series.id)

                dispatch(saveEpisodeDetails(response.data.data));
            }
        }
    }

    if (podcastUploadStatus.status === 2 && episodeId){
        getEpisodeDetail(parseInt(episodeId))
        dispatch(HidePodcastUploadingBar());
    }

    useEffect(() => {
        if (episodeId) {
            getEpisodeDetail(parseInt(episodeId));
        }
    }, [episodeId]);

    const buttonBox = (
        <>
        <CustomButton
        type="button"
        styleName={`button--outlined active`}
            onClick={() => navigate('/admin/show/' + episodeDetails?.series?.id + '/episode/detail/' + episodeId,{ replace: true })}
        >Edit Episode</CustomButton>
        </>
    )

    return (
        <>
            <Box className="dashboard__topbar">
                <Box className="row align--items--center">
                    <div className="md-col-8">
                    <Box className="button__text__navigate">
                        <button onClick={() =>
                            (back==="consumer_transaction") ?
                                navigate(-1)
                             : (back==="all_gift_spend") ?
                             navigate(-1)
                             :
                            navigate("/admin/show/"+ episodeDetails?.series?.id,{replace: true})

                        }><ArrowBackOutlinedIcon /></button>
                        <Box className="breadcrumb__title dashboard__topbar--heading" component="h2">{episodeDetails.series.title || "Episode Details"}</Box>
                    </Box>
                    </div>
                    <div className="md-col-4">
                        <Box className="button__box">{buttonBox}</Box>
                    </div>
                </Box>
            </Box>


                <Box className='dashboard__content'>
                    <Box className='row'>
                        <div className="col-12 md-col-5">
                            <Box className='episode__detail__banner aspect__ratio--16by9'>
                                {(episodeDetails && episodeDetails.picture) ?
                                <Box style={{objectFit: 'contain'}} component='img' className='avatorpic--16by9' src={getEpisodeThumbnail(episodeDetails)}/> : ""}
                            </Box>

                            <Box className="row">

                                <div className="col-12">
                                    <Box component="span" className='episode__duration'>
                                        {getDuration(episodeDetails)}
                                    </Box>
                                    <Box component="span" className='episode__duration pulse--anim'>
                                    {episodeDetails.runs_received
                                    ?
                                        <BasicModal episodeDetails={episodeDetails} id={episodeId ?? ""}/>
                                    :
                                        <div className='ball__received'>
                                            <img src="/images/ball.png" alt="9994DM-giftball" />
                                            {(episodeDetails?.runs_received ?? "No")+" gifts recieved"}
                                        </div>
                                    }
                                    </Box>
                                </div>

                            </Box>
                        </div>

                        <div className="col-12 md-col-8">
                            <div className="">
                                <Box component="h1" className='episode__topbar--title'>
                                    {episodeDetails?.title}
                                </Box>
                                <Box component="p" className='episode__metadetail'>
                                    <Box component="span" className='episode__metadetail--epno'>EP {episodeDetails?.episodeNumber}</Box>
                                    <Box component="span" className='episode__metadetail--created'>{moment(episodeDetails?.createdAt).format('lll')}</Box>
                                </Box>
                                <Box className='showdetail__creators'>
                                    <Box>
                                        <label htmlFor="" className='creator--title'>Authors</label>
                                        <Box className='showdetail__area'>
                                            {getAuthorsString(episodeDetails)}
                                        </Box>
                                    </Box>
                                </Box>
                                
                                <Box className='showdetail__descBox max--950'>
                                    {parse(StringToHtml(detectAnchorLinks(episodeDetails?.description)))}
                                </Box>
                                <Box className='media__playerbutton'>
                                    {(podcastUploadStatus.status === 0) ? Player(episodeDetails): "Media upload in progress..."}
                                </Box>
                            </div>
                        </div>

                    </Box>
                </Box>
            <GoToTop/>
        </>
    );
}


const Player = (episodeDetails:any) => (
    <ReactAudioPlayer
      src= {episodeDetails ? episodeDetails.streamUrl : ""}
        controls
    />

);


const getAuthorsString = (episodeDetails: any) => {
    var authorsString = "";
    if (episodeDetails) {
        let authors = episodeDetails.authors
        if (authors){
            authorsString = (authors.map((author: any, index) => {
                return (
                    <Box className='profie__circle__box' key={index}>
                        <Box className='profie__circle__box--img'>
                            <Box
                            component="img"
                            src = {(author.user.profile_pic_path) ? author.user.profile_pic_path : profilepicplaceholder}
                            >
                            </Box>
                        </Box>
                      <Box component='p'>
                            {(author.user.firstName) ? author.user.firstName : author.user.lastName}
                      </Box>
                    </Box>
                )
            }))
        }
    }
    return authorsString;
}

const getEpisodeThumbnail = (episodeDetails: any) => {
    if (episodeDetails && episodeDetails.picture) {
        return episodeDetails.picture;
    }
    return "";
}


const getDuration = (episodeDetails: any) => {
    var durationFinal = "";
    if (episodeDetails) {
        let duration:number = parseInt(episodeDetails.duration)
        if (duration > 61){
            let durationMinutes= parseInt((duration/60).toString());
            let seconds = (duration%60).toString();
            if (seconds){
                durationFinal =  durationMinutes + " min " + seconds + " sec"
            }
            else{
                durationFinal =  durationMinutes + " min "
            }
        }else{
            durationFinal =  duration + " sec"
        }
    }
    return durationFinal;
}

export default EpisodeDetail;
