import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectPermissions,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { HangUpIcon } from "@100mslive/react-icons";
import {
  Button,
  Popover,
  Dialog,
  Tooltip,
  Box,
  IconButton,
  styled,
} from "@100mslive/react-ui";
import {
  DialogCheckbox,
  DialogContent,
  DialogRow,
} from "./primitives/DialogContent";
import { postRequest } from "../data/helpers/services";
import { ROOM_END } from "../constants";
import { RootStateType } from "../store";
import { useSelector } from "react-redux";

export const LeaveRoom = () => {
  const naviage = useNavigate();
  const params = useParams();
  const [showEndRoomModal, setShowEndRoomModal] = useState(false);
  const [lockRoom, setLockRoom] = useState(false);
  const permissions = useHMSStore(selectPermissions);
  const hmsActions = useHMSActions();
  const token = useSelector((state: RootStateType) => state.user.access_token);


  const redirectToLeavePage = () => {
        naviage("/creator/dashboard");
    // if (params.role) {
    //     naviage("/leave/" + params.roomId + "/" + params.role);
    // } else {
    //     naviage("/leave/" + params.roomId);
    // }
  };

  const leaveRoom = async () => {
    await hmsActions.stopHLSStreaming().then(() => {
        console.log("Stream Stopped");
    }).catch((e) => {
        console.log(e);
    });
    await sendRoomEndedOnServer();
    hmsActions.leave();
    redirectToLeavePage();
  };

  const sendRoomEndedOnServer = async () => {
      await postRequest(ROOM_END, {roomId: params.roomId}, token).catch((e) => {
          console.log(e);
      });
  }

  const endRoom = async () => {
    await hmsActions.stopHLSStreaming().then(() => {
        console.log("Stream Stopped");
    }).catch((e) => {
        console.log(e);
    });

    hmsActions.leave();
    // hmsActions.endRoom(lockRoom, "End Room");
    // always lock the room on end
    await hmsActions.endRoom(true, "End Room").then(() => {
        console.log("Room ended");
    }).catch((e) => {
        console.log("Error occured while ending room", e);
    });

    redirectToLeavePage();
  };

  return (
    <Fragment>
      {permissions && permissions.endRoom ? (
        <Popover.Root>
          <Popover.Trigger asChild>
            <LeaveIconButton
              // @ts-ignore
              variant="danger"
              key="LeaveRoom"
              data-testid="leave_room_btn"
            >
              <Tooltip title="End Room">
                <Box>
                  <HangUpIcon key="hangUp" />
                </Box>
              </Tooltip>
            </LeaveIconButton>
          </Popover.Trigger>
          <Popover.Content sideOffset={10}>
            <Button
              variant="standard"
              css={{ w: "100%" }}
              onClick={leaveRoom}
              data-testid="end_room_btn"
            >
              End Room
            </Button>
          </Popover.Content>
        </Popover.Root>
      ) : (
         //@ts-ignore
        <LeaveIconButton onClick={leaveRoom} variant="danger" key="LeaveRoom">
          <Tooltip title="Leave Room">
            <Box>
              <HangUpIcon key="hangUp" />
            </Box>
          </Tooltip>
        </LeaveIconButton>
      )}

      <Dialog.Root
        open={showEndRoomModal}
        onOpenChange={value => {
          if (!value) {
            setLockRoom(false);
          }
          setShowEndRoomModal(value);
        }}
      >
        { //@ts-ignore
        <DialogContent title="End Room" Icon={HangUpIcon}>
          { // @ts-ignore
          <DialogCheckbox
            id="lockRoom"
            title="Disable future joins"
            value={lockRoom}
            onChange={setLockRoom}
          />}
          { //@ts-ignore
          <DialogRow justify="end">
            <Button
              variant="danger"
              onClick={endRoom}
              data-testid="lock_end_room"
            >
              End Room
            </Button>
          </DialogRow>
          }
        </DialogContent>}
      </Dialog.Root>
    </Fragment>
  );
};

// @ts-ignore
const LeaveIconButton = styled(IconButton, {
  color: "$white",
  width: "$15",
  mx: "$4",
  bg: "$error",
  "&:not([disabled]):hover": {
    bg: "$errorTint",
  },
  "&:not([disabled]):active": {
    bg: "$errorTint",
  },
});
