import { RESET, UPDATE_SHOW_LIST_CONTENT_DATA, DELETE_SHOW_STATUS, UPDATE_SHOW_STATUS, SAVE_SEARCHED_SHOW, ADD_SHOW_LIST_CONTENT_DATA, SAVE_SHOW_CURRENT_PAGE, SAVE_TOTAL_SHOW } from '../../constants';
import { showListContentDataModifiedObj } from '../../data/mappers/showList';
import { UpdateshowListContentAction, AddShowListContentAction } from './types';

export const UpdateShowListContent = (data: Array<showListContentDataModifiedObj>): UpdateshowListContentAction => ({
    type: UPDATE_SHOW_LIST_CONTENT_DATA,
    data,
});

export const AddShowListContent = (data: Array<showListContentDataModifiedObj>): AddShowListContentAction => ({
    type: ADD_SHOW_LIST_CONTENT_DATA,
    data,
});


export const UpdateShowStatusContent = (data:  number) => ({
    type: UPDATE_SHOW_STATUS,
    data,
});


export const DeleteShowContent = (data:  number) => ({
    type: DELETE_SHOW_STATUS,
    data,
});


export const SaveSearchedShowContent = (data:  string) => ({
    type: SAVE_SEARCHED_SHOW,
    data,
});


export const ShowCurrentPage = (data:  number) => ({
    type: SAVE_SHOW_CURRENT_PAGE,
    data,
});

export const TotalShowSave = (data:  number) => ({
    type: SAVE_TOTAL_SHOW,
    data,
});

export const ShowResetAction = () => ({
    type: RESET
});
