import { Box, Paper } from '@mui/material';
import React from 'react';
import AdminProfileForm from '../customAdmin/Form';
import GoToTop from '../common/GoTop'


interface AdminCreateProps {
    modeType: string;
    // back: string;
}

const AddAdmin = (props: AdminCreateProps) => {
    return (
        <>
            { //@ts-ignore
            <AdminProfileForm modeType={props.modeType} redirect={false} />}
            <GoToTop/>
        </>
    );

}

export default AddAdmin;
