import { Reducer } from "react";
import { USER_LOGGED_IN, USER_LOGGED_OUT } from "../../constants";
import { UserLoginActions, userState } from "./types";

export const initialState:userState = {
    id: 0,
    handle: "",
    firstName: "",
    lastName: "",
    email: "",
    access_token : "",
    role: "",
    profile_pic_path: "",
    bio: "",
    email_verified: false,
    isProfileCreated: false,
    permissions:{
        match:false,
        series: false,
        app_version: false,
        creator: false,
        push: false,
        series_delete: false,
        consumer:false,
    }
}


export const userReducer: Reducer<userState, UserLoginActions> = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case USER_LOGGED_IN:
            return {
                // @ts-ignore
                ...payload.data,
            };
        case USER_LOGGED_OUT:
            return {
                ...initialState
            };
        default:
            return state;
    }
};
