import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { CREATOR_API, UPLOAD_FILE } from '../../constants';
import { postFileRequest, putRequest } from '../../data/helpers/services';
import { creatorMapper, profileApiMapper } from '../../data/mappers';
import UploadIcon from '../../icons/UploadIcon';
import { RootStateType } from '../../store';
import { creatorLoggedIn } from '../../store/creator';
import { Button } from '../common/Button';
import { TextArea, TextField } from '../common/TextField';
import { userLoggedIn } from '../../store/user';

// @ts-ignore
import profilepicplaceholder from '../../styles/images/placeholder.png';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import Compressor from 'compressorjs';

import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface profileForm {
    firstName: string;
    lastName: string;
    handle: string;
    bio: string;
}

const validate = (values: profileForm) => {
    const errors: any = {}
    if (!values.firstName || (values.firstName && !values.firstName.trim())) {
        errors.firstName = 'Field is required.';
    } else if (values.firstName && values.firstName.trim().length > 32) {
        errors.firstName = 'Field value must be 32 characters or less.';
    }

    if (!values.lastName || (values.lastName && !values.lastName.trim())) {
        errors.lastName = 'Field is required.';
    } else if (values.lastName && values.lastName.trim().length > 32) {
        errors.lastName = 'Field value must be 32 characters or less.';
    }

    if (!values.handle || (values.handle && !values.handle.trim())) {
        errors.handle = "Field is required.";
    } else if (values.handle && values.handle.length > 20) {
        errors.handle = 'Field value must be 20 characters or less.';
    } else if (values.handle.trim().indexOf(" ") !== -1 || values.handle.trim().indexOf("@") !== -1) {
        errors.handle = 'Spaces and @ is not allowed in handle.';
    }

    if (values.bio && values.bio.trim().length > 4096) {
        errors.bio = "Field value must be 4096 characters or less.";
    }

    return errors
}

const ProfileForm = (props:any) => {
    const { handleSubmit, change, submitting } = props;

    const user = useSelector((state: RootStateType) => state.user);
    const token = user.access_token;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [profileImage, setProfileImage] = useState<string>("");
    const [fileUploading, setFileUploading] = useState(false);

    const saveProfile = async (data: any) => {
        dispatch(ShowProgressBar());
        const apiData = profileApiMapper(data);
        const response: any = await putRequest(CREATOR_API.UPDATE, apiData, token);
        if (response) {
            const resData = response.data;
            dispatch(HideProgressBar());
            if (resData.code === 200) {
                const creator = creatorMapper(resData.data, token);
                dispatch(creatorLoggedIn(creator));
                if(props.redirect) {
                    navigate("/creator/dashboard", { replace: true });
                }
                const newInfo = { ...user,
                    profile_pic_path:apiData.profile_pic_path,
                    firstName:apiData.firstName,
                    lastName:apiData.lastName,
                    handle:apiData.handle,
                    bio:apiData.bio,
                }
                dispatch(userLoggedIn(newInfo));

                // dispatch(userLoggedIn(response.data.data));
                dispatch(ShowSuccessAlert({visible:true, message: "Profile saved successfully."}));
            }
        }
    }

    const handleCompressedUpload = async(e: any) => {
        const image = e.target.files[0];
        new Compressor(image, {
          quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
        //   maxHeight: imageHeight,
        //   maxWidth : imageWidth,
          success: async(res) => {

            let reader = new FileReader();
            reader.onload = (e: any) => {
                setProfileImage(e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);

            // upload file and obtain url for image
            setFileUploading(true);
            dispatch(ShowProgressBar());

            const formData = new FormData();

            formData.append('file', res, (Date.now()).toString() + e.target.files[0].name );

            const response: any = await postFileRequest(UPLOAD_FILE, formData, token);

            if (response) {
                const resData = response.data;
                if (resData.code === 200) {
                    let fileUrl = resData.data.file_path;
                    change( 'profile_pic_path', fileUrl);
                }
            } else {
                setProfileImage('')
            }

            dispatch(HideProgressBar());
            setFileUploading(false);

            return true;

          },
        });
      };


    const handleFileSelect = async (event: any) => {
        const filetype = event.target.files[0].type;

        const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
        // if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 10000)) {
        if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 500)) {
            event.target.value = null;
            dispatch(ShowErrorAlert({visible:true, message: "Must be a .png, .jpg, .jpeg, .webp format image & not more than 500kb"}));
            dispatch(HideProgressBar());
            setFileUploading(false);
            return false;
        }
        setFileUploading(true);
        dispatch(ShowProgressBar());

        handleCompressedUpload(event);

        // if (event !== undefined && event.target.files && event.target.files[0]) {
        //     let reader = new FileReader();
        //     reader.onload = (e: any) => {
        //         setProfileImage(e.target.result);
        //     };
        //     reader.readAsDataURL(event.target.files[0]);
        // }
        // upload file and obtain url for image
        // const formData = new FormData();

        // // Update the formData object
        // formData.append(
        //     "file",
        //     event.target.files[0],
        //     event.target.files[0].name
        // );

        // const response: any = await postFileRequest(UPLOAD_FILE, formData, token);
        // if (response) {
        //     const resData = response.data;
        //     if (resData.code === 200) {
        //         let fileUrl = resData.data.file_path;
        //         change("profile_pic_path", fileUrl);
        //     }
        // } else {
        //     setProfileImage("");
        // }
        // dispatch(HideProgressBar());
        // setFileUploading(false);
        // set that url in profile_image field which will be sent to backend
        return true;
    }

    useEffect(() => {
        change('firstName', user.firstName);
        change('lastName', user.lastName);
        change('handle', user.handle);
        change('bio', user.bio);
        change('profile_pic_path', user.profile_pic_path);
        setProfileImage(user.profile_pic_path ?? "");
    }, [user]);

    return (
        <form onSubmit={handleSubmit(saveProfile)}>
            <div className="row m-t-30">
                <div className='col-12 m-b-20'>
                    <div className='profilepic__circle__wrapper'>
                        <div className='profilepic__circle'>
                            <img src={(profileImage) ? profileImage : profilepicplaceholder} alt="creator Profile of 99.94 DM" />
                        </div>
                        <Box component={"label"}>
                            <Button type='button' styleName='pic--uploadBtn'>
                                <span>
                                    <UploadIcon />
                                </span>
                            </Button>
                        </Box>
                        <Field
                            name="profile_pic_path"
                            type="hidden"
                            component="input"
                            id="profile_pic_path"
                            placeholder="profile_pic_path"
                        />
                        <input type="file" name="profile_pic" className="hidden-file-input" onChange={handleFileSelect} />
                    </div>
                    <Box sx={{
                        fontSize: '14px',
                        marginTop: '8px',
                        display: 'grid',
                        fontWeight: 400,
                        gridTemplateColumns: '30px 1fr',
                        alignItems: 'center',
                        color: '#fff'
                    }}>
                        <HelpOutlineIcon/>
                         Recomended size is 180X180 for best fit.
                    </Box>
                </div>

                <div className='col-12 md-col-6 m-b-20'>
                    <label className='input--label m-b-10' htmlFor="firstName">First Name</label>
                    <Field name="firstName" component={TextField} type="text" placeholder='Write First Name'
                        styleName='input--transparent' />
                </div>
                <div className='col-12 md-col-6 m-b-20'>
                    <label className='input--label m-b-10' htmlFor="lastName">Last Name</label>
                    <Field name="lastName" component={TextField} type="text" placeholder='Write Last Name'
                        styleName='input--transparent' />
                </div>
                <div className='col-12 md-col-6 m-b-20'>
                    <label className='input--label m-b-10' htmlFor="userName">Handle</label>
                    <Field name="handle" component={TextField} type="text" placeholder='Write Handle Name'
                        styleName='input--transparent text--initial' />
                </div>
                <div className='col-12 m-b-40'>
                    <label className='input--label m-b-10' htmlFor="userBio">Bio</label>
                    <Field name="bio" component={TextArea} row='4' type="text" placeholder='Write description'
                        styleName='input--transparent' />
                </div>
                <div className="col-12 m-b-20">
                    <Button styleName='saveBtn' type='submit' disabled={submitting || fileUploading}>
                        {showSubmitButtonLabel(submitting, fileUploading)}
                    </Button>
                </div>
            </div>
        </form>
    );

}

const showSubmitButtonLabel = (submitting:boolean, fileUploading:boolean) => {
    if (submitting) {
        return "Saving...";
    } else if (fileUploading) {
        return "Saving Photo...";
    } else {
        return "Save";
    }
}

export default reduxForm({
    form: 'profile',
    validate
})(ProfileForm)
