import React from 'react';
import ConsumerCustomData from '../consumerCustomDates/form';
import GoToTop from '../common/GoTop'


const CreateCustomConsumerData = () => {
    return (
        <>
            {//@ts-ignore
            <ConsumerCustomData />}
            <GoToTop/>
        </>
    );

}


export default CreateCustomConsumerData;

