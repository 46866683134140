import * as React from 'react';

import  { useEffect } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


export function IndeterminateCheckbox( props:any ) {
    const {checked, setChecked, specificUserListValue} = props

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([checked[0], event.target.checked]);
  };

  useEffect(() => {
      if (specificUserListValue?.length){
          setChecked([false,false]);

        }
    },[specificUserListValue]);


    let disableEvent = (specificUserListValue?.length) ? "checkbox--white disabled--event" : "checkbox--white"


  const children = (
    <>
      <FormControlLabel
        label="iOS"
        className= {disableEvent}
        control={<Checkbox checked={checked[0]}
        onChange={handleChange2} />}
      />
      <FormControlLabel
        label="Android"
        className={disableEvent}
        control={<Checkbox checked={checked[1]} onChange={handleChange3} />}
      />
    </>
  );

  return (
    <>
      <FormControlLabel
        label="All"
        className= {disableEvent}
        control={
          <Checkbox
            checked={checked[0] && checked[1]}
            indeterminate={checked[0] !== checked[1]}
            onChange={handleChange1}
          />
        }
      />
      {children}
    </>
  );
}


export function PermissionsCheckbox( props:any ) {
    const {permissions, modeType} = props

    let disableEvent = (modeType === "detail") ? "checkbox--white disabled--event" : "checkbox--white"


    return (
        <>
        <FormControlLabel
            label="Add Creator"
            className= {disableEvent}
            control={ <Checkbox checked={permissions.creator} onChange={() => {
                permissions.setCreator(!permissions.creator)
            }} />}
        />

        <FormControlLabel
            label="Add App Versions"
            className= {disableEvent}
            control={ <Checkbox checked={permissions.appVersion} onChange={() => {
                permissions.setAppVersion(!permissions.appVersion)
            }} />}
        />

        <FormControlLabel
            label="Add Podcast"
            className= {disableEvent}
            control={ <Checkbox checked={permissions.show} onChange={() => {
                permissions.setShow(!permissions.show)
            }} />}
        />

        <FormControlLabel
            label="Add Match"
            className= {disableEvent}
            control={ <Checkbox checked={permissions.match} onChange={ () => {
                permissions.setMatch(!permissions.match)
            }} />}
        />

        <FormControlLabel
            label="Push Notification"
            className= {disableEvent}
            control={ <Checkbox checked={permissions.push} onChange={() => {
                permissions.setPush(!permissions.push)
            }} />}
        />

        <FormControlLabel
            label="Delete Show & Episode"
            className= {disableEvent}
            control={ <Checkbox checked={permissions.deleteShow} onChange={() => {
                permissions.setDeleteShow(!permissions.deleteShow)
            }} />}
        />


        </>
    );
}
