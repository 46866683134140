import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel } from '@mui/material';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';


import Checkbox from '@mui/material/Checkbox';



import { Field, reduxForm } from 'redux-form';
import { TextField } from '../common/TextField';
import { createAppVersion, editAppVersion } from '../../data/mappers/appVersion';

import {APP_VERSION_API} from '../../constants';


import { RootStateType } from '../../store';
import {  postRequest } from '../../data/helpers/services';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';

interface AppVersionFormInterface {
    id: number;
    androidVersion: string;
    iosVersion: string;
    isForceUpdate: boolean;
}

const validate = (values: AppVersionFormInterface) => {
    const errors: any = {};

    if (!(values.androidVersion) && !(values.iosVersion)){
        errors.androidVersion = "Field is required";
        errors.iosVersion = "Field is required";
    }

    if (values.androidVersion){
        if (!values.androidVersion.match(/^(\d?\d)?(\.+\d?\d)?(\.+\d?\d)?(\.+\d?\d+)?$/)){
            errors.androidVersion = 'Enter a valid version.';
        }
    }

    if (values.iosVersion){
        if (!values.iosVersion.match(/^(\d?\d)?(\.+\d?\d)?(\.+\d?\d)?(\.+\d?\d)?$/)){
            errors.iosVersion = 'Enter a valid version.';
        }
    }
    return errors;
};

const AppVersionForm = (props: any) => {
    const { handleSubmit, change, submitting, reset, modeType, back } = props;
    let token = useSelector((state: RootStateType) => state.user.access_token);

    const [appVersionDetails,  setAppVersionDetails] = useState<AppVersionFormInterface | undefined>(undefined)
    const [isForceUpdate, setIsForceUpdate] = useState<boolean>(false)

    const [androidChecked, setAndroidChecked] = useState(false);

    const [ioschecked, setIosChecked] = useState<boolean>(false);

    const [editable, setEditable] = useState<boolean>(true);

    const [showAndroid, setShowAndroid] = useState<boolean>(false);
    const [showIos, setShowIos] = useState<boolean>(false);

    const [ loading, setLoading ] = useState<boolean>(true);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();

    const getAppVersionDetails = async (id: string) => {
        dispatch(ShowProgressBar());
        const response = await postRequest(
            APP_VERSION_API.DETAIL,
            {
                version_id: id,
            },
            token
        );

        if (response) {
            if (response.data.code === 200) {
                let resData = response.data.data

                setAppVersionDetails(resData);
                setEditable(resData.isLatest);

                if (resData.OSType === "android") {
                    setAndroidChecked(true)
                    setShowIos(false);
                    setShowAndroid(true);
                    change("androidVersion", resData.version);
                }else{
                    setIosChecked(true);
                    setShowAndroid(false);
                    setShowIos(true);
                    change("iosVersion", resData.version);
                }
                setLoading(false);

            }
        }
        dispatch(HideProgressBar());
    };

    const initForm = () => {
        setAppVersionDetails(undefined);
    }

    useEffect(() => {
        if(modeType === 'add') {
            initForm();
            setShowIos(true);
            setShowAndroid(true);
            setLoading(false);
        }
    }, [modeType]);

    useEffect(() => {
        if (id && modeType === 'edit') {
            getAppVersionDetails(id);
        }
    }, [id]);

    useEffect(() => {
        if (appVersionDetails && modeType === 'edit') {
            fillAppVersionDetails(appVersionDetails);
        }
    }, [appVersionDetails]);

    const fillAppVersionDetails = async (appVersionDetails:any) => {
        change('version', appVersionDetails?.version);
        setIsForceUpdate(appVersionDetails?.isForceUpdate);
    }

    const saveAppVersion = async (values: any) => {
        dispatch(ShowProgressBar());


        var response: any = null;

        if (appVersionDetails) {
            values.id = appVersionDetails.id
            let data = editAppVersion(values, isForceUpdate);
            response = await postRequest(APP_VERSION_API.UPDATE, data, token);
        } else {
            let data = createAppVersion(values, isForceUpdate, androidChecked, ioschecked);
            response = await postRequest(APP_VERSION_API.CREATE, data, token);
        }

        dispatch(HideProgressBar());

        if (response) {
            const resData = response.data;
            if (resData){
                let message:string = ""
                modeType === "edit" ? message = "Version updated successfully." : message = "New version added successfully.";
                dispatch(ShowSuccessAlert({visible:true, message: message}));
                navigate('/admin/app/version', { replace: true })
            }
        }
    };


    return (
        <>
        <Box className="dashboard__topbar">
            <Box className="dashboard__topbar--heading col-12">
                <Box className="row">
                    <button onClick={() => navigate(-1)
                        }><ArrowBackOutlinedIcon /></button>
                    <Box component="h2">App Version</Box>
                </Box>
            </Box>
        </Box>
        {(!loading)?
        <Box className='dashboard__content max--650 mx--auto'>
            <Box component="form" onSubmit={handleSubmit(saveAppVersion)}>
                <Box component="div" className=" app__borderbox">
                    <Box className='p--4'>
                        <Box className="row">
                        { (showAndroid)
                            ?
                                <div className='col-12 m-b-20'>
                                    <Box className="row">
                                        <Box className='col-5'>
                                            <FormControlLabel
                                                checked={androidChecked}
                                                name="android"
                                                // value={isAndroidUpdate}
                                                disabled={(!editable) || (modeType === "edit")}
                                                control={<Checkbox />}
                                                className='checkbox--white'
                                                label= 'Android'
                                                labelPlacement="end"
                                                onChange={() => {
                                                    setAndroidChecked(!androidChecked)
                                                }}
                                            />
                                        </Box>
                                        <Box className='col-7'>
                                            <Field name="androidVersion" component={TextField} type="text" placeholder='Write your version here' className='input--transparent'
                                            disabled={(!androidChecked) || (!editable)}
                                            // onChange={e => setText(e.target.value)}
                                            />
                                        </Box>
                                    </Box>
                                </div>
                            : ""
                        }
                        {
                            (showIos)
                            ?
                                <div className='col-12 m-b-20'>
                                    <Box className="row">
                                        <Box className='col-5'>
                                            <FormControlLabel
                                                checked={ioschecked}
                                                name="ios"
                                                disabled={(!editable) || (modeType === "edit")}
                                                className='checkbox--white'
                                                control={<Checkbox />}
                                                label='iOS'
                                                labelPlacement="end"
                                                onChange={() => {
                                                    setIosChecked(!ioschecked)
                                                    }
                                                }
                                            />
                                        </Box>
                                        <Box className='col-7'>
                                            <Field name="iosVersion" component={TextField} type="text"  placeholder='Write your version here' className='input--transparent'
                                            disabled={(!ioschecked) || (!editable)}
                                            />
                                        </Box>
                                    </Box>
                                </div>
                            : ""
                        }
                        </Box>
                    </Box>
                    <Box style={{borderBottomColor: 'transparent', padding: '1rem 1.5rem 1.5rem', textAlign: 'center'}}>
                        <FormControlLabel
                                name="forceUpdate"
                                value={isForceUpdate}
                                control={<Checkbox checked={isForceUpdate}/>}
                                disabled={!editable}
                                className='checkbox--white m-b-20'
                                label="Force Update"
                                labelPlacement="end"
                                onChange={() => {
                                    setIsForceUpdate(!isForceUpdate);
                                    }
                                }
                        />
                        { editable
                            ?
                                <Box>
                                    <button type="submit" className={`btn--pill saveBtn`} disabled={submitting}>
                                        {showSubmitButtonLabel(submitting, modeType)}
                                    </button>
                                </Box>
                            :
                                ""
                        }
                    </Box>
                    </Box>
                </Box>
        </Box>
        : ""
        }
        </>
    );
};

const showSubmitButtonLabel = (submitting: boolean, modeType:string) => {
    if (submitting) {
        return 'Saving...';
    }
    else {
        if (modeType === 'edit'){
            return `Save`;
        }
        return `Add New Version`;
    }
};

export default reduxForm({
    form: 'appVersion',
    validate,
})(AppVersionForm);

