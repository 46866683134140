import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CustomButton } from '../common/Button';
import ConsumerListDataTable from '../common/ConsumerListDataTable';
import { getRequest, postRequest } from '../../data/helpers/services';
import { CONSUMER_ADMIN_API, COUNTRIES_LIST } from '../../constants';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { consumerListContentData, consumerListContentDataModified } from '../../data/mappers/consumerList';
import { UpdateConsumerListContent, AddConsumerListContent, TotalConsumerSave, ConsumerCurrentPage } from '../../store/consumerList';

import { useNavigate } from 'react-router-dom';
import GoToTop from '../common/GoTop'

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';



import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

function ClickableAndDeletableChips() {
  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  return (
    <Stack direction="row" spacing={1}>
      <Chip
        label="Clickable Deletable"
        onClick={handleClick}
        onDelete={handleDelete}
      />
    </Stack>
  );
}

export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}
export interface country{
    id:number;
    title:string;
}

function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef<HTMLElement>(null);

  const [ countriesAvialable, setCountriesAvialable] = React.useState<country[]>();

  let token = useSelector((state: RootStateType) => state.user.access_token);
  useEffect(() => {
      getCountriesList();
    }, []);

    const getCountriesList = async () => {
        let response = await postRequest(COUNTRIES_LIST, [], token);
        if (response) {
            let countriesList: country[] = [];
            let countries = response?.data?.data;
            if (countries && countries.length) {
            countries.map((country: country) => countriesList.push({ id: country.id, title: country.title }));
            setCountriesAvialable(countriesList);
        }
    } else {
        console.log('Unable to get list');
    }
    }

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Countries</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {countriesAvialable ?
          countriesAvialable.map((option) => (
              <FormControlLabel
              value={option.id}
              key={option.title}
              control={<Radio />}
              label={option.title}
              />
          )):""
        }
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

function ConfirmationDialog() {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('Dione');

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  return (
      <div className="row">
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <List component="div" role="group">
            <ListItem
            button
            divider
            aria-haspopup="true"
            aria-controls="ringtone-menu"
            aria-label="phone ringtone"
            onClick={handleClickListItem}
            >
            <ListItemText primary="Filter" />
            </ListItem>
            <ConfirmationDialogRaw
            id="ringtone-menu"
            keepMounted
            open={open}
            onClose={handleClose}
            value={value}
            />
        </List>

        </Box>
        <ClickableAndDeletableChips />
    </div>
  );
}
























const ConsumerList = (props: any) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [ offsetValue, setOffsetValue ] = useState<number | 0>(0)
    const [ maxNotification, setMaxNotification ] = useState<number | 0 >(0)
    const [ searchValue, setSearchValue ] = useState<string | "">("")
    const [ loader, setLoader ] = useState<boolean>(false)

    const [ compareSearchRedux, setCompareSearchRedux ] = useState<string | "">("")
    const [ currentPage, setCurrentPage ] = useState<number>(0)

    let token = useSelector((state: RootStateType) => state.user.access_token);

    const ConsumerListObject = useSelector((state: RootStateType) => state.consumerList);

    const ConsumerListContent = ConsumerListObject.consumerListContent

    if ((ConsumerListObject.searchedValue) && (ConsumerListObject.searchedValue !== searchValue)){
        setSearchValue(ConsumerListObject.searchedValue);
    }

    if ((ConsumerListObject.currentPage) && (ConsumerListObject.currentPage !== currentPage)) {
        setCurrentPage(ConsumerListObject.currentPage);
    }else if ((ConsumerListObject.currentPage === 0) && (currentPage !== 0)) {
        setCurrentPage(0)
    }

    if ((currentPage > 0) && (offsetValue === 0) && (searchValue !== compareSearchRedux)) {
        setOffsetValue(ConsumerListContent.length)
    } else if ((currentPage > 0) && (offsetValue === 0)) {
        setOffsetValue(ConsumerListContent.length)
    }


    // useEffect(() => {
    //     getCreatorListContent(searchValue);
    // }, [offsetValue, searchValue]);

    useEffect(() => {
        // getCreatorListContent(searchValue);
        if ((currentPage !== 0) &&
            (((currentPage+1)*10) <= ConsumerListContent.length) &&
            (ConsumerListObject.totalConsumer >= ConsumerListContent.length) &&
            (searchValue === compareSearchRedux) &&
            (offsetValue !== 0)
            ){
            setMaxNotification(ConsumerListObject.totalConsumer)
        } else{
            getCreatorListContent(searchValue);
            setCompareSearchRedux(searchValue);
        }

    }, [offsetValue, searchValue]);


    const getCreatorListContent = async (searchingValue:string) => {
        let res:any = {}
        if ((searchingValue === (searchValue) || (searchingValue === ""))) {
            setLoader(true);
            res = await postRequest(CONSUMER_ADMIN_API.LIST,{
                "offset": offsetValue,
                "search_text": searchValue
            }, token);
        }
        if (res) {
            if ((searchingValue === (searchValue) || (searchingValue === ""))) {
                setMaxNotification(res?.data?.data.total_consumer)
                dispatch(TotalConsumerSave(res.data?.data.total_consumer))

                // let formattedCreatorListContentData = res.data?.data.creator_list as consumerListContentData,
                let formattedConsumerListContentData = res?.data?.data.consumer_list as consumerListContentData,
                formattedConsumerListContent: consumerListContentDataModified = [];
                formattedConsumerListContentData.forEach((x) => {
                    formattedConsumerListContent.push({
                        email: x.email ?? "-",
                        id: x.id,
                        fullName: (x.firstName ? x.firstName : "" )+ ' ' +  (x.lastName ? x.lastName : "" ),
                        //@ts-ignore
                        status: (
                            //@ts-ignore
                            (x.status === "active") ? "Active" : "Inactive"
                        ),
                        country: (x?.country_data?.country_name ?? "-"),
                        runs: x.runs_gifted || "0",
                        phone:(x.phone ?
                            ((x.country_code ? (x.country_code + " ")
                            : "" )
                            + x.phone ) : "-"),
                        balance: x.balance || "0",
                        createdAt: x.createdAt

                    });
                });

                if (!offsetValue){
                    dispatch(ConsumerCurrentPage(0))
                    dispatch(UpdateConsumerListContent(formattedConsumerListContent));
                }
                else{
                    dispatch(AddConsumerListContent(formattedConsumerListContent))
                }
            }
        }
        setLoader(false);
    };

    const buttonBox = (
        <CustomButton
            type="button"
            styleName={`button--outlined active`}
            onClick={() => navigate('/admin/consumers/customDate')}
        >Export User Data
        </CustomButton>
    )

    return (
        <>
            <Box className="dashboard__content">
                <Box className="col-12 md-col-8 m-b-20">
                        <Box className="button__box" style={{display: 'block'}}>{buttonBox}</Box>
                </Box>
                <Box>
                    {/* <ConfirmationDialog /> */}
                    <ConsumerListDataTable consumerListContent={ConsumerListContent} offsetValue={offsetValue} setOffsetValue={setOffsetValue} maxNumber={maxNotification} searchValue={searchValue} setSearchValue={setSearchValue} loader={loader} currentPage={currentPage}/>
                </Box>

            </Box>
            <GoToTop/>
        </>
    );
};

export default ConsumerList;
