import { UPDATE_ALL_GIFT_SPEND_LIST_CONTENT_DATA, UPDATE_EPISODE_GIFT_LIST_CONTENT_DATA } from '../../constants';
import { allGiftSpendListContentDataModifiedObj, episodeGiftListContentDataModifiedObj } from '../../data/mappers/allTransactions';
import { allGiftSpendListState, allGiftSpendListActions, episodeGiftListActions } from './types';

export const UpdateAllGiftSpendListContent = (data: Array<allGiftSpendListContentDataModifiedObj>): allGiftSpendListActions => ({
    type: UPDATE_ALL_GIFT_SPEND_LIST_CONTENT_DATA,
    data,
});

export const UpdateEpisodeTransactionListContent = (data: Array<episodeGiftListContentDataModifiedObj>): episodeGiftListActions => ({
    type: UPDATE_EPISODE_GIFT_LIST_CONTENT_DATA,
    data,
});


// export const AddCreatorListContent = (data: Array<creatorListContentDataModifiedObj>): AddCreatorListContentAction => ({
//     type: ADD_CREATOR_LIST_CONTENT_DATA,
//     data,
// });

// export const UpdateCreatorAccountStatus = (data:  number) => ({
//     type: UPDATE_CREATOR_ACCOUNT_STATUS,
//     data,
// });

// export const CreatorSearchText = (data:  string) => ({
//     type: SAVE_CREATOR_SEARCH_TEXT,
//     data,
// });

// export const CreatorCurrentPage = (data:  number) => ({
//     type: SAVE_CREATOR_CURRENT_PAGE,
//     data,
// });

// export const TotalCreatorsSave = (data:  number) => ({
//     type: SAVE_TOTAL_CREATORS,
//     data,
// });

// export const CreatorResetAction = () => ({
//     type: RESET
// });

