import React, { useEffect, useContext, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectRoomState,
  HMSRoomState,
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSStore,
  selectHLSState,
  useHMSVanillaStore,
} from "@100mslive/react-sdk";
import { Box, Flex } from "@100mslive/react-ui";
import { Header } from "./Header";
import { Footer } from "./Footer";
import FullPageProgress from "./FullPageProgress";
import { RoleChangeRequestModal } from "./RoleChangeRequestModal";
import { ConferenceMainView } from "./layouts/mainView";
import { AppContext } from "./context/AppContext";
import { putRequest } from "../data/helpers/services";
import { STREAM_UPDATE } from "../constants";
import { useSelector } from "react-redux";
import { RootStateType } from "../store";
import { Backdrop, CircularProgress } from "@mui/material";

var requestSent = false;
var urlReceived = false;
var subscribed = false;

const Conference = () => {
  const navigate = useNavigate();
  const { roomId, role } = useParams();
  //@ts-ignore
  const { isHeadless, isAudioOnly } = useContext(AppContext);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const toggleChat = useCallback(() => {
    setIsChatOpen(open => !open);
  }, []);
  const isConnectingToRoom =
    useHMSStore(selectRoomState) === HMSRoomState.Connecting;
  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
  const hmsActions = useHMSActions();
  const hlsState = useHMSStore(selectHLSState);
  const token = useSelector((state: RootStateType) => state.user.access_token);
  const [backdrop, setBackdrop] = useState(false);
  const hmsStore = useHMSVanillaStore();

  const updateStreamUrl = async (url:string) => {
        const data = {
            roomId: roomId,
            streamUrl: url,
            stream_status: '1'
        }

        let response = await putRequest(STREAM_UPDATE, data, token);
        if(response) {
            // setBackdrop(false);
            console.log("Stream Updated", {response});
        }
  }

  useEffect(() => {
    if (!roomId) {
      navigate(`/`);
    }

    if (!(isConnectingToRoom || isConnectedToRoom)) {
      if (role) navigate(`/preview/${roomId || ""}/${role}`);
      else navigate(`/preview/${roomId || ""}`);
    } else {
        setTimeout(function() {
            if(hlsState.running === false && urlReceived === false && requestSent === false) {
                console.log("Generating after 2 seconds of joining");
                requestSent = true;
                generateHlsURL();
            }
        }, 2000);
    }

    return () => {
      // This is needed to handle mac touchpad swipe gesture
      console.log("Left");
      if(requestSent === true || urlReceived === true) {
          requestSent = false;
          urlReceived = false;
      }
    //   hmsActions.leave();
    };
    // eslint-disable-next-line
  }, []);

  const generateHlsURL = async () => {
    await hmsActions.startHLSStreaming({
        variants: [{ meetingURL: process.env.REACT_APP_BASE_PATH+"/preview/"+roomId+"?skip_preview=true" }], //
        recording: true
          ? { hlsVod: true, singleFilePerLayer: false }
          : undefined,
      }).catch((e) => {
          if(!subscribed) {
            hmsStore.subscribe(updateStreamLinkFromStore, selectHLSState);
            subscribed = true;
          }

        console.log(e);
      });
  }

  const stopStream = async () => {
    await hmsActions.stopHLSStreaming().then(() => {
        console.log("Stream Stopped");
    });
  }

  const updateStreamLinkFromStore = (hlsState) => {
    if(hlsState.running === true && urlReceived === false) {
        urlReceived = true;
        console.log("Got Stream URL");
        updateStreamUrl(hlsState.variants[0].url);
    }
  }

  useEffect(() => {
    updateStreamLinkFromStore(hlsState);
  }, [hlsState]);

  if (!isConnectedToRoom) {
    return <FullPageProgress />;
  }


  const alertUser = () => {
      alert("You are leaving the meeting, it will stop the match and streaming");
  }

  const handleEndConcert = () => {
      console.log("You have left the page handle end concert");
  }

  return (
    <Flex css={{ size: "100%" }} direction="column">
      {!isHeadless && (
        <Box css={{ h: "$18", "@md": { h: "$17" } }}>
          {//@ts-ignore
          <Header />}
        </Box>
      )}
      <Box
        css={{
          w: "100%",
          flex: "1 1 0",
          minHeight: 0,
        }}
      >
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <ConferenceMainView isChatOpen={isChatOpen} toggleChat={toggleChat} />
      </Box>
      {!isHeadless && (
        <Box css={{ flexShrink: 0, minHeight: "$24" }}>
          <Footer
            isChatOpen={isChatOpen}
            toggleChat={toggleChat}
            isAudioOnly={isAudioOnly}
          />
        </Box>
      )}
      <RoleChangeRequestModal />
    </Flex>
  );
};

export default React.memo(Conference);
