import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';


import { creatorListContentDataModifiedObj } from '../../data/mappers/creatorList';

import { useSelector } from 'react-redux';
import { RootStateType } from '../../store';

import { CREATOR_ADMIN_API } from '../../constants';
import { postRequest } from '../../data/helpers/services';

import { useDispatch } from 'react-redux';
import { ShowSuccessAlert } from '../../store/alerts';

import { UpdateCreatorAccountStatus,CreatorSearchText, CreatorCurrentPage } from '../../store/creatorList'


import { Link } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export function SortedDescendingIcon() {
    return <ExpandMoreIcon className="icon" />;
  }

  export function SortedAscendingIcon() {
    return <ExpandLessIcon className="icon" />;
  }


function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{mr: 2}} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}


function UpdateCreatorStatus(params) {
    let dispatch = useDispatch()

    const [ updating, setUpdating ] = React.useState<boolean>(false)

    const [ userActive, setUserActive] = React.useState<boolean>(true)

    let token = useSelector((state: RootStateType) => state.user.access_token);
    let id = params.params.row.id;

    // setUserActive(params.params.row.userActive)

    const handleClick = async () => {
        if (!updating){
            setUpdating(true);
            // let message = userActive ? "Creator account deactivating." : "Creator account activating";
            dispatch(ShowSuccessAlert({visible:true, message: "Action in progress"}));

            const res: any = await postRequest(CREATOR_ADMIN_API.STATUS_UPDATE, {
                creatorId: id
            }, token);
            if (!res) {
                setUserActive(!userActive);
                dispatch(UpdateCreatorAccountStatus(id))
            }
            setUpdating(false);
        }
    }


    return (
        <div>
            <button className={`deleteButton normal--btn ${(userActive ? 'cancel-class' : 'deleted-class')}`} onClick={handleClick}>
                {(userActive) ? "Active" : "Inactive"}
            </button>
        </div>
    );
  }


const columns: GridColDef[] = [
    { field: 'email', headerName: 'Email', type: 'string',sortable: false, width: 320 },
    { field: 'firstName', headerName: 'First Name', type: 'string',sortable: false, width: 220 },
    { field: 'lastName', headerName: 'Last Name', type: 'string', sortable: false,width: 220 },
    { field: 'fullName', headerName: 'Full Name', type: 'string',sortable: false,  width: 300 },
    { field: 'id', headerName: 'ID', width: 0, hide: true },
    { field: 'handle', headerName: 'Handle', type: 'number', width: 200, align: 'left',sortable: false, headerAlign: 'left' },

    // {
    //     field: 'date',
    //     headerName: 'Created on',
    //     type: 'date',
    //     width: 220,
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <>
    //             <label>{moment(params.row.date).format('D.MM.YYYY, h:mm a')}</label>
    //         </>
    //     ),
    // },

    // {
    //     field: 'status',
    //     headerName: 'Status',
    //     type: 'string',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 180,
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <>
    //             <label className={params.row.id % 2 == 0 ? 'active-class' : 'suspended-class'}>
    //                 {params.row.id % 2 == 0 ? 'Active' : 'Suspended'}
    //             </label>
    //         </>
    //     ),
    // },
    // {
    //     field: 'edit',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'edit-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <Link to={`/admin/show/${params.row.id}/edit/`}>
    //             <span >Edit</span>
    //         </Link>
    //     ),
    // },
    {
        field: 'view',
        headerName: '',
        type: 'actions',
        align: 'left',
        headerAlign: 'left',
        width: 100,
        sortable: false,
        cellClassName: 'view-actions',
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Link to={`/admin/creators/${params.row.id}`}>
                <span><ArrowRightAltIcon/></span>
            </Link>
        ),
    },
    // {
    //     field: 'userActiveStatus',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'delete-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <>
    //         {/* <button className='deleteButton normal--btn deleted-class'>
    //             Delete
    //         </button> */}
    //         <UpdateCreatorStatus params={params} />
    //         </>
    //     ),
    // },
    // {
    //     field: 'delete',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'delete-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <button className='deleteButton normal--btn deleted-class'>
    //             Delete
    //         </button>
    //     ),
    // },
];


interface creatorListContentDataTableProps {
    creatorListContent: Array<creatorListContentDataModifiedObj>;
    offsetValue:any;
    setOffsetValue:any;
    maxNumber:number;
    searchValue: string;
    setSearchValue: any;
    loader:boolean;
    currentPage:number;
}

export default function CreatorListDataTable({ creatorListContent, offsetValue, setOffsetValue, maxNumber, searchValue, setSearchValue, loader,currentPage }: creatorListContentDataTableProps) {
    const [rows, setRows] = React.useState<Array<creatorListContentDataModifiedObj>>(creatorListContent);

    const [searchTimerReset , setSearchTimerReset ] = React.useState<boolean>(false);
    const [userInput , setUserInput ] = React.useState<any>("");
    const [searchExists , setSearchExists] = React.useState<boolean>(true);
    const [timerInstance, setTimerInstance ] = React.useState<any>();

    const dispatch = useDispatch();

    // const requestSearch = (search: string) => {
    //     if (searchValue !== search) {
    //         dispatch(CreatorSearchText(search))
    //         setSearchValue(search);
    //         setOffsetValue(0);
    //     }
    // };


    const updateSearch = () =>{
        dispatch(CreatorSearchText(userInput ?? ""));
        dispatch(CreatorCurrentPage(0))
        setSearchValue(userInput);
        setOffsetValue(0);
    };

    useEffect(() => {
        if (timerInstance){
            let check = clearTimeout(timerInstance);
        }
        if (!searchExists){
            let timer1 = setTimeout(() => updateSearch(), 1600);
            setTimerInstance(timer1)
        }
    },[searchTimerReset])

    const requestSearch = (search: string) => {
        if (searchValue !== search) {
            setUserInput(search);
            setSearchExists(false);
            setSearchTimerReset(!searchTimerReset);
        }
    };

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
      ]);

    useEffect(() => {
        setRows(creatorListContent);
    }, [creatorListContent]);

    function NoRowsOverlay() {
        return (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No result found
          </Stack>
        );
      }

    return (
        <>
            {/* <div style={{ height: 500, width: '100%', marginTop: '22px' }}> */}
            <div style={{ height: 810, width: '100%'}}>
                <DataGrid
                    className="datatable__custom"
                    disableColumnMenu
                    components={{  Toolbar: QuickSearchToolbar, NoRowsOverlay}}
                    rows={rows}
                    page={currentPage}
                    loading={loader}
                    columns={columns}
                    pageSize={10}
                    rowCount={maxNumber}
                    onPageChange={(params) => {
                        {dispatch(CreatorCurrentPage(params))}
                        if((params*10) > offsetValue ){
                            if ((((params+1)*10) <= creatorListContent.length) || (creatorListContent.length === maxNumber)){
                            }
                            else{
                                setOffsetValue((params)*10)
                            }
                        }
                    }}
                    componentsProps={{
                        toolbar: {
                            value: searchExists ? searchValue : userInput,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
}
