import { RESET, UPDATE_MATCH_LIST_CONTENT_DATA, ADD_MATCH_LIST_CONTENT_DATA, SAVE_MATCH_SEARCH_TEXT, SAVE_MATCH_CURRENT_PAGE, SAVE_TOTAL_MATCH } from '../../constants';
import { matchListContentDataModifiedObj } from '../../data/mappers/matchList';
import { UpdateMatchListContentAction, AddMatchListContentAction } from './types';

export const UpdateMatchListContent = (data: Array<matchListContentDataModifiedObj>): UpdateMatchListContentAction => ({
    type: UPDATE_MATCH_LIST_CONTENT_DATA,
    data,
});

export const AddMatchListContent = (data: Array<matchListContentDataModifiedObj>): AddMatchListContentAction => ({
    type: ADD_MATCH_LIST_CONTENT_DATA,
    data,
});


export const MatchSearchText = (data:  string) => ({
    type: SAVE_MATCH_SEARCH_TEXT,
    data,
});

export const MatchCurrentPage = (data:  number) => ({
    type: SAVE_MATCH_CURRENT_PAGE,
    data,
});

export const TotalMatchSave = (data:  number) => ({
    type: SAVE_TOTAL_MATCH,
    data,
});

export const MatchResetAction = () => ({
    type: RESET
});

