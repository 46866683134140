import React, { useEffect } from 'react'
import LeftBarContainer from '../common/LeftBar.Component'
import ProfileFields from '../profile/ProfileFields.Component'

export default function ProfileSetUp() {

  useEffect(() => {
    document.body.className = 'signin__setup';
    return () => { document.body.className = ''; }
  });

  return (
    <section aria-label='profile-section' className='minh--100vh bg--dark'>
      <div className='authentication__wrapper grid__container grid-md-1-2-column'>
          <LeftBarContainer />
          <ProfileFields />
      </div>
    </section>
  )
}
