import { Box, FormControl, FormControlLabel } from '@mui/material';
import { Field, reduxForm } from 'redux-form';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar } from '../../store/alerts';
import {  renderDateField } from '../form/Fields';

import json2csv from "json2csv";
import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../data/helpers/services';
import { CONSUMER_ADMIN_API, } from '../../constants';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from "react-csv";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';


import { useNavigate } from 'react-router-dom';
import GoToTop from '../common/GoTop'


const validate = (values: any) => {
    const errors: any = {};
    return errors;
};

const ConsumerCustomData = (props: any) => {
    const { handleSubmit, change, submitting, reset } = props;

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [csvData, setCSVData] = useState<any>();
    const [csvExist, setCSVExist] = useState<boolean>(false);



    const user = useSelector((state: RootStateType) => state.user);
    const token = user.access_token;


    const sendRequest = async (values: any) => {
        if (!values.startDate){
            values.startDate = new Date();
        }
        if (!values.endDate){
            values.endDate = new Date();
        }


        let valid = true;
        if (values.startDate > values.endDate){
            dispatch(ShowErrorAlert({visible:true, message: "End date cannot be smaller than start date"}));
            valid = false;
        }

        if (valid){


            dispatch(ShowProgressBar());
            let data = {start_date:values.startDate, end_date:values.endDate}
            let response = await postRequest(CONSUMER_ADMIN_API.REPORT, data, token);

            if (response?.status === 200) {
                if (response?.data.data.length >0){
                    const { Parser } = require('json2csv');

                    const json2csvParser = new Parser();
                    const csvConvertedData = json2csvParser.parse(response?.data.data);
                    setCSVData(csvConvertedData)
                    setCSVExist(true)
                }else{
                    dispatch(ShowErrorAlert({visible:true, message: "No new data available"}));
                }
            }

            dispatch(HideProgressBar());
            }
        };

    const checkAvialable = () => {
        if (csvExist) {
            return true;
        }
        return false;
    }


    const handleReset = () => {
        setCSVExist(false);
    }

    return (
        <>
          <Box className="dashboard__topbar">
                <Box className="button__text__navigate">
                    <button onClick={() => navigate(-1)}><ArrowBackOutlinedIcon /></button>
                    <Box className="breadcrumb__title dashboard__topbar--heading" component="h2">Export User Details</Box>
                </Box>
            </Box>
            <Box className="dashboard__content pt--1">
                <Box className="col-12 md-col-8 m-b-20">
                        {/* <Box className="button__box" style={{display: 'block'}}>{buttonBox}</Box> */}
                </Box>
                <Box className='dashboard__content max--760 mx--auto'>
                    <Box component="form" onSubmit={handleSubmit(sendRequest)}>
                        <Box component="div" className="row">
                            <div className='col-12 md-col-6 m-b-20 overflow--hidden'>
                                <label className='input--label m-b-10' htmlFor="startDate">Start Date</label>
                                    <FormControl fullWidth>
                                        <Field component={renderDateField} name="startDate" disablePrevious={false} disableFuture={true} disabled={checkAvialable()}/>
                                    </FormControl>
                            </div>

                            <div className='col-12 md-col-6 m-b-20 overflow--hidden'>
                                <label className='input--label m-b-10' htmlFor="endDate">End Date</label>
                                <FormControl fullWidth>
                                    <Field component={renderDateField} name="endDate" disablePrevious={false} disableFuture={true} disabled={checkAvialable()}/>
                                </FormControl>
                            </div>

                        {

                         csvExist ? "" :
                         <div className="col-12 md-col-12 m-b-40">
                            <button type="submit" className={`btn--pill saveBtn`}>
                                Generate CSV
                            </button>
                        </div>
                        }
                        </Box>
                    </Box>
                {
                    csvExist ?
                        <>
                        <div className="row">
                            <div className="col-12 md-col-3 m-b-40">
                                <CSVLink
                                    data={csvData}
                                    filename= {new Date() + ".csv"}
                                    // className="btn btn-primary"
                                    // target="_blank"
                                    >
                                    <button type="button" className={`btn--pill saveBtn`}>
                                        Download
                                    </button>
                                </CSVLink>
                            </div>

                            <div className="col-12 md-col-4 m-b-40">
                            </div>

                            <div className="col-12 md-col-3 m-b-40">
                                <button type="button" className={`btn--pill saveBtn`} onClick={handleReset}>
                                    Reset
                                </button>
                            </div>
                        </div>
                        </>
                    :
                    ""
                }
                </Box>

            </Box>
            <GoToTop/>
        </>
    );
};

// export default ConsumerCustomData;

export default reduxForm({
    form: 'ConsumerCustomData',
    validate,
})(ConsumerCustomData);
