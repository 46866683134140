import { Reducer } from 'react';
import { UPDATE_ADMIN_LIST_CONTENT_DATA, ADD_ADMIN_LIST_CONTENT_DATA, SAVE_ADMIN_SEARCH_TEXT, SAVE_ADMIN_CURRENT_PAGE, SAVE_TOTAL_ADMIN, RESET } from '../../constants';
import { AdminListActions, AdminListState } from './types';

export const initialState: AdminListState = {
    adminListContent: [],
    searchedValue: "",
    currentPage: 0,
    totalAdmin: 0
};

export const adminListReducer: Reducer<AdminListState, AdminListActions> = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ADMIN_LIST_CONTENT_DATA:
            return {
                ...state,
                adminListContent: [...(action?.data || [])],
            };

        case ADD_ADMIN_LIST_CONTENT_DATA:
            return {
                ...state,
                // creatorListContent: [...state.creatorListContent, ...(action?.data || [])],
                adminListContent: state.adminListContent.concat(action?.data),
            };

        case SAVE_ADMIN_SEARCH_TEXT:
            return {
                ...state,
                // adminListContent: state.adminListContent,
                searchedValue : action.data
            };

        case SAVE_ADMIN_CURRENT_PAGE:
            return {
                ...state,
                currentPage : action.data
            };

        case SAVE_TOTAL_ADMIN:
            return {
                ...state,
                totalAdmin : action.data
            };

        case RESET:
            return {
                ...initialState //Always return the initial state
            };

        default:
            return state;
    }
};
