import React, { useEffect, useState } from 'react';
import { Box, FormControl } from '@mui/material';

import { createCustomPush } from '../../data/mappers/customPush';

import { SEND_PUSH, PODCAST_API, CREATOR_API, PACKAGES_API } from '../../constants';


import {  renderCreatorsFollowingField, renderSpecificUserField, renderShowFollowingField, renderPackageField } from '../form/Fields';

import { Field, reduxForm } from 'redux-form';
import { TextArea, TextField } from '../common/TextField';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { RootStateType } from '../../store';
import { getRequest, postRequest } from '../../data/helpers/services';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import { IndeterminateCheckbox } from '../common/IndeterminateCheckbox'



interface creator{
    id: string;
    handle: string;
    firstName: string;
    lastName: string;
    fullName: string;
}

interface appUser{
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
}

interface show{
    id: string;
    title: string;
}

interface CustomPushForm {
    title:string;
    description:string;
    creator: creator[];
    appUser: appUser[];
}


const validate = (values: CustomPushForm) => {
    const errors: any = {};

    if (!values.title || (values.title && !values.title.trim())) {
        errors.title = 'Field is required.';
    } else if (values.title.trim().length > 48) {
        errors.title = 'Field value must be 48 characters or less.';
    }

    if (!values.description || (values.description && !values.description.trim())) {
        errors.description = 'Field is required.';
    } else if (values.description.trim().length > 100) {
        errors.description = 'Field value must be 100 characters or less.';
    }

    return errors;
};

const CustomPushForm = (props: any) => {
    const { handleSubmit, change } = props;
    let token = useSelector((state: RootStateType) => state.user.access_token);

    const [ creatorFollowingList, setCreatorFollowingList ] = useState<creator[]>();
    const [ creatorFollowingListValue, setCreatorFollowingListValue ] = useState<creator[]>();
    const [ checked, setChecked ] = useState<boolean[]>([true, false]);

    const [ promotionalPush, setPromotionalPush ] = useState<boolean>(true);

    const [ packageList, setPackageList ] = useState<show[]>();
    const [ packageListValue, setPackageListValue ] = useState<show[]>();


    const [ specificUserListValue, setSpecificUserListValue] = useState<appUser[]>();

    const [ showFollowingList, setShowFollowingList ] = useState<show[]>();
    const [ showFollowingListValue, setShowFollowingListValue] = useState<show[]>();

    const [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const [options, setOptions] = useState<appUser[]>([]);

    let navigate = useNavigate();
    const dispatch = useDispatch();


    const getPackageList = async () => {
        let response = await postRequest(PACKAGES_API.LIST,{}, token);
        if (response) {
            let formattedPackageContentData = response.data?.data as any,
            formattedPackageContent: show[] = [];
            formattedPackageContentData.forEach((x) => {
                if (x.is_active){
                    formattedPackageContent.push({
                        id: x.id,
                        title:x.gift_quantity + " Runs - (" +x.sku +")",
                    });
                }
            });
           setPackageList(formattedPackageContent);

        } else {
            console.log('Unable to get list');
        }

    }

    const getCreatorFollowingList = async () => {
        let response = await getRequest(CREATOR_API.LIST, token);
        if (response) {
            let creators = response?.data?.data;
            let creatorList: creator[] = [];
            if (creators && creators.length) {
                creators.map((creator: creator) => creatorList.push({
                    id: creator.id, handle: creator.handle, fullName: ((creator?.firstName ?? "") + ' ' + (creator?.lastName ?? "")),
                    firstName: creator.firstName,
                    lastName: creator.lastName
                }));
            }
            setCreatorFollowingList(creatorList);

        } else {
            console.log('Unable to get list');
        }

    }

    const getShowFollowingList = async () => {
        const res: any = await postRequest(PODCAST_API.PUSH_SERIES_LIST, {}, token);
        if (res) {
            let shows = res?.data?.data;
            let showsList: show[] = [];
            if (shows && shows.length) {
                shows.map((show: show) => showsList.push({
                    id: show.id, title: show.title
                }));
            }
            setShowFollowingList(showsList);
        } else {
            console.log('Unable to get list');
        }
    }


    const initForm = () => {
        getPackageList();
        getCreatorFollowingList();
        getShowFollowingList();
        // setChecked([true, false]);
        // setCreatorFollowingListValue([]);
        // setSpecificUserListValue([]);
        // setOptions([]);
        // change('title', "");
        // change('description', "");
        // change('specificUser',"")
    }

    useEffect(() => {
        initForm();
    }, []);

    useEffect(() => {
        if (!open) {
            setOptions([])
        }
    }, [open]);


    const validateBeforeAdd = (value: any) => {
        let userOnly = false;
        if (checked[0] || checked[1]) {
            userOnly = true
        }
        if ((!checked[0]) && (!checked[1]) && (!value.specificUser)) {
            dispatch(ShowErrorAlert({visible:true, message: "Notification destination device or user is required."}));
            return false;
        }
        else if (!userOnly && (value.specificUser?.length === 0)){
            dispatch(ShowErrorAlert({visible:true, message: "Notification destination device or user is required."}));
            return false;
        }
        else if ((!promotionalPush) && (!value.packageChoose)){
            dispatch(ShowErrorAlert({visible:true, message: "Package selection is required."}));
            return false;
        }
        else{
            return true;
        }
    }

    const saveCustomPush = async (values: any) => {
        let validated = validateBeforeAdd(values)
        if (validated){
            dispatch(ShowProgressBar());

            let notificationTypes = (checked[0] && checked[1]) ? "1" : (checked[0]) ? "3" :  (checked[1]) ?  "2" : "4"

            let data = createCustomPush(values, notificationTypes, promotionalPush);
            var response: any = null;

            response = await postRequest(SEND_PUSH, data, token);

            dispatch(HideProgressBar());
            // initForm();
            if (response) {
                const resData = response.data.message;
                let message = "Custom Push Notification sent succesfully."
                dispatch(ShowSuccessAlert({visible:true, message: (resData ?? message)}));
            }
        }
    };


    return (
        <>
            <Box className="dashboard__topbar">
                <Box className="">
                    <Box className="breadcrumb__title dashboard__topbar--heading" component="h2">Create New Notification</Box>
                </Box>
            </Box>
            <Box className='dashboard__content max--760 mx--auto'>
                <Box component="form" onSubmit={handleSubmit(saveCustomPush)}>
                    <Box component="div" className="row">
                        <Box className='col-12 md-col-12 m-b-20'>
                            <label className='input--label m-b-10' htmlFor="title">Title (max 48 chars)</label>
                            <Field name="title" component={TextField} type="text" placeholder='Write your title here'
                                styleName='input--transparent' />
                        </Box>
                        <Box className='col-12 md-col-12 m-b-20'>
                            <label className='input--label m-b-10' htmlFor="description">Content (max 100 chars)</label>

                            <Field name="description" component={TextArea} row='2' type="text" placeholder='Write your content here'
                            styleName='input--transparent' />

                        </Box>

                        <Box className='col-12 md-col-12 m-b-20'>
                            <Box className='fieldset__custom__react'>
                                <label className='input--label custom--legend m-b-10' htmlFor="description">Notification Type</label>

                                <Box className="row">
                                    <Box className="col-12 md-col-6 m-b-10">
                                        <FormControlLabel control={
                                            <Checkbox checked={promotionalPush}/>
                                            } label= "Promotional Push"
                                            className={"checkbox--white"}
                                            onChange={(event) => {
                                                if(!promotionalPush){
                                                    change('packageChoose',"")
                                                }
                                                setPromotionalPush(!promotionalPush);
                                            }
                                        }
                                        />
                                    </Box>
                                    <Box className="col-12 md-col-4 m-b-10">
                                        <FormControlLabel control={
                                            <Checkbox checked={!promotionalPush}/>
                                        } label= "Package Based"
                                        className={"checkbox--white"}
                                        onChange={(event) => {
                                            if(!promotionalPush){
                                                change('packageChoose',"")
                                            }
                                            setPromotionalPush(!promotionalPush);
                                        }}
                                        />
                                    </Box>
                                </Box>

                                <FormControl className={`input-inner-transparent m-b-10${promotionalPush ? " disabled--event" : ""}`} fullWidth>
                                    <label className='input--label m-b-10' htmlFor="description">Choose Package</label>
                                    <FormControl className='input-inner-transparent' fullWidth>
                                        <Field name="packageChoose" component={renderPackageField} id="package-selection"  options={packageList} defaultValue={' '} setPackageListValue={setPackageListValue}/>
                                        {/* <Field name="specificUser" component={renderSpecificUserField} id="specific-users" options={specificUserList} defaultValue={' '} setSpecificUserListValue={setSpecificUserListValue} getUserSpecificList={getUserSpecificList} searchUser={searchUser} setSearchUser={setSearchUser} /> */}
                                    </FormControl>
                                </FormControl>
                            </Box>
                        </Box>

                        <Box className='col-12 md-col-12 m-b-20'>
                            <Box className='fieldset__custom__react'>
                                <label className='input--label custom--legend m-b-10' htmlFor="description">Reciever Type</label>

                                <Field name={`m-b-10`} component=
                                {IndeterminateCheckbox}  checked={checked} setChecked={setChecked} specificUserListValue={specificUserListValue} />

                                <FormControl className={`input-inner-transparent m-b-10${checked[0] || checked[1] ? " disabled--event" : ""}`} fullWidth>
                                    <label className='input--label m-b-10' htmlFor="description">Specific users</label>
                                    <FormControl className='input-inner-transparent' fullWidth>
                                        <Field name="specificUser" component={renderSpecificUserField} id="specific-users"  defaultValue={' '} setSpecificUserListValue={setSpecificUserListValue} open={open} setOpen={setOpen} options={options} setOptions={setOptions} token={token} loading={loading} setLoading={setLoading}/>
                                        {/* <Field name="specificUser" component={renderSpecificUserField} id="specific-users" options={specificUserList} defaultValue={' '} setSpecificUserListValue={setSpecificUserListValue} getUserSpecificList={getUserSpecificList} searchUser={searchUser} setSearchUser={setSearchUser} /> */}
                                    </FormControl>
                                </FormControl>
                            </Box>
                        </Box>

                        <Box className='col-12 md-col-12 m-b-20'>
                            <Box className='fieldset__custom__react'>
                                <label className='input--label custom--legend m-b-10' htmlFor="description">Users Following</label>
                                <FormControl className={`input-inner-transparent m-b-10${showFollowingListValue?.length ? " disabled--event" : ""}`} fullWidth>
                                    <label className='input--label m-b-10' htmlFor="description">Creators</label>
                                    <FormControl className='input-inner-transparent' fullWidth>
                                        <Field name="creatorFollowed" component={renderCreatorsFollowingField} id="follwoing-creator" options={creatorFollowingList} defaultValue={' '} setCreatorFollowingListValue={setCreatorFollowingListValue}/>
                                    </FormControl>
                                </FormControl>

                                <FormControl className={`input-inner-transparent m-b-10${creatorFollowingListValue?.length ? " disabled--event" : ""}`} fullWidth>
                                    <label className='input--label m-b-10' htmlFor="description">Shows</label>
                                    <FormControl className='input-inner-transparent' fullWidth>
                                        <Field name="showFollowed" component={renderShowFollowingField} id="following-show" options={showFollowingList} defaultValue={' '} setShowFollowingListValue={setShowFollowingListValue}/>
                                    </FormControl>
                                </FormControl>
                            </Box>
                        </Box>

                        <Box className="col-12 m-b-40">
                            <button type="submit" className={`btn--pill saveBtn`}>
                                Send
                            </button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};


export default reduxForm({
    form: 'customPush',
    validate,
})(CustomPushForm);


