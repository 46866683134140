import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { ADMIN_API } from '../../constants';
import { postRequest, putRequest } from '../../data/helpers/services';
import { adminProfileApiMapper } from '../../data/mappers/user';
import { RootStateType } from '../../store';
import { Button } from '../common/Button';
import { TextField } from '../common/TextField';
import { PasswordDialog, CancelEditConfirmationDialog } from '../common/PassDialog';
// @ts-ignore

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';


import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import { PermissionsCheckbox } from '../common/IndeterminateCheckbox'


import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface AdminProfileFormInterface {
    firstName: string;
    lastName: string;
    email: string;
    permissions: object;
    password: string;
}

const validate = (values: AdminProfileFormInterface) => {
    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-])+(?:\.[a-zA-Z0-9-]+)*$/;
    const errors: any = {}

    if (!values.firstName || (values.firstName && !values.firstName.trim())) {
        errors.firstName = 'Field is required.';
    } else if (values.firstName && values.firstName.trim().length > 32) {
        errors.firstName = 'Field value must be 32 characters or less.';
    }

    if (!values.email || (values.email && !values.email.trim())) {
        errors.email = 'Field is required.';
    } else if(!(values.email).match(validRegex)){
        errors.email = 'Please enter valid email address.';
    }
    // else if (values.email && values.email.trim().length > 32) {
    //     errors.email = 'Field value must be 32 characters or less.';
    // }

    if (!values.password || (values.password && !values.password.trim())) {
        errors.password = 'Field is required.';
    }
    else if (values.password && values.password.trim().length < 8) {
        errors.password = 'Password must contains at least 8 characters.';
    } else if (values.password && values.password.trim().length > 32) {
        errors.password = 'Field value must be 32 characters or less.';
    }

    if (!values.lastName || (values.lastName && !values.lastName.trim())) {
        errors.lastName = 'Field is required.';
    } else if (values.lastName && values.lastName.trim().length > 32) {
        errors.lastName = 'Field value must be 32 characters or less.';
    }

    return errors
}

const AdminProfileForm = (props:any) => {
    const { handleSubmit, change, submitting, reset, back  } = props;
    const { id } = useParams();

    const user = useSelector((state: RootStateType) => state.user);
    const [adminDetails, setAdminDetails] = useState<AdminProfileFormInterface | null>(null);

    const [ modeType, setModeType] = useState<string | undefined>(props.modeType);

    const [ match, setMatch] = useState<boolean>(false);
    const [ show, setShow] = useState<boolean>(false);
    const [ push, setPush] = useState<boolean>(false);
    const [ appVersion, setAppVersion] = useState<boolean>(false);
    const [ creator, setCreator] = useState<boolean>(false);
    const [ deleteShow, setDeleteShow] = useState<boolean>(false);

    const [resetForm, setResetForm] = useState<boolean>(false);

    const [ newPassword, setNewPassword ] = useState<string | "">("");

    const permissions = {
        match:match,
        show:show,
        push:push,
        appVersion:appVersion,
        creator:creator,
        deleteShow:deleteShow,

        setShow: setShow,
        setMatch: setMatch,
        setPush: setPush,
        setAppVersion: setAppVersion,
        setCreator:setCreator,
        setDeleteShow:setDeleteShow,
    }


    const token = user.access_token;
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const saveProfile = async (data: any) => {
        dispatch(ShowProgressBar());
        const apiData = adminProfileApiMapper(data, permissions);

        if (newPassword){
            apiData.password = newPassword;
        }


        let response: any = ""
        if (adminDetails){
            response = await putRequest(ADMIN_API.EDIT, apiData, token);
        }else{
            response = await postRequest(ADMIN_API.CREATE, apiData, token);
        }
        if (response) {
            const resData = response.data;
            dispatch(HideProgressBar());
            if (resData.code === 200) {
                dispatch(ShowSuccessAlert({visible:true, message: "Admin " + ((adminDetails) ? "updated" : "added") + " successfully."}));

                if (modeType === "edit"){
                    setResetForm(false)
                    fillAdminDetails(adminDetails);
                    setModeType('detail')
                }
                else {
                    navigate('/admin/admin', { replace: true })
                }
            }
        }
    }

    const initForm = () => {
    }

    useEffect(() => {
        initForm();
    }, []);

    useEffect(() => {
        if(modeType === 'add') {
            initForm();
        } else if (modeType === 'detail') {
            getAdminDetails(id ?? "");
        }
    }, [modeType]);

    useEffect(() => {
        if (id && ((modeType === 'edit') || (modeType === 'detail'))) {
            getAdminDetails(id);
        }
    }, [id]);

    useEffect(() => {
        if (adminDetails && ((modeType === 'edit') || (modeType === 'detail'))) {
            fillAdminDetails(adminDetails);
        }
    }, [adminDetails]);


    const getAdminDetails = async (id: string) => {
        dispatch(ShowProgressBar());

        const response = await postRequest(
            ADMIN_API.DETAIL,
            {
                admin_id: id,
            },
            token
        );

        if (response) {
            if (response.data.code === 200) {
                setAdminDetails(response.data.data);
            }
        }

        dispatch(HideProgressBar());
    };


    const fillAdminDetails = async (adminDetails:any) => {
        change('id',adminDetails.id);
        change('lastName', adminDetails?.lastName);
        change('firstName', adminDetails?.firstName);
        change('email', adminDetails?.email);
        setMatch(adminDetails?.permissions.match);
        setShow(adminDetails?.permissions.series);
        setPush(adminDetails?.permissions.push);
        setAppVersion(adminDetails?.permissions.app_version);
        setCreator(adminDetails?.permissions.creator);
        setDeleteShow(adminDetails?.permissions.series_delete);
    }


    const updateModeType = () => {
        if (modeType === "detail"){
            setModeType("edit");
        }
        else{
            if ((newPassword !== undefined) && (newPassword !== "")){
                setResetForm(true);
            }
            else{
                setModeType("detail");
            }
        }
    }

    const buttonBox = (
        <>
        <Button styleName='btn--pill btn--pill button--outlined active' type='button' onClick={updateModeType}>
            {modeType === "detail" ? "Edit" : "Cancel" }
        </Button>
        </>
    );

    return (
        <>
        <Box className="dashboard__topbar">
            <Box className="row align--items--center">
                <div className="md-col-8 mb--20 mb--md--0">
                        <Box className="button__text__navigate">
                            {
                            (modeType !== "edit") ?
                                <button onClick={() => navigate(-1)}><ArrowBackOutlinedIcon /></button>
                            :
                            <span></span>
                            }
                            {/* <Box className="dashboard__topbar--heading" component="h2">Show Details</Box> */}
                            <Box className="breadcrumb__title dashboard__topbar--heading" component="h2">
                                {(modeType === "add") ? "Add Admin" : (modeType === "edit") ? "Edit Admin" : "Admin Details"}
                            </Box>
                        </Box>
                </div>
                {/* <div className="md-col-8">
                    <Box className="dashboard__topbar--heading col-12" component="h2">
                        {(modeType === "add") ? "Add Admin" : (modeType === "edit") ? "Edit Admin" : "Admin Details"}
                        </Box>
                </div> */}
                {(modeType !== "add") ?
                <div className="md-col-4">
                    <Box className="button__box">{buttonBox}</Box>
                </div>
                : ""
                }
            </Box>
        </Box>

        {
            resetForm ?
            <CancelEditConfirmationDialog setModeType={setModeType} setResetForm={setResetForm} formId={"adminForm"} />
            : ""
        }

        <Box className='dashboard__content max--760'>
            <form id="adminForm" onSubmit={handleSubmit(saveProfile)}>
                <div className="row">

                    <div className='col-12 md-col-6 m-b-40'>
                        <label className='input--label m-b-10' htmlFor="firstName">First Name</label>
                        <Field name="firstName" component={TextField} type="text" placeholder='Write First Name'
                            styleName='input--transparent' disabled={modeType==="detail"} />
                    </div>
                    <div className='col-12 md-col-6 m-b-40'>
                        <label className='input--label m-b-10' htmlFor="lastName">Last Name</label>
                        <Field name="lastName" component={TextField} type="text" placeholder='Write Last Name'
                            styleName='input--transparent' disabled={modeType==="detail"} />
                    </div>


                    <div className='col-12 md-col-6 m-b-40'>
                        <label className='input--label m-b-10' htmlFor="email">Email</label>
                        <Field name="email" component={TextField} type="text" placeholder='Write Email here'
                            styleName='input--transparent' disabled={modeType==="detail"} />
                    </div>

                    {
                        (modeType ==="add")
                        ?
                        <div className='col-12 md-col-6 m-b-40'>
                            <label className='input--label m-b-10' htmlFor="password">Password</label>
                            <Field name="password" component={TextField} type="password" placeholder='Write Password here'
                                styleName='input--transparent' />
                        </div>
                        :
                        (modeType ==="edit")
                        ?
                        <div className="col-12 md-col-6 m-b-40">
                            <label className='input--label m-b-10' htmlFor="password">Password</label>
                            <PasswordDialog handleSubmit={handleSubmit} setNewPassword={setNewPassword} />
                        </div>
                        :
                        <div className="col-12 md-col-6 m-b-40">
                        </div>
                    }

                    <div className='col-12 md-col-12 m-b-40' >
                        <div className='fieldset__custom__react'>
                            <label className='input--label custom--legend m-b-10' htmlFor="description">Permissions</label>

                            <Field name={`m-b-12`} component=
                            {PermissionsCheckbox}  permissions={permissions}  modeType={modeType}/>

                        </div>
                    </div>
                    {
                    (modeType !== "detail")
                    ?

                    <div className="col-12 m-b-20">
                        <Button styleName='saveBtn' type='submit' disabled={submitting}>
                            {showSubmitButtonLabel(submitting, modeType ?? "detail")}
                        </Button>
                    </div>
                    :
                    ""
                    }
                </div>
            </form>
        </Box>
        </>
    );

}

const showSubmitButtonLabel = (submitting:boolean, modeType:string) => {
    if (submitting) {
        return "Saving...";
    } else {
        return (modeType === "add") ? "Add new admin" : "Save";
    }
}

export default reduxForm({
    form: 'profile',
    validate
})(AdminProfileForm)
