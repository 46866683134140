import React from 'react'

export default function ShowIcon() {
  return (
      <>
        <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5833 18.5H3.91667C2.30083 18.5 1 17.1992 1 15.5833V8.29167C1 6.67583 2.30083 5.375 3.91667 5.375H15.5833C17.1992 5.375 18.5 6.67583 18.5 8.29167V15.5833C18.5 17.1992 17.1992 18.5 15.5833 18.5" stroke="#C2C1E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.83313 21.4165H12.6665" stroke="#C2C1E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.75 5.375L14.125 1" stroke="#C2C1E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.75 5.375L5.375 1" stroke="#C2C1E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </>
  )
}


