export const createShow = (data: any, isTopPick: string) => {
    let show:any = {
        title: data.title.trim(),
        description: data.description.trim(),
        logo: data.thumbnail ?? "",
        host_id:data.host.id.toString() ?? "",
        isTopPick: isTopPick,
        displayOrder: (isTopPick==="1") ? (data.displayOrder ?? "0").toString() : "0",
        topPickLogo: data.featuredImage ?? "",
    }
    if(data.id) {
        show.id = data.id;
    }

    if (data.coHost) {
        show.co_host_id_list = data.coHost.map(({id}) => id.toString())
    }

    return show;
}
