import React from 'react'
import ProfileForm from './ProfileForm';

const ProfileFields = (props: any) => {
    return (
        <div className='login__innerarea'>
            <div className='login__textfiled'>
                <h1 className='login--heading'>Profile Setup</h1>
                <h2 className='login--text'>Complete your profile information</h2>
                { //@ts-ignore
                <ProfileForm redirect={true} />}
            </div>
        </div>
    )
}

export default ProfileFields;
