import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import { episodeListContentDataModifiedObj } from '../../data/mappers/episodeList';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../store';
import { PODCAST_API } from '../../constants';
import { postRequest } from '../../data/helpers/services';
import { useDispatch } from 'react-redux';
import { ShowSuccessAlert, ShowProgressBar, HideProgressBar } from '../../store/alerts';

import { UpdateEpisodeStatusContent, DeleteEpisodeContent, EpisodeSearchText, EpisodeCurrentPage } from '../../store/episodeList'

import moment from 'moment';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export function SortedDescendingIcon() {
    return <ExpandMoreIcon className="icon" />;
  }

  export function SortedAscendingIcon() {
    return <ExpandLessIcon className="icon" />;
  }


function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{mr: 2}} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}

function AlertDialog(params) {
    const [open, setOpen] = React.useState(false);
    const [inProgress, setInProgress] = React.useState(false);

    let dispatch = useDispatch()

    const handleClickOpen = () => {
      setOpen(true);
    };

    // let token = params.params.row.token;
    let token = useSelector((state: RootStateType) => state.user.access_token);
    let id = params.params.row.id;

    const deleteConfirmed = async() => {
        dispatch(ShowProgressBar());
        setInProgress(true);
        const res: any = await postRequest(PODCAST_API.EPISODE_DELETE, {
            episode_id: id
        }, token);
        dispatch(HideProgressBar());
        if (res) {
            setOpen(false);
            // params.params.row.deleteStatus(true)
            dispatch(ShowSuccessAlert({visible:true, message: "Episode deleted successfully."}));
            dispatch(DeleteEpisodeContent(id))
        }
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <button className='deleteButton normal--btn deleted-class' onClick={handleClickOpen}>
            Delete
        </button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <h4>{"Episode Delete Confirmation"}</h4>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this episode <b>{params.params.row.title}</b> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {
                inProgress
                ?
                    <span>
                        <h4>Deletion in Progress...</h4>
                    </span>
                :
                    <>
                    <button className='deleteButton normal--btn cancel-class' onClick={handleClose}>
                        Cancel
                    </button>

                    <button className='deleteButton normal--btn deleted-class' onClick={deleteConfirmed}>
                        Delete
                    </button>
                    </>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }


function UpdateEpisodeStatus(params) {
    let dispatch = useDispatch()

    const [ updating, setUpdating ] = React.useState<boolean>(false)

    const [ episodeActive, setEpisodeActive] = React.useState<boolean>(params.params.row.isActive)

    let token = useSelector((state: RootStateType) => state.user.access_token);
    let id = params.params.row.id;

    const handleClick = async () => {
        if (!updating){
            setUpdating(true);

            const res: any = await postRequest(PODCAST_API.EPISODE_STATUS_UPDATE, {
                episode_id: id,
                status: (episodeActive ? '2' : "1")
            }, token);
            if (res) {
                dispatch(ShowSuccessAlert({visible:true, message: "Episode status updated successfully"}));
                setEpisodeActive(!episodeActive);
                dispatch(UpdateEpisodeStatusContent(id))
            }
            setUpdating(false);
        }
    }

    return (
        <div>
            <button className={`deleteButton normal--btn ${(episodeActive ? 'active--class' : 'deleted-class')}`} onClick={handleClick}>
                {(episodeActive) ? "Active" : "Inactive"}
            </button>
        </div>
    );
  }


const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 0, hide: true },
    { field: 'title', headerName: 'Title', type: 'string',sortable: false, minWidth: 220, flex: 1 },
    { field: 'authors', headerName: 'Authors', type: 'string',sortable: false, minWidth: 220, flex: 1 },
    // { field: 'description', headerName: 'Description', type: 'string',sortable: false,  width: 220 },
    { field: 'runs', headerName: 'Runs', type: 'string',sortable: false,  minWidth: 100, flex: 1 },
    { field: 'episodeLikeCount', headerName: 'Likes', type: 'number', minWidth: 100, align: 'left', sortable: false,headerAlign: 'left', flex: 1, },
    {
        field: 'date',
        headerName: 'Created on',
        type: 'date',
        sortable: false,
        align: 'left',
        headerAlign: 'left',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
                <label>{moment(params.row.date).format('D.MM.YYYY, h:mm a')}</label>
            </>
        ),
    },
    // {
    //     field: 'status',
    //     headerName: 'Status',
    //     type: 'string',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 180,
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <>
    //             <label className={params.row.id % 2 == 0 ? 'active-class' : 'suspended-class'}>
    //                 {params.row.id % 2 == 0 ? 'Active' : 'Suspended'}
    //             </label>
    //         </>
    //     ),
    // },
    {
        field: 'edit',
        headerName: '',
        type: 'actions',
        align: 'left',
        headerAlign: 'left',
        minWidth: 100,
        flex: 1,
        sortable: false,
        cellClassName: 'edit-actions',
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
            <Link to={`/admin/show/${params.row.showId}/episode/${params.row.id}`}>
                <span>Edit</span>
            </Link>
            </>
        ),
    },
    {
        field: 'view',
        headerName: '',
        type: 'actions',
        align: 'left',
        headerAlign: 'left',
        minWidth: 100,
        flex: 1,
        sortable: false,
        cellClassName: 'view-actions',
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Link to={`/admin/show/episode/detail/${params.row.id}`}>
                <span><ArrowRightAltIcon/></span>
            </Link>
        ),
    },
    // {
    //     field: 'userActiveStatus',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'delete-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <>
    //         {/* <button className='deleteButton normal--btn deleted-class'>
    //             Delete
    //         </button> */}
    //         <UpdateEpisodeStatus params={params} />
    //         </>
    //     ),
    // },
    // {
    //     field: 'delete',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'delete-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         // <button className='deleteButton normal--btn deleted-class'>
    //         //     Delete
    //         // </button>
    //         <AlertDialog params={params} />
    //     ),
    // },
];


interface episodeListContentDataTableProps {
    episodeListContent: Array<episodeListContentDataModifiedObj>;
    deleteAccess: boolean;
    offsetValue:any;
    setOffsetValue:any;
    maxNumber:number;
    searchValue: string;
    setSearchValue: any;
    loader:boolean;
    currentPage:number;
}

export default function EpisodeListDataTable({ episodeListContent, deleteAccess, offsetValue, setOffsetValue, maxNumber, searchValue, setSearchValue, loader, currentPage }: episodeListContentDataTableProps) {
    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState<Array<episodeListContentDataModifiedObj>>(episodeListContent);


    const [searchTimerReset , setSearchTimerReset ] = React.useState<boolean>(false);
    const [userInput , setUserInput ] = React.useState<any>("");
    const [searchExists , setSearchExists] = React.useState<boolean>(true);
    const [timerInstance, setTimerInstance ] = React.useState<any>();


    const dispatch = useDispatch();

    // const requestSearch = (search: string) => {
    //     if (searchValue !== search) {
    //         dispatch(EpisodeSearchText(search))
    //         setSearchValue(search);
    //         setOffsetValue(0);
    //     }
    // };


    const updateSearch = () =>{
        dispatch(EpisodeSearchText(userInput ?? ""));
        setSearchValue(userInput);
        setOffsetValue(0);
        dispatch(EpisodeCurrentPage(0))
    };

    useEffect(() => {
        if (timerInstance){
            let check = clearTimeout(timerInstance);
        }
        if (!searchExists){
            let timer1 = setTimeout(() => updateSearch(), 1600);
            setTimerInstance(timer1)
        }
    },[searchTimerReset])

    const requestSearch = (search: string) => {
        if (searchValue !== search) {
            setUserInput(search);
            setSearchExists(false);
            setSearchTimerReset(!searchTimerReset);
        }
    };


    const [sortModel, setSortModel] = React.useState<GridSortModel>([
      ]);

    useEffect(() => {
        setRows(episodeListContent);
    }, [episodeListContent]);


    const indexOfStatus = columns.findIndex(object => {
        return (
            //@ts-ignore
            object.field === "episodeActiveStatus");
    });
    const indexOfDelete = columns.findIndex(object => {
        return (
            //@ts-ignore
            object.field === "delete");
    });

    if (deleteAccess){
        const showActiveStatusField = ({
            field: 'episodeActiveStatus',
            headerName: '',
            type: 'actions',
            align: 'left',
            headerAlign: 'left',
            minWidth: 100,
            flex: 1,
            sortable: false,
            cellClassName: 'delete-actions',
            renderCell: (params: GridRenderCellParams<Date>) => (
                <>
                {/* <button className='deleteButton normal--btn deleted-class'>
                    Delete
                </button> */}
                <UpdateEpisodeStatus params={params} />
                </>
            ),
        });
        const deleteField = (
            {
                field: 'delete',
                headerName: '',
                type: 'actions',
                align: 'left',
                headerAlign: 'left',
                minWidth: 100,
                flex: 1,
                sortable: false,
                cellClassName: 'delete-actions',
                renderCell: (params: GridRenderCellParams<Date>) => (
                    // <button className='deleteButton normal--btn deleted-class'>
                    //     Delete
                    // </button>
                    <AlertDialog params={params} />
                ),
            }
        )
        var arr = []

        columns.forEach((x) => {
            //@ts-ignore
            arr.push(x.field)
        });

        //@ts-ignore
        if (arr.includes("episodeActiveStatus")){
        }else{
            //@ts-ignore
            columns.splice(-1, 0 ,showActiveStatusField)
        }

        //@ts-ignore
        if (arr.includes("delete")){
        }else{
            //@ts-ignore
            columns.splice(-1, 0 ,deleteField);
        }

    }else{
        if (columns[indexOfDelete]){
            if (columns[indexOfDelete].field === ("delete")){
                columns.splice(indexOfDelete, 1);
            }}

        if (columns[indexOfStatus]){
            if (columns[indexOfStatus].field === ("episodeActiveStatus")){
                columns.splice(indexOfStatus, 1);
            }}

    }

    function NoRowsOverlay() {
        return (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No result found
          </Stack>
        );
      }

    return (
        <>
            <div style={{ height: 500, width: '100%', marginTop: '30px' }}>
                <DataGrid
                    className="datatable__custom"
                    disableColumnMenu
                    components={{ Toolbar: QuickSearchToolbar,ColumnSortedDescendingIcon: SortedDescendingIcon, ColumnSortedAscendingIcon: SortedAscendingIcon, NoRowsOverlay }}
                    rows={rows}
                    columns={columns}
                    // page={currentPage}
                    pageSize={5}
                    rowCount={maxNumber}
                    onPageChange={(params) => {
                        {dispatch(EpisodeCurrentPage(params))}
                        // console.log(params, offsetValue)
                        // if((params*5) > offsetValue ){
                        //     if ((((params+1)*5) <= episodeListContent.length) || (episodeListContent.length === maxNumber)){
                        //     }
                        //     else{
                        //         if(((params+1)%5) === 0) {
                        //             setOffsetValue((params)*5)
                        //         }
                        //     }
                        // }

                        if((params*5) > offsetValue ){
                            if(((params+1)%5) === 0) {
                                setOffsetValue((params)*5)
                            }
                        }
                    }}
                    componentsProps={{
                        toolbar: {
                            value: searchExists ? searchValue : userInput,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
}
