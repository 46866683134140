import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import MatchListDataTable from '../common/MatchListDataTable';

import { getRequest, postRequest } from '../../data/helpers/services';
import { MATCH } from '../../constants';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { matchListContentData, matchListContentDataModified } from '../../data/mappers/matchList';
import { UpdateMatchListContent, AddMatchListContent, TotalMatchSave, MatchCurrentPage } from '../../store/matchList';
import GoToTop from '../common/GoTop'

import { useNavigate } from 'react-router-dom';


const MatchList = (props: any) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [ offsetValue, setOffsetValue ] = useState<number | 0>(0)
    const [ maxNotification, setMaxNotification ] = useState<number | 0 >(0)
    const [ searchValue, setSearchValue ] = useState<string | "">("")
    const [ loader, setLoader ] = useState<boolean>(false)

    const [ compareSearchRedux, setCompareSearchRedux ] = useState<string | "">("")
    const [ currentPage, setCurrentPage ] = useState<number>(0)


    let token = useSelector((state: RootStateType) => state.user.access_token);

    let matchListObject = useSelector((state: RootStateType) => state.matchList);

    const matchListContent = matchListObject.matchListContent

    if ((matchListObject.currentPage) && (matchListObject.currentPage !== currentPage)) {
        setCurrentPage(matchListObject.currentPage);
    }else if ((matchListObject.currentPage === 0) && (currentPage !== 0)) {
        setCurrentPage(0)
    }

    if ((currentPage > 0) && (offsetValue === 0)){
        setOffsetValue(matchListContent.length)
    }

    if ((matchListObject.searchedValue) && (matchListObject.searchedValue !== searchValue)){
        setSearchValue(matchListObject.searchedValue);
    }

    if ((currentPage > 0) && (offsetValue === 0) && (searchValue !== compareSearchRedux)) {
        setOffsetValue(matchListContent.length)
    } else if ((currentPage > 0) && (offsetValue === 0)) {
        setOffsetValue(matchListContent.length)
    }

    useEffect(() => {
        if ((currentPage !== 0) &&
            (((currentPage+1)*10) < matchListContent.length) &&
            (matchListObject.totalMatch >= matchListContent.length) &&
            (searchValue === compareSearchRedux) &&
            (offsetValue !== 0)
            ){
            setMaxNotification(matchListObject.totalMatch)
        } else{
            getMatchListContent(searchValue);
            setCompareSearchRedux(searchValue);
        }

    }, [offsetValue, searchValue]);

    const getMatchListContent = async (searchingValue:string) => {
        let res:any = {}
        if ((searchingValue === (searchValue) || (searchingValue === ""))) {
            setLoader(true);
            res = await postRequest(MATCH.LIST,{
                    "offset": offsetValue,
                    "search_text": searchValue
                }, token);
        }
        if (res) {
            if ((searchingValue === (searchValue) || (searchingValue === ""))) {
                setMaxNotification(res.data?.data.match_count)
                dispatch(TotalMatchSave(res.data?.data.match_count))

                let formattedMatchListContentData = res.data?.data.match_list as matchListContentData,
                    formattedMatchListContent: matchListContentDataModified = [];
                    formattedMatchListContentData.forEach((x) => {

                        let matchStatus = (x.status === "upcoming")
                            ? "Scheduled" : (x.status === "completed") ?
                            "Ended" : "Live";

                        // let matchStatus = (x.status === "upcoming")
                        // ? "1" : (x.status === "completed") ?
                        // "3" : "2";


                        formattedMatchListContent.push({
                            id: x.id,
                            title: x.title,
                            creator: (x.streams[0].user.firstName + " " + x.streams[0].user.lastName),
                            date: x.startTime,
                            // type: x.streams
                            //     .map((streams) => {
                            //         return streams.eventType;
                            //     })
                            //     .join(' '),
                            type: matchStatus,
                            streamtags: x.streams
                                .map((streams) => {
                                    return streams.streamtags
                                        .map((streamtags) => {
                                            return '#' + streamtags;
                                        })
                                        .join(' ');
                                })
                                .join(' '),
                            count: x.count ?? "0",
                            // isEnded: x.isEnded
                    });
                });
                if (!offsetValue){
                    dispatch(MatchCurrentPage(0))
                    dispatch(UpdateMatchListContent(formattedMatchListContent));
                }
                else{
                    dispatch(AddMatchListContent(formattedMatchListContent))
                }
            }
        }
        setLoader(false);
    };

    const buttonBox = (
        <Button
            type="button"
            styleName={`button--outlined active`}
            onClick={() => navigate('/admin/match/create')}
        >Add New Match
        </Button>
    )

    return (
        <>
            <Box className="dashboard__content">
                <Box className="col-12 md-col-8 m-b-20">
                        <Box className="button__box" style={{display: 'block'}}>{buttonBox}</Box>
                </Box>
                <Box>
                    <MatchListDataTable matchListContent={matchListContent} offsetValue={offsetValue} setOffsetValue={setOffsetValue} maxNumber={maxNotification} searchValue={searchValue} setSearchValue={setSearchValue} loader={loader} currentPage={currentPage}/>
                </Box>

            </Box>
            <GoToTop/>
        </>
    );
};

export default MatchList;
