import {
    UPDATE_CONSUMER_LIST_CONTENT_DATA,
    ADD_CONSUMER_LIST_CONTENT_DATA,
    UPDATE_CONSUMER_ACCOUNT_STATUS,
    SAVE_CONSUMER_SEARCH_TEXT,
    SAVE_CONSUMER_CURRENT_PAGE,
    SAVE_TOTAL_CONSUMER,
    RESET
    } from '../../constants';
import { consumerListContentDataModifiedObj } from '../../data/mappers/consumerList';
import { UpdateConsumerListContentAction , AddConsumerListContentAction} from './types';

export const UpdateConsumerListContent = (data: Array<consumerListContentDataModifiedObj>): UpdateConsumerListContentAction => ({
    type: UPDATE_CONSUMER_LIST_CONTENT_DATA,
    data,
});


export const AddConsumerListContent = (data: Array<consumerListContentDataModifiedObj>): AddConsumerListContentAction => ({
    type: ADD_CONSUMER_LIST_CONTENT_DATA,
    data,
});

export const UpdateConsumerAccountStatus = (data:  number) => ({
    type: UPDATE_CONSUMER_ACCOUNT_STATUS,
    data,
});

export const ConsumerSearchText = (data:  string) => ({
    type: SAVE_CONSUMER_SEARCH_TEXT,
    data,
});


export const ConsumerCurrentPage = (data:  number) => ({
    type: SAVE_CONSUMER_CURRENT_PAGE,
    data,
});

export const TotalConsumerSave = (data:  number) => ({
    type: SAVE_TOTAL_CONSUMER,
    data,
});


export const ConsumerResetAction = () => ({
    type: RESET
});
