import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, GridRowParams } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { consumerSpendListContentModifiedObj } from '../../data/mappers/consumerList';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ModelTraining } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


export function SortedDescendingIcon() {
    return <ExpandMoreIcon className="icon" />;
  }

  export function SortedAscendingIcon() {
    return <ExpandLessIcon className="icon" />;
  }


function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{mr: 2}} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}

const durationCalculate = (x) => {
    var durationFinal = "-";
    let duration:number = parseInt(x);
    if (duration >= 0){
        if (duration > 61){
            let durationMinutes= parseInt((duration/60).toString());
            let seconds = (duration%60).toString();
            if (seconds){
                durationFinal =  durationMinutes + " min " + seconds + " sec"
            }
            else{
                durationFinal =  durationMinutes + " min "
            }
        }else{
            durationFinal =  duration.toString() + " sec"
        }
    }
    return durationFinal
}


const columns: GridColDef[] = [
    { field: 'receiveTo',
      headerName: 'Gifted to',
      type: 'string',
      minWidth: 230,
      flex: 1,
      valueGetter: (params) => params.row.receiveTo.title,
      renderCell: (params) => (
          <>
            <Link to={
                (params.row.type === "Podcast") ?
                `/admin/consumer/show/episode/detail/${params.row.receiveTo.id}`
                : (params.row.type === "Creator") ?
                `/admin/creators/${params.row.receiveTo.id}`
                :
                `/admin/match/${params.row.receiveTo.id}`}

            style={{
                display: 'inline-block',
                maxWidth: '100%',
                flexShrink: 0,
                width: '100%',
                }}>
                <span style={{
                whiteSpace: 'nowrap',
                display: 'inline-block',
                maxHeight: 'auto',
                width: 'calc(100% - 10px)',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            }} >{params.row.receiveTo.title}</span>
            </Link>

        </>
        )
    },
    { field: 'id', headerName: 'ID', width: 0, hide: true },
    { field: 'runs', headerName: 'Runs', type: 'string', minWidth: 130,align: 'left',headerAlign: 'left', flex: 1, },
    { field: 'type', headerName: 'Type', type: 'string', minWidth: 150,align: 'left',headerAlign: 'left', flex: 1,},
    // { field: 'duration', headerName: 'At Duration', type: 'string', width: 140 },
    { field: 'duration', headerName: 'Duration',align: 'left',headerAlign: 'left',type: 'number', minWidth: 200, flex: 1,
    renderCell: (params) => (
        <>
            <span>{durationCalculate(params.row.duration)}</span>
        </>
      )
    },

    // { field: 'creator',
    //   headerName: 'Creator',
    //   type: 'string',
    //   width: 230,
    //   valueGetter: (params) => params.row.creator.title,
    //   renderCell: (params) => (
    //     <>
    //         {
    //             params.row.creator.id ?
    //             <Link to={`/admin/creators/${params.row.creator.id}`}
    //             style={{
    //                 display: 'inline-block',
    //                 maxWidth: '100%',
    //                 flexShrink: 0,
    //                 width: '100%',
    //               }}>
    //                 <span style={{
    //                 whiteSpace: 'nowrap',
    //                 display: 'inline-block',
    //                 maxHeight: 'auto',
    //                 width: 'calc(100% - 10px)',
    //                 textOverflow: 'ellipsis',
    //                 overflow: 'hidden',
    //             }} >{params.row.creator.title}</span>
    //             </Link>
    //         :
    //             <span style={{
    //                 whiteSpace: 'nowrap',
    //                 display: 'inline-block',
    //                 maxHeight: 'auto',
    //                 width: 'calc(100% - 10px)',
    //                 textOverflow: 'ellipsis',
    //                 overflow: 'hidden',
    //             }} >{params.row.creator.title}</span>
    //         }
    //     </>
    //     )
    // },
    // { field: 'stream', headerName: 'Stream', type: 'string', width: 250 },
    // {
    //     field: 'stream.title',
    //   headerName: 'Stream',
    //   type: 'string',
    //   width: 180,
    //   valueGetter: (params) => params.row.stream.title,
    //   renderCell: (params) => (
    //       <>
    //         {
    //             params.row.stream.id ?
    //             <Link to={`/admin/match/${params.row.stream.id}`}
    //             style={{
    //               display: 'inline-block',
    //               maxWidth: '100%',
    //               flexShrink: 0,
    //               width: '100%',
    //             }}
    //             >
    //                 <span style={{
    //                   whiteSpace: 'nowrap',
    //                   display: 'inline-block',
    //                   maxHeight: 'auto',
    //                   width: 'calc(100% - 10px)',
    //                   textOverflow: 'ellipsis',
    //                   overflow: 'hidden',
    //                 }}>{params.row.stream.title}</span>
    //             </Link>
    //             :
    //             <span>{'-'}</span>
    //         }
    //     </>
    //     ),
    //  },
    // { field: 'podcast',
    //   headerName: 'Podcast',
    //   type: 'string',
    //   width: 250,
    //   valueGetter: (params) => params.row.podcast.title,
    //   renderCell: (params: GridRenderCellParams<Date>) => (
    //     <>
    //         {
    //             params.row.podcast.id ?
    //             <Link to={`/admin/consumer/show/episode/detail/${params.row.podcast.id}`}
    //             style={{
    //                 display: 'inline-block',
    //                 maxWidth: '100%',
    //                 flexShrink: 0,
    //                 width: '100%',
    //               }}>
    //                 <span style={{
    //                 whiteSpace: 'nowrap',
    //                 display: 'inline-block',
    //                 maxHeight: 'auto',
    //                 width: 'calc(100% - 10px)',
    //                 textOverflow: 'ellipsis',
    //                 overflow: 'hidden',
    //               }} >{params.row.podcast.title}</span>
    //             </Link>
    //             :
    //             <span>{params.row.podcast.title}</span>
    //         }
    //     </>
    //     ),
    // },
    // { field: 'purchases', headerName: 'Purchase', type: 'number', width: 130, align: 'center', headerAlign: 'center' },
    {
        field: 'date',
        headerName: 'Gifted on',
        type: 'date',
        minWidth: 150,
        flex: 1.2,
          valueGetter: (params) => moment(params.row.date).format('D.MM.YYYY, h:mm a'),
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
                <label>{moment(params.row.date).format('D.MM.YYYY, h:mm a')}</label>
            </>
        ),
        align: 'left', headerAlign: 'left'
    },

    // { field: 'streamtags', headerName: 'Tag', type: 'string', width: 220 },
    // { field: 'count', headerName: 'View Count', type: 'number', width: 150, align: 'left', headerAlign: 'left' },

    // {
    //     field: 'view',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'left',
    //     headerAlign: 'left',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'view-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <Link to={`/admin/package/${params.row.id}`}>
    //             {/* <button type="button">View</button> */}
    //             <span><ArrowRightAltIcon/></span>

    //         </Link>
    //     ),
    // },
];


interface userContentDataTableProps {
    transactionListContent: Array<consumerSpendListContentModifiedObj>;
}

export default function ConsumerSpendListDataTable({ transactionListContent }: userContentDataTableProps) {
    let navigate = useNavigate();

    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState<Array<consumerSpendListContentModifiedObj>>(transactionListContent);

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = transactionListContent.filter((row: any) => {
          return Object.keys(row).some((field: any) => {
            // if (["stream","podcast","creator"].includes(field)){
            //     return searchRegex.test(row[field]?.title);
            // }

            if (["receiveTo"].includes(field)){
                return searchRegex.test(row[field]?.title);
            }

            // if (["date"].includes(field)){
            //     return searchRegex.test(moment(row[field]).format('D.MM.YYYY, h:mm a'));
            // }

            if (["date","duration","id"].includes(field)){
                return false;
            }

            return searchRegex.test(row[field]);
          });
        });
        setRows(filteredRows);
    };

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
          field: 'id',
          sort: 'desc',
        }
      ]);

    useEffect(() => {
        setRows(transactionListContent);
    }, [transactionListContent]);

    function NoRowsOverlay() {
        return (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No result found
          </Stack>
        );
      }

    return (
        <>
            <div style={{ height: 810, width: '100%' }}>
                <DataGrid
                    className="datatable__custom cursor__pointer"
                    disableColumnMenu
                    components={{ Toolbar: QuickSearchToolbar,ColumnSortedDescendingIcon: SortedDescendingIcon, ColumnSortedAscendingIcon: SortedAscendingIcon, NoRowsOverlay }}
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    sortingOrder={['desc', 'asc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) =>
                        setSortModel(model)
                    }
                    onRowClick= {
                        (value: GridRowParams) => {
                            let rowValue:any = value.row;
                            {
                                (rowValue?.type === "Podcast") ?
                                navigate(`/admin/consumer/show/episode/detail/${rowValue.receiveTo.id}`)
                                : (rowValue?.type === "Creator") ?
                                navigate(`/admin/creators/${rowValue.receiveTo.id}`)
                                :
                                navigate(`/admin/match/${rowValue.receiveTo.id}`)
                            }
                        }
                    }
                    // checkboxSelection
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
}
