import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, GridSelectionModel } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import usePagination from '@mui/material/usePagination';
import Pagination from '@mui/material/Pagination';



import { countryListContentDataObj } from '../../data/mappers/countryCurrecy';


import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { current } from '@reduxjs/toolkit';

export function SortedDescendingIcon() {
    return <ExpandMoreIcon className="icon" />;
  }

  export function SortedAscendingIcon() {
    return <ExpandLessIcon className="icon" />;
  }


function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

const BasicPagination = (props) => {
    // @ts-ignore
    let rowCount:any = parseInt(props.count/props.pageSize)
    if (props.count%props.pageSize !== 0) {
        rowCount += 1
    }

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        props.setCurrentPage(value);
      };


    return (
      <Stack className='pagination--end' spacing={2}>
        <Pagination style={{marginTop: '20px'}} count={rowCount} page={props.currentPage} onChange={handleChange} />
      </Stack>
    );
  }


function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{mr: 2}} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}


let columns: GridColDef[] = [
    { field: 'title', headerName: 'Country', type: 'string', minWidth: 300,flex: 1,  },
    // { field: 'currency', headerName: 'Currency', type: 'string', width: 250 },
    { field: 'currency', headerName: 'Currency', type: 'string', minWidth: 120,flex: 1, },
    // { field: 'amount', headerName: 'Amount', type: 'number', width: 250},
    { field: 'amount', headerName: 'Amount', type: 'number', minWidth: 120,flex: 1, editable: true, cellClassName:'pointer-none'},
    // { field: 'actions', headerName: 'Action', type: '', width: 120, align: 'left'},
    { field: 'id', headerName: 'ID',  hide: true },
];


interface countryListContentDataTableProps {
    countryListContent: Array<countryListContentDataObj>;
    setCountryListContent: any;
    modeType: string
    setAllCountryListContent:any
    allCountryListContent:any
    selectionType: string;
}

interface country {
    id: number;
    title: string;
    countryCode: string;
    countryCodeText: string;
    currency: string;
    amount: number;
}

function AlertDialog(params) {

    let id = params.params.row.id;

    const clickDetected = (value) => {
        params.setRemoveCountry(id)
    }

    return (
      <div>
        <button className='deleteButton normal--btn deleted-class delete--row' onClick={clickDetected}>
            <HighlightOffIcon/>
        </button>
      </div>
    );
  }


export default function CountryCurrencyListDataTable({ countryListContent, setCountryListContent, modeType, setAllCountryListContent, allCountryListContent, selectionType}: countryListContentDataTableProps) {
    const [searchText, setSearchText] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [rows, setRows] = React.useState<Array<countryListContentDataObj>>(countryListContent);
    // const [selectModel, setSelectModel] = React.useState<GridSelectionModel>([]);


    const [removeCountry,setRemoveCountry] = React.useState("")
    const [hideBtn,setHideBtn] = React.useState("")

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        searchValue = searchValue.trim();
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = countryListContent.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        // {
        // //   field: 'id'
        // //   sort: 'asc',
        // }
      ]);

    useEffect(() => {
        setRows(countryListContent);
    }, [countryListContent]);


    // const handleNewSelection = (newSelectionModel) =>{
    //     if ((selectModel.length > 0) && (selectModel.includes(newSelectionModel[newSelectionModel.length - 1]))){
    //         var index = selectModel.indexOf(newSelectionModel[newSelectionModel.length - 1]);
    //         if (index >= 0) {
    //             selectModel.splice( index, 1 );
    //         }
    //     }else{
    //         selectModel.push(newSelectionModel[newSelectionModel.length - 1])
    //     }
    //     setSelectModel(selectModel)
    // }

    function NoRowsOverlay() {
        return (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No result found
          </Stack>
        );
      }

      useEffect(() => {
        if (removeCountry){

            let finalList:any = [];
            countryListContent.forEach((x) => {
                finalList.push(x)
            })

            const indexOfCountry = finalList.findIndex(object => {
                return (
                    //@ts-ignore
                    object.id === parseInt(removeCountry));
            });

            if (finalList[indexOfCountry].id === parseInt(removeCountry)){
                finalList.splice(indexOfCountry, 1);
            }

            setCountryListContent(finalList);
        }
    },[removeCountry])

    const updateData = (id,value) => {
        if (!value){
            value = 0;
        }
        let tempDict = countryListContent.map((x) => {
            if (x.id === id) {
                return {
                ...x,
                amount: parseFloat(value).toFixed(2)
                };
            }
        return x;
        });
        if (tempDict.length > 0){
            setCountryListContent(tempDict);
        }

        // if (countryListContent.length < allCountryListContent.length){
        if (allCountryListContent && (allCountryListContent.length > 0)){
            let secondaryCountryDict = allCountryListContent.map((x) => {
                if (x.id === id) {
                    return {
                        ...x,
                        amount: parseFloat(value).toFixed(2)
                        };
                    }
                return x;
            });
            if (secondaryCountryDict.length > 0){
                setAllCountryListContent(secondaryCountryDict);
            }
        }
        // }
    }

        const handleRowEditCommit = React.useCallback(
            (params) => {
                const id = params.id;
                const key = params.field;
                const value = params.value;
                updateData(id,value)
                },
                [countryListContent, allCountryListContent]
            );
        // if(modeType === "detail"){
        //     let c = columns.findIndex(p => p.field == "amount");
        //     columns[c].editable = false;
        // }else{
        // // if (["add","edit"].includes(modeType)){
        //     let c = columns.findIndex(p => p.field == "amount");
        //     columns[c].editable = true;
        // }


        // if (selectionType !== "specificCountry"){
        //     if (hideBtn !== "pointer-none bgBtn--none btnHide"){
        //         setHideBtn("pointer-none bgBtn--none btnHide")
        //     }
        // }else{
        //     if (hideBtn !== "pointer-none bgBtn--none"){
        //         setHideBtn("pointer-none bgBtn--none")
        //     }
        // }




        const removeCountryField = ({
            field: 'removeCountry',
            headerName: '',
            type: 'actions',
            align: 'center',
            headerAlign: 'center',
            width: 100,
            sortable: false,
            // cellClassName: 'delete-actions',
            cellClassName: "pointer-none bgBtn--none" +  ((selectionType !== "specificCountry") ? " btnHide" : "" ),
            renderCell: (params: GridRenderCellParams<Date>) => (
                <>
                    <AlertDialog params={params} setRemoveCountry={setRemoveCountry}/>
                </>
            ),
        });

        var arr = []
        const indexOfStatus = columns.findIndex(object => {
            return (
                //@ts-ignore
                object.field === "showActiveStatus");
        });



        // if (columns[indexOfStatus]){
        //     if (columns[indexOfStatus].field === ("removeCountry")){
        //         columns.splice(indexOfStatus, 1);
        //     }
        // }


        // if (selectionType === "specificCountry"){
        columns.forEach((x) => {
            //@ts-ignore
            arr.push(x.field)
        });

        //@ts-ignore
        if (arr.includes("removeCountry")){
            const indexOfStatus = columns.findIndex(object => {
                return (
                    //@ts-ignore
                    object.field === "removeCountry");
                });
            if (columns[indexOfStatus]){
                if (columns[indexOfStatus].field === ("removeCountry")){
                    columns.splice(indexOfStatus, 1);
                }
            }
            //@ts-ignore
            columns.splice(-1, 0 ,removeCountryField)
        }else{
                //@ts-ignore
                columns.splice(-1, 0 ,removeCountryField)
            }

        // }

    return (
        <>
            {/* <div style={{ height: 500, width: '100%', marginTop: '22px' }}> */}
            <div style={{ height: 380, width: '100%'}}>
                <DataGrid
                    className="datatable__custom pagination--none searchbar--absolute pe--0"
                    disableColumnMenu
                    components={{  Toolbar: QuickSearchToolbar, NoRowsOverlay }}
                    // components={{  NoRowsOverlay }}
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    page={currentPage-1}
                    sortingOrder={['desc', 'asc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    // checkboxSelection
                    onCellEditCommit={handleRowEditCommit}

                    // onSelectionModelChange={(newSelectionModel) => {
                    //     // handleNewSelection(newSelectionModel)
                    //     setSelectModel(newSelectionModel);
                    //   }}
                    // selectionModel={selectModel}

                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                />
                <BasicPagination setCurrentPage={setCurrentPage} currentPage={currentPage} count={rows ? rows.length : 0} pageSize={5}/>
            </div>
        </>
    );
}
