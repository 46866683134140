import { UPDATE_PACKAGE_LIST_CONTENT_DATA, UPDATE_PACKAGE_STATUS } from '../../constants';
import { packageListContentDataModifiedObj } from '../../data/mappers/package';
import { UpdatePackageListContentAction } from './types';

export const UpdatePackageListContent = (data: Array<packageListContentDataModifiedObj>): UpdatePackageListContentAction => ({
    type: UPDATE_PACKAGE_LIST_CONTENT_DATA,
    data,
});


// export const AddCreatorListContent = (data: Array<creatorListContentDataModifiedObj>): AddCreatorListContentAction => ({
//     type: ADD_CREATOR_LIST_CONTENT_DATA,
//     data,
// });

export const UpdatePackageStatusContent = (data:  number) => ({
    type: UPDATE_PACKAGE_STATUS,
    data,
});

// export const CreatorSearchText = (data:  string) => ({
//     type: SAVE_CREATOR_SEARCH_TEXT,
//     data,
// });

// export const CreatorCurrentPage = (data:  number) => ({
//     type: SAVE_CREATOR_CURRENT_PAGE,
//     data,
// });

// export const TotalCreatorsSave = (data:  number) => ({
//     type: SAVE_TOTAL_CREATORS,
//     data,
// });

// export const CreatorResetAction = () => ({
//     type: RESET
// });

