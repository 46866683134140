import React from 'react';
import EpisodeCreateForm from '../episode/Form';
import GoToTop from '../common/GoTop'


interface EpisodeCreateProps {
    modeType: string;
    back: string;
}

const EpisodeCreate = (props:EpisodeCreateProps) => {
    return (
        <>
            {//@ts-ignore
            <EpisodeCreateForm modeType={props.modeType} back={props.back} />}
            <GoToTop />
        </>
    );

}


export default EpisodeCreate;
