import React, { useEffect, useState } from 'react';
import { Box, FormControl } from '@mui/material';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Compressor from 'compressorjs';

import { Field, reduxForm } from 'redux-form';
import { TextArea, TextField } from '../common/TextField';
import {  renderAuthorsField } from '../form/Fields';
import { createEpisode, mediaAdd } from '../../data/mappers/episode';

import { RootStateType } from '../../store';
import {  postFileRequest, postRequest } from '../../data/helpers/services';
import { PODCAST_API, UPLOAD_FILE } from '../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ShowPodcastUploadingBar } from '../../store/episode';

import UploadIcon from '../../icons/UploadIcon';
import { Button } from '../common/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';

import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import Axios from 'axios';



interface author {
    id: number;
    handle: string;
    firstName: string;
    lastName: string;
    fullName: string;
}


const prepareAuthors = (authors:any, authorsList:any) => {
    let authorsListArray:author[] = [];
    let arrAuthorsIds:number[] = [];
    authors.map((author:any) => arrAuthorsIds.push(author.user.id));

    authorsList.map((author:author) => {
        if(arrAuthorsIds.includes(author.id)) {
            authorsListArray.push(author);
        }
        return true;
    });
    return authorsListArray;
};


interface EpisodeCreateForm {
    title: string;
    episodeNumber: number;
    showName: string;
    description: string;
    authorIdList: author[];
    series_id: string;
    duration: number;
    picture: string;
    file_name: string;
    mime_type: string;
}

const validate = (values: EpisodeCreateForm) => {
    const errors: any = {};

    if (!values.title || (values.title && !values.title.trim())) {
        errors.title = 'Field is required.';
    } else if (values.title.trim().length > 256) {
        errors.title = 'Field value must be 256 characters or less.';
    }

    if (!values.description || (values.description && !values.description.trim())) {
        errors.description = 'Field is required.';
    } else if (values.description.trim() && values.description.trim().length > 4096) {
        errors.description = 'Field value must be 4096 characters or less.';
    }

    if (!values.duration) {
        errors.duration = 'Field is required.';
    } else if (values.duration<1){
        errors.duration = 'Must contains positive integer values.';
    }

    if (!values.episodeNumber) {
        errors.episodeNumber = 'Field is required.';
    } else if (values.episodeNumber<0){
        errors.episodeNumber = 'Must contains positive integer values.';
    } else if (values.episodeNumber>1000){
        errors.episodeNumber = 'Must be less than 1000.';
    }

    return errors;
};

const EpisodeCreateForm = (props: any) => {
    const { handleSubmit, change, submitting, reset, modeType, back } = props;
    let token = useSelector((state: RootStateType) => state.user.access_token);

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [thumbnailImage, setThumbnailImage] = useState('');
    const [audioFile, setAudioFile] = useState(false);
    const [audioUploading, setAudioUploading] = useState(false);

    const [audioDuration, setAudioDuration] = useState<string | undefined>("");

    const{ showId } = useParams();
    const { id } = useParams();
    const [episodeDetails, setEpisodeDetails] = useState<EpisodeCreateForm | null>(null);

    const [fileUploading, setFileUploading] = useState(false);
    const [authorList, setAuthorList] = useState<author[]>();
    const [authorValue, setAuthorValue] = useState<author|null>(null);

    const [isAudioCorrect, setIsAudioCorrect] = useState(false);
    const [isFileChanged, setIsFileChanged] = useState(false);

    const [ showTitle, setShowTitle ] = useState<string>("");

    const getAuthorList = async (id: string) => {
        const response = await postRequest(PODCAST_API.SERIES_DETAIL, {
            seriesId: id,
            offset: 0,
            search_text: ""
        }, token);

        if (response) {
            setShowTitle(response?.data?.data.title)

            let hosts = (response?.data?.data).host;
            let hostsList:author[]= [];
            if (hosts && hosts.length) {
                hosts.map((author:any) => hostsList.push({
                    id: author.user.id, handle: author.user.handle, fullName: ((author.user.firstName ?? "") + ' ' + (author.user.lastName ?? "")),
                    firstName: author.user.firstName,
                    lastName: author.user.lastName
                }));
            }
            setAuthorList(hostsList);

            if (modeType === 'add') {
                change('episodeNumber', parseInt(response?.data?.data.episodes_count)+1);
            }

        } else {
            console.log('Unable to get list');
        }

    }

    const getEpisodeDetails = async (id: string) => {
        dispatch(ShowProgressBar());
        const response = await postRequest(
            PODCAST_API.EPISODE_DETAIL,
            {
                episodeId: id,
            },
            token
        );

        if (response) {
            if (response.data.code === 200) {
                setEpisodeDetails(response.data.data);
            }
        }
        dispatch(HideProgressBar());
    };

    const initForm = () => {
        setThumbnailImage("");
        setEpisodeDetails(null);
        setAuthorList([]);
        setAuthorValue(null);
        setAudioFile(false);
        setAudioDuration("");
        setAudioUploading(false);
    }


    useEffect(() => {
        initForm();
        if(showId) {
            getAuthorList(showId);
        }

    }, []);

    useEffect(() => {
        if(modeType === 'add') {
            initForm();
        }
    }, [modeType]);

    useEffect(() => {
        if (id && modeType === 'edit') {
            if (authorList && authorList.length > 0){
                getEpisodeDetails(id);
            }
        }
    }, [id, authorList]);

    useEffect(() => {
        if (episodeDetails && modeType === 'edit') {
            fillEpisodeDetails(episodeDetails);
        }
    }, [episodeDetails]);

    const fillEpisodeDetails = async (episodeDetails:any) => {
        change('id', episodeDetails?.id);
        change('title', episodeDetails?.title);
        change('description', episodeDetails?.description);
        // change('duration', episodeDetails?.duration);

        setAudioDuration(episodeDetails?.duration);
        change('episodeNumber', episodeDetails?.episodeNumber);
        let AuthorsSelected = episodeDetails.authors

        let authors = prepareAuthors(AuthorsSelected, authorList);
        change('author', authors);

        setThumbnailImage(episodeDetails.picture);
        change('thumbnail', episodeDetails.picture);

        if (episodeDetails.streamUrl && (episodeDetails.streamUrl !== "")){
            setAudioFile(true);
            setIsAudioCorrect(true);
            setAudioUploading(false);
        }
    }


    const handleKeyDown = (event: any) => {
        switch (event.key) {
            case ',':
            case ' ':
            case 'Enter': {
                event.preventDefault();
                event.stopPropagation();
                if (event.target.value.length > 0) {
                }
                break;
            }
            default:
                console.log('...');
        }
    };

    const handleAuthorKeyDown = (event: any) => {
        switch (event.key) {
            case ',':
            case 'Enter': {
                event.preventDefault();
                event.stopPropagation();
                break;
            }
            default:
                console.log('...');
        }
    };

    const saveEpisode = async (values: any) => {
        if (!values.author){
            dispatch(ShowErrorAlert({ visible: true, message: 'Author is required' }));
        }
        else if (!isAudioCorrect && modeType === 'add') {
            dispatch(ShowErrorAlert({ visible: true, message: 'please upload mp3 file' }));
        }
        else if (!audioFile) {
            dispatch(ShowErrorAlert({ visible: true, message: 'please upload mp3 file' }));
        }
        // else if (!values.thumbnail){
        //     dispatch(ShowErrorAlert({ visible: true, message: 'Podcast Image is required' }));
        // }
        else{
            dispatch(ShowProgressBar());

            let data = createEpisode(values, showId, isFileChanged, audioDuration);

            var response: any = null;

            if (episodeDetails) {
                response = await postRequest(PODCAST_API.EPISODE_UPDATE, data, token);
            } else {
                response = await postRequest(PODCAST_API.EPISODE_CREATE, data, token);
            }
            if (response) {
                const resData = response.data;
                if (resData) {
                    let presignedurl = resData.data.presigned_url;
                    // navigate('/admin/show/' + showId , { replace: true });
                    navigate('/admin/show/episode/detail/'+ resData.data.id, { replace: true })

                    if (presignedurl){
                        // dispatch(ShowProgressBar());
                        let mediaToken = resData.data.mediaToken;
                        let fileKey = resData.data.file_key;


                        dispatch(ShowPodcastUploadingBar({ status:1 }));

                        dispatch(ShowSuccessAlert({visible:true, message: "Podcast upload in progress."}));

                        await Axios.put(presignedurl, values.file_name ,{
                            headers: {
                                'Content-Type': values.file_name.type
                                }}).catch(function (error: any) {
                            console.log(error)})

                        var response: any = null;
                        let data = mediaAdd(mediaToken, ((values.file_name.name).replaceAll(" ","")).replaceAll("+", "_"), fileKey)

                        response = await postRequest(PODCAST_API.MEDIA_ADD, data, token);

                        dispatch(HideProgressBar());
                        dispatch(ShowSuccessAlert({visible:true, message: "New Podcast added successfully."}));

                        dispatch(ShowPodcastUploadingBar({ status:2 }));
                    }
                    else{
                        let message:string = ""
                        modeType == "edit" ? message = "podcast updated successfully" : message = "podcast added successfully";
                        dispatch(ShowSuccessAlert({visible:true, message: message}));
                        dispatch(HideProgressBar());
                    }
                }
            }
        }
    };


    const handleCompressedUpload = async(e: any) => {
        const image = e.target.files[0];
        new Compressor(image, {
          quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
          success: async(res) => {

            let reader = new FileReader();
            reader.onload = (e: any) => {
                setThumbnailImage(e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);

            // upload file and obtain url for image
            setFileUploading(true);
            dispatch(ShowProgressBar());

            const formData = new FormData();

            formData.append('file', res, (Date.now()).toString() + e.target.files[0].name );

            const response: any = await postFileRequest(UPLOAD_FILE, formData, token);

            if (response) {
                const resData = response.data;
                if (resData.code === 200) {
                    let fileUrl = resData.data.file_path;
                    change( 'thumbnail', fileUrl);
                }
            } else {
                setThumbnailImage('')
            }

            dispatch(HideProgressBar());
            setFileUploading(false);
          },
        });
      };


    const handleFileSelect = async (event: any) => {
        if (event !== undefined && event.target.files && event.target.files[0]) {
            const filetype = event.target.files[0].type;

            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
            // if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 10000)) {
            if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 500)) {
                dispatch(ShowErrorAlert({ visible: true, message: 'Must be a .png, .jpg, .jpeg, .webp format image & not more than 500kb' }));
                event.target.value = null;
                return false;
            }


            handleCompressedUpload(event);

        }
        dispatch(HideProgressBar());
        setFileUploading(false);
        return true;
    };

    const handleFileRemove = () => {
        setThumbnailImage("");
        change('thumbnail', "");
    }


    const handleAudioRemove = () => {
        change('file_name', "");
        setIsAudioCorrect(false)
        setAudioFile(false);
        setAudioDuration("");
        setAudioUploading(false);
    }



    // Create a non-dom allocated Audio element
    var audio:any = document.createElement('audio');

    // Add a change event listener to the file input
    const addFileToCalculate = (event:any) => {
        setAudioUploading(true);
        var target = event.target;
        var file = target.files[0];
        var reader = new FileReader();

        if (target.files && file) {
            var reader = new FileReader();

            reader.onload = function (e) {
                audio.src = e?.target?.result;
                audio.addEventListener('loadedmetadata', function(){
                    var duration = parseInt(audio.duration);

                    setAudioDuration(duration.toString());
                    setAudioUploading(false);
                },false);
            };

            reader.readAsDataURL(file);
        }
    }

    const addFile = (event:any) => {
        if (!(event.target.files[0].type).includes("audio")){
            dispatch(ShowErrorAlert({ visible: true, message: 'Must be a .mp3, .wav, .ogg format' }));
        }
        else{
            setIsAudioCorrect(true);
            setAudioFile(true);
            change('file_name', event.target.files[0]);

            addFileToCalculate(event);
            if (modeType === "edit") {
                setIsFileChanged(true);
            }
        }
    };

    let nav: string = ""
    if (modeType === "edit") {
        if (back === "list"){
            nav = "/admin/show/" + showId
        }
        else if (back === "detail"){
            nav = '/admin/show/episode/detail/'+id
        }
    }
    else{
        nav = "/admin/show/" + showId
    }

    return (
        <>
        <Box className="dashboard__topbar">
            <Box className="dashboard__topbar--heading col-12">
                <Box className="button__text__navigate">
                    <button onClick={() => navigate(nav,{ replace: true })
                        }><ArrowBackOutlinedIcon /></button>
                    <Box component="h2">{showTitle || "Episode"}</Box>
                </Box>
            </Box>
        </Box>
        <Box className='dashboard__content max--760 mx--auto'>
            <Box component="form" onSubmit={handleSubmit(saveEpisode)}>
                <Box component="div" className="row m-t-30">
                    <div className='col-12 md-col-12 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="title">Episode Title (max 256 chars)</label>
                        <Field name="title" component={TextField} type="text" placeholder='Write your title here'
                            //@ts-ignore
                            styleName='input--transparent' />
                    </div>


                    <div className='col-12 md-col-8 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="episode-author">Author</label>
                        <FormControl className='input-inner-transparent' fullWidth>
                            <Field name="author" component={renderAuthorsField} id="episode-author" options={authorList} defaultValue={' '} handleAuthorKeyDown={handleAuthorKeyDown} setAuthorValue={setAuthorValue} />
                        </FormControl>
                    </div>

                    <div className='col-12 md-col-4 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="episodeNumber">Episode number</label>
                        <Field name="episodeNumber" component={TextField} type="number" min="0" max="1000" placeholder=''
                            //@ts-ignore
                            styleName='input--transparent' />
                    </div>

                    <div className='col-12 md-col-12 m-b-20 overflow--hidden'>
                        <label className='input--label m-b-10' htmlFor="userBio">Description (max 4096 chars)</label>
                        <Field name="description" component={TextArea} row='7' type="text" placeholder='Write description'
                            styleName='input--transparent' />
                    </div>


                    <div className="col-12 md-col-6">
                        <label className='input--label m-b-10' htmlFor="file-upload">Upload episode mp3 file</label>

                     <Field
                        name="file_name"
                        type="hidden"
                        component="input"
                        id="file_name"
                        placeholder="file_name"
                    />
                    {! isAudioCorrect ?
                        (<Box component="div"
                            sx={{
                                height: '205px',
                                maxWidth: '274px',
                                width: '100%',
                                background: '#232157',
                                marginBottom: "20px",
                                position: 'relative',
                                borderRadius: "15px",
                                cursor: "pointer"
                            }}
                        >
                                <Box
                                    component="div"
                                    sx={{
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%,-50%)',
                                    }}
                                >
                                    <UploadIcon />
                                </Box>
                                <Box
                                    component="input"
                                    type="file"
                                    name="file_name"
                                    onChange={addFile}
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        opacity: '0',
                                        cursor: 'pointer',
                                    }}
                                ></Box>
                            </Box>
                        ) : (
                            <>
                                <Box className="img--uploadBg">

                                    {submitting || fileUploading ?
                                    '' : <Button type="button" styleName="remove--pic" onClick={() => handleAudioRemove()}>
                                    <CancelIcon />
                                </Button>}
                                    <Box
                                        component="img"
                                        src="/images/mp3_icon.png"
                                        sx={{
                                            maxHeight: '100%',
                                            maxWidth: '100%',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            borderRadius: '15px',
                                        }}
                                ></Box>
                                </Box>
                            </>
                        )}


                        </div>


                    <div className="col-12 md-col-6">
                        <div className='row m-b-10 align--items--center'>
                            <div className='col-9'>
                                <label className='input--label' htmlFor="thumbnail">Podcast Image(ratio: 16:9)</label>
                            </div>
                            <div className='col-3 text--end'>
                                <Tooltip title="Get your image ready by making sure it has right width, height, resolution, size, type. This protect's the image quality & prevents error during upload process. Recomended aspect ratio is 16:9 & less than 500 kb for best fit."
                                PopperProps={{
                                    sx: {
                                      "& .MuiTooltip-tooltip": {
                                        backgroundColor: '#191840',
                                        fontSize: '12px',
                                        padding: '12px',
                                        fontWeight: 600,
                                        border: '1px solid #F89E24'
                                      }
                                    }
                                }}
                                >
                                    <HelpOutlineIcon/>
                                </Tooltip>
                            </div>
                        </div>

                        <Field
                            name="thumbnail"
                            type="hidden"
                            component="input"
                            id="thumbnail"
                            placeholder="thumbnail"
                        />
                        <Field
                            name="id"
                            type="hidden"
                            component="input"
                            id="id"
                            placeholder="id"
                        />
                        {!thumbnailImage || thumbnailImage === '' ?
                            (<Box component="div"
                                sx={{
                                    height: '200px',
                                    width: '100%',
                                    background: '#232157',
                                    marginBottom: "20px",
                                    position: 'relative',
                                    borderRadius: "15px",
                                    cursor: "pointer"
                                }}
                            >
                                    <Box
                                        component="div"
                                        sx={{
                                            position: 'absolute',
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%,-50%)',
                                        }}
                                    >
                                        <UploadIcon />
                                    </Box>
                                    <Box
                                        component="input"
                                        type="file"
                                        name="thumbnail"
                                        onChange={handleFileSelect}
                                        sx={{
                                            height: '100%',
                                            width: '100%',
                                            opacity: '0',
                                            cursor: 'pointer',
                                        }}
                                    ></Box>
                                </Box>
                            ) : (
                                <Box
                                style={{
                                    background: '#232157',
                                    padding: '15px',
                                    marginBottom: "20px",
                                    borderRadius: "15px",
                                    minHeight: '200px'
                                }}
                                >
                                    <Box className="ratio16-9"
                                        sx={{
                                            width: '100%',
                                            position: 'relative',
                                            cursor: "pointer"
                                        }}
                                    >

                                        {submitting || fileUploading ?
                                        '' : <Button type="button" styleName="remove--pic" onClick={() => handleFileRemove()}>
                                        <CancelIcon />
                                    </Button>}
                                        <Box
                                            component="img"
                                            src={thumbnailImage}
                                            sx={{
                                                maxHeight: '100%',
                                                maxWidth: '100%',
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                borderRadius: '15px',
                                            }}
                                        ></Box>
                                    </Box>
                                </Box>
                            )}
                        </div>

                        <div className="col-12 m-b-40">
                            <button type="submit" className={`btn--pill saveBtn`} disabled={submitting || fileUploading || audioUploading}>
                                {showSubmitButtonLabel(submitting, fileUploading, modeType, audioUploading)}
                            </button>
                        </div>
                    </Box>
                </Box>
        </Box>
        </>
    );
};

const showSubmitButtonLabel = (submitting: boolean, fileUploading: boolean, modeType:string, audioUploading: boolean) => {
    if (submitting) {
        return 'Saving...';
    } else if (fileUploading) {
        return 'Saving Photo...';
    }
    else if (audioUploading) {
        return 'Calculating Duration...';
    }
    else {
        if (modeType === 'edit'){
            return `Save`;
        }
        return `Add New Episode`;
    }
};

export default reduxForm({
    form: 'episode',
    validate,
})(EpisodeCreateForm);

