import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';


import {  renderDateField } from '../form/Fields';


import { Field, reduxForm } from 'redux-form';
import { TextArea, TextField } from '../common/TextField';
import {  renderHostsField, renderCoHostsField, renderSelectField } from '../form/Fields';
import { createShow } from '../../data/mappers/createPodcast';
import { RootStateType } from '../../store';
import { getRequest, postFileRequest, postRequest } from '../../data/helpers/services';
import { CREATOR_ADMIN_API } from '../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { HideProgressBar, ShowErrorAlert, ShowProgressBar } from '../../store/alerts';
import { ShowInterface } from '../../store/show';


const validate = (values: any) => {
    const errors: any = {};


    return errors;
};



const DateRangeForm = (props: any) => {
    const { handleSubmit, change, submitting, reset, modeType, back, creatorId, creatorDetails, setCreatorDetails, setStartDate,setEndDate } = props;
    let token = useSelector((state: RootStateType) => state.user.access_token);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const initForm = () => {
        let dateToday = new Date().getDate();

        change('startDate', (new Date(new Date().setDate(dateToday - 30))));
        change('endDate', new Date())

    }

    useEffect(() => {
        initForm();
    }, []);


    const sendRequest = async (props) => {
        dispatch(ShowProgressBar());

        setStartDate(props.startDate);
        setEndDate(props.endDate);
        const response = await postRequest(
            CREATOR_ADMIN_API.DETAIL,
            {
                creator_id: creatorId,
                start_date:props.startDate,
                end_date:props.endDate,
            },
            token
        );

        if (response) {
            if (response.data.code === 200) {
                setCreatorDetails(response.data.data);


            }
        }
        dispatch(HideProgressBar());
    }

    return (
        <>
            <Box component="form" id="dateForm" onSubmit={handleSubmit(sendRequest)}>
                <Box component="div" className="row">
                    <div className="col-12">
                        <label className='input--label m-b-10' htmlFor="startDate">Date Range</label>
                    </div>
                    <div className='col-12 md-col-4 xl-col-4 m-b-10 bdr-rad--6'>
                        <FormControl fullWidth>
                            <Field component={renderDateField} name="startDate" disablePrevious={false} disableFuture={true} />
                        </FormControl>
                    </div>

                    <div className='col-12 md-col-4 xl-col-4 bdr-rad--6'>
                        <FormControl fullWidth>
                            <Field component={renderDateField} name="endDate" disablePrevious={false} disableFuture={true} />
                        </FormControl>
                    </div>

                    <div className='col-12 m-t-10 md-col-auto'>
                        <button style={{background: 'rgb(44, 43, 93)'}} type="submit" form={"dateForm"} className={`btn--pill button--outlined active`}>
                            Apply
                        </button>
                    </div>
                </Box>
            </Box>

        </>
    );
};


export default reduxForm({
    form: 'customDate',
    validate,
})(DateRangeForm);

