import { Reducer } from 'react';
import { RESET, UPDATE_CREATOR_LIST_CONTENT_DATA, ADD_CREATOR_LIST_CONTENT_DATA, UPDATE_CREATOR_ACCOUNT_STATUS, SAVE_CREATOR_SEARCH_TEXT, SAVE_CREATOR_CURRENT_PAGE, SAVE_TOTAL_CREATORS } from '../../constants';
import { creatorListActions, creatorListState } from './types';

export const initialState: creatorListState = {
    creatorListContent: [],
    searchedValue: "",
    currentPage: 0,
    totalCreators:0
};

export const creatorListReducer: Reducer<creatorListState, creatorListActions> = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CREATOR_LIST_CONTENT_DATA:
            return {
                ...state,
                creatorListContent: [...(action?.data || [])],
            };

        case ADD_CREATOR_LIST_CONTENT_DATA:
            return {
                ...state,
                // creatorListContent: [...state.creatorListContent, ...(action?.data || [])],
                creatorListContent: state.creatorListContent.concat(action?.data),
            };

        case UPDATE_CREATOR_ACCOUNT_STATUS:
            return {
                ...state,
                creatorListContent: state.creatorListContent.map(creator => {
                    if (creator.id === action.data) {
                        creator.status = !creator.status
                        return {...creator}
                    };
                    return creator;
                })
            }

        case SAVE_CREATOR_SEARCH_TEXT:
            return {
                ...state,
                // creatorListContent: state.creatorListContent,
                searchedValue : action.data
            };

        case SAVE_CREATOR_CURRENT_PAGE:
            return {
                ...state,
                // creatorListContent: state.creatorListContent,
                currentPage : action.data
            };

        case SAVE_TOTAL_CREATORS:
            return {
                ...state,
                // creatorListContent: state.creatorListContent,
                totalCreators : action.data
            };

        case RESET:
            return {
                ...initialState //Always return the initial state
            };

        default:
            return state;
    }
};
