import React, { useEffect, useState } from 'react';
import { Box, FormControl } from '@mui/material';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Compressor from 'compressorjs';

import {  renderCountriesField } from '../form/Fields';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import { Field, reduxForm } from 'redux-form';
import { TextArea, TextField } from '../common/TextField';
// import {  renderAuthorsField } from '../form/Fields';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import {  renderDateField,renderPackageDateTimeField } from '../form/Fields';
import { getParamByISO } from 'iso-country-currency';

import { createPackage, packageSubmitMapper, countriesMapper } from '../../data/mappers/package';

import { RootStateType } from '../../store';
import {  postFileRequest, postRequest, isApiCodeSucess } from '../../data/helpers/services';
import { PACKAGES_API, UPLOAD_FILE, COUNTRIES_LIST } from '../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import  CountryCurrencyListDataTable  from '../common/CountryCurrencyDataTable'

import { ShowPodcastUploadingBar } from '../../store/episode';

import UploadIcon from '../../icons/UploadIcon';
import { CustomButton } from '../common/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';

import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import Axios from 'axios';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


const steps = [
  'Details',
  'Pricing',
  'Notifications',
];

export function HorizontalLabelPositionBelowStepper(value) {
    return (
    <Box sx={{ width: '100%' }}>

      <Stepper activeStep={value.currentState} alternativeLabel>
        {steps.map((label) => (
            // <button form='first-page' type="submit">
                <Step key={label} onChange={(value) => {
                }}>
                        <StepLabel>{label}</StepLabel>
                </Step>
            // </button>
        ))}
      </Stepper>
    </Box>
  );
}


export function HorizontalNonLinearStepper(value) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
      [k: number]: boolean;
    }>({});

    const totalSteps = () => {
      return steps.length;
    };

    const completedSteps = () => {
      return Object.keys(completed).length;
    };

    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };

    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
      value.setCurrentState(step)

      setActiveStep(step);
    };

    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };

    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };

    return (
      <Box sx={{ width: '100%' }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                Step {activeStep + 1}
              </Typography> */}
              {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext} sx={{ mr: 1 }}>
                  Next
                </Button>
                {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button onClick={handleComplete}>
                      {completedSteps() === totalSteps() - 1
                        ? 'Finish'
                        : 'Complete Step'}
                    </Button>
                  ))}
              </Box> */}
            </React.Fragment>
          )}
        </div>
      </Box>
    );
  }


interface country {
    id: number;
    title: string;
    countryCode: string;
    countryCodeText: string;
    currency: string;
    amount: number;
}


interface PackageCreateForm {
    title: string;
    // description: string;
    picture: string;
    runs: string;
    startDate: string;
    endDate: string;
}

const validate = (values: PackageCreateForm) => {
    const errors: any = {};

    if (values.title && (values.title.trim().length > 256)) {
        errors.title = 'Field value must be 256 characters or less.';
    }

    if (values.runs && (values.runs).length > 10) {
        errors.runs = 'Field value must be 10 characters or less.';
    }

    return errors;
};



function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}


const PackageCreateForm = (props: any) => {
    const { handleSubmit, change, submitting, reset, modeTypeProp, back } = props;
    let token = useSelector((state: RootStateType) => state.user.access_token);

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [thumbnailImage, setThumbnailImage] = useState('');
    const{ showId } = useParams();
    const { id } = useParams();
    const [packageDetails, setPackageDetails] = useState<PackageCreateForm | null>(null);

    const [packageDbResponse, setPackageDbResponse] = useState<any>();

    const [ basicPackageDetails, setBasicPackageDetails ] = useState<PackageCreateForm | null>(null);

    const [open, setOpen] = React.useState(false);

    const [giftTypeId, setGiftTypeId] = useState<number>(0);

    const [fileUploading, setFileUploading] = useState(false);

    const [currentState , setCurrentState] = useState<number>(0);

    const [countriesList, setCountriesList ] = useState<any>([]);
    const [existingCountriesList, setExistingCountriesList ] = useState<any>([]);

    const [modeType, setModeType ] = useState<string>(modeTypeProp);

    const [storedCountries, setStoredCountries ] = useState<any>([]);


    const [ allCountries, setAllCountries ] = useState<boolean>(false);
    const [ specificCountries, setSpecificCountries ] = useState<boolean>(false);

    const [ selectedCountriesList, setSelectedCountriesList ] = useState<any | undefined>(undefined);
    const [ currencyFetched, setCurrencyFetched ] = useState<any>(null);
    const [ usdAmount, setUsdAmount ] = useState<number>(0);


    const [startCheck1, setStartCheck1] = useState<[boolean, string]>([false,"6"]);
    const [startCheck2, setStartCheck2] = useState<[boolean, string]>([false,"12"]);
    const [startCheck3, setStartCheck3] = useState<[boolean, string]>([false,"24"]);


    const [ endCheck1, setEndCheck1] = useState<[boolean, string]>([false,"6"]);
    const [ endCheck2, setEndCheck2] = useState<[boolean, string]>([false,"12"]);
    const [ endCheck3, setEndCheck3] = useState<[boolean, string]>([false,"24"]);

    const getCountriesList = async () => {
        let response = await postRequest(COUNTRIES_LIST, [], token);
        if (response) {
            let countries = response?.data?.data;
            let countriesList: country[] = [];
            if (countries && countries.length) {
                countries.map((country: any) =>{
                    let countryWithCurrency = "-"
                    try {
                        countryWithCurrency = getParamByISO(country.country_code_text, 'currency')
                    }
                    catch (e){
                        countryWithCurrency = "-"
                    }

                    countriesList.push({
                        id: country.id,
                        title: country.title,
                        countryCode: country.country_code,
                        countryCodeText: country.country_code_text,
                        currency: countryWithCurrency,
                        amount: country.amount || 0
                    })
                }
                );
            }

            setCountriesList(countriesList);

        } else {
            console.log('Unable to get list');
        }
    }

    const initForm = () => {
        var durationInMinutes = 16;
        let setTime = new Date()
        setTime.setMinutes(setTime.getMinutes() + durationInMinutes);
        change('runs', 1);
        change('startDate', setTime);
        setThumbnailImage("");
        setPackageDetails(null);
        if (modeType === 'add'){
            getGiftTypeList()
        }
    }


    useEffect(() => {
        if (id  && (existingCountriesList.length > 0) && (!specificCountries) && (!allCountries)){
            fillCountries(existingCountriesList)
        }
    },[countriesList,existingCountriesList])

    const fillCountries = (gift_region) => {
        if (countriesList.length > 0){
            let storeCountryData: any = []
            gift_region.forEach(element => {
                storeCountryData.push({
                    countryId: element.country.id,

                    dbId: element.id
                }
                )
            });

            setStoredCountries(storeCountryData)
        }

        if (gift_region.length < countriesList.length){
            setSpecificCountries(true)

            let country_data:any = []
            gift_region.forEach((x) =>
                country_data.push(
                    {
                        id: x.country.id,
                        title: x.country.country_name,
                        countryCode: x.country.country_code,
                        countryCodeText: x.country.country_code_text,
                        currency: x.currency,
                        amount: x.price
                    }
                )
            )

            let tempArray:any = []
            gift_region.forEach(element => {
                tempArray.push(element.country.id)
            });

            let countriesData = countriesList.map((x) => {
                if (((tempArray).indexOf(x.id)) >= 0){
                    let value = gift_region.map(m => {
                        if ((m.country.id) === x.id){
                            return (m.price)
                        }
                    })
                    value = value.filter(item => item)
                    return {
                        ...x,
                        amount: value[0]
                        };
                    }
                });

            if (selectedCountriesList === undefined){
                setSelectedCountriesList(countriesData.filter(item => item))
                // change("countries",countriesData.filter(item => item))
            }

        }
        else if (gift_region.length === countriesList.length){
            setAllCountries(true);

            let tempArray:any = []
            gift_region.forEach(element => {
                tempArray.push(element.country.id)
            });

            let countriesData = countriesList.map((x) => {
                if (((tempArray).indexOf(x.id)) >= 0){
                    let value = gift_region.map(m => {
                        if ((m.country.id) === x.id){
                            return (m.price)
                        }
                    })
                    value = value.filter(item => item)
                    return {
                        ...x,
                        amount: value[0]
                        };
                    }
                });


            setCountriesList(countriesData.filter(item => item))

        }
    }

    const getPackageData = async() => {
        dispatch(ShowProgressBar());
        const response = await postRequest(PACKAGES_API.DETAIL, {
            gift_pack_id: id
        } , token);
        if (response){
            let data = response.data.data.gift_pack
            setPackageDbResponse(data);
            // setGiftTypeId(data.gift.id)
            // change("description", data.description)
            change("startDate", data.start_at)
            change('thumbnail', data.icon);
            setThumbnailImage(data.icon);
            change("endDate",data.expiry_at)
            change("title", data.title)
            change('runs', data.gift_quantity);

            let gift_region = data.gift_pack_regions

            setExistingCountriesList(gift_region)

            let afterStartPush = data.after_start_push_list;
            afterStartPush.forEach((value) => {
                if (value === startCheck1[1]){setStartCheck1([true,value])}
                if (value === startCheck2[1]){setStartCheck2([true,value])}
                if (value === startCheck3[1]){setStartCheck3([true,value])}
            })

            let beforeEndtPush = data.before_end_push_list;
            beforeEndtPush.forEach((value) => {
                if (value === endCheck1[1]){setEndCheck1([true,value])}
                if (value === endCheck2[1]){setEndCheck2([true,value])}
                if (value === endCheck3[1]){setEndCheck3([true,value])}
            })
        }
        dispatch(HideProgressBar());
    }

    const getGiftTypeList = async () => {
        let response = await postRequest(PACKAGES_API.GIFT_TYPE_LIST, {} , token);
        setGiftTypeId(response?.data?.data[0].id)
    }

    useEffect(() => {
        if (countriesList.length === 0) {
            getCountriesList();
        }
        if(modeType === 'add') {
            initForm();
        }
        else if (modeType === 'detail'){
            if (id){
                getPackageData()
            }
        }
    }, [modeType]);

    useEffect(() => {
        if (id && modeType === 'edit') {

        }
    }, [id]);


    const savePackage = async (values: any) => {
        // if (!values.startDate){
        //     values.startDate = new Date;
        // }
        if (values.startDate && values.endDate){
            if ((new Date(values.startDate)) > (new Date(values.endDate))){
                dispatch(ShowErrorAlert({ visible: true, message: 'Start Date cannot be equal or greator than end date.' }));
                return false;
            }
        }

        if (modeType === "add"){
            var durationInMinutes = 1;
            let pastTime = new Date()
            pastTime.setMinutes(pastTime.getMinutes() + durationInMinutes);

            if ( pastTime > (values.startDate)){
                dispatch(ShowErrorAlert({ visible: true, message: 'Please select a future start time 15 minutes from now.' }));
                return false;
            }
        }


        let data = createPackage(values);
        setBasicPackageDetails(data)
        setCurrentState(1);
        return true;
    };


    const handleCompressedUpload = async(e: any) => {
        const image = e.target.files[0];
        new Compressor(image, {
          quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
          success: async(res) => {

            let reader = new FileReader();
            reader.onload = (e: any) => {
                setThumbnailImage(e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);

            // upload file and obtain url for image
            setFileUploading(true);
            dispatch(ShowProgressBar());

            const formData = new FormData();

            formData.append('file', res, (Date.now()).toString() + e.target.files[0].name );

            const response: any = await postFileRequest(UPLOAD_FILE, formData, token);

            if (response) {
                const resData = response.data;
                if (resData.code === 200) {
                    let fileUrl = resData.data.file_path;
                    change( 'thumbnail', fileUrl);
                }
            } else {
                setThumbnailImage('')
            }

            dispatch(HideProgressBar());
            setFileUploading(false);
          },
        });
      };


    const handleFileSelect = async (event: any) => {
        if (event !== undefined && event.target.files && event.target.files[0]) {
            const filetype = event.target.files[0].type;

            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
            // if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 10000)) {
            if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 500)) {
                dispatch(ShowErrorAlert({ visible: true, message: 'Must be a .png, .jpg, .jpeg, .webp format image & not more than 500kb' }));
                event.target.value = null;
                return false;
            }


            handleCompressedUpload(event);

        }
        dispatch(HideProgressBar());
        setFileUploading(false);
        return true;
    };

    const handleFileRemove = () => {
        setThumbnailImage("");
        change('thumbnail', "");
    }

    const convertPriceCurrencyWise = () => {
        let keys = Object.keys(currencyFetched)
        let value = Object.values(currencyFetched)

        let amountInUsd = usdAmount

        let tempDict = countriesList.map((x) => {
            let currecy = x.currency;
            let countryPrice:any = value[keys.indexOf(currecy)]
            countryPrice = countryPrice ? countryPrice * amountInUsd : 0
        return {
            ...x,
            amount: parseFloat(countryPrice).toFixed(2)
            };
        });
        //@ts-ignore
        if (tempDict.length > 0){
            setCountriesList(tempDict);
        }

        if (specificCountries){

            if (selectedCountriesList && (selectedCountriesList.length > 0 )){
            let specificTempDict = selectedCountriesList.map((x) => {
                let currecy = x.currency;
                let countryPrice:any = value[keys.indexOf(currecy)]
                countryPrice = countryPrice ? countryPrice * amountInUsd : 0
                return {
                    ...x,
                    amount: parseFloat(countryPrice).toFixed(2)
                };
            });
            //@ts-ignore
            if (specificTempDict.length > 0){
                setSelectedCountriesList(specificTempDict);
            }
            }
        }
    }

    useEffect(() => {
        if (currencyFetched !== null) {
            convertPriceCurrencyWise();
        }
    },[currencyFetched]);

    const handleClickOpen = () => {
        setOpen(true);
      };

    const handleClose = () => {
        setOpen(false);
    };

    const saveValue = () => {

        const response: any = Axios.get("https://v6.exchangerate-api.com/v6/531db60ae04c271d532c30ce/latest/USD").then(
            x => {
                setCurrencyFetched(x.data.conversion_rates)
                setOpen(false);
            }

        );
        if(!allCountries && !specificCountries) {
            setAllCountries(true);
        }
    };



    const handleConversion = () => {
        return(
            <>
            <div className='text--center'>
                {/* <button type="button" className={`btn--pill button--outlined active`} style={{background: '#2c2b5d'}} onClick={() => handleClickOpen()}>
                    Calculate
                </button> */}

                <Dialog className='w--100' open={open} onClose={handleClose}>
                <DialogTitle style={{fontWeight: 600, fontSize: '1.4rem'}}>Enter Package Amount in USD</DialogTitle>
                <DialogContent>

                <div className="dialog__content__box m-b-20">
                    <label className='input--label m-b-10' htmlFor="passwordEdit">Amount</label>
                    <TextField
                    id="initialAmount"
                    type="number"
                    fullWidth
                    value={usdAmount}
                    onChange={(event) => {setUsdAmount(event.target.value)}}
                    // error={passwordError[0] || false}
                    // helperText={passwordError[1]}
                    />
                </div>

                </DialogContent>
                <DialogActions>
                    <CustomButton styleName='deleteButton normal--btn deleted-class' type='button' onClick={handleClose}>Cancel</CustomButton>
                    <CustomButton styleName='deleteButton normal--btn cancel-class' type='button' onClick={saveValue}>Confirm</CustomButton>
                </DialogActions>
                </Dialog>
            </div>
        </>
        );
    }


    const validateCountries = () => {
        let valid = true;

        if (allCountries){
            if (countriesList.length > 0){
                countriesList.every((x) => {
                    if (parseFloat(x.amount) === 0.00){
                        valid = false
                        dispatch(ShowErrorAlert({ visible: true, message: 'Package price cannot be 0 for ' + x.title }));
                        return false;
                    }
                    else{
                        return true;
                    }
                })
            }

        }else if (specificCountries){
            if (selectedCountriesList && selectedCountriesList.length > 0){

                selectedCountriesList.every((x) => {
                    if (parseFloat(x.amount) === 0.00){
                        valid = false
                        dispatch(ShowErrorAlert({ visible: true, message: 'Package price cannot be 0 for ' + x.title }));
                        return false;
                    }
                    else{
                        return true;
                    }
                })

            }
            else{
                valid = false
                dispatch(ShowErrorAlert({ visible: true, message: 'Kindly select any specific country where package will be available.' }));
            }
        }
        else{
            valid = false
            dispatch(ShowErrorAlert({ visible: true, message: 'Kindly select countries where package will be available.' }));
        }
        if (valid){
            setCurrentState(2);
        }
    }

    const submitForm = async () => {

        let startedPush: Array<string> = [];
        if (startCheck1[0]){startedPush.push(startCheck1[1])}
        if (startCheck2[0]){startedPush.push(startCheck2[1])};
        if (startCheck3[0]){startedPush.push(startCheck3[1])};

        let endPush: Array<string> = [];
        if (endCheck1[0]){ endPush.push(endCheck1[1])}
        if (endCheck2[0]){ endPush.push(endCheck2[1])}
        if (endCheck3[0]){ endPush.push(endCheck3[1])}


        let countriesSelected = allCountries ? countriesList : selectedCountriesList;

        countriesSelected = countriesMapper(countriesSelected, modeType);

        dispatch(ShowProgressBar());

        var response: any = null;

        let extras:any = {
            modeType: modeType,
            id: (id ? id : ""),
            storedCountries: storedCountries
        };
        let data = packageSubmitMapper(giftTypeId,basicPackageDetails,countriesSelected,startedPush,endPush,extras)

        if (modeType==="edit") {
            response = await postRequest(PACKAGES_API.UPDATE, data, token);
        } else {
            response = await postRequest(PACKAGES_API.CREATE, data, token);
        }
        if (response) {
            const resData = response.data;
            dispatch(ShowSuccessAlert({visible:true, message: resData.message}));
            if (modeType === "add"){
                navigate('/admin/package/', { replace: true })
            }
            else if (modeType ==="edit"){
                setModeType("detail");
                setCurrentState(0);
            }
        }

        dispatch(HideProgressBar());

    }

    const checkAvialable = () => {
        if (modeType==="detail") {
            return true;
        }
        return false;
    }

    const updateModeType = () => {
        if (modeType === "detail"){
            // if (allCountries){
            //     setAllCountries(!allCountries);
            //     setAllCountries(!allCountries);
            // }
            // if (specificCountries){
            //     setSpecificCountries(!specificCountries);
            //     setSpecificCountries(!specificCountries);
            // }
            setModeType("edit");
            // setCurrentState(0);
        }
        else{
            setModeType("detail");
            // setCurrentState(0);
        }
    }

    const buttonBox = (
        <>
        <CustomButton styleName='btn--pill button--outlined active' type='button' onClick={updateModeType}>
            {modeType === "detail" ? "Edit" : "Cancel" }
        </CustomButton>
        </>
    );


    useEffect(() => {
        setCountriesList(countriesList);
        setSelectedCountriesList(selectedCountriesList);
    },[allCountries,specificCountries]);

    return (
        <>
        <Box className={`${(modeType==="detail") ? "": "allPointer"}`}>
        <Box className="dashboard__topbar" style={{padding: '1.5rem 3rem 1.2rem'}}>
            <Box className="dashboard__topbar--heading col-12">
                <Box className="row align--items--center">

                <div className="md-col-8 mb--20 mb--md--0">
                    <Box className="button__text__navigate">
                        {
                        (modeType === "edit")
                        ?
                        <>
                            <Box component="h2">
                            </Box>
                        </>
                        :
                            <button onClick={() => navigate(-1)
                            }><ArrowBackOutlinedIcon /></button>
                        }
                        <Box component="h2" style={{
                                fontSize: '1.5rem',
                                lineHeight: 'normal'
                        }}>{ (modeType === "add") ? "Add Package" : (modeType === "detail") ? "Package Detail" : "Edit Package"}</Box>
                    </Box>
                </div>
                    {(modeType !== "add") ?
                        <div className="md-col-4">
                            <Box className="button__box">{buttonBox}</Box>
                        </div>
                    : ""
                    }
                </Box>
            </Box>
        </Box>
        <Box className='dashboard__content newstepperui'>
            <div className='newstepperui__block'>
                <HorizontalLabelPositionBelowStepper currentState={currentState} setCurrentState={setCurrentState}/>
                {
                    (modeType === "add") ?
                        ""
                    : (packageDbResponse?.sku) ?
                        <span className='sku--no'>
                            {packageDbResponse?.sku}
                        </span>
                    :
                    ""
                }
            </div>
        </Box>

        {
            currentState === 0 ?
            <Box className='dashboard__content pt--1 max--760 mx--auto'>
                <Box component="form" id='first-page' onSubmit={handleSubmit(savePackage)}>
                    <Box component="div" className="row">
                        <div className='col-12 md-col-12 m-b-20'>
                            <label className='input--label m-b-10' htmlFor="title">Package Title (max 256 chars)<sub className='text--danger'>*</sub></label>
                            <Field name="title" component={TextField} type="text" placeholder='Write your package title here' disabled={checkAvialable()}
                                //@ts-ignore
                                styleName='input--transparent' />
                        </div>
                        <div className='col-12 m-b-20'>
                            <label className='input--label m-b-10' htmlFor="runs">Runs<sub className='text--danger'>*</sub></label>
                            <Field name="runs" component={TextField} type="number" min="1" placeholder='' disabled={checkAvialable()}
                                //@ts-ignore
                                styleName='input--transparent' />
                        </div>

                        {/* <div className='col-12 md-col-12 m-b-20 overflow--hidden'>
                            <label className='input--label m-b-10' htmlFor="description">Description (max 4096 chars)</label>
                            <Field name="description" component={TextArea} row='7' type="text" placeholder='Write description' disabled={checkAvialable()}
                                styleName='input--transparent' />
                        </div> */}

                        <div className='col-12 md-col-6 m-b-20 overflow--hidden bdr-rad--6'>
                            <label className='input--label m-b-10' htmlFor="startDate">Start Date</label>
                                <FormControl fullWidth>
                                    {/* <Field component={renderDateField} name="startDate" disablePrevious={true} disabled={checkAvialable()}/> */}
                                    <Field component={renderPackageDateTimeField} name="startDate" disablePrevious={true} disabled={checkAvialable()}/>

                                </FormControl>
                        </div>

                        <div className='col-12 md-col-6 m-b-20 overflow--hidden bdr-rad--6'>
                            <label className='input--label m-b-10' htmlFor="endDate">End Date</label>
                            <FormControl fullWidth>
                                {/* <Field component={renderDateField} name="endDate" disablePrevious={true} isNull={true} disabled={checkAvialable()}/> */}
                                <Field component={renderPackageDateTimeField} name="endDate" disablePrevious={true} isNull={true} disabled={checkAvialable()}/>
                            </FormControl>
                        </div>


                        <div className="col-12 md-col-6">
                            <div className='row m-b-10 align--items--center'>
                                <div className='col-9'>
                                    <label className='input--label' htmlFor="thumbnail">Icon(ratio: 1:1)</label>
                                </div>
                                <div className='col-3 text--end'>
                                    <Tooltip title="Get your image ready by making sure it has right width, height, resolution, size, type. This protect's the image quality & prevents error during upload process. Recomended aspect ratio is 16:9 & less than 500 kb for best fit."
                                    PopperProps={{
                                        sx: {
                                        "& .MuiTooltip-tooltip": {
                                            backgroundColor: '#191840',
                                            fontSize: '12px',
                                            padding: '12px',
                                            fontWeight: 600,
                                            border: '1px solid #F89E24'
                                        }
                                        }
                                    }}
                                    >
                                        <HelpOutlineIcon/>
                                    </Tooltip>
                                </div>
                            </div>

                            <Field
                                name="thumbnail"
                                type="hidden"
                                component="input"
                                id="thumbnail"
                                disabled={checkAvialable()}
                                placeholder="thumbnail"
                            />
                            <Field
                                name="id"
                                type="hidden"
                                component="input"
                                disabled={checkAvialable()}
                                id="id"
                                placeholder="id"
                            />
                            {!thumbnailImage || thumbnailImage === '' ?
                                (<Box component="div"
                                    sx={{
                                        height: '200px',
                                        width: '100%',
                                        background: '#232157',
                                        marginBottom: "20px",
                                        position: 'relative',
                                        borderRadius: "15px",
                                        cursor: "pointer"
                                    }}
                                >
                                        <Box
                                            component="div"
                                            sx={{
                                                position: 'absolute',
                                                left: '50%',
                                                top: '50%',
                                                transform: 'translate(-50%,-50%)',
                                            }}
                                        >
                                            <UploadIcon />
                                        </Box>
                                        <Box
                                            component="input"
                                            type="file"
                                            name="thumbnail"
                                            disabled={checkAvialable()}
                                            onChange={handleFileSelect}
                                            sx={{
                                                height: '100%',
                                                width: '100%',
                                                opacity: '0',
                                                cursor: 'pointer',
                                            }}
                                        ></Box>
                                    </Box>
                                ) : (
                                    <Box
                                    style={{
                                        background: '#232157',
                                        padding: '15px',
                                        marginBottom: "20px",
                                        borderRadius: "15px",
                                        minHeight: '200px'
                                    }}
                                    >
                                        <Box className="ratio16-9"
                                            sx={{
                                                width: '100%',
                                                position: 'relative',
                                                cursor: "pointer"
                                            }}
                                        >

                                            {submitting || fileUploading ?
                                            '' : <CustomButton type="button" styleName="remove--pic" disabled={checkAvialable()} style={{
                                                top: '-25px',
                                                right: '-25px',
                                            }} onClick={() => handleFileRemove()}>
                                            <CancelIcon />
                                        </CustomButton>}
                                            <Box
                                                component="img"
                                                src={thumbnailImage}
                                                sx={{
                                                    maxHeight: '100%',
                                                    maxWidth: '100%',
                                                    objectFit: 'contain',
                                                    borderRadius: '15px',
                                                    margin: 'auto'
                                                }}
                                            ></Box>
                                        </Box>
                                    </Box>
                                )}
                        </div>
                            <div className="col-12 m-b-40">
                                <button type="submit" className={`btn--pill button--outlined active`} disabled={submitting || fileUploading}>
                                    {showSubmitButtonLabel(submitting, fileUploading, modeType)}
                                </button>
                            </div>
                        </Box>
                    </Box>
            </Box>
            :

            currentState === 1 ?

                <Box className='dashboard__content '>
                    <FormGroup>
                        <div className="row align-items-md-end shrinkBtn">
                            <div className='col-12 col--arrow text--end'>
                                <button type="button" onClick={() => setCurrentState(0)} style={{marginRight: '10px'}}>
                                    <ArrowBackIosNewIcon/>
                                    <span  style={{marginLeft: '5px', fontWeight: '500'}}>Prev</span>
                                </button>
                                <button type="button" style={{marginLeft: '10px'}} onClick={() => validateCountries()}>
                                    <span style={{marginRight: '5px', fontWeight: '500'}}>Next</span>
                                    <ArrowForwardIosIcon/>
                                </button>
                            </div>
                            <div className="col-12 md-col-5 xl-col-4 xxl-col-3">
                            <label className='input--label m-b-10' htmlFor="passwordEdit">Enter package amount in USD</label>
                                <TextField
                                className='input--transparent'
                                id="initialAmount"
                                type="number"
                                min="1"
                                fullWidth
                                value={usdAmount}
                                onChange={(event) => {setUsdAmount(event.target.value)}}
                                disabled={checkAvialable()}
                                // error={passwordError[0] || false}
                                // helperText={passwordError[1]}
                                />
                            </div>
                            <div className='col-12 m-t-10 md-col-auto'>
                                <button type="button" className={`btn--pill button--outlined active`} disabled={checkAvialable()} style={{background: '#2c2b5d'}} onClick={saveValue}>
                                    Convert
                                </button>
                            </div>
                            <div className="col-12 md-col-auto text--center m-t-30">

                                    <FormControlLabel control={
                                        <Checkbox checked={allCountries} disabled={checkAvialable()}/>
                                    } label= "All Countries"
                                    className={"checkbox--white"}
                                    onChange={(event) => {
                                        if (!allCountries){setSpecificCountries(false)}
                                        setAllCountries(!allCountries);
                                        }
                                        }
                                    />

                                    <FormControlLabel control={
                                        <Checkbox checked={specificCountries} disabled={checkAvialable()}/>
                                    } label= "Specific Countries"
                                    className={"checkbox--white"}
                                    onChange={(event) => {
                                        if (!specificCountries){setAllCountries(false)}
                                        setSpecificCountries(!specificCountries);
                                        }}
                                    />
                            </div>
                            <Box className={`col-12 m-t-10 ${allCountries ? 'dynamic__spacing' : ''}`} style={{
                                position: 'relative',
                                paddingTop: '20px'
                            }}>
                                {
                                    allCountries ?
                                    <CountryCurrencyListDataTable countryListContent={countriesList} setCountryListContent={setCountriesList} modeType={modeType} setAllCountryListContent={setSelectedCountriesList} allCountryListContent={selectedCountriesList} selectionType={"allCountry"}/>
                                    :
                                    specificCountries ?

                                        <div className='col-12 m-b-20'>
                                            <div className="specificlist__wrapper">
                                                <label className='input--label m-b-10' htmlFor="stream-countries">Countries</label>
                                                <div className="m-b-20">
                                                    <FormControl className='input-inner-transparent input__py__shrink' fullWidth>
                                                        <Field name="countries" component={renderCountriesField} id="stream-countries" options={countriesList} defaultValue={[]} countryValue={selectedCountriesList} setCountryValue={setSelectedCountriesList} disabled={checkAvialable()}/>
                                                    </FormControl>
                                                </div>
                                            </div>

                                        <CountryCurrencyListDataTable countryListContent={selectedCountriesList} setCountryListContent={setSelectedCountriesList} modeType={modeType} setAllCountryListContent={setCountriesList} allCountryListContent={countriesList} selectionType={"specificCountry"}/>
                                        </div>

                                :
                                    ""
                                }
                            </Box>
                            <div className="col-12">
                                {
                                (modeType ==='detail')
                                ?
                                ""
                                :
                                handleConversion()
                                }
                            </div>
                        </div>
                    </FormGroup>
                </Box>
            :
            <>
                <div className="">
                    <div className=''>
                        <div className='col-12 col--arrow text--end'>
                            <button type="button" onClick={() => setCurrentState(1)} style={{marginRight: '10px'}}>
                                <ArrowBackIosNewIcon/>
                                <span  style={{marginLeft: '5px', fontWeight: '500'}}>Prev</span>
                            </button>
                            <button type="button" style={{marginLeft: '10px'}}>


                            </button>
                        </div>
                    </div>
                </div>
                <Box className='dashboard__content max--760 mx--auto'>
                        <div className='col-12 m-b-20'>
                            <label className='input--label m-b-10' htmlFor="stream-countries">After start of package</label>
                            <FormGroup>
                                <div className='row'>
                                    <div className="col-12 md-col-4 m-b-40">
                                            <FormControlLabel control={
                                                <Checkbox checked={startCheck1[0]} disabled={checkAvialable()}/>
                                            } label= {`${startCheck1[1]} hrs`}
                                            className={"checkbox--white"}
                                            onChange={(event) => {
                                                setStartCheck1([!startCheck1[0],startCheck1[1]])
                                            }
                                        }
                                        />
                                    </div>

                                    <div className="col-12 md-col-4 m-b-40">
                                            <FormControlLabel control={
                                                <Checkbox checked={startCheck2[0]} disabled={checkAvialable()}/>
                                            } label= {`${startCheck2[1]} hrs`}
                                            className={"checkbox--white"}
                                            onChange={(event) => {
                                                setStartCheck2([!startCheck2[0],startCheck2[1]])
                                            }
                                        }
                                        />
                                    </div>

                                    <div className="col-12 md-col-4 m-b-40">
                                            <FormControlLabel control={
                                                <Checkbox checked={startCheck3[0]} disabled={checkAvialable()}/>
                                            } label= {`${startCheck3[1]} hrs`}
                                            className={"checkbox--white"}
                                            onChange={(event) => {
                                                setStartCheck3([!startCheck3[0],startCheck3[1]])
                                            }
                                        }
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </div>

                        <div className='col-12 m-b-20'>
                            <label className='input--label m-b-10' htmlFor="stream-countries">Before End of package</label>
                            <FormGroup>
                                <div className='row'>
                                    <div className="col-12 md-col-4 m-b-40">
                                            <FormControlLabel control={
                                                <Checkbox checked={endCheck1[0]} disabled={checkAvialable()}/>
                                            } label= {`${endCheck1[1]} hrs`}
                                            className={"checkbox--white"}
                                            onChange={(event) => {
                                                setEndCheck1([!endCheck1[0],endCheck1[1]])
                                            }
                                        }
                                        />
                                    </div>

                                    <div className="col-12 md-col-4 m-b-40">
                                            <FormControlLabel control={
                                                <Checkbox checked={endCheck2[0]} disabled={checkAvialable()}/>
                                            } label= {`${endCheck2[1]} hrs`}
                                            className={"checkbox--white"}
                                            onChange={(event) => {
                                                setEndCheck2([!endCheck2[0],endCheck2[1]])
                                            }
                                        }
                                        />
                                    </div>

                                    <div className="col-12 md-col-4 m-b-40">
                                            <FormControlLabel control={
                                                <Checkbox checked={endCheck3[0]} disabled={checkAvialable()}/>
                                            } label= {`${endCheck3[1]} hrs`}
                                            className={"checkbox--white"}
                                            onChange={(event) => {
                                                setEndCheck3([!endCheck3[0],endCheck3[1]])
                                            }
                                        }
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </div>
                        <div className="">
                            <div className=" text--center">
                                {
                                    modeType === "detail"
                                    ?
                                    ""
                                    :
                                    <button type="button" className={`btn--pill button--outlined active`} onClick={() => submitForm()}>
                                        Submit
                                    </button>
                                }
                            </div>
                        </div>
                </Box>
            </>
            }
            </Box>

        </>
    );
};

const showSubmitButtonLabel = (submitting: boolean, fileUploading: boolean, modeType:string) => {
    if (submitting) {
        return 'Saving...';
    } else if (fileUploading) {
        return 'Saving Photo...';
    }
    else {
        if (modeType === 'edit'){
            return `Next`;
        }
        return `Next`;
    }
};

export default reduxForm({
    form: 'package',
    validate,
})(PackageCreateForm);

