import { Box, Paper } from '@mui/material';
import React from 'react';
import CustomCreatorProfileForm from '../customCreatorProfile/ProfileForm';
import GoToTop from '../common/GoTop'

interface CreatorAddProps {
    modeType: string;
    // back: string;
}

const AddCreator = (props: CreatorAddProps) => {
    return (
        <>
            {//@ts-ignore
            <CustomCreatorProfileForm modeType={props.modeType} />}
            <GoToTop/>

        </>
    );

}

export default AddCreator;
