import React from 'react';
import SignIn from './components/signIn/SignIn.Component'
import ProfileSetUp from './components/signIn/ProfileSetUp.Component'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

import './styles/App.scss';
import { RootStateInterface, RootStateType } from './store';
// import { creatorState } from './store/creator';
import { userState } from './store/user';

import { Navigate } from "react-router";
import DashboardLayout from './components/layout/DashboardLayout';
import PreviewContainer from './100ms/PreviewContainer';

import "./100ms/base.css";
import "./100ms/index.css";
import ConfrenceContainer from './100ms/ContrenceContainer';
import { useDispatch, useSelector } from "react-redux";
import { Alert, Box, LinearProgress, Snackbar } from "@mui/material";
import { ShowErrorAlert, ShowSuccessAlert } from './store/alerts';

import PasswordReset from './components/pages/ResetAppPassword';

function App() {

    const dispatch = useDispatch();
    const alerts = useSelector((state: RootStateType) => state.alerts);


    const handleClose = () => {
        dispatch(ShowSuccessAlert({visible:false, message: ""}));
    }

    const handleErrorClose = () => {
        dispatch(ShowErrorAlert({visible:false, message: ""}));
    }

    const publicRoutes = (
        <>
            <Route path='/password_reset/:token' element={
                <PasswordReset type="change_password"/>
            } />

            <Route path='/gift/purchase/checkout/:exp_token/:intent' element={
                <PasswordReset type="redirect_to_stripe"/>
            } />

            <Route path='/success/' element={
                <PasswordReset type="success_stripe_redirect_to_app"/>
            } />

            <Route path='/fail/' element={
                <PasswordReset type="fail_stripe_redirect_to_app"/>
            } />
        </>
    )


    return (
        <>
            {alerts.loader.visible ? <Box component={'div'} sx={{ width: '100%', position:'fixed', zIndex:"9999" }}>
                <LinearProgress sx = {{
                    backgroundColor: 'var(--brand--seondary)'
                }}/>
            </Box> : ''}
            <Snackbar anchorOrigin={{ vertical : 'top', horizontal : 'center' }} open={alerts.success.visible} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%', color: '#fff' }}>
                    {alerts.success.message}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical : 'top', horizontal : 'center' }} open={alerts.error.visible} autoHideDuration={3000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%', color: '#fff' }}>
                    {alerts.error.message}
                </Alert>
            </Snackbar>
            <Router>
                <Routes>
                    <Route path='/' element={<SignIn />}></Route>
                    <Route path='/preview/:roomId' element={
                            <PreviewContainer />
                    }></Route>
                    <Route path='/meeting/:roomId' element={<ConfrenceContainer />}></Route>

                    <Route path='/profile-setup' element={
                        <PrivateRoute>
                            <ProfileSetUp />
                        </PrivateRoute>
                    } />

                    {publicRoutes}

                    <Route path='/creator/*' element={
                        <PrivateRoute>
                            <DashboardLayout />
                        </PrivateRoute>
                    } />
                    <Route path='/admin/*' element={
                        <PrivateRoute>
                            <DashboardLayout role="AdminRole"/>
                        </PrivateRoute>
                    } />
                    <Route path='/super_admin/*' element={
                        <PrivateRoute>
                            <DashboardLayout role="SuperAdminRole"/>
                        </PrivateRoute>
                    } />
                </Routes>
            </Router>
        </>
    );
}

export const PrivateRoute = ({ children, ...rest }: any) => {
    // const creator: creatorState = useSelector((state: RootStateInterface) => state.creator);
    const user: userState = useSelector((state: RootStateInterface) => state.user);

    if (((user.access_token?.length > 0) || (user.access_token?.length > 0)) === false) {
        return <Navigate to={{ pathname: "/" }} />
    }

    return children;
}

// export const AdminRoute = ({ children, ...rest }: any) => {
//     // const creator: creatorState = useSelector((state: RootStateInterface) => state.creator);
//     const superAdmin: superAdminState = useSelector((state: RootStateInterface) => state.superAdmin);

//     if (superAdmin && (superAdmin.access_token?.length > 0) === false) {
//         return <Navigate to={{ pathname: "/" }} />
//     }

//     return children;
// }

export default App;
