import React from 'react';
import AppVersionForm from '../appVersion/form';
import GoToTop from '../common/GoTop'


interface AppVersionCreateProps {
    modeType: string;
    back: string;
}

const AppVersionCreate = (props:AppVersionCreateProps) => {
    return (
        <>
            {//@ts-ignore
            <AppVersionForm modeType={props.modeType} back={props.back} />}
            <GoToTop/>
        </>
    );

}


export default AppVersionCreate;
