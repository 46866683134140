import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { userContentDataModifiedObj } from '../../data/mappers/dashboard';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export function SortedDescendingIcon() {
    return <ExpandMoreIcon className="icon" />;
  }

  export function SortedAscendingIcon() {
    return <ExpandLessIcon className="icon" />;
  }


function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <Box
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <TextField
                className="searchvalue"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" sx={{mr: 2}} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 0, hide: true },
    {
        field: 'date',
        headerName: 'Date & Time Created',
        type: 'date',
        width: 220,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <>
                <label>{moment(params.row.date).format('D.MM.YYYY, h:mm a')}</label>
            </>
        ),
    },
    { field: 'title', headerName: 'Title', type: 'string', width: 180 },
    { field: 'type', headerName: 'Status', type: 'string', width: 150 },
    { field: 'streamtags', headerName: 'Tag', type: 'string', width: 220 },
    // { field: 'count', headerName: 'View Count', type: 'number', width: 150, align: 'left', headerAlign: 'left' },
    // {
    //     field: 'status',
    //     headerName: 'Status',
    //     type: 'string',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 180,
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <>
    //             <label className={params.row.id % 2 == 0 ? 'active-class' : 'suspended-class'}>
    //                 {params.row.id % 2 == 0 ? 'Active' : 'Suspended'}
    //             </label>
    //         </>
    //     ),
    // },
    // {
    //     field: 'edit',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'edit-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <Link to={`/creator/edit_match/${params.row.id}`}>
    //             {/* <button disabled={true} type="button">Edit</button> */}
    //             <button type="button">Edit</button>
    //         </Link>
    //     ),
    // },
    // {
    //     field: 'view',
    //     headerName: '',
    //     type: 'actions',
    //     align: 'center',
    //     headerAlign: 'center',
    //     width: 100,
    //     sortable: false,
    //     cellClassName: 'edit-actions',
    //     renderCell: (params: GridRenderCellParams<Date>) => (
    //         <Link to={`/creator/match/${params.row.id}`}>
    //             <button type="button">View</button>
    //         </Link>
    //     ),
    // },

    {
        field: 'view',
        headerName: '',
        type: 'actions',
        align: 'left',
        headerAlign: 'left',
        width: 100,
        sortable: false,
        cellClassName: 'view-actions',
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Link to={`/creator/match/${params.row.id}`}>
                <span><ArrowRightAltIcon/></span>
            </Link>
        ),
    },
];

// const data = {
//     rows: [
//         {
//             id: 1,
//             date: '27/1/2021',
//             title: 'Smash it for 11',
//             type: 'Podcast',
//             tag: '#Musical  #Humorous',
//             count: '1,625,532',
//             status: 'Active',
//         },
//         {
//             id: 2,
//             date: '27/1/2021',
//             title: 'Smash it for 10',
//             type: 'Videos',
//             tag: '#Musical  #Humorous',
//             count: '1,625,532',
//             status: 'Deleted',
//         },
//         {
//             id: 3,
//             date: '27/1/2021',
//             title: 'Smash it for 09',
//             type: 'Podcast',
//             tag: '#Musical  #Humorous',
//             count: '1,625,532',
//             status: 'Suspended',
//         },
//         {
//             id: 4,
//             date: '27/1/2021',
//             title: 'Smash it for 08',
//             type: 'Videos',
//             tag: '#Musical  #Humorous',
//             count: '1,625,532',
//             status: 'Active',
//         },
//         {
//             id: 5,
//             date: '27/1/2021',
//             title: 'Smash it for 07',
//             type: 'Podcast',
//             tag: '#Musical  #Humorous',
//             count: '1,625,532',
//             status: 'Deleted',
//         },
//         {
//             id: 6,
//             date: '27/1/2021',
//             title: 'Smash it for 06',
//             type: 'Videos',
//             tag: '#Musical  #Humorous',
//             count: '1,625,532',
//             status: 'Suspended',
//         },
//         {
//             id: 7,
//             date: '27/1/2021',
//             title: 'Smash it for 05',
//             type: 'Podcast',
//             tag: '#Musical  #Humorous',
//             count: '1,625,532',
//             status: 'Active',
//         },
//         {
//             id: 8,
//             date: '27/1/2021',
//             title: 'Smash it for 04',
//             type: 'Videos',
//             tag: '#Musical  #Humorous',
//             count: '1,625,532',
//             status: 'Deleted',
//         },
//         {
//             id: 9,
//             date: '27/1/2021',
//             title: 'Smash it for 03',
//             type: 'Podcast',
//             tag: '#Musical  #Humorous',
//             count: '1,625,532',
//             status: 'Suspended',
//         },
//         {
//             id: 10,
//             date: '27/1/2021',
//             title: 'Smash it for 02',
//             type: 'Videos',
//             tag: '#Musical  #Humorous',
//             count: '1,625,532',
//             status: 'Active',
//         },
//         {
//             id: 11,
//             date: '27/1/2021',
//             title: 'Smash it for 01',
//             type: 'Podcast',
//             tag: '#Musical  #Humorous',
//             count: '1,625,532',
//             status: 'Deleted',
//         },
//     ],
// };

interface userContentDataTableProps {
    userContent: Array<userContentDataModifiedObj>;
}

export default function DataTable({ userContent }: userContentDataTableProps) {
    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState<Array<userContentDataModifiedObj>>(userContent);

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = userContent.filter((row: any) => {
          return Object.keys(row).some((field: any) => {
            return searchRegex.test(row[field].toString());
          });
        });
        setRows(filteredRows);
    };

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
          field: 'id',
          sort: 'desc',
        }
      ]);

    useEffect(() => {
        setRows(userContent);
    }, [userContent]);

    function NoRowsOverlay() {
        return (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No result found
          </Stack>
        );
      }

    return (
        <>
            <div style={{ height: 500, width: '100%', marginTop: '22px' }}>
                <DataGrid
                    className="datatable__custom"
                    disableColumnMenu
                    components={{ Toolbar: QuickSearchToolbar,ColumnSortedDescendingIcon: SortedDescendingIcon, ColumnSortedAscendingIcon: SortedAscendingIcon, NoRowsOverlay }}
                    rows={rows}
                    columns={columns}
                    pageSize={9}
                    sortingOrder={['desc', 'asc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    // checkboxSelection
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                />
            </div>
        </>
    );
}
