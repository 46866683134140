const createAndroidVersion = (data: any, isForceUpdate:boolean ) => {
    let appVersion:any = {
        version: data.androidVersion,
        isForceUpdate: isForceUpdate ?? false,
        deviceType: "1",
    }

    if(data.id) {
        appVersion.id = (data.id).toString();
    }
    return appVersion;
}

const createIosVersion = (data:any, isForceUpdate:boolean) => {
    let appVersion:any = {
        version: data.iosVersion,
        isForceUpdate: isForceUpdate ?? false,
        deviceType: "2"
    }
    if(data.id) {
        appVersion.id = (data.id).toString();
    }
    return appVersion;
}

export const createAppVersion = (data: any, isForceUpdate:boolean, androidChecked:boolean, ioschecked:boolean ) => {

    let appVersion:any = []
    if (androidChecked){
        appVersion.push(createAndroidVersion(data, isForceUpdate))
    }
    if (ioschecked){
        appVersion.push(createIosVersion(data, isForceUpdate))
    }

    let finalData:any = {
        version_list: appVersion
    }
    return finalData;
};


export const editAppVersion = (data:any, isForceUpdate:boolean) =>{
    let appVersion:any = {
        version: data.iosVersion || data.androidVersion,
        isForceUpdate: isForceUpdate ?? false,
    }
    if(data.id) {
        appVersion.version_id = (data.id).toString();
    }
    return appVersion;
}
