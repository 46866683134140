import { Reducer } from "react";
import { CLEAR_SHOW_DATA, UPDATE_SHOW_DATA } from "../../constants";
import { ShowInterface, ShowActions } from "./types";

export const initialState: ShowInterface = {
    title: "",
    id: 0,
    description: "",
    logo:""
}


export const showReducer: Reducer<ShowInterface, ShowActions> = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case UPDATE_SHOW_DATA:
            return {
                // @ts-ignore
                ...payload.payload,
            };
        case CLEAR_SHOW_DATA:
            return {
                ...initialState
            };
        default:
            return state;
    }
};
