import { Box } from '@mui/material';

import EpisodeListDataTable from '../common/EpisodeListDataTable';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { Scrollbars } from 'react-custom-scrollbars-2';

import React, { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PODCAST_API } from '../../constants';
import { isApiCodeSucess, postRequest } from '../../data/helpers/services';
import { RootStateType } from '../../store';

import SingleStar from '../../icons/SingleStarIcon'
import { episodeListContentData, episodeListContentDataModified } from '../../data/mappers/episodeList';
import { UpdateEpisodeListContent, AddEpisodeListContent, TotalEpisodeSave, EpisodeCurrentPage } from '../../store/episodeList';

import { HideProgressBar, ShowProgressBar } from '../../store/alerts';
import profilepicplaceholder from '../../styles/images/placeholder.png';


import { ShowInterface, saveShowDetails } from '../../store/show';
import GoToTop from '../common/GoTop'

const ShowDetail = () => {

    let navigate = useNavigate();

    const[ deleted,setDeleted ] = useState<boolean>(false);

    const { id } = useParams();
    const [showDetails, setShowDetails] = useState<ShowInterface | null>(null);

    const [rating, setRating] = useState<string | undefined>("");
    const [ratingCount, setRatingCount] = useState<string | undefined>("");


    const [ offsetValue, setOffsetValue ] = useState<number | 0>(0)
    const [ maxNotification, setMaxNotification ] = useState<number | 0 >(0)
    const [ searchValue, setSearchValue ] = useState<string | "">("")
    const [ loader, setLoader ] = useState<boolean>(false)

    const [ compareSearchRedux, setCompareSearchRedux ] = useState<string | "">("")
    const [ currentPage, setCurrentPage ] = useState<number>(0)


    let user = useSelector((state: RootStateType) => state.user);
    let token = user.access_token;

    let deleteAccess = user.permissions.series_delete;

    const dispatch = useDispatch();

    const episodeListObject = useSelector((state: RootStateType) => state.episodeList);
    const episodeListContent = episodeListObject.episodeListContent;

    if ((episodeListObject.currentPage) && (episodeListObject.currentPage !== currentPage)) {
        setCurrentPage(episodeListObject.currentPage);
    }else if ((episodeListObject.currentPage === 0) && (currentPage !== 0)) {
        setCurrentPage(0)
    }

    if ((episodeListObject.searchedValue) && (episodeListObject.searchedValue !== searchValue)){
        setSearchValue(episodeListObject.searchedValue);
    }

    if ((currentPage > 0) && (offsetValue === 0)){
        setOffsetValue(episodeListContent.length)
    }

    if ((currentPage > 0) && (offsetValue === 0) && (searchValue !== compareSearchRedux)) {
        setOffsetValue(episodeListContent.length)
    } else if ((currentPage > 0) && (offsetValue === 0)) {
        setOffsetValue(episodeListContent.length)
    }

    // useEffect(() => {
    //     if (id){
    //         if ((currentPage !== 0) &&
    //             (((currentPage+4)*5) <= episodeListContent.length) &&
    //             (episodeListObject.totalEpisode >= episodeListContent.length) &&
    //             (searchValue === compareSearchRedux) &&
    //             (offsetValue !== 0)
    //             ){
    //                 setMaxNotification(episodeListObject.totalEpisode)
    //             } else{
    //                 getEpisodeListContent(searchValue, parseInt(id));
    //                 setCompareSearchRedux(searchValue);
    //             }
    //     }

    // }, [offsetValue, searchValue]);

    useEffect(() => {
        if(id) {
            getEpisodeListContent(searchValue,parseInt(id));
        }
    }, [offsetValue, searchValue]);



    const getEpisodeListContent = async (searchingValue: string, id: number|undefined) => {
        dispatch(ShowProgressBar());

        let res:any = {}
        if ((searchingValue === (searchValue) || (searchingValue === ""))) {
            setLoader(true);
            res = await postRequest(PODCAST_API.SERIES_DETAIL,{
                "seriesId": id,
                "offset": offsetValue,
                "search_text": searchValue
            }, token);
        }

        if (isApiCodeSucess(res)) {
            dispatch(HideProgressBar());
            setRating(res.data?.data?.ratingAverage);
            setRatingCount(res.data?.data?.ratingCount);
            if ((searchingValue === (searchValue) || (searchingValue === ""))) {
                setMaxNotification(res.data?.data.episodes_count)
                dispatch(TotalEpisodeSave(res.data?.data.episodes_count))

                setShowDetails(res.data.data);

                dispatch(HideProgressBar());

                dispatch(saveShowDetails(res.data.data));

                let formattedEpisodeListContentData = res.data?.data.episodes as episodeListContentData,
                formattedEpisodeListContent: episodeListContentDataModified = [];
                    formattedEpisodeListContentData.forEach((x) => {

                        formattedEpisodeListContent.push({
                            title: x.title,
                            showId: (x.series.id),
                            id: x.id,
                            description: x.description,
                            authors:(x.authors.map((author) => {
                                return (author?.user?.firstName) ? (author?.user?.firstName) : (author?.user?.lastName)
                            }).join(', ')),

                            episodeLikeCount: x.episodeLikeCount ?? 0,
                            runs:x.runs_received,
                            date: x.createdAt,
                            deleteStatus: setDeleted,
                            isActive: x.isActive,

                            deleteAccess:deleteAccess,
                        });
                    });
                // dispatch(UpdateEpisodeListContent(formattedEpisodeListContent));
                if (!offsetValue){
                    dispatch(EpisodeCurrentPage(0))
                    dispatch(UpdateEpisodeListContent(formattedEpisodeListContent));
                }
                else{
                    dispatch(AddEpisodeListContent(formattedEpisodeListContent))
                }
            }
        }
        setLoader(false);

    };


    const getShowDetail = async (id: number|undefined) => {
        dispatch(ShowProgressBar());

        const response = await postRequest(PODCAST_API.SERIES_DETAIL, {
            seriesId: id
        }, token);
        if (response) {
            if (response.data.code === 200) {
                setShowDetails(response.data.data);

                dispatch(HideProgressBar());

                dispatch(saveShowDetails(response.data.data));
            }
        }
    }

    // useEffect(() => {
    //     if (id) {
    //         getShowDetail(parseInt(id));
    //     }
    // }, [id]);

    const buttonBox = (
        <>
        <Button
        type="button"
        styleName={`button--outlined active`}
            onClick={() => navigate('/admin/show/detail/' + id + '/edit/',{ replace: true })}
        >Edit Show</Button>

        <Button
            type="button"
            styleName={`button--outlined active`}
            onClick={() => navigate('/admin/show/' + id +'/episode/',{ replace: true })}
        >Add New Episode
        </Button>
        </>
    )

    return (
        <>
            <Box className="dashboard__topbar">
                <Box className="row align--items--center">
                    <div className="md-col-5 mb--20 mb--md--0">
                        <Box className="button__text__navigate">
                            <button onClick={() => navigate(-1)}><ArrowBackOutlinedIcon /></button>
                            <Box className="dashboard__topbar--heading" component="h2">Show Details</Box>
                        </Box>
                    </div>
                    <div className="md-col-7">
                        <Box className="button__box font--14">{buttonBox}</Box>
                    </div>
                </Box>
            </Box>

            <Box className='dashboard__content'>
                    <Box className='showdetail__topbar'>
                        {showLogoBox(showDetails?.logo)}
                        <Box>
                            <Box component="h2" className='showdetail__topbar--title'>
                                {showDetails?.title}
                            </Box>
                            <Box className='showdetail__ratingBox'>
                                <Box className='showdetail__ratingBox__outer'>
                                    <b>{rating || "0.0"}</b>
                                    <SingleStar/>
                                    <span>({ratingCount || "0"})</span>
                                </Box>
                            </Box>
                            <Box className='showdetail__creators'>
                                <Box>
                                    <label htmlFor="" className='creator--title'>Creators</label>
                                    {showCretorBox(getHostsString(showDetails, user))}
                                </Box>
                            </Box>
                        </Box>
                    </Box>


                    <Box className='showdetail__descBox'>
                        <Scrollbars
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            autoHeight
                            autoHeightMin={0}
                            autoHeightMax={200}
                            thumbMinSize={30}
                            >
                            {showDetails?.description}
                        </Scrollbars >
                    </Box>

                <Box>
                    <EpisodeListDataTable episodeListContent={episodeListContent} deleteAccess={user.permissions.series_delete} offsetValue={offsetValue} setOffsetValue={setOffsetValue} maxNumber={maxNotification} searchValue={searchValue} setSearchValue={setSearchValue} loader={loader} currentPage={currentPage}/>
                </Box>
            </Box>
            <GoToTop/>
        </>
    );
}

const showLogoBox = (value?: any) => {
    return (
        <>
            <Box className="showdetail__imgBox">
                <Box
                    component="img"
                    src={value}
                ></Box>
            </Box>
        </>
    )
}

const showCretorBox = (value?: any) => {
    return (
        <>
            <Box className='showdetail__area'>
                {value}
            </Box>
        </>
    )
}


const getHostsString = (showDetails: any, user:any) => {
    var hostsString = "";
    if (showDetails) {
        let hosts = showDetails.host
        hostsString = (hosts.map((host: any, index) => {
            return (
                <>
                <Box className='profie__circle__box' key={index}>
                    {
                        (user.permissions.creator) ?
                        <a href={"/admin/creators/"+host.user.id} >
                            <Box className='profie__circle__box--img'>
                                    <Box
                                    component="img"
                                    src = {(host.user.profile_pic_path) ? host.user.profile_pic_path : profilepicplaceholder}
                                    >
                                    </Box>
                                </Box>
                            <Box component='p' className="lmt-txt">
                                    {(host.user.firstName) ? host.user.firstName : host.user.lastName}
                            </Box>
                        </a>

                        :
                        <>
                            <Box className='profie__circle__box--img'>
                                    <Box
                                    component="img"
                                    src = {(host.user.profile_pic_path) ? host.user.profile_pic_path : profilepicplaceholder}
                                    >
                                    </Box>
                                </Box>
                            <Box component='p'>
                                    {(host.user.firstName) ? host.user.firstName : host.user.lastName}
                            </Box>
                        </>

                    }

                    </Box>
            </>
            )
        }))
    }
    return hostsString;
}


export default ShowDetail;
