import { UPDATE_SHOW_DATA, CLEAR_SHOW_DATA } from "../../constants";
import { SaveMatchDetailsAction, ClearShowDetaisAction, ShowInterface } from "./types";

export const saveShowDetails = (payload: ShowInterface) : SaveMatchDetailsAction => ({
    type: UPDATE_SHOW_DATA,
    payload: payload

});

export const clearShowDetails = () : ClearShowDetaisAction => ({
    type: CLEAR_SHOW_DATA
});
