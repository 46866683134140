import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Compressor from 'compressorjs';


import { Field, reduxForm } from 'redux-form';
import { TextArea, TextField } from '../common/TextField';
import {  renderHostsField, renderCoHostsField, renderSelectField } from '../form/Fields';
import { createShow } from '../../data/mappers/createPodcast';
import { RootStateType } from '../../store';
import { getRequest, postFileRequest, postRequest } from '../../data/helpers/services';
import { CREATOR_API, PODCAST_API, UPLOAD_FILE } from '../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import UploadIcon from '../../icons/UploadIcon';
import { Button } from '../common/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { HideProgressBar, ShowErrorAlert, ShowProgressBar } from '../../store/alerts';
import { ShowInterface } from '../../store/show';

import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

let orderArray: any[] = [];
for(let i=1; i<11; i++){
    orderArray.push({
        'name':i,
        'value':i
    });
}

interface host {
    id: number;
    handle: string;
    firstName: string;
    lastName: string;
    fullName: string;
}

const prepareHosts = (hosts:any, hostsList:any) => {
    let hostsListArray:host[] = [];
    let arrHostsIds:number[] = [];
    hosts.map((host:any) => arrHostsIds.push(host.user.id));
    hostsList.map((host:host) => {
        if(arrHostsIds.includes(host.id)) {
            hostsListArray.push(host);
        }
        return true;
    });
    return hostsListArray;
};


interface ShowCreateForm {
    title: string;
    showName: string;
    description: string;
    host: string;
    coHost: string;
    displayOrder: string;
    isTopPick: string;

}

const validate = (values: ShowCreateForm) => {
    const errors: any = {};

    if (!values.title || (values.title && !values.title.trim())) {
        errors.title = 'Field is required.';
    } else if (values.title.trim().length > 140) {
        errors.title = 'Field value must be 140 characters or less.';
    }

    if (!values.description || (values.description && !values.description.trim())) {
        errors.description = 'Field is required.';
    } else if (values.description.trim() && values.description.trim().length > 4096) {
        errors.description = 'Field value must be 4096 characters or less.';
    }
    return errors;
};


function AlertDialog(params, setShowAlert) {
    const [open, setOpen] = React.useState(true);
    let navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
        params.setShowAlert([false,{id:"0",title:""}]);
    };

    const renderList = params.params.map((param) =>
        <>
        <div className="row">
            <div onClick={() => navigate("/admin/show/"+(param.id),{replace: true})}>{param.title}</div>
            {/* <button className='deleteButton normal--btn cancel-class' onClick={() => navigate("/admin/show/"+(param.id),{replace: true})}>
                Details
            </button> */}
        </div>
        </>
    );

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-order"
          aria-describedby="alert-dialog-order"
        >
          <DialogTitle id="alert-dialog-title">
            {"Following show have similar display order"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-order">
                {renderList}
            </DialogContentText>
          </DialogContent>
            <button className='remove--pic' onClick={handleClose}>
                <CancelIcon />
            </button>
        </Dialog>
      </div>
    );
  }

interface AlertMessage {
    id: string;
    title: string;
}

const ShowCreateForm = (props: any) => {
    const { handleSubmit, change, submitting, reset, modeType, back  } = props;
    let token = useSelector((state: RootStateType) => state.user.access_token);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [thumbnailImage, setThumbnailImage] = useState('');
    const [featuredImage, setFeaturedImage] = useState('');
    const [hostValue, setHostValue] = useState<host|null>(null);
    const [coHostValue, setCoHostValue] = useState<host|null>(null);

    const { id } = useParams();
    const [showDetails, setShowDetails] = useState<ShowInterface | null>(null);

    const [fileUploading, setFileUploading] = useState(false);

    const [showAlert, setShowAlert] = useState<[boolean , AlertMessage[]]>([false,[{id:'0',title:""}]]);

    const [isTopPick, setIsTopPick] = useState("2");

    const [hostsList, setHostsList] = useState<host[]>([]);
    const [coHostsList, setCoHostsList] = useState<host[]>([]);


    const getShowDetails = async (id: string) => {
        dispatch(ShowProgressBar());

        await getHostsList();

        const response = await postRequest(
            PODCAST_API.SERIES_DETAIL,
            {
                seriesId: id,
            },
            token
        );

        if (response) {
            if (response.data.code === 200) {
                setShowDetails(response.data.data);
            }
        }
        dispatch(HideProgressBar());
    };

    const initForm = () => {
        setThumbnailImage("");
        setFeaturedImage("");
        setShowDetails(null);
        setHostValue(null);
        setCoHostValue(null);
        setHostsList([]);
        setCoHostsList([]);
        change('displayOrder','1')
    }

    const getHostsList = async () => {

        let response = await getRequest(CREATOR_API.LIST, token);

        if (response) {
            let hosts = response?.data?.data;
            let hostList: host[] = [];
            if (hosts && hosts.length) {
                hosts.map((host: host) => hostList.push({
                    id: host.id, handle: host.handle, fullName: ((host.firstName ?? "") + ' ' + (host.lastName ?? "")),
                    firstName: host.firstName,
                    lastName: host.lastName
                }));
            }
            setHostsList(hostList);

            let coHosts = response?.data?.data;
            let coHostList: host[] = [];
            if (coHosts && coHosts.length) {
                coHosts.map((host: host) => coHostList.push({
                    id: host.id, handle: host.handle, fullName: ((host.firstName ?? "") + ' ' + (host.lastName ?? "")),
                    firstName: host.firstName,
                    lastName: host.lastName
                }));
            }
            setCoHostsList(coHostList);
        } else {
            console.log('Unable to get list');
        }
    }

    useEffect(() => {
        initForm();
        getHostsList();
    }, []);

    useEffect(() => {
        if(modeType === 'add') {
            initForm();
        }
    }, [modeType]);

    useEffect(() => {

        if (id && modeType === 'edit') {
            getShowDetails(id);
        }
    }, [id]);

    useEffect(() => {
        if (showDetails && modeType === 'edit') {
            fillShowDetails(showDetails);
        }
    }, [showDetails]);

    const fillShowDetails = async (showDetails:any) => {
        change('id', showDetails?.id);

        change('title', showDetails?.title);
        change('description', showDetails?.description);

        setIsTopPick(showDetails?.isTopPick ? "1" :"2");
        change('displayOrder', showDetails?.displayOrder);

        setThumbnailImage(showDetails.logo);
        change('thumbnail', showDetails.logo);

        setFeaturedImage(showDetails.topPickLogo);
        change('featuredImage', showDetails.topPickLogo);


        if (showDetails.host) {
            let host = showDetails.host.filter((el)=>el.series_user_type === 'host' ? el : "");
            let hosts = prepareHosts(host, hostsList);
            change('host', hosts[0]);

            let coHostSelected = showDetails.host.filter((el)=>el.series_user_type === 'co_host' ? el : "");
            let cohosts = prepareHosts(coHostSelected, hostsList);
            change('coHost', cohosts);
            }
    }

    const handleHostKeyDown = (event: any) => {
        switch (event.key) {
            case ',':
            case 'Enter': {
                event.preventDefault();
                event.stopPropagation();
                break;
            }
            default:
                console.log('...');
        }
    };

    const handleCoHostKeyDown = (event: any) => {
        switch (event.key) {
            case ',':
            case 'Enter': {
                event.preventDefault();
                event.stopPropagation();
                break;
            }
            default:
                console.log('...');
        }
    };


    const validateBeforeAdd = (values: any) => {
        if (!values.host){
            dispatch(ShowErrorAlert({visible:true, message: "Host is required"}));
            return false;
        }
        else if (!values.thumbnail){
            dispatch(ShowErrorAlert({visible:true, message: "Show Logo is required"}));
            return false;
        }
        else if (isTopPick === "1"){
            if (!values.featuredImage){
                dispatch(ShowErrorAlert({visible:true, message: "Featured image is required"}));
                return false;
            }
            if (values.displayOrder === "0"){
                dispatch(ShowErrorAlert({visible:true, message: "Display order is required"}));
                return false;
            }
            return true
        }
        else{
            return true;
        }
    }

    const saveStream = async (values: any) => {
        let dataValidated = validateBeforeAdd(values);

        if (dataValidated) {
            dispatch(ShowProgressBar());

            let data = createShow(values, isTopPick);
            var response: any = null;

            if (showDetails) {
                response = await postRequest(PODCAST_API.SERIES_UPDATE, data, token);
            } else {
                response = await postRequest(PODCAST_API.SERIES_CREATE, data, token);
            }

            dispatch(HideProgressBar());
            if (response) {
                const resData = response.data;
                if (resData.code === 409){
                    setShowAlert([true,resData.data.existing_display_orders]);
                }
                else {
                    navigate('/admin/show/' + resData.data.id, { replace: true });
                }
            }
        }
    };


    const handleCompressedUpload = async(type:string, e: any ) => {
        const image = e.target.files[0];
        new Compressor(image, {
          quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
          success: async(res) => {

            let reader = new FileReader();
            reader.onload = (e: any) => {
                if(type === 'logo') {
                    setThumbnailImage(e.target.result);
                }
                else{
                    setFeaturedImage(e.target.result);
                }
            }
            reader.readAsDataURL(e.target.files[0]);

            setFileUploading(true);
            dispatch(ShowProgressBar());

            const formData = new FormData();

            formData.append('file', res, (Date.now()).toString() + e.target.files[0].name );

            const response: any = await postFileRequest(UPLOAD_FILE, formData, token);

            if (response) {
                const resData = response.data;
                if (resData.code === 200) {
                    let fileUrl = resData.data.file_path;
                    change( ((type==='logo')? 'thumbnail' : 'featuredImage'), fileUrl);
                }
            } else {
                ((type === 'logo') ? setThumbnailImage('') : setFeaturedImage(''))
            }

            dispatch(HideProgressBar());
            setFileUploading(false);
          },
        });
      };


    const handleFileSelect = async (event: any) => {
        if (event !== undefined && event.target.files && event.target.files[0]) {
            const filetype = event.target.files[0].type;

            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
            // if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 10000)) {
            if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 500)) {
                dispatch(ShowErrorAlert({ visible: true, message: 'Must be a .png, .jpg, .jpeg, .webp format image & not more than 500kb' }));
                event.target.value = null;
                return false;
            }
            handleCompressedUpload('logo', event)
        }
        dispatch(HideProgressBar());
        setFileUploading(false);
        return true;
    };

    const handleFeaturedFileSelect = async (event: any) => {
        if (event !== undefined && event.target.files && event.target.files[0]) {
            const filetype = event.target.files[0].type;

            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
            // if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 100000)) {
            if ((!validImageTypes.includes(filetype)) || ((event.target.files[0].size/1024) > 500)) {
                dispatch(ShowErrorAlert({ visible: true, message: 'Must be a .png, .jpg, .jpeg, .webp format image & not more than 500kb' }));
                event.target.value = null;
                return false;
            }
            handleCompressedUpload('featured', event);

        }
        dispatch(HideProgressBar());
        setFileUploading(false);
        return true;
    };

    const handleFileRemove = () => {
        setThumbnailImage("");
        change('thumbnail', "");
    }

    const handleFeaturedFileRemove = () => {
        setFeaturedImage("");
        change('featuredImage', "");
    }

    let nav:string = ""
    if (modeType === 'add'){
        nav = '/admin/show'
    }else{
        if (back === 'list'){
            nav = '/admin/show'
        }
        else if (back === 'detail'){
            nav = '/admin/show/' + id
        }
    }

    return (
        <>
        <Box className="dashboard__topbar">
            <Box className="dashboard__topbar--heading col-12">
                <Box className="row">
                    <button onClick={() => navigate(nav,{replace: true})}><ArrowBackOutlinedIcon /></button>
                    <Box component="h2">Show</Box>
                </Box>
            </Box>
        </Box>
        {
            (showAlert[0])
            ?
                <AlertDialog params={showAlert[1]} setShowAlert={setShowAlert}/>
            :
                ""
        }
        <Box className='dashboard__content max--760 mx--auto'>
            <Box component="form" onSubmit={handleSubmit(saveStream)}>
                <Box component="div" className="row">
                    <div className='col-12 md-col-12 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="title">Show Title (max 140 chars)</label>
                        <Field name="title" onChange={(e:any) => {
                            change('title', e.target.value);
                            change('showName', e.target.value);
                        }} component={TextField} type="text" placeholder='Write your title here'
                            //@ts-ignore
                            styleName='input--transparent' />
                    </div>

                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="podcast-host">Host</label>
                        <FormControl className='input-inner-transparent' fullWidth>
                            <Field name="host" component={renderHostsField} id="podcast-host" options={hostsList} defaultValue={' '} handleHostKeyDown={handleHostKeyDown} setHostValue={setHostValue} />
                        </FormControl>
                    </div>

                    <div className='col-12 md-col-6 m-b-20'>
                        <label className='input--label m-b-10' htmlFor="podcast-host">Co Host</label>
                        <FormControl className='input-inner-transparent' fullWidth>
                            <Field name="coHost" component={renderCoHostsField} id="podcast-co-host" options={coHostsList} defaultValue={' '} handleCoHostKeyDown={handleCoHostKeyDown} setCoHostValue={setCoHostValue} hostValue={hostValue}/>
                        </FormControl>
                    </div>


                    <div className='col-12 md-col-12 m-b-20 overflow--hidden'>
                        <label className='input--label m-b-10' htmlFor="userBio">Description (max 4096 chars)</label>
                        <Field name="description" component={TextArea} row='7' type="text" placeholder='Write description'
                            styleName='input--transparent' />
                    </div>
                    <div className="col-12 md-col-6">
                        <div className='row m-b-10 align--items--center'>
                            <div className='col-8'>
                                <label className='input--label' htmlFor="thumbnail">Logo (ratio: 1:1)</label>
                            </div>
                            <div className='text--end'>
                                <Tooltip title="Get your image ready by making sure it has right width, height, resolution, size, type. This protect's the image quality & prevents error during upload process. Recomended aspect ratio is 1:1 & less than 10 MB for best fit."
                                PopperProps={{
                                    sx: {
                                    "& .MuiTooltip-tooltip": {
                                        backgroundColor: '#191840',
                                        fontSize: '12px',
                                        padding: '12px',
                                        fontWeight: 600,
                                        border: '1px solid #F89E24'
                                    }
                                    }
                                }}
                                >
                                    <HelpOutlineIcon/>
                                </Tooltip>
                            </div>
                        </div>

                        <Field
                            name="thumbnail"
                            type="hidden"
                            component="input"
                            id="thumbnail"
                            placeholder="thumbnail"
                        />
                        <Field
                            name="id"
                            type="hidden"
                            component="input"
                            id="id"
                            placeholder="id"
                        />
                        {!thumbnailImage || thumbnailImage === '' ?
                            (<Box component="div"
                                sx={{
                                    height: '205px',
                                    maxWidth: '245px',
                                    width: '100%',
                                    background: '#232157',
                                    marginBottom: "20px",
                                    position: 'relative',
                                    borderRadius: "15px",
                                    cursor: "pointer"
                                }}
                            >
                                    <Box
                                        component="div"
                                        sx={{
                                            position: 'absolute',
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%,-50%)',
                                        }}
                                    >
                                        <UploadIcon />
                                    </Box>
                                    <Box
                                        component="input"
                                        type="file"
                                        name="thumbnail"
                                        onChange={handleFileSelect}
                                        sx={{
                                            height: '100%',
                                            width: '100%',
                                            opacity: '0',
                                            cursor: 'pointer',
                                        }}
                                    ></Box>
                                </Box>
                            ) : (
                                <>
                                    <Box className="img--uploadBg" sx={{
                                            height: '205px',
                                            maxWidth: '245px',
                                            width: '100%',
                                    }}>

                                        {submitting || fileUploading ?
                                        '' : <Button type="button" styleName="remove--pic" onClick={() => handleFileRemove()}>
                                        <CancelIcon />
                                    </Button>}
                                        <Box
                                            component="img"
                                            src={thumbnailImage}
                                            sx={{
                                                maxHeight: '100%',
                                                maxWidth: '100%',
                                                objectFit: 'contain',
                                                borderRadius: '15px',
                                                margin: 'auto'
                                            }}
                                        ></Box>
                                    </Box>
                                </>
                            )}
                        </div>

                        <div className="col-12 md-col-6">
                            {
                                (isTopPick == "1")
                                ?
                                <>
                                <div className='row m-b-10 align--items--center'>
                                    <div className='col-9'>
                                        <label className='input--label' htmlFor="thumbnail">Featured Image(ratio: 2:1)</label>
                                    </div>
                                    <div className='col-3 text--end'>
                                        <Tooltip title="Get your image ready by making sure it has right width, height, resolution, size, type. This protect's the image quality & prevents error during upload process. Recomended aspect ratio is 2:1 & less than 10 MB for best fit."
                                        PopperProps={{
                                            sx: {
                                            "& .MuiTooltip-tooltip": {
                                                backgroundColor: '#191840',
                                                fontSize: '12px',
                                                padding: '12px',
                                                fontWeight: 600,
                                                border: '1px solid #F89E24'
                                            }
                                            }
                                        }}
                                        >
                                        <HelpOutlineIcon/>
                                        </Tooltip>
                                    </div>
                                </div>

                                <Field
                                    name="featuredImage"
                                    type="hidden"
                                    component="input"
                                    id="featuredImage"
                                    placeholder="featuredImage"
                                />
                                <Field
                                    name="id"
                                    type="hidden"
                                    component="input"
                                    id="id"
                                    placeholder="id"
                                />
                                {!featuredImage || featuredImage === '' ?
                                    (<Box component="div"
                                        sx={{
                                            height: '205px',
                                            width: '100%',
                                            background: '#232157',
                                            marginBottom: "20px",
                                            position: 'relative',
                                            borderRadius: "15px",
                                            cursor: "pointer"
                                        }}
                                    >
                                            <Box
                                                component="div"
                                                sx={{
                                                    position: 'absolute',
                                                    left: '50%',
                                                    top: '50%',
                                                    transform: 'translate(-50%,-50%)',
                                                }}
                                            >
                                                <UploadIcon />
                                            </Box>
                                            <Box
                                                component="input"
                                                type="file"
                                                name="featuredImage"
                                                onChange={handleFeaturedFileSelect}
                                                sx={{
                                                    height: '100%',
                                                    width: '100%',
                                                    opacity: '0',
                                                    cursor: 'pointer',
                                                }}
                                            ></Box>
                                        </Box>
                                    ) : (
                                        <>
                                        <Box
                                            style={{
                                                background: '#232157',
                                                padding: '15px',
                                                marginBottom: "20px",
                                                borderRadius: "15px",
                                                minHeight: '200px'
                                            }}
                                            >
                                            <Box className="ratio2-1">

                                                {submitting || fileUploading ?
                                                '' : <Button type="button" styleName="remove--pic" style={{
                                                    right: '-25px',
                                                    top: '-25px'
                                                }} onClick={() => handleFeaturedFileRemove()}>
                                                <CancelIcon />
                                            </Button>}
                                                <Box
                                                    component="img"
                                                    src={featuredImage}
                                                    sx={{
                                                        maxHeight: '100%',
                                                        maxWidth: '100%',
                                                        // width: '100%',
                                                        // height: '100%',
                                                        objectFit: 'contain',
                                                        borderRadius: '15px',
                                                        margin: 'auto'
                                                    }}
                                                ></Box>
                                            </Box>
                                        </Box>
                                        </>
                                    )}
                                </>
                                :
                                ""
                            }
                            </div>


                    <div className='col-12 md-col-6 m-b-20'>
                        <FormControl>
                            <label className='input--label' htmlFor="top-pick-setting-label">Is Top Pick ?</label>
                            <RadioGroup
                                className='radio--select'
                                aria-labelledby="top-pick-setting-label"
                                name="top-pick-setting-buttons-group"
                                value={isTopPick}
                                onChange={(e: any) => setIsTopPick(e.target.value)}
                            >
                                <Box className='col-6'>
                                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                </Box>
                                <Box className='col-6'>
                                    <FormControlLabel value="2" control={<Radio />} label="No" />
                                </Box>
                            </RadioGroup>
                        </FormControl>
                    </div>


                    <div className='col-12 md-col-6 m-b-20'>
                        {
                        (isTopPick == "1")
                        ?
                        <>
                        <label className='input--label m-b-10' htmlFor="display-order-select">Display at</label>
                        <FormControl className="input-inner-dropdown"  fullWidth>
                            <Field name="displayOrder" component={renderSelectField} labelId="dislay-order-select-label" id="display-order-select" defaultValue="0" options={orderArray} />
                        </FormControl>
                        </>
                        :
                        ""
                        }

                    </div>

                        <div className="col-12 m-b-40">
                            <button type="submit" className={`btn--pill saveBtn`} disabled={submitting || fileUploading}>
                                {showSubmitButtonLabel(submitting, fileUploading, modeType)}
                            </button>
                        </div>
                    </Box>
                </Box>
        </Box>
        </>
    );
};

const showSubmitButtonLabel = (submitting: boolean, fileUploading: boolean, modeType:string) => {
    if (submitting) {
        return 'Saving...';
    } else if (fileUploading) {
        return 'Saving Photo...';
    } else {
        if (modeType === 'edit'){
            return `Save`;
        }
        return `Add New Show`;
    }
};

export default reduxForm({
    form: 'show',
    validate,
})(ShowCreateForm);

