import { stringify } from 'querystring';
import { Reducer } from 'react';
import { RESET,UPDATE_SHOW_LIST_CONTENT_DATA, DELETE_SHOW_STATUS , UPDATE_SHOW_STATUS, SAVE_SEARCHED_SHOW, ADD_SHOW_LIST_CONTENT_DATA, SAVE_SHOW_CURRENT_PAGE, SAVE_TOTAL_SHOW} from '../../constants';
import { ShowListActions, showListState } from './types';

export const initialState: showListState = {
    showListContent: [],
    searchedValue: "",
    currentPage: 0,
    totalShow: 0
};

export const showListReducer: Reducer<showListState, ShowListActions> = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SHOW_LIST_CONTENT_DATA:
            return {
                ...state,
                showListContent: [...(action?.data || [])],
                // searchedValue : ""
            };

        case ADD_SHOW_LIST_CONTENT_DATA:
            return {
                ...state,
                showListContent: state.showListContent.concat(action?.data),
            };

        case UPDATE_SHOW_STATUS:
            return {
                ...state,
                showListContent: state.showListContent.map(show => {
                    if (show.id === action.data) {
                        show.isActive = !show.isActive
                        return {...show}
                    };
                    return show;
                }),
                // searchedValue : state.searchedValue
            }

        case DELETE_SHOW_STATUS:
            return {
                ...state,
                showListContent: state.showListContent.filter((items) => items?.id !== action?.data),
            }

        case SAVE_SEARCHED_SHOW:
            return {
                ...state,
                searchedValue : action.data
            };


        case SAVE_SHOW_CURRENT_PAGE:
            return {
                ...state,
                currentPage : action.data
            };

        case SAVE_TOTAL_SHOW:
            return {
                ...state,
                totalShow : action.data
            };

        case RESET:
            return {
                ...initialState //Always return the initial state
            };
        default:
            return state;
    }
};
