import { Reducer } from "react";
import { CREATOR_LOGGED_IN, CREATOR_LOGGED_OUT } from "../../constants";
import { CreatorLoginActions, creatorState } from "./types";

export const initialState:creatorState = {
    id: 0,
    handle: "",
    firstName: "",
    lastName: "",
    email: "",
    access_token : "",
    role: "",
    profile_pic_path: "",
    bio: "",
    email_verified: false,
    isProfileCreated: false
}


export const creatorReducer: Reducer<creatorState, CreatorLoginActions> = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case CREATOR_LOGGED_IN:
            return {
                // @ts-ignore
                ...payload.creator,
            };
        case CREATOR_LOGGED_OUT:
            return {
                ...initialState
            };
        default:
            return state;
    }
};
