import { Reducer } from 'react';
import {
    UPDATE_CONSUMER_LIST_CONTENT_DATA,
    ADD_CONSUMER_LIST_CONTENT_DATA,
    UPDATE_CONSUMER_ACCOUNT_STATUS,
    SAVE_CONSUMER_SEARCH_TEXT,
    SAVE_CONSUMER_CURRENT_PAGE,
    SAVE_TOTAL_CONSUMER,
    RESET
    } from '../../constants';

import { consumerListActions, consumerListState } from './types';

export const initialState: consumerListState = {
    consumerListContent: [],
    searchedValue: "",
    currentPage: 0,
    totalConsumer: 0
};

export const consumerListReducer: Reducer<consumerListState, consumerListActions> = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CONSUMER_LIST_CONTENT_DATA:
            return {
                ...state,
                consumerListContent: [...(action?.data || [])],
            };

        case ADD_CONSUMER_LIST_CONTENT_DATA:
            return {
                ...state,
                // creatorListContent: [...state.creatorListContent, ...(action?.data || [])],
                consumerListContent: state.consumerListContent.concat(action?.data),
            };

        case UPDATE_CONSUMER_ACCOUNT_STATUS:
            return {
                ...state,
                consumerListContent: state.consumerListContent.map(creator => {
                    if (creator.id === action.data) {
                        creator.status = !creator.status
                        return {...creator}
                    };
                    return creator;
                })
            }

        case SAVE_CONSUMER_SEARCH_TEXT:
            return {
                ...state,
                // creatorListContent: state.creatorListContent,
                searchedValue : action.data
            };

        case SAVE_CONSUMER_CURRENT_PAGE:
            return {
                ...state,
                currentPage : action.data
            };

        case SAVE_TOTAL_CONSUMER:
            return {
                ...state,
                totalConsumer : action.data
            };

        case RESET:
            return {
                ...initialState //Always return the initial state
            };

        default:
            return state;
    }
};
